import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { updateRemovedFromInventory } from "../../api/apiInvoice";
interface ModalProps {
  show: any;
  onHide: any;
  invoiceId: any;
  payeeNumber: any;
  vehiclePlate: any;
  vin: any;
  vehicleDetails: any;
  setInventoryList: any;
  inventoryList: any;
  toastMessage: any;
}
export function ModalInventoryRemove(props: ModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="RemoveInventory"
        >
          <h4>Remove From Inventory</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="RemoveInventoryBody">
          Are you sure you want to remove this vehicle from inventory?
        </div>
        <p />
        <div className="RemoveInventoryVehicle">
          Plate: <span>{props.vehiclePlate}</span>
        </div>
        <div className="RemoveInventoryVehicle">
          VIN: <span>{props.vin}</span>
        </div>
        <div className="RemoveInventoryVehicle">
          Description:
          <span>{props.vehicleDetails}</span>
        </div>
      </Modal.Body>

      <Modal.Footer className="left">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={HandleCancel}
          data-testid="noButton"
        >
          No
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={HandleConfirm}
          data-testid="yesButton"
        >
          {isLoading && <Spinner animation="border" role="status" />}
          {!isLoading && "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleCancel(event: any) {
    props.onHide(false);
    setIsLoading(false);
    props.toastMessage("");
  }

  async function HandleConfirm() {
    setIsLoading(true);
    var result: any = await updateRemovedFromInventory(
      props.invoiceId,
      true, // removedFromInventory
      props.payeeNumber
    );
    if (result.status === 200) {
      props.setInventoryList([
        ...props.inventoryList.filter(
          (s: any) => s.invoiceId !== props.invoiceId
        ),
      ]);
      props.toastMessage(
        `Vehicle ${
          props.vehiclePlate !== "" ? props.vehiclePlate : props.vin
        } is removed from inventory.`
      );
    }
    props.onHide(false);
    setIsLoading(false);
  }
}
