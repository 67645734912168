import React, { useEffect } from "react";
import { useState } from "react";
import {
	Col,
	Container,
	Form,
	Row,
	Table,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { getRatesWithinEffectiveDateApi } from "../../api/ratesApi";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { WeightClass } from "../../types/LineItems";
import { mapLineTypeToEnum } from "../../utils/RateDataTransformer";
import {
	convertDateToStringFormat,
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";

export default function EmployeeRateHistory() {
	const todayDate = new Date();
	const { screenSize } = useWindowDimensions();
	const defaultRateList: any[] = [];
	const defaultWeightClass = 0;
	const [towDate, setTowDate] = useState(todayDate);
	const [towRates, setTowRates] = useState(defaultRateList);
	const [loading] = useState(false);
	const [selectedWeightClass, setSelectedWeightClass] =
		useState(defaultWeightClass);

	function trimDateString(date: string) {
		return date.substring(0, date.length - 10);
	}

	async function getApiRates(date: any) {
		const result = await getRatesWithinEffectiveDateApi(
			convertDateToStringFormat(date)
		);

		if (result.status === 200) {
			setTowRates(result.data);
		}
	}

	function onChangeServiceDate(event: any) {
		setTowDate(event);
		if (event) {
			getApiRates(event);
		}
	}

	function RateHistoryRow(col: any, towrate: any) {
		if (col.isRateObj) {
			return (
				<td key={col.dataField}>
					{towrate[col.dataField] != null && (
						<div>
							<div>
								Base Rate: ${towrate[col.dataField]?.baseRate}
								{"\n"}
							</div>
							{towrate[col.dataField]?.minCharge !== 0 && (
								<div>
									Min. Charge: ${towrate[col.dataField]?.minCharge}
									{"\n"}
								</div>
							)}
							{towrate[col.dataField]?.maxCharge !== 0 && (
								<div>
									Max. Charge: ${towrate[col.dataField]?.maxCharge}
									{"\n"}
								</div>
							)}
							{towrate[col.dataField]?.hourlyRate !== 0 && (
								<div>
									Hourly Rate: ${towrate[col.dataField]?.hourlyRate}
									{"\n"}
								</div>
							)}
							{towrate[col.dataField]?.increment !== 0 && (
								<div>
									Increment: ${towrate[col.dataField]?.increment}
									{"\n"}
								</div>
							)}
						</div>
					)}
				</td>
			);
		} else if (col.tranformData != null) {
			return (
				<td key={col.dataField}>{col.tranformData(towrate[col.dataField])}</td>
			);
		} else {
			return <td key={col.dataField}>{towrate[col.dataField]}</td>;
		}
	}

	const columns = [
		{
			dataField: "title",
			text: "Title",
			hideForMobile: false,
		},
		{
			dataField: "effdt",
			text: "Effective Date",
			hideForMobile: true,
			tranformData: trimDateString,
		},
		{
			dataField: "lineType",
			text: "Line Type",
			hideForMobile: true,
			headerClasses: "width10",
			tranformData: mapLineTypeToEnum,
		},
		{
			dataField: "rate",
			text: "Rates",
			hideForMobile: false,
			isRateObj: true,
		},
	];

	useEffect(() => {
		getApiRates(todayDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <PageSpinner />;
	} else
		return (
			<Container className="CustomTableContainer">
				<Row>
					<Col lg="8">
						<div className="Title">
							<h3>Rate History</h3>
						</div>
					</Col>
					<Col lg="4">
						<Form.Group controlId="rateFormServiceDate">
							<Form.Label className="formLabelText">
								Date of Tow Service
							</Form.Label>
							<br />
							<DatePicker
								data-testid="rateFormServiceDate"
								onChange={onChangeServiceDate}
								maxDate={new Date("2099/12/31")}
								minDate={new Date("1950/12/31")}
								name="serviceDate"
								calendarAriaLabel={"serviceDate"}
								yearAriaLabel="serviceDateYear"
								monthAriaLabel="serviceDateMonth"
								dayAriaLabel="serviceDateDay"
								clearAriaLabel="serviceDateClearButton"
								value={towDate}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<ToggleButtonGroup
						type="radio"
						name="options"
						defaultValue={defaultWeightClass}
						onChange={setSelectedWeightClass}
						value={selectedWeightClass}
						data-testid="weightClassSelector"
					>
						<ToggleButton
							id="tbg-radio-1"
							value={WeightClass.light}
							variant="outline-secondary"
							data-testid="weightClass-light"
						>
							Light Duty
						</ToggleButton>
						<ToggleButton
							id="tbg-radio-2"
							value={WeightClass.medium}
							variant="outline-secondary"
							data-testid="weightClass-medium"
						>
							Medium Duty
						</ToggleButton>
						<ToggleButton
							id="tbg-radio-3"
							value={WeightClass.heavy}
							variant="outline-secondary"
							data-testid="weightClass-heavy"
						>
							Heavy Duty
						</ToggleButton>
					</ToggleButtonGroup>
				</Row>
				<Row>
					<Table>
						<thead data-testid="RateListLineHeader">
							{screenSize >= ScreenSize.md ? (
								<tr>
									{columns.map((col: any) => {
										return <th key={col.dataField}>{col.text}</th>;
									})}
								</tr>
							) : (
								<tr>
									{columns
										.filter((i) => !i.hideForMobile)
										.map((col: any) => {
											return <th key={col.dataField}>{col.text}</th>;
										})}
								</tr>
							)}
						</thead>
						<tbody data-testid="RateListLineItems">
							{towRates
								.filter((i) => i.weightClass === selectedWeightClass)
								.map((towrate: any) => {
									return (
										<React.Fragment
											key={towrate.systemName + towrate.weightClass}
										>
											{
												<tr key={towrate.systemName + towrate.weightClass}>
													{screenSize >= ScreenSize.md
														? columns.map((col: any) => {
																return RateHistoryRow(col, towrate);
														  })
														: columns
																.filter((i) => !i.hideForMobile)
																.map((col: any) => {
																	return RateHistoryRow(col, towrate);
																})}
												</tr>
											}
										</React.Fragment>
									);
								})}
						</tbody>
					</Table>
				</Row>
			</Container>
		);
}
