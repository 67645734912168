import { iInvoice, iInvoiceIdentifier } from "../types/Invoice";
import { iLineItem } from "../types/LineItems";

export interface LineDifference {
  name: string;
  id: string;
  diffType: DifferenceType;
  current?: iLineItem;
  previous?: iLineItem;
}

export enum DifferenceType {
  Change,
  Added,
  Deleted,
}

export function sortInvoiceVersions(versions: iInvoice[]): iInvoice[] {
  // return the collection of versions in descending order: current version in index 0, previous in index 1, etc.

  // look at the versionTimestamp field (comes from RowKey)
  return versions.sort((a, b) => {
    var aDateTime = new Date(
      parseVersionTimestamp(a.invoiceDetails?.versionTimestamp)
    );
    var bDateTime = new Date(
      parseVersionTimestamp(b.invoiceDetails?.versionTimestamp)
    );

    return aDateTime < bDateTime ? 1 : -1;
  });
}

export function sortInvoiceIdentifierVersions(versions: iInvoiceIdentifier[]): iInvoiceIdentifier[] {
  // return the collection of versions in descending order: current version in index 0, previous in index 1, etc.

  // look at the versionTimestamp field (comes from RowKey)
  return versions.sort((a, b) => {
    var aDateTime = new Date(
      parseVersionTimestamp(a.versionTimestamp)
    );
    var bDateTime = new Date(
      parseVersionTimestamp(b.versionTimestamp)
    );

    return aDateTime < bDateTime ? 1 : -1;
  });
}

export function sortLineDiffs(
  lineDiffs: LineDifference[],
  lineItems: iLineItem[]
): LineDifference[] {
  // return the collection of line diffs matching the existing line item order, followed by deleted lines

  var sortedDiffs: LineDifference[] = [];

  // go through the existing line items array, if you find a match, add
  lineItems.forEach((lineItem) => {
    var index = lineDiffs.findIndex((x) => x.id === lineItem.id);

    if (index > -1) {
      sortedDiffs.push(lineDiffs[index]);
    }
  });

  // go through the line diffs provided and add all deletes (in the order they appear is fine)
  lineDiffs.forEach((lineDiff) => {
    if (lineDiff.diffType === DifferenceType.Deleted) {
      sortedDiffs.push(lineDiff);
    }
  });

  return sortedDiffs;
}

function parseVersionTimestamp(versionTimestamp: string | null): Date {
  var year = versionTimestamp?.substr(0, 4) ?? "";
  var month = versionTimestamp?.substr(5, 2) ?? "";
  var day = versionTimestamp?.substr(8, 2) ?? "";

  var hours = versionTimestamp?.substr(11, 2) ?? "";
  var minutes = versionTimestamp?.substr(14, 2) ?? "";
  var seconds = versionTimestamp?.substr(17, 2) ?? "";

  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
    parseInt(seconds)
  );
}

export default function diffInvoiceLines(
  currentLines: iLineItem[],
  previousLines: iLineItem[]
): LineDifference[] {
  var resultSet: LineDifference[] = [];

  currentLines.forEach((line: iLineItem) => {
    var index = previousLines.findIndex((x) => x.id === line.id);
    // compare rows in both
    if (index !== -1) {
      // see if there are differences in the line
      if (LineItemDifferent(line, previousLines[index])) {
        var result: LineDifference = {
          name: line.name,
          id: line.id,
          diffType: DifferenceType.Change,
          current: line,
          previous: previousLines[index],
        };

        resultSet.push(result);
      }
    }
    // rows in currently only (user added a row)
    else {
      result = {
        name: line.name,
        id: line.id,
        diffType: DifferenceType.Added,
        current: line,
        previous: undefined,
      };

      resultSet.push(result);
    }
  });

  previousLines.forEach((line: iLineItem) => {
    var index = currentLines.findIndex((x) => x.id === line.id);
    // rows in previously only (user deleted a row)
    if (index === -1) {
      var result: LineDifference = {
        name: line.name,
        id: line.id,
        diffType: DifferenceType.Deleted,
        current: undefined,
        previous: line,
      };

      resultSet.push(result);
    }
  });

  return resultSet;
}

function LineItemDifferent(
  currentLineItem: iLineItem,
  previousLineItem: iLineItem
): boolean {
  // already know the lines are the same type.  need to check qty, lineData, otherDescription & lineTotal
  if (currentLineItem.qty !== previousLineItem.qty) {
    return true;
  }

  if (!isEquivalent(currentLineItem.lineData, previousLineItem.lineData)) {
    return true;
  }

  if (
    currentLineItem.lineData.otherDescription !==
    previousLineItem.lineData.otherDescription
  ) {
    return true;
  }

  if (currentLineItem.lineTotal !== previousLineItem.lineTotal) {
    return true;
  }
  return false;
}

function isEquivalent(a: any, b: any) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let row of aProps) {
    // If values of same property are not equal,
    // objects are not equivalent
    if (a[row] !== b[row]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}
