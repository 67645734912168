import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { getConfig } from "./utils/auth/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ai } from "./AppInsights";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

var clientSideID: string;

declare global {
  interface Window {
    REACT_APP_API_BASEURL: string;
    REACT_APP_MSAL_CLIENT_ID: string;
    REACT_APP_MSAL_TENANT_ID: string;
    REACT_APP_BASEURL: string;
    REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
    REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
    REACT_APP_MSAL_EDITPROFILE_POLICY: string;
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
    REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
    REACT_APP_BFF_API_SCOPES: string;
    REACT_APP_APPINSIGHTS_INSTR_KEY: string;
    LAUNCH_DARKLY_CLIENTID: string;
  }
}

fetch("/config.json")
  .then((response) => response.json())
  .then((data) => {
    (window as any)["runConfig"] = data;
    ai.initialize(
      (window as any)["runConfig"]?.REACT_APP_APPINSIGHTS_INSTR_KEY ??
        process.env.REACT_APP_APPINSIGHTS_INSTR_KEY
    ).then(({ appInsights, reactPlugin }) => {
      clientSideID = data.LAUNCH_DARKLY_CLIENTID;
      renderApp(reactPlugin);
    });
  });

async function renderApp(reactPlugin?: ReactPlugin) {
  const msalInstance = new PublicClientApplication(getConfig());
  var LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideID ?? process.env.LAUNCH_DARKLY_CLIENTID,
  });
  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AppInsightsContext.Provider value={reactPlugin!}>
          <MsalProvider instance={msalInstance}>
            <Router>
              <Provider store={store}>
                <App />
              </Provider>
            </Router>
          </MsalProvider>
        </AppInsightsContext.Provider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
