import { iLineItem, LineType } from "../types/LineItems";

export function rateDataTransformer(apiRateData: any): iLineItem[] {
  var returnData: iLineItem[] = [];

  // map api data received
  apiRateData.data.forEach((rateItem: any) => {
    var newReturnData: iLineItem = {
      name: rateItem.systemName,
      title: rateItem.title,
      description: rateItem.description,
      isStandard: rateItem.isStandard,
      lineType: mapLineTypeToEnum(rateItem.lineType),
      lineData: {},
      qty: rateItem.lineType === 0 ? 0 : undefined,
      lineTotal: 0,
      rate: returnRateObject(rateItem),
      id: "",
      allowMultiple: rateItem.allowMultiple,
      weightClass: rateItem.weightClass,
    };

    returnData.push(newReturnData);
  });

  return returnData;
}
export function returnLineDataObject(rateItem: any): any {
  if (rateItem.lineType === "Per Day with date range and maximum") {
    return {
      fromDate: rateItem.lineData.fromDate ? rateItem.lineData.fromDate : null,
      toDate: rateItem.lineData.toDate ? rateItem.lineData.toDate : null,
    };
  }
  if (rateItem.lineType === "Other") {
    return {
      otherDescription: rateItem.lineData.otherDescription
        ? rateItem.lineData.otherDescription
        : null,
    };
  } else return {};
}
export function returnRateObject(rateItem: any): any {
  var lineEnum: any = mapLineTypeToEnum(rateItem.lineType);
  switch (lineEnum) {
    case LineType.FlatRate:
    case LineType.PerKM:
    case LineType.PerMin:
    case LineType.PerHr:
    case LineType.PerDay:
    case LineType.PerAmount:
    case LineType.PerWheel:
      return {
        baseRate: rateItem.rate.baseRate,
        maxCharge: rateItem.rate.maxCharge,
      };

    case LineType.PerDayFromToDatesWithMax:
      return {
        baseRate: rateItem.rate.baseRate,
        maxCharge: rateItem.rate.maxCharge,
      };
    case LineType.ProRatedMinutes:
      return {
        baseRate: rateItem.rate.baseRate,
        minCharge: rateItem.rate.minCharge,
        increment: rateItem.rate.increment,
        hourlyRate: rateItem.rate.hourlyRate,
        maxCharge: rateItem.rate.maxCharge,
      };
    case LineType.ProRatedIntervalMinutes:
      return {
        baseRate: rateItem.rate.baseRate,
        increment: rateItem.rate.increment,
        intervals: rateItem.rate.intervals,
      };
    default:
      return {};
  }
}

export function mapLineTypeToEnum(lineType: any): LineType {
  switch (lineType) {
    case 0:
    case "": {
      return LineType.FlatRate;
    }
    case 1:
    case "Per kilometer": {
      return LineType.PerKM;
    }
    case 2:
    case "Minutes": {
      return LineType.PerMin;
    }
    case 3:
    case "Per Hour": {
      return LineType.PerHr;
    }
    case 4:
    case "Per Day": {
      return LineType.PerDay;
    }
    case 5:
    case "Per Day with date range and maximum": {
      return LineType.PerDayFromToDatesWithMax;
    }
    case 6:
    case "Pro-Rated Minutes With Min/Max": {
      return LineType.ProRatedMinutes;
    }
    case 7:
    case "Pro-Rated Minutes With Intervals": {
      return LineType.ProRatedIntervalMinutes;
    }
    case 8:
    case "Other": {
      return LineType.Other;
    }
    case 9:
    case "Percentage of an Amount": {
      return LineType.PerAmount;
    }
    case 10:
    case "Per Wheel": {
      return LineType.PerWheel;
    }
    default: {
      return LineType.FlatRate;
    }
  }
}
