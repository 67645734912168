import React from "react";
import { Button, Modal, Row, Container } from "react-bootstrap";
import { iInvoiceDetails } from "../../types/Invoice";
import VehicleInfoDisplayOnly from "../VehicleInfoDisplayOnly/VehicleInfoDisplayOnly";
import "../TowerInformation/TowerInformation.css";
import "./Modal.css";

interface ModalProps {
  show: any;
  onHide: any;
  title: string;
  invoiceDetails: iInvoiceDetails;
  buttonYesText: string;
  buttonNoText: string;
  onYesButtonClick: any;
  onNoButtonClick: any;
}

export function ModalSubsequentConfirmation(props: ModalProps) {
  return (
    <>
      <Modal
        className="ModalConfirmation modalSubsequent"
        data-testid="modalSubsequent"
        show={props.show}
        onHide={props.onHide}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="confirmedModal">
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="confirmedModal">
          <div>
            The claim number matches a previously submitted invoice. Is this a
            subsequent tow?
            <br></br>
            <br />
            <Container>
              <Row xs={2} sm={2} md={4} lg={4}>
                <VehicleInfoDisplayOnly invoiceDetails={props.invoiceDetails} />
              </Row>
            </Container>
          </div>
        </Modal.Body>
        <Modal.Footer className="confirmedModal">
          <Button
            data-testid="subsequentModalYes"
            variant="outline-primary"
            onClick={(e) => {
              if (props.onHide) {
                props.onHide(e);
              }
              if (props.onYesButtonClick) {
                props.onYesButtonClick();
              }
            }}
          >
            {props.buttonYesText}
          </Button>
          <Button
            data-testid="subsequentModalNo"
            variant="outline-primary"
            onClick={(e) => {
              if (props.onHide) {
                props.onHide(e);
              }
              if (props.onNoButtonClick) {
                props.onNoButtonClick();
              }
            }}
          >
            {props.buttonNoText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
