import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./EmployeeInvoiceSummaryDetails.css";
import { ModalClaimNumber } from "../Modals/ModalClaimNumber";
import { EmployeeSummaryLineItems } from "./EmployeeSummaryLineItems";
import { EmployeeSummaryFields } from "./EmployeeSummaryFields";
import { EmployeeSummaryPhotos } from "./EmployeeSummaryPhotos";
import { EmployeeSummaryLineDiffs } from "./EmployeeSummaryLineDiffs";
import { ReasonForDeletionDisplay } from "../ReasonForDeletionDisplay/ReasonForDeletionDisplay";
import { ModalVehiclePlate } from "../Modals/ModalVehiclePlate";
import { ModalEditVinNumber } from "../Modals/ModalEditVinNumber";

export function EmployeeInvoiceSummaryDetails(props: any) {
	const [modalShow, setModalShow] = useState(false);
	const [modalVehiclePlateShow, setModalVehiclePlateShow] = useState(false);
	const [modalVehicleVinShow, setModalVehicleVinShow] = useState(false);

	return (
		<>
			<ModalClaimNumber
				show={modalShow}
				onHide={setModalShow}
				invoiceSummary={props.invoiceSummary}
				setInvoiceSummary={props.setInvoiceSummary}
				setNewVersionCreated={props.setNewVersionCreated}
			/>
			<ModalVehiclePlate
				show={modalVehiclePlateShow}
				onHide={setModalVehiclePlateShow}
				invoiceSummary={props.invoiceSummary}
				setInvoiceSummary={props.setInvoiceSummary}
				setNewVersionCreated={props.setNewVersionCreated}
			/>
			<ModalEditVinNumber
				show={modalVehicleVinShow}
				onHide={setModalVehicleVinShow}
				invoiceSummary={props.invoiceSummary}
				setInvoiceSummary={props.setInvoiceSummary}
				setNewVersionCreated={props.setNewVersionCreated}
			/>
			{props.invoiceSummary.invoiceDetails.status === 6 && (
				<div className="deletionDisplayContainer">
					<ReasonForDeletionDisplay
						reasonForDeletion={
							props.invoiceSummary.invoiceDetails.reasonForDeletion
						}
						deletionReasonDetails={
							props.invoiceSummary.invoiceDetails.deletionReasonDetails
						}
					/>
				</div>
			)}
			<Container className="invoiceSummaryDetailsContainer">
				<EmployeeSummaryFields
					invoiceSummary={props.invoiceSummary}
					displayAsEditableClaim={props.displayAsEditableClaim}
					disableEditClaim={props.disableEditClaim}
					sortedInvoiceVersions={props.sortedInvoiceVersions}
					setModalShow={setModalShow}
					setModalVehiclePlateShow={setModalVehiclePlateShow}
					setModalVehicleVinShow={setModalVehicleVinShow}
					setVersionTimeStamp={props.setVersionTimeStamp}
					showVersionTimeStamp={props.showVersionTimeStamp}
					setShowVersionTimeStamp={props.setShowVersionTimeStamp}
					viewingCurrentVersion={props.viewingCurrentVersion}
				/>
				<EmployeeSummaryLineItems
					invoiceSummary={props.invoiceSummary}
					annotations={props.annotations}
					setAnnotations={props.setAnnotations}
					displayAsEditable={props.displayAsEditable}
					disableEdit={props.disableEdit}
					lineDiffs={props.lineDiffs}
					viewingCurrentVersion={props.viewingCurrentVersion}
				/>
				<EmployeeSummaryLineDiffs
					lineDiffs={props.lineDiffs}
					annotations={props.annotations}
				/>
				<EmployeeSummaryPhotos
					invoiceSummary={props.invoiceSummary}
					showFullSizePhotos={props.showFullSizePhotos}
					setShowFullSizePhotos={props.setShowFullSizePhotos}
					isLoadingFullSizePhotos={props.isLoadingFullSizePhotos}
					setIsLoadingFullSizePhotos={props.setIsLoadingFullSizePhotos}
					setToastErrors={props.setToastErrors}
					viewingCurrentVersion={props.viewingCurrentVersion}
				/>
			</Container>
		</>
	);
}
