import { initialAssignedToUser } from "../types/Invoice";
import { iInvoiceList } from "../types/InvoiceList";

export function mapToIInvoiceList(apiData: any): iInvoiceList[] {
	var returnData: iInvoiceList[] = [];

	// map api data received
	apiData.forEach((item: any) => {
		var assignedToSGIEmployee = initialAssignedToUser;

		if (item.assignedToSGIEmployee != null) {
			try {
				assignedToSGIEmployee = JSON.parse(item.assignedToSGIEmployee);
			} catch {}
		}
		var newReturnData: iInvoiceList = {
			vendorId: item.vendorId,
			invoiceId: item.invoiceId,
			towerReference: item.towerReference,
			vehiclePlate: item.vehiclePlate,
			claimNumber: item.claimNumber,
			requestedPayment: item.requestedPayment,
			submittedDate: item.submittedDate,
			status: item.status,
			statusDisplay: item.statusDisplay,
			lineItems: item.lineItems,
			paidDate: item.paidDate,
			assignedToSGIEmployee: assignedToSGIEmployee,
			payeeName: item.payeeName,
			deletedDate: item.deletedDate,
			gisSearchDate: item.gisSearchDate,
		};

		returnData.push(newReturnData);
	});

	return returnData;
}
