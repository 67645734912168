import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createOrEditVendor, getVendorByVendorId } from "../../api/apiVendor";
import { useTypedSelector } from "../../redux/store";
import { defaultVendor } from "../../types/Vendor";
import { ModalMap } from "./CompoundModalMap";
import { Map, PencilSquare } from "react-bootstrap-icons";
import {
	Button,
	Col,
	Container,
	Form,
	OverlayTrigger,
	Row,
	Spinner,
	Tooltip,
} from "react-bootstrap";
import { Error } from "../../components/Alerts/Error";
import { useLoadScript } from "@react-google-maps/api";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { CompoundInput } from "./CompoundMapSearch";
import {
	distanceInKmBetweenEarthCoordinates,
	formatPhoneNumber,
	useWindowDimensions,
} from "../../utils/Utils";
import { getMapsZoomLevel } from "../../utils/MapUtils";
import "./VendorDetails.css";
import { ModalVendorDetailChangeConfirmation } from "../../components/Modals/ModalVendorDetailChangeConfirmation";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";
import {
	validateCompanyName,
	validateEmail,
	validatePayeeNumber,
	validatePhoneNumber,
} from "../../utils/Validators/VendorProfileValidator";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";

export default function VendorDetails(props: any) {
	const login = useTypedSelector((state) => state.login);
	const [libraries]: any = useState(["places"]);
	const { vendorId } = useParams<{ vendorId: string }>();

	const history = useHistory();
	const [compoundErrorMessage, setCompoundErrorMessage] = useState("");
	const [changesConfirmed, setChangesConfirmed] = useState(false);
	const [vendorDetails, setVendorDetails] = useState(defaultVendor);
	const [areaBoundsCompoundLocation, setAreaBoundsCompoundLocation] = useState({
		northEast: { lat: 0, lng: 0 },
		southWest: { lat: 0, lng: 0 },
	});
	const { width } = useWindowDimensions();
	const [preciseCompoundPosition, setPreciseCompoundPosition] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalChangeConfirmationShow, setModalChangeConfirmationShow] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [currentlySavedPayeeNumber, setCurrentlySavedPayeeNumber] =
		useState("");
	const [currentlySavedCompoundLocation, setCurrentlySavedCompoundLocation] =
		useState("");
	const [changedFields, setChangedFields] = useState({
		payeeNumber: { changed: false, oldValue: "", newValue: "" },
		compoundLocation: { changed: false, oldValue: "", newValue: "" },
	});
	const [apiUnavailable, setApiUnavailable] = useState(false);
	const [payeeNumberEditable, setPayeeNumberEditable] = useState(false);
	const [compoundLocationEditable, setCompoundLocationEditable] =
		useState(false);
	const [isValid, setIsValid] = useState(true);
	const [showAllFieldErrors, setShowAllFieldErrors] = useState(false);
	const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");
	const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
	const [payeeNumberErrorMessage, setPayeeNumberErrorMessage] = useState("");
	const [emailErrorMessage, setEmailErrorMessage] = useState("");

	const ValidatorFunctions: ValidatorFunction[] = useMemo(
		() => [
			{
				fieldname: "payeeNumber",
				validator: validatePayeeNumber,
				stateSetter: setPayeeNumberErrorMessage,
			},
			{
				fieldname: "companyName",
				validator: validateCompanyName,
				stateSetter: setCompanyNameErrorMessage,
			},
			{
				fieldname: "phoneNumber",
				validator: validatePhoneNumber,
				stateSetter: setPhoneNumberErrorMessage,
			},
			{
				fieldname: "email",
				validator: validateEmail,
				stateSetter: setEmailErrorMessage,
			},
		],
		[]
	);
	const loadData = useCallback(async () => {
		if (vendorId) {
			var vendor: any;
			try {
				vendor = await getVendorByVendorId(vendorId);
			} catch {
				setApiUnavailable(true);
			}
			if (vendor) {
				setVendorDetails({
					...vendor.data,
					phoneNumber: formatPhoneNumber(vendor.data.phoneNumber),
				});
				setCurrentlySavedPayeeNumber(vendor.data.payeeNumber);
				setCurrentlySavedCompoundLocation(
					vendor.data.compoundLocation?.placeId
				);
			} else {
				setApiUnavailable(true);
			}
		}
	}, [vendorId]);

	useEffect(() => {
		if (login.username !== "") {
			loadData();
		}
	}, [login, loadData]);

	function HandleChange(event: any) {
		const { name, value } = event.target;
		let fieldErrorMessage = "Error";
		var validator = ValidatorFunctions.find(
			(element) => element.fieldname === name
		);
		if (validator !== undefined) {
			fieldErrorMessage = validator.validator(value);
			validator.stateSetter(fieldErrorMessage);
			setIsValid(false);
		}
		if (name === "payeeNumber") {
			setChangedFields({
				...changedFields,
				payeeNumber: {
					...changedFields.payeeNumber,
					changed:
						currentlySavedPayeeNumber && value !== currentlySavedPayeeNumber
							? true
							: false,
					oldValue: currentlySavedPayeeNumber,
					newValue: value,
				},
			});
			setVendorDetails({
				...vendorDetails,
				payeeNumber: event.target.value,
			});
		}

		setVendorDetails({
			...vendorDetails,
			[name]: value,
		});
	}

	useEffect(() => {
		let allFieldsValid = true;
		let fieldErrorMessage = "";

		ValidatorFunctions.forEach((f) => {
			fieldErrorMessage = f.validator(vendorDetails[f.fieldname]);
			if (fieldErrorMessage !== "") {
				allFieldsValid = false;
			}
		});
		if (compoundErrorMessage !== "") {
			allFieldsValid = false;
		}
		setIsValid(allFieldsValid);
	}, [ValidatorFunctions, compoundErrorMessage, vendorDetails]);

	useEffect(() => {
		if (showAllFieldErrors) {
			ValidatorFunctions.forEach((f) => {
				let fieldErrorMessage: string = f.validator(vendorDetails[f.fieldname]);
				f.stateSetter(fieldErrorMessage);
			});
		}
	}, [showAllFieldErrors, ValidatorFunctions, vendorDetails]);

	function customZoomLevel(): number {
		let distance = distanceInKmBetweenEarthCoordinates(
			areaBoundsCompoundLocation.northEast.lat,
			areaBoundsCompoundLocation.northEast.lng,
			areaBoundsCompoundLocation.southWest.lat,
			areaBoundsCompoundLocation.southWest.lng
		);
		return getMapsZoomLevel(distance);
	}

	async function HandleSave() {
		if (isValid) {
			if (
				changedFields.payeeNumber.changed === true ||
				changedFields.compoundLocation.changed === true
			)
				setModalChangeConfirmationShow(true);
			else setChangesConfirmed(true);
		} else {
			setShowAllFieldErrors(true);
		}
	}

	useEffect(() => {
		const saveDataAndClose = async () => {
			setIsLoading(true);
			await createOrEditVendor(vendorDetails);
			setIsLoading(false);
			history.goBack();
		};

		if (changesConfirmed) {
			saveDataAndClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [changesConfirmed]);

	const { isLoaded, loadError } = useLoadScript({
		libraries,
		googleMapsApiKey: (window as any)["apiConfig"]?.googleMapsApiData,
	});

	if (loadError) {
		return <>Error Loading Google Maps</>;
	} else if (apiUnavailable) {
		return <ApiUnavailable />;
	} else if (!isLoaded) return <PageSpinner />;
	else
		return (
			<>
				<Container>
					<ModalVendorDetailChangeConfirmation
						show={modalChangeConfirmationShow}
						onHide={() => setModalChangeConfirmationShow(false)}
						vendorDetailChangeField={changedFields}
						setChangesConfirmed={setChangesConfirmed}
						setModalChangeConfirmationShow={setModalChangeConfirmationShow}
					/>
					<ModalMap
						title={"Select a location"}
						heading={"Select Compound Location"}
						body={"body"}
						show={modalShow}
						onHide={() => setModalShow(false)}
						vendorDetails={vendorDetails}
						setVendorDetails={setVendorDetails}
						setErrorMessage={setCompoundErrorMessage}
						autoZoom={customZoomLevel()}
						precisePosition={preciseCompoundPosition}
						setPrecisePosition={setPreciseCompoundPosition}
						changedFields={changedFields}
						setChangedFields={setChangedFields}
						currentlySavedCompoundLocation={currentlySavedCompoundLocation}
					/>
					<Row>
						<div className="vendorDetailsHeader">
							<h4>Vendor Details</h4>
						</div>
					</Row>
					<Row>
						<Col>
							<Form.Group controlId="companyName">
								<Form.Label className="formLabelText">Company Name</Form.Label>
								<Form.Control
									type="text"
									placeholder=""
									onChange={(e) => {
										HandleChange(e);
									}}
									data-testid="companyName"
									className="vendorCompanyName"
									name="companyName"
									value={vendorDetails.companyName}
								/>
							</Form.Group>
							{companyNameErrorMessage !== "" && (
								<Error message={companyNameErrorMessage} />
							)}
						</Col>
						<Col>
							<Form.Group controlId="payeeNumber">
								<Form.Label className="formLabelText">Payee Number</Form.Label>
								<div className="inline">
									<Form.Control
										type="text"
										placeholder=""
										onChange={(e) => {
											HandleChange(e);
										}}
										data-testid="payeeNumber"
										className="vendorPayee"
										name="payeeNumber"
										value={vendorDetails.payeeNumber}
										disabled={!payeeNumberEditable}
									/>
									&nbsp;
									<Button
										id="payeeNumberEditButton"
										data-testid="payeeNumberEditButton"
										variant="link"
										name="payeeNumberEditButton"
										onClick={() => {
											setPayeeNumberEditable(!payeeNumberEditable);
										}}
									>
										<PencilSquare
											className="editIcon"
											size={20 + Math.min(width / 100, 10)}
										/>
									</Button>
								</div>
								{payeeNumberErrorMessage !== "" && (
									<Error message={payeeNumberErrorMessage} />
								)}
							</Form.Group>
						</Col>
					</Row>
					<Form.Group controlId="address">
						<Form.Label className="formLabelText">Address</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							onChange={(e) => {
								HandleChange(e);
							}}
							className="vendorAddress"
							data-testid="address"
							name="address"
							value={vendorDetails.address}
						/>
					</Form.Group>
					<Form.Group controlId="phoneNumber">
						<Form.Label className="formLabelText">Phone Number</Form.Label>
						<Form.Control
							type="text"
							placeholder="(999) 999-9999"
							onChange={(e) => {
								HandleChange(e);
							}}
							className="vendorPhone"
							data-testid="phoneNumber"
							name="phoneNumber"
							value={vendorDetails.phoneNumber}
						/>
						{phoneNumberErrorMessage !== "" && (
							<Error message={phoneNumberErrorMessage} />
						)}
					</Form.Group>
					<Form.Group controlId="email">
						<Form.Label className="formLabelText">Email</Form.Label>
						<Form.Control
							type="text"
							placeholder=""
							onChange={(e) => {
								HandleChange(e);
							}}
							className="vendorEmail"
							data-testid="email"
							name="email"
							value={vendorDetails.email}
						/>
						{emailErrorMessage !== "" && <Error message={emailErrorMessage} />}
					</Form.Group>

					<Form.Group controlId="formEntryCompoundLocation">
						<Form.Label className="formLabelText">
							Compound Location{" "}
							<OverlayTrigger
								overlay={<Tooltip id="mapTooltip">Open Map</Tooltip>}
								transition={false}
							>
								{({ ref, ...triggerHandler }) => (
									<Button
										{...triggerHandler}
										ref={ref}
										variant="outline-primary"
										onClick={() => {
											setModalShow(true);
										}}
										data-testid="compoundLocationButton"
										disabled={!compoundLocationEditable}
									>
										<Map size={20 + Math.min(width / 100, 10)} />
									</Button>
								)}
							</OverlayTrigger>{" "}
							<Button
								id="compoundLocationEditButton"
								data-testid="compoundLocationEditButton"
								variant="link"
								name="compoundLocationEditButton"
								onClick={() => {
									setCompoundLocationEditable(!compoundLocationEditable);
								}}
							>
								<PencilSquare
									className="editIcon"
									size={20 + Math.min(width / 100, 10)}
								/>
							</Button>
						</Form.Label>
						<CompoundInput
							name="CompoundLocation"
							className="compoundAddress"
							vendorDetails={vendorDetails}
							setVendorDetails={setVendorDetails}
							errorMessage={compoundErrorMessage}
							setErrorMessage={setCompoundErrorMessage}
							setAreaBounds={setAreaBoundsCompoundLocation}
							setPrecisePosition={setPreciseCompoundPosition}
							compoundLocationEditable={compoundLocationEditable}
							changedFields={changedFields}
							setChangedFields={setChangedFields}
							currentlySavedCompoundLocation={currentlySavedCompoundLocation}
						/>

						{compoundErrorMessage !== "" && (
							<Error message={compoundErrorMessage} />
						)}
					</Form.Group>
					<Row className="right">
						<Col></Col>
						<Col></Col>
						<Col>
							<Button
								id="cancelButton"
								data-testid="cancelButton"
								variant="outline-primary"
								onClick={() => {
									history.goBack();
								}}
							>
								Cancel
							</Button>
							&nbsp;&nbsp;&nbsp;
							<Button
								variant="outline-primary"
								onClick={HandleSave}
								data-testid="saveVendorDetailsButton"
							>
								{isLoading && <Spinner animation="border" role="status" />}
								{!isLoading && "Save"}
							</Button>
							{!isValid && showAllFieldErrors && (
								<Error message="Field corrections required before saving" />
							)}
						</Col>
					</Row>
				</Container>
			</>
		);
}
