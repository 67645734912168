export interface CompoundLocation {
  streetAddress: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  placeId: string;
  googleResults: {
    address: CompoundLocationAddress;
    formattedAddressFull: string;
    locationTypes: string;
    placeId: string;
    latitude: number;
    longitude: number;
    isSelectedByPin: boolean;
  };
}

export interface CompoundLocationAddress {
  streetNumber: string;
  street: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
}

export function getCompoundLocationDisplayAddress(
  compoundLocation: CompoundLocation
): string {
  if (
    compoundLocation &&
    compoundLocation.googleResults.address.streetNumber &&
    compoundLocation.googleResults.address.street &&
    compoundLocation.googleResults.address.city
  )
    return (
      compoundLocation.googleResults.address.streetNumber +
      " " +
      compoundLocation.googleResults.address.street +
      ", " +
      compoundLocation.googleResults.address.city
    );
  else if (compoundLocation)
    return compoundLocation.googleResults.formattedAddressFull;
  else return "";
}

export const defaultNewCompoundLocationAddress: CompoundLocationAddress = {
  streetNumber: "",
  street: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
};

export const defaultNewCompoundLocation: CompoundLocation = {
  streetAddress: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  placeId: "",
  googleResults: {
    address: defaultNewCompoundLocationAddress,
    formattedAddressFull: "",
    locationTypes: "",
    placeId: "",
    latitude: 0,
    longitude: 0,
    isSelectedByPin: false,
  },
};
