import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Table } from "react-bootstrap";
import { iLineItem, LineItemsWithDecimalValues } from "../../types/LineItems";
import { SpecialItem } from "./SpecialItem";
import { calcRate } from "../../utils/rateCalc";
import { convertDateToStringFormat, getDiffDays } from "../../utils/Utils";

export function SpecialItemsTable(props: any) {
	const removeLineItem = (value: number) => () => {
		let newSpecialLineItems = [...props.specialLineItems];
		newSpecialLineItems.splice(value, 1);
		props.setSpecialLineItems(newSpecialLineItems);
	};

	function onChangeDatePicker(
		dateRange: any,
		lineItemName: string,
		lineItemId: string
	) {
		var dayQty = 0;
		if (
			dateRange &&
			dateRange[0] &&
			dateRange[1] &&
			getDiffDays(dateRange[0], dateRange[1]) >= 0
		)
			dayQty = getDiffDays(dateRange[0], dateRange[1]) + 1;

		var fromDate = null;

		if (dateRange && dateRange[0])
			fromDate = convertDateToStringFormat(dateRange[0]);

		var toDate = null;
		if (dateRange && dateRange[1])
			toDate = convertDateToStringFormat(dateRange[1]);

		let lineOptionChanged = {
			...props.lineOptions.filter((l: iLineItem) => {
				return l.name === lineItemName;
			})[0],
		};

		let lineAlreadySelected =
			props.specialLineItems.filter((l: iLineItem) => l.id === lineItemId)
				.length > 0;

		if (lineAlreadySelected) {
			let lineItemChanged = {
				...props.specialLineItems.filter((l: iLineItem) => {
					return l.id === lineItemId;
				})[0],
			};

			lineItemChanged.lineData = {
				...lineItemChanged.lineData,
				fromDate: fromDate,
				toDate: toDate,
			};
			lineItemChanged.qty = dayQty;
			lineItemChanged.lineTotal = calcRate(lineItemChanged);
			props.setSpecialLineItems(
				props.specialLineItems.map((l: iLineItem) =>
					l.id === lineItemId
						? {
								...l,
								qty: lineItemChanged.qty,
								lineData: lineItemChanged.lineData,
								lineTotal: lineItemChanged.lineTotal,
						  }
						: l
				)
			);
		} else {
			//Line is not selected
			let lineItemChanged = { ...lineOptionChanged };
			lineItemChanged.lineData = {
				fromDate: fromDate,
				toDate: toDate,
			};
			lineItemChanged.qty = dayQty;
			lineItemChanged.lineTotal = calcRate(lineItemChanged);
			props.setSpecialLineItems(props.specialLineItems.concat(lineItemChanged));
		}
	}

	function updateLineItemDecimalValue(lineItem: iLineItem, isIncrement: boolean) {
		let lineItemChanged = props.specialLineItems.filter((l: iLineItem) => {
			return l.id === lineItem.id;
		})[0];
		
		if(LineItemsWithDecimalValues.includes(lineItemChanged.name)) {
			if(!isIncrement && lineItemChanged.qty > 0) lineItemChanged.qty = lineItemChanged.qty - 0.50;
			else lineItemChanged.qty = lineItemChanged.qty + 0.50;

			lineItemChanged.lineTotal = calcRate(lineItemChanged);
			
			props.setSpecialLineItems(
				props.specialLineItems.map((l: iLineItem) =>
					l.id === lineItem.id
						? {
								...l,
								qty: lineItemChanged.qty,
								lineTotal: lineItemChanged.lineTotal,
							}
						: l
				)
			);
		}
		
	}

	function updateLineQuantity(event: any) {
		const { value, id } = event.target;
		var lineItemChanged: iLineItem;
		if (/^[0-9]{0,9}(?:\.(?:[0-9]{0,2}))?$/u.test(value)) {
			lineItemChanged = props.specialLineItems.filter((lineItem: iLineItem) => {
				return lineItem.id === id;
			})[0];
			if (lineItemChanged.lineType === "Percentage of an Amount") {
				lineItemChanged.qty = value;
			} else {
				lineItemChanged.qty = parseInt(value === "" ? 0 : value);
			}

			lineItemChanged.lineTotal = calcRate(lineItemChanged);

			props.setSpecialLineItems(
				props.specialLineItems.map((l: iLineItem) =>
					l.id === id
						? {
								...l,
								qty: lineItemChanged.qty,
								lineTotal: lineItemChanged.lineTotal,
						  }
						: l
				)
			);
		}
	}

	function updateLineTotal(value: string, id: string) {
		if (/^[0-9]{0,9}(?:\.(?:[0-9]{0,2}))?$/u.test(value)) {
			props.setSpecialLineItems(
				props.specialLineItems.map((l: iLineItem) =>
					l.id === id
						? {
								...l,
								qty: value,
								lineTotal: parseFloat(
									value === undefined || value === "" ? "0.0" : value
								),
						  }
						: l
				)
			);
		}
	}

	function updateOtherDescription(event: any) {
		const { value, id } = event.target;

		props.setSpecialLineItems(
			props.specialLineItems.map((l: iLineItem) =>
				l.id === id
					? {
							...l,
							lineData: {
								...l.lineData,
								otherDescription: value,
							},
					  }
					: l
			)
		);
	}

	return (
		<>
			{props.specialLineItems.length !== 0 && (
				<Table striped bordered hover id="SpecialItemTable">
					<thead>
						<tr key="title">
							<th></th>
							<th>Work Item</th>
							<th>Rates</th>
							{/* <th className="right"></th> */}
							<th className="right">Total</th>
						</tr>
					</thead>
					<tbody key="tbody">
						{props.specialLineItems
							.filter((i: iLineItem) => !i.isStandard)
							.map((line: iLineItem, index: number) => {
								return (
									<tr key={line.name + index}>
										<td>
											<div onClick={removeLineItem(index)}>
												<FontAwesomeIcon
													icon={faMinus}
													data-testid={line.name + line.id + "removeButton"}
												/>
											</div>
										</td>
										<SpecialItem
											item={line}
											index={index}
											selectedLineItems={props.specialLineItems}
											updateLineQuantity={updateLineQuantity}
											updateLineItemDecimalValue={updateLineItemDecimalValue}
											updateLineTotal={updateLineTotal}
											updateOtherDescription={updateOtherDescription}
											updateDate={onChangeDatePicker}
										/>
									</tr>
								);
							})}
					</tbody>
				</Table>
			)}
		</>
	);
}
