import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { postFeedback } from "../../api/apiFeedback";
import { FeedbackType } from "../../types/Feedback";
import { validateFeedback } from "../../utils/Validators/FeedbackValidator";
import { Error } from "../Alerts/Error";
interface ModalProps {
  show: any;
  onHide: any;
  toastMessage: any;
  email: any;
  name: any;
}
export function ModalFeedback(props: ModalProps) {
  const [feedbackContents, setFeedbackContents] = useState("");
  const [feedbackType, setFeedbackType] = useState("General Feedback");
  var errorExists = false;
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState("");

  function handleError(feedbackContents: string) {
    var errorMessage = "";
    errorMessage = validateFeedback(feedbackContents);
    if (errorMessage !== "") {
      setFeedbackErrorMessage(errorMessage);
      errorExists = true;
    } else {
      setFeedbackErrorMessage("");
      errorExists = false;
    }
  }

  function handleOnChange(event: any) {
    const { name, value } = event.target;

    if (name === "feedbackType") {
      setFeedbackType(value);
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Please provide feedback/comments</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formEntryFeedbackType">
          <Form.Label className="formLabelText">Feedback Type</Form.Label>
          <Form.Control
            as="select"
            placeholder=""
            data-testid="formEntryFeedbackType"
            onChange={handleOnChange}
            onBlur={handleOnChange}
            name="feedbackType"
            defaultValue="General Feedback"
          >
            {Object.keys(FeedbackType).map((feedbackType, index) => {
              return (
                <option key={index}>
                  {Object.values(FeedbackType)[index]}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="feedbackContents">
          <Form.Control
            as="textarea"
            rows={4}
            maxLength={500}
            type="textarea"
            placeholder=""
            onChange={(e) => {
              HandleChange(e);
            }}
            data-testid="feedbackContents"
            name="feedbackContents"
            value={feedbackContents}
          />
        </Form.Group>
      </Modal.Body>
      {feedbackErrorMessage !== "" && <Error message={feedbackErrorMessage} />}
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleOnCancel}
          data-testid="cancelFeedbackButton"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={HandleSend}
          data-testid="sendFeedbackButton"
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleChange(event: any) {
    setFeedbackContents(event.target.value);
    handleError(event.target.value);
  }

  function HandleOnCancel(event: any) {
    setFeedbackContents("");
    props.onHide(false);
    props.toastMessage("");
    setFeedbackErrorMessage("");
  }

  async function HandleSend() {
    handleError(feedbackContents);
    if (!errorExists) {
      const feedback = {
        content: feedbackContents,
        email: props.email,
        feedbackType: feedbackType,
        name: props.name,
      };

      await postFeedback(feedback);
      setFeedbackContents("");
      setFeedbackType("General Feedback");
      props.onHide(false);
      props.toastMessage("Thank you for your Feedback");
      setFeedbackErrorMessage("");
    }
  }
}
