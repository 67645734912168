import {
	Annotation,
	AnnotationStatus,
	defaultNewInvoiceDetails,
	emptyAnnotations,
	iInvoice,
	iInvoiceDetails,
	iInvoiceIdentifier,
	initialAssignedToUser,
	ReasonForDeletion,
	ReasonForNoVehiclePlate,
	ReasonForNoVIN,
	ReasonForTow,
	SGI_PICK_UP,
} from "../types/Invoice";
import { iLineItem } from "../types/LineItems";
import { Photo } from "../types/Photo";
import { returnLineDataObject, returnRateObject } from "./RateDataTransformer";
import { getLocationObject } from "../types/Location";
import { v4 as uuidv4 } from "uuid";
import { getEnumKeyByEnumValue } from "./Utils";

export function invoiceDataTransformer(apiInvoiceData: any): iInvoice {
	var returnData: iInvoice = {} as iInvoice;
	returnData.invoiceLineItems = [];
	returnData.invoiceAnnotations = [];

	var locations = JSON.parse(apiInvoiceData.data.locations);
	var towedFrom = getLocationObject("towedFrom", locations);
	var towedTo = getLocationObject("towedTo", locations);

	var assignedToSGIEmployee = initialAssignedToUser;

	if (apiInvoiceData.data.assignedToSGIEmployee != null) {
		try {
			assignedToSGIEmployee = JSON.parse(
				apiInvoiceData.data.assignedToSGIEmployee
			);
		} catch { }
	}

	returnData.invoiceDetails = {
		id: apiInvoiceData.data.invoiceId,
		vendorId: apiInvoiceData.data.vendorId,
		plate: apiInvoiceData.data.vehiclePlate,
		reasonForNoVehiclePlate:
			ReasonForNoVehiclePlate[
			getEnumKeyByEnumValue(
				ReasonForNoVehiclePlate,
				apiInvoiceData.data.reasonForNoVehiclePlate
			)
			],
		vehicleYear: String(apiInvoiceData.data.vehicleYear),
		vehicleMake: apiInvoiceData.data.vehicleMake,
		vehicleModel: apiInvoiceData.data.vehicleModel,
		vehicleVIN: apiInvoiceData.data.vehicleVIN || "",
		vehicleColour: apiInvoiceData.data.vehicleColour,
		driveable: apiInvoiceData.data.driveable,
		keys: apiInvoiceData.data.keys,
		towerReference: apiInvoiceData.data.towerReference,

		serviceDate: apiInvoiceData.data.serviceDate,

		towerName: apiInvoiceData.data.towerName,
		claimNumber: apiInvoiceData.data.claimNumber,
		vendorPlate: apiInvoiceData.data.vendorPlate,
		requestedPayment: apiInvoiceData.data.requestedPayment,

		policeFile: apiInvoiceData.data.policeFile,
		policeDept: apiInvoiceData.data.policeDept,
		towedFromAccident: apiInvoiceData.data.towedFromAccident,
		status: apiInvoiceData.data.status,
		statusDisplay: apiInvoiceData.data.statusDisplay,
		reasonForTow:
			apiInvoiceData.data.reasonForTow === SGI_PICK_UP
				? SGI_PICK_UP
				: ReasonForTow[
				getEnumKeyByEnumValue(
					ReasonForTow,
					apiInvoiceData.data.reasonForTow
				)
				],
		locations: {
			towedFrom: towedFrom,
			towedTo: towedTo,
		},
		photos: transformThumbnails(JSON.parse(apiInvoiceData.data.photos)),
		photosBase64: apiInvoiceData.data.photos,
		photoGuid: apiInvoiceData.data.photoGuid,
		reasonForNoVIN:
			ReasonForNoVIN[
			getEnumKeyByEnumValue(
				ReasonForNoVIN,
				apiInvoiceData.data.reasonForNoVIN
			)
			] || "",
		noVINReasonDetails: apiInvoiceData.data.noVINReasonDetails || "",
		vinScanned: apiInvoiceData.data.vinScanned || false,
		vinDecoded: apiInvoiceData.data.vinDecoded || false,
		vinData: apiInvoiceData.data.vinData
			? JSON.parse(apiInvoiceData.data.vinData)
			: null,

		createdBy: apiInvoiceData.data.createdBy
			? JSON.parse(apiInvoiceData.data.createdBy)
			: null,
		submittedBy: apiInvoiceData.data.submittedBy
			? JSON.parse(apiInvoiceData.data.submittedBy)
			: null,
		submittedDate: apiInvoiceData.data.submittedDate,
		assignedToSGIEmployee: assignedToSGIEmployee,
		paidDate: apiInvoiceData.data.paidDate,
		payeeNumber: apiInvoiceData.data.payeeNumber,
		payeeName: apiInvoiceData.data.payeeName,
		versionTimestamp: apiInvoiceData.data.versionDateTime,
		removedFromInventory: apiInvoiceData.data.removedFromInventory,
		subsequentTow: apiInvoiceData.data.subsequentTow || false,
		weightClass: apiInvoiceData.data.weightClass,
		reasonForDeletion:
			ReasonForDeletion[
			getEnumKeyByEnumValue(
				ReasonForDeletion,
				apiInvoiceData.data.reasonForDeletion
			)
			],
		deletionReasonDetails: apiInvoiceData.data.deletionReasonDetails,
		deletedDate: apiInvoiceData.data.deletedDate,
	};

	var lineItems = JSON.parse(apiInvoiceData.data.lineItems);
	for (var i in lineItems) {
		var returnLineItem: iLineItem = {
			name: lineItems[i].name,
			title: lineItems[i].title,
			description: lineItems[i].description,
			isStandard: lineItems[i].isStandard,
			lineType: lineItems[i].lineType,
			lineData: returnLineDataObject(lineItems[i]),
			qty: lineItems[i].qty,
			lineTotal: lineItems[i].lineTotal,
			rate: returnRateObject(lineItems[i]),
			id: lineItems[i].id ? lineItems[i].id : uuidv4().substring(0, 8),
			allowMultiple: lineItems[i].allowMultiple,
			weightClass: lineItems[i].weightClass,
		};
		returnData.invoiceLineItems.push(returnLineItem);
	}

	var annotations = apiInvoiceData.data.annotations
		? JSON.parse(apiInvoiceData.data.annotations)
		: emptyAnnotations;

	for (var j in annotations) {
		var newAnnotationStatus: AnnotationStatus = AnnotationStatus.Draft;
		switch (apiInvoiceData.data.status) {
			case 0:
			case 1:
			case 2:
				newAnnotationStatus = AnnotationStatus.Draft;
				break;
			case 3:
				newAnnotationStatus = AnnotationStatus.Requested;
				break;
			case 4:
			case 5:
				newAnnotationStatus = AnnotationStatus.Complete;
				break;
		}
		var returnAnnotations: Annotation = {
			name: annotations[j].name,
			id: annotations[j].id,
			title: annotations[j].title,
			annotationText: annotations[j].annotationText,
			annotatedValue: annotations[j].annotatedValue,
			rejectedComment: annotations[j].rejectedComment,
			status: annotations[j].status ?? newAnnotationStatus,
		};
		returnData.invoiceAnnotations.push(returnAnnotations);
	}

	return returnData;
}

export function invoiceIdentifierDataTransformer(apiInvoiceData: any): iInvoiceIdentifier {
	return {
		id: apiInvoiceData.data.invoiceId,
		status: apiInvoiceData.data.status,
		versionTimestamp: apiInvoiceData.data.versionDateTime,
		statusDisplay: apiInvoiceData.data.statusDisplay
	} as iInvoiceIdentifier;
}

export function combineInvoicesForSubsequentTow(
	invoiceDetails: iInvoiceDetails,
	data: any
) {
	var locations = JSON.parse(data.locations);
	var towedTo = getLocationObject("towedTo", locations);
	return {
		...invoiceDetails,
		plate: data.vehiclePlate,
		reasonForNoVehiclePlate:
			ReasonForNoVehiclePlate[
			getEnumKeyByEnumValue(
				ReasonForNoVehiclePlate,
				data.reasonForNoVehiclePlate
			)
			],
		vehicleYear: data.vehicleYear ? String(data.vehicleYear) : "",
		vehicleMake: data.vehicleMake,
		vehicleModel: data.vehicleModel,
		vehicleColour: data.vehicleColour,
		vehicleVIN: data.vehicleVIN,
		reasonForNoVIN:
			ReasonForNoVIN[
			getEnumKeyByEnumValue(ReasonForNoVIN, data.reasonForNoVIN)
			],
		noVINReasonDetails: data.noVINReasonDetails || "",
		vinScanned: data.vinScanned || false,
		vinDecoded: data.vinDecoded || false,
		vinData: data.vinData ? JSON.parse(data.vinData) : null,
		driveable: data.driveable,
		keys: data.keys,
		locations: {
			towedFrom: towedTo,
			towedTo: invoiceDetails.locations.towedTo,
		},
		weightClass: data.weightClass,
	};
}

export function clearSubsequentTowData(invoiceDetails: iInvoiceDetails) {
	var d = defaultNewInvoiceDetails;

	return {
		...invoiceDetails,
		claimNumber: d.claimNumber,
		plate: d.plate,
		reasonForNoVehiclePlate: d.reasonForNoVehiclePlate,
		vehicleYear: d.vehicleYear,
		vehicleMake: d.vehicleMake,
		vehicleModel: d.vehicleModel,
		vehicleColour: d.vehicleColour,
		vehicleVIN: d.vehicleVIN,
		reasonForNoVIN: d.reasonForNoVIN,
		noVINReasonDetails: d.noVINReasonDetails,
		vinScanned: d.vinScanned || false,
		vinDecoded: d.vinDecoded || false,
		vinData: d.vinData,
		driveable: d.driveable,
		keys: d.keys,
		locations: {
			towedFrom: d.locations.towedFrom,
			towedTo: invoiceDetails.locations.towedTo,
		},
		weightClass: d.weightClass,
	};
}

function transformThumbnails(photos: any[]): Photo[] {
	const emptyPhotoArray: Photo[] = [];
	let photoObjects = emptyPhotoArray;

	photos.forEach(async (photo: any, index: number) => {
		let thumb = dataURLtoFile(photo.thumbnail, photo.name);

		const newPhoto: Photo = {
			id: photo.id,
			name: photo.name,
			size: photo.size,
			type: photo.type,
			thumbnail: thumb,
		};

		photoObjects.push(newPhoto);
	});

	return photoObjects;
}

function dataURLtoFile(dataUrl: any, filename: any) {
	var arr = dataUrl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
