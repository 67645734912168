import React, { useEffect } from "react";
// @ts-ignore
import { Switch } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./theme.css";
import "./custom-theme.css";
import "./App.css";
import { useTypedSelector } from "./redux/store";
import PublicRoutes from "./Routes/PublicRoutes";
import { useDispatch } from "react-redux";
import { setConnectionStatus } from "./redux/actions/connectionStatusActions";
import { appInsights } from "./AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import PrivateRoutes from "./Routes/PrivateRoutes";

function App() {
  const reduxLogin = useTypedSelector((state) => state.login);

  const reduxConnectionStatus = useTypedSelector(
    (state) => state.connectionStatus
  );
  const dispatch = useDispatch();

  function handleOnlineEvent() {
    if (reduxConnectionStatus.connected === false)
      dispatch(setConnectionStatus(true));
    if (appInsights !== undefined && reduxLogin.signedIn) {
      appInsights.trackTrace({
        message: "Internet re-connection for " + reduxLogin.email,
        severityLevel: SeverityLevel.Information,
      });
    }
  }
  function handleOfflineEvent() {
    if (reduxConnectionStatus.connected === true)
      dispatch(setConnectionStatus(false));
  }
  useEffect(() => {
    window.addEventListener("online", handleOnlineEvent);
    window.addEventListener("offline", handleOfflineEvent);
    return () => {
      window.removeEventListener("online", handleOnlineEvent);
      window.removeEventListener("offline", handleOfflineEvent);
    };
  });

  return (
    <>
      <Header />
      <Switch>
        {PrivateRoutes()}
        {PublicRoutes()}
      </Switch>
      <Footer />
    </>
  );
}

export default App;
