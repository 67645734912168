export function validateTowerInvoice(towerInvoice: string): string {
  if (!/^[ A-Za-z0-9_@./#&+-]*$/u.test(towerInvoice)) {
    return "Tower Invoice must be alphanumeric or contain _@./#&+-";
  } else {
    return "";
  }
}

export function validateTowerName(nameToValidate: string): string {
  if (nameToValidate.trim() === "") {
    return "";
  }

  if (
    !/^[ 0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.#'-]+$/.test(
      nameToValidate.trim()
    )
  ) {
    return "Tow Operator Name contains invalid characters";
  }

  return "";
}

export function validateVendorPlate(plateToValidate: string) {
  if (plateToValidate === "") {
    return "";
  }

  if (
    plateToValidate.trim().length === 1 ||
    plateToValidate.trim().length === 2
  ) {
    if (!/^([a-zA-Z0-9]{1})([a-zA-Z0-9]{0,1})$/.test(plateToValidate.trim())) {
      return "Invalid plate";
    } else {
      return "";
    }
  }

  if (
    !/^([a-zA-Z0-9]{1})[a-zA-Z0-9\s-]{1,5}([a-zA-Z0-9]{1})$/.test(
      plateToValidate.trim()
    )
  ) {
    return "Invalid plate";
  } else {
    return "";
  }
}

/* 
export function validateRequestedPayment(
  requestedPaymentToValidate: number,
  setRequestedPaymentMessage: any
): boolean {
  if (
    requestedPaymentToValidate.toString() === "" ||
    requestedPaymentToValidate == 0
  ) {
    setRequestedPaymentMessage("Total Requested Payment is required");
    return false;
  }
  if (requestedPaymentToValidate < 0) {
    setRequestedPaymentMessage(
      "Total Requested Payment must be at least one dollar"
    );
    return false;
  }

  if (requestedPaymentToValidate > 100000) {
    setRequestedPaymentMessage("Cannot exceed 100,000");
    return false;
  }

  if (!/^\d+(\.\d{1,2})?$/.test(requestedPaymentToValidate.toString())) {
    setRequestedPaymentMessage("Invalid Payment Amount");
    return false;
  }

  setRequestedPaymentMessage("");
  return true;
}
 */
