import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";
import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { Bulletin } from "../types/Bulletin";
import { DownloadUrl, DeleteUrl, GetAllUrl, UploadUrl } from "./bulletinPaths";

const uploadBulletin = async (bulletin: Bulletin, file: File) => {
  var baseUrl = getBaseApiUrl() + UploadUrl;

  const token = await getAccessToken();

  let data = new FormData();
  data.append("file", file)

  const url = `${baseUrl}?title=${encodeURIComponent(bulletin.title)}`;

  return axios
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse);
};

const getAllBulletin = async () => {
  var baseUrl = getBaseApiUrl() + GetAllUrl;

  const token = await getAccessToken();

  return axios
    .get(baseUrl, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

const downloadBulletinFile = async (id: string) => {
  var url = getBaseApiUrl() + `${DownloadUrl}?id=${id}`;

  const token = await getAccessToken();

  return axios
    .get(url, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

const deleteBulletin = async (id: string) => {
  var url = getBaseApiUrl() + `${DeleteUrl}?id=${id}`;

  const token = await getAccessToken();

  return axios
    .delete(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export { uploadBulletin, getAllBulletin, downloadBulletinFile, deleteBulletin };
