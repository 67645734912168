import { AssignedToUser } from "./AssignedToUser";

export interface iInvoiceList {
  vendorId: string;
  invoiceId: string;
  towerReference: string;
  claimNumber: string;
  vehiclePlate: string;
  requestedPayment: number;
  submittedDate: string | null;
  status: number;
  lineItems:string | null;
  statusDisplay: string;
  paidDate: string | null;
  assignedToSGIEmployee: AssignedToUser;
  payeeName: string;
  deletedDate: string | null;
  gisSearchDate: string | null;
}

export interface iInvoiceListSortSettings {
  invoiceListSortColumn: string;
  invoiceListSortOrder: string;
}

export const defaultInvoiceList: iInvoiceList[] = [];
