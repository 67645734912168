export function policeFileValidator(policeFileToValidate: string) {
  if (!/^([a-zA-Z0-9]*)$/.test(policeFileToValidate.trim())) {
    return "Police File Number can only contain letters and numbers";
  }

  return "";
}

export function policeDeptValidator(
  policeDeptToValidate: string,
  policeFile: string
) {
  if (policeFile !== "" && policeDeptToValidate.trim() === "") {
    return "Police Dept cannot be blank if a Police File is added";
  }

  if (!/^([ a-zA-Z0-9]*)$/.test(policeDeptToValidate.trim())) {
    return "Police Department can only contain letters, numbers, and spaces";
  }

  return "";
}
