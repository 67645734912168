import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { postLegacyAttach } from "../../api/apiLegacy";
import { AnnotationStatus } from "../../types/Invoice";
import { Error } from "../Alerts/Error";
interface ModalProps {
  show: any;
  onHide: any;
  invoiceId: any;
  annotations: any;
  claimNumber: any;
}
export function ModalSendToLegacy(props: ModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [sendErrorMessage, setSendErrorMessage] = useState("");

  var claimNumberSpecified =
    props.claimNumber === null || props.claimNumber === "" ? false : true;

  var outstandingAnnotations = false;
  if (
    props.annotations.filter(
      (x: { status: string }) => x.status === AnnotationStatus.Draft
    ).length !== 0
  ) {
    outstandingAnnotations = true;
  }

  function HandleCancel(event: any) {
    setSendErrorMessage("");
    props.onHide(false);
    setIsLoading(false);
  }

  async function HandleSend() {
    var response: any;
    try {
      setIsLoading(true);
      response = await postLegacyAttach(props.invoiceId);
    } catch (error) {
      setSendErrorMessage(
        "Oops, there was a problem sending the tow invoice to GIS, please check the transfer summary to see why"
      );
    }

    if (response) {
      props.onHide(false);
    } else {
      setSendErrorMessage(
        "Oops, there was a problem sending the tow invoice to GIS, please check the transfer summary to see why"
      );
    }
    setIsLoading(false);
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Send Invoice to GIS</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!claimNumberSpecified && (
          <p>* A claim number is required to send the invoice to GIS</p>
        )}
        {outstandingAnnotations && (
          <p>* Cannot send the invoice to GIS with unsent annotations</p>
        )}
        {claimNumberSpecified && !outstandingAnnotations && (
          <p>Confirm that the Invoice should be sent to GIS</p>
        )}
        <p />
      </Modal.Body>
      {sendErrorMessage !== "" && <Error message={sendErrorMessage} />}
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleCancel}
          data-testid="cancelButton"
        >
          Cancel
        </Button>
        {claimNumberSpecified && !outstandingAnnotations && (
          <Button
            variant="primary"
            onClick={HandleSend}
            data-testid="sendButton"
          >
            {isLoading && <Spinner animation="border" role="status" />}
            {!isLoading && "Send to GIS"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
