import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import { Annotation, AnnotationStatus } from "../../types/Invoice";
import {
  getRateUnitsForInvoiceSummary,
  iLineItem,
  LineType,
  WeightClass,
} from "../../types/LineItems";
import currencyFormat, { calculateGstFromTotal, getDiffDays, showGstInformation } from "../../utils/Utils";
import EmployeeInvoiceLineItemDiff from "../EmployeeInvoiceLineItemDiffs/EmployeeInvoiceLineItemDiff";
import { getEmployeeSummaryOverlay } from "./EmployeeInvoiceSummaryOverlays";
import "./EmployeeInvoiceSummaryDetails.css";
import { EmployeeSummaryLineItemsAnnotations } from "./EmployeeInvoiceSummaryLineItemsAnnotations";

export function EmployeeSummaryLineItems(props: any) {
  function handleAddAnnotate(lineId: string) {
    // find the object from the props array
    const lineItem = props.invoiceSummary.invoiceLineItems.find(
      (x: { id: any }) => x.id === lineId
    );

    // check if it's already been added to the state
    const annotation = props.annotations.find(
      (x: { id: any; status: AnnotationStatus }) =>
        x.id === lineId && x.status === AnnotationStatus.Draft
    );

    if (annotation === undefined) {
      // add to the array
      const newAnnotation: Annotation = {
        name: lineItem.name,
        id: lineItem.id,
        title: lineItem.title,
        annotationText: "",
        annotatedValue: "",
        rejectedComment: "",
        status: AnnotationStatus.Draft,
      };

      var updatedAnnotations = props.annotations.slice(0);
      updatedAnnotations.push(newAnnotation);

      props.setAnnotations(updatedAnnotations);
    }
  }

  return (
    <>
      <br></br>
      <Row className="InvoiceSummaryLineItems">
        <Table className="InvoiceSummaryLineItemTable">
          <thead>
            <tr>
              <th></th>
              <th className="workItemHeaderWidth">
                Work Item (
                {
                  WeightClass[
                    props.invoiceSummary.invoiceDetails?.weightClass ===
                    undefined
                      ? 0
                      : props.invoiceSummary.invoiceDetails.weightClass
                  ]
                }
                )
              </th>
              <th>Rate/Unit(s)</th>
              <th className="right">Total</th>
              {props.displayAsEditable && props.viewingCurrentVersion && (
                <th className="right">Req. Changes:</th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.invoiceSummary.invoiceLineItems.map((line: iLineItem) => {
              return (
                <React.Fragment key={line.name + line.id}>
                  <tr key={line.name + line.id}>
                    <td align="center">
                      {getEmployeeSummaryOverlay(
                        line.lineType,
                        line.name,
                        line.id
                      )}
                    </td>
                    <td>{line.title}</td>
                    <td>
                      <table className="table-borderless">
                        <tbody>
                          <tr>
                            <td>{getRateUnitsForInvoiceSummary(line)}</td>
                          </tr>
                          {line.lineType ===
                          LineType.PerDayFromToDatesWithMax ? (
                            <>
                              <tr>
                                <td>From: {line.lineData.fromDate}</td>
                              </tr>
                              <tr>
                                <td>To: {line.lineData.toDate}</td>
                              </tr>
                            </>
                          ) : (
                            <tr>
                              <td />
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>

                    <td className="right">
                      <div data-testid={line.name + line.id + "Total"}>
                        {currencyFormat(line.lineTotal)}
                      </div>
                    </td>
                    {props.displayAsEditable && props.viewingCurrentVersion && (
                      <td className="right">
                        <Button
                          className="annotateButton"
                          size="sm"
                          id={"annotateButton." + line.name + line.id}
                          data-testid={"annotateButton." + line.name + line.id}
                          variant="outline-primary"
                          name={line.name + line.id}
                          onClick={() => handleAddAnnotate(line.id)}
                          disabled={props.disableEdit}
                        >
                          <PencilSquare />
                        </Button>
                      </td>
                    )}
                  </tr>
                  <EmployeeSummaryLineItemsAnnotations
                    line={line}
                    annotations={props.annotations}
                    disableEdit={props.disableEdit}
                    displayAsEditable={props.displayAsEditable}
                    setAnnotations={props.setAnnotations}
                    invoiceSummary={props.invoiceSummary}
                  />
                  {/* show any changes */}
                  {props.lineDiffs
                    .filter((item: any) => item.id === line.id)
                    .map((lineDiff: any) => {
                      return (
                        <tr key={"lineDiff" + lineDiff.name + lineDiff.id}>
                          <td></td>

                          <EmployeeInvoiceLineItemDiff
                            lineDiff={lineDiff}
                            key={lineDiff.name + lineDiff.id}
                          />
                        </tr>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
        <Table className="referRateSheetTable">
          <tbody>
            <tr>
              <td className="referRateSheetLabel">* Refer to Rate Sheet</td>
            </tr>
          </tbody>
        </Table>
      </Row>
      {showGstInformation(props.invoiceSummary.invoiceDetails.submittedDate) &&
      <Row>
        <Col>
          <h5 className="InvoiceTotal" data-testid="InvoiceGstTotal">
            GST (5%): {calculateGstFromTotal(props.invoiceSummary.invoiceDetails.requestedPayment)}
          </h5>
        </Col>
      </Row>
      }
      
      <Row>
        <Col></Col>
        <Col>
          <h5 className="InvoiceTotal" data-testid="InvoiceTotal">
            Invoice Total:{" "}
            {currencyFormat(
              props.invoiceSummary.invoiceDetails.requestedPayment
            )}
          </h5>
        </Col>
      </Row>
    </>
  );
}
