import { useState } from "react";
import { Container } from "react-bootstrap";
import { markSearched, getActiveInvoicesMainDetails } from "../../api/apiInvoice";
import { defaultInvoiceList } from "../../types/InvoiceList";
import { mapToIInvoiceList } from "../../utils/InvoiceListTransformer";
import { useHistory } from "react-router-dom";
import {
	convertDateToDisplayFormatWithTime,
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";
import CustomTable from "../../components/CustomTable/CustomTable";
import { SortOrder } from "react-bootstrap-table-next";
import TableButton from "../../components/CustomTable/TableButton";
import { faBookOpen, faFileCheck } from "@fortawesome/pro-light-svg-icons";
import "./EmployeeInvoiceList.css";
import InvoiceStatus from "../../components/InvoiceStatus/InvoiceStatus";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import LoadingOverlay from "react-loading-overlay";
import { GetUserPermissions } from "../../utils/auth/authUtilities";

export default function EmployeeInvoiceList() {
	const history = useHistory();
	const [invoiceList, setInvoiceList] = useState(defaultInvoiceList);
	const { screenSize } = useWindowDimensions();

	const [successMessages, setSuccessMessages] = useState<string[]>([]);
	const [tableLoading, setTableLoading] = useState(false);
	const permissions = GetUserPermissions();

	const getAllFilteredInvoices = async () => {
		var result: any = await getActiveInvoicesMainDetails();
		if (result !== undefined) {
			result.data = result.data.filter(
				(i: any) => i.status === 2 || i.status === 3 || i.status === 5
			); //Only show active statuses
		}
		return result;
	};

	function noDataIndication() {
		return "There are no invoices to display.";
	}

	const defaultSorted: [{ dataField: any; order: SortOrder }] = [
		{
			dataField: "submittedDate",
			order: "asc",
		},
	];

	function viewButtonFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<div className="buttonCellWrapper">
				<TableButton
					icon={faBookOpen}
					onClick={() =>
						history.push("/Invoices/EmployeeInvoiceSummary/" + row.invoiceId)
					}
					tooltipText="View Invoice"
					id={row.invoiceId}
					datatestid={row.invoiceId}
				/>
			</div>
		);
	}

	const markAsSearched = async (invoiceId: string) => {
		setTableLoading(true);
		await markSearched(invoiceId);

		const response = await getAllFilteredInvoices();
		setInvoiceList(mapToIInvoiceList(response.data));

		setSuccessMessages(["Invoice was successfully marked as 'searched'."]);

		setTableLoading(false);
	};

	function markAsSearchedButtonFormatter(
		cell: string,
		row: any,
		rowIndex: any
	) {
		return (
			<>
				{
					permissions.canMarkSearchedInvoice &&
					<div className="buttonCellWrapper">
						<TableButton
							icon={faFileCheck}
							onClick={() => markAsSearched(row.invoiceId)}
							tooltipText="Mark As Searched"
							id={row.invoiceId}
							datatestid={row.invoiceId}
						/>
					</div>
				}
			</>
		);
	}

	function submittedDateSorter(cell: string) {
		return new Date(cell).toISOString();
	}

	function submittedDateFormatter(cell: string) {
		var formattedDate = convertDateToDisplayFormatWithTime(cell);
		var n = 1;
		return formattedDate.replace(/,/g, (v) => (n++ === 2 ? "\n" : v));
	}

	function assignedToUserFormatter(cell: any) {
		return cell["fullName"];
	}

	function assignedToUserSorter(cell: any) {
		return cell["fullName"];
	}

	function requestedPaymentFormatter(cell: any) {
		return "$" + cell.toFixed(2);
	}

	function statusFormatter(cell: any) {
		return <InvoiceStatus status={cell} />;
	}

	const columns = [
		{
			dataField: "payeeName",
			text: "Vendor",
			sort: true,
		},
		{
			dataField: "towerReference",
			text: "Vendor Invoice",
			sort: true,
			classes: "text-truncate",
			hidden: screenSize < ScreenSize.lg,
		},

		{
			dataField: "claimNumber",
			text: "Claim",
			sort: true,
			headerClasses: screenSize < ScreenSize.lg ? "" : "width10",
		},
		{
			dataField: "vehiclePlate",
			text: "Plate",
			sort: true,
			headerClasses: "width10",
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "requestedPayment",
			text: "Amount",
			sort: true,
			formatter: requestedPaymentFormatter,
			classes: "right amount",
			headerClasses: "width10",
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "submittedDate",
			text: "Submitted",
			sort: true,
			formatter: submittedDateFormatter,
			sortValue: submittedDateSorter,
		},
		{
			dataField: "statusDisplay",
			text: "Hidden status for searching",
			hidden: true,
		},
		{
			dataField: "statusDisplay",
			text: "Status",
			sort: true,
			formatter: statusFormatter,
		},
		{
			dataField: "assignedToSGIEmployee",
			text: "Clerk",
			sort: true,
			formatter: assignedToUserFormatter,
			sortValue: assignedToUserSorter,
		},
		{
			dataField: "gisSearchDate",
			text: "Search Date",
			sort: true,
			formatter: submittedDateFormatter,
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "viewButton",
			text: "",
			formatter: viewButtonFormatter,
			headerClasses: "icons",
		},
		{
			dataField: "markAsSearchedButton",
			text: "",
			formatter: markAsSearchedButtonFormatter,
			headerClasses: "icons",
		},
	];

	const rowClasses = (row: any, rowIndex: number) => {
		let classes = null;
		if (row.lineItems !== null && reviewRows(row, rowIndex)) classes = "review-row";
		return classes;
	};

	const reviewRows = (row: any, rowIndex: number) => {
		let otherLineItem = JSON.parse(row.lineItems).find((o: { name: string; }) => o.name === "Other");
		return row.requestedPayment >= 1500 ? true : otherLineItem ? otherLineItem.lineTotal >= 0.01 : false;
	}

	return (
		<Container className="CustomTableContainer employeeInvoiceList" data-testid="invoiceListContainer">
			<div className="Title">
				<h3>Invoice List </h3>
			</div>
			<ToastSuccess
				title="Invoice Notification"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<LoadingOverlay active={tableLoading} spinner text="Loading ...">
				<CustomTable
					noDataIndication={noDataIndication}
					defaultSorted={defaultSorted}
					columns={columns}
					data={invoiceList}
					setData={setInvoiceList}
					defaultData={defaultInvoiceList}
					dataGetter={getAllFilteredInvoices}
					dataMapper={mapToIInvoiceList}
					rowClasses={rowClasses}
					keyField={"invoiceId"}
				/>
			</LoadingOverlay>
		</Container>
	);
}
