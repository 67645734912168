import { Button, Col, Form, Row } from "react-bootstrap";
import { openMap, displayVersionStatusAndTime } from "../../utils/Utils";
import { PencilSquare, GeoAlt, UpcScan, Check2 } from "react-bootstrap-icons";
import "./EmployeeInvoiceSummaryDetails.css";
import { getDisplayAddress } from "../../types/Location";
import { useFlags } from "launchdarkly-react-client-sdk";
import { iInvoiceIdentifier } from "../../types/Invoice";

export function EmployeeSummaryFields(props: any) {
	const flags = useFlags();
	const copyToClipboard = (copyText: any) => () => {
		navigator.clipboard.writeText(copyText);
	};
	function isDecoded(fieldName: string) {
		if (props.invoiceSummary.invoiceDetails.vinDecoded) {
			if (
				fieldName === "vehicleYear" &&
				props.invoiceSummary.invoiceDetails.vehicleYear ===
				props.invoiceSummary.invoiceDetails.vinData.modelYear
			) {
				return (
					<span title="Vehicle Year Decoded">
						&nbsp;
						<Check2 name="year decoded" data-testid="yearDecodedIcon" />
						&nbsp;
					</span>
				);
			} else if (
				fieldName === "vehicleMake" &&
				props.invoiceSummary.invoiceDetails.vehicleMake ===
				props.invoiceSummary.invoiceDetails.vinData.make
			) {
				return (
					<span title="Vehicle Make Decoded">
						&nbsp;
						<Check2 name="make decoded" data-testid="makeDecodedIcon" />
						&nbsp;
					</span>
				);
			} else if (
				fieldName === "vehicleModel" &&
				props.invoiceSummary.invoiceDetails.vehicleModel ===
				props.invoiceSummary.invoiceDetails.vinData.model
			) {
				return (
					<span title="Vehicle Model Decoded">
						&nbsp;
						<Check2 name="model decoded" data-testid="modelDecodedIcon" />
						&nbsp;
					</span>
				);
			}
		}
		return " ";
	}
	async function handleVersionHistorySelect(event: any) {
		props.setVersionTimeStamp(event.target.value);
	}

	return (
		<>
			{flags.vendorPaymentShowInvoiceHistory &&
				props.showVersionTimeStamp === true && (
					<Row xs={2} sm={2} md={4} lg={4}>
						<Col className="summaryLabel" md={9} lg={9}>
							Version:
						</Col>
						<Col className="summaryField" md={3} lg={3}>
							<Form.Control
								as="select"
								size={"sm"}
								data-testid="versionHistory"
								name="versionHistory"
								onChange={handleVersionHistorySelect}
							>
								{props.sortedInvoiceVersions.length === 0 && (
									<option
										key={0}
										value={props.invoiceSummary.invoiceDetails.versionTimestamp?.toString()}
										data-testid={`${props.invoiceSummary.invoiceDetails.versionTimestamp}Option`}
									>
										Current Version
									</option>
								)}
								{props.sortedInvoiceVersions?.map(
									(invoice: iInvoiceIdentifier, index: number) => {										
										return (
											<option
												key={index}
												value={invoice.versionTimestamp?.toString()}
												data-testid={`${invoice.versionTimestamp}Option`}
											>
												{index === 0 ? "Current Version" : displayVersionStatusAndTime(invoice.statusDisplay, invoice.versionTimestamp)}
											</option>
										);
									}
								)}
							</Form.Control>
						</Col>
					</Row>
				)}
			<Row xs={2} sm={2} md={4} lg={4}>
				<Col className="summaryLabel" md={4} lg={4}>
					Assigned To:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{
						props.invoiceSummary.invoiceDetails.assignedToSGIEmployee[
						"fullName"
						]
					}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Status:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails.statusDisplay}
				</Col>
			</Row>
			<Row className="borderDiv">&nbsp;</Row>
			<Row xs={2} sm={2} md={4} lg={4}>
				<Col className="summaryLabel" md={4} lg={4}>
					SGI Claim #:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{!props.viewingCurrentVersion && (
						<div data-testid="claimNumberText">
							{props.invoiceSummary.invoiceDetails.claimNumber}
						</div>
					)}
					{props.displayAsEditableClaim && props.viewingCurrentVersion && (
						<Button
							size="sm"
							id="claimEditButton"
							data-testid="claimEditButton"
							variant="link"
							name="claimEditButton"
							onClick={(e) => {
								navigator.clipboard.writeText(
									props.invoiceSummary.invoiceDetails.claimNumber
								);
								props.setModalShow(true);
							}}
							disabled={props.disableEditClaim}
						>
							{props.invoiceSummary.invoiceDetails.claimNumber}
							{props.viewingCurrentVersion && (
								<PencilSquare className="editIcon" />
							)}
						</Button>
					)}
					{!props.displayAsEditableClaim &&
						props.invoiceSummary.invoiceDetails.claimNumber}
				</Col>
				<Col className="summaryLabel " md={3} lg={3}>
					Payee Number:
				</Col>
				<Col
					className="summaryField"
					md={3}
					lg={3}
					onClick={copyToClipboard(
						props.invoiceSummary.invoiceDetails.payeeNumber
					)}
				>
					{props.invoiceSummary.invoiceDetails.payeeNumber}
				</Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Towing Invoice&nbsp;#:
				</Col>
				<Col
					className="summaryField"
					md={2}
					lg={2}
					onClick={copyToClipboard(
						props.invoiceSummary.invoiceDetails.towerReference
					)}
				>
					{props.invoiceSummary.invoiceDetails.towerReference}
				</Col>

				<Col className="summaryLabel" md={3} lg={3}>
					Payee Name:
				</Col>
				<Col
					className="summaryField"
					md={3}
					lg={3}
					onClick={copyToClipboard(
						props.invoiceSummary.invoiceDetails.payeeName
					)}
				>
					{props.invoiceSummary.invoiceDetails.payeeName}
				</Col>
				{props.invoiceSummary.invoiceDetails.plate !== "" ? (
					<>
						<Col className="summaryLabel" md={4} lg={4}>
							Towed Vehicle Plate&nbsp;#:
						</Col>
						<Col className="summaryField" md={2} lg={2}>
							{!props.viewingCurrentVersion && (
								<div data-testid="vehiclePlateText">
									{props.invoiceSummary.invoiceDetails.plate}
								</div>
							)}
							{props.displayAsEditableClaim && props.viewingCurrentVersion && (
								<Button
									size="sm"
									id="vehiclePlateEditButton"
									data-testid="vehiclePlateEditButton"
									variant="link"
									name="vehiclePlateEditButton"
									onClick={(e) => {
										navigator.clipboard.writeText(
											props.invoiceSummary.invoiceDetails.plate
										);
										props.setModalVehiclePlateShow(true);
									}}
									disabled={props.disableEditClaim}
								>
									{props.invoiceSummary.invoiceDetails.plate}
									{props.viewingCurrentVersion && (
										<PencilSquare className="editIcon" />
									)}
								</Button>
							)}
							{!props.displayAsEditableClaim &&
								props.invoiceSummary.invoiceDetails.plate}
						</Col>
					</>
				) : (
					<>
						<Col className="summaryLabel" md={4} lg={4}>
							Reason for No Plate&nbsp;#:
						</Col>
						<Col className="summaryField" md={2} lg={2}>
							{props.invoiceSummary.invoiceDetails.reasonForNoVehiclePlate}
						</Col>
					</>
				)}
				{props.invoiceSummary.invoiceDetails.vehicleVIN &&
					props.invoiceSummary.invoiceDetails.vehicleVIN !== "" ? (
					<>
						<Col className="summaryLabel" md={3} lg={3}>
							VIN:
						</Col>
						<Col
							className="summaryField"
							md={3}
							lg={3}
							onClick={copyToClipboard(
								props.invoiceSummary.invoiceDetails.vehicleVIN
							)}
						>
							{props.displayAsEditableClaim && props.viewingCurrentVersion && (
								<Button
									size="sm"
									id="vehicleVinEditButton"
									data-testid="vehicleVinEditButton"
									variant="link"
									name="vehicleVinEditButton"
									onClick={(e) => {
										navigator.clipboard.writeText(
											props.invoiceSummary.invoiceDetails.vehicleVIN
										);
										props.setModalVehicleVinShow(true);
									}}
									disabled={props.disableEditClaim}
								>
									{props.invoiceSummary.invoiceDetails.vehicleVIN}
									&nbsp;
									<span title="Vin Scanned"></span>
									{props.invoiceSummary.invoiceDetails.vinScanned ? (
										<UpcScan name="vin Scanner" data-testid="scannerIcon" />
									) : (
										""
									)}
									{props.viewingCurrentVersion && (
										<PencilSquare className="editIcon" />
									)}
								</Button>
							)}

							{!props.viewingCurrentVersion &&
								props.invoiceSummary.invoiceDetails.vehicleVIN}
						</Col>
					</>
				) : (
					<>
						<Col className="summaryLabel" md={3} lg={3}>
							No VIN Reason:
						</Col>
						<Col className="summaryField" md={3} lg={3}>
							{!props.viewingCurrentVersion
								? NoVinReasonText(props.invoiceSummary)
								: ""}
							{props.displayAsEditableClaim && props.viewingCurrentVersion && (
								<Button
									size="sm"
									id="vehicleVinEditButton"
									data-testid="vehicleNoVinReasonEditButton"
									variant="link"
									name="vehicleNoVinReasonEditButton"
									onClick={(e) => {
										props.setModalVehicleVinShow(true);
									}}
									disabled={props.disableEditClaim}
								>
									{NoVinReasonText(props.invoiceSummary)}
									{props.viewingCurrentVersion && (
										<PencilSquare className="editIcon" />
									)}
								</Button>
							)}
						</Col>
					</>
				)}
			</Row>
			<Row className="borderBottomDiv">&nbsp;</Row>
			<Row xs={2} sm={2} md={4} lg={4}>
				<Col className="summaryLabel" md={4} lg={4}>
					Tower Name/Truck #:
				</Col>
				<Col className="summaryField" md={2} lg={2} data-test-id="towerName">
					{props.invoiceSummary.invoiceDetails.towerName}
				</Col>
				<Col md={3} lg={3}></Col>
				<Col md={2} lg={2}></Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Year:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails?.vehicleYear ? props.invoiceSummary.invoiceDetails?.vehicleYear : ""}
					{isDecoded("vehicleYear")}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Make:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.vehicleMake}
					{isDecoded("vehicleMake")}
				</Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Model:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.vehicleModel}
					{isDecoded("vehicleModel")}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Colour:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.vehicleColour}
				</Col>

				<Col className="summaryLabel" md={4} lg={4}>
					Driveable:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.driveable === "Y" ? "Yes" : "No"}
				</Col>

				<Col className="summaryLabel" md={3} lg={3}>
					Have keys:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails.keys === "Y" ? "Yes" : "No"}
				</Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Date of Tow Service:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.serviceDate !== null
						? props.invoiceSummary.invoiceDetails.serviceDate
						: ""}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Vendor vehicle Plate&nbsp;#:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails.vendorPlate}
				</Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Reason For Tow:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.reasonForTow}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Towed From Accident?:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails.towedFromAccident === "Y"
						? "Yes"
						: "No"}
				</Col>

				<Col className="summaryLabel" md={4} lg={4}>
					Towed From Location:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{getDisplayAddress(
						props.invoiceSummary.invoiceDetails.locations.towedFrom
					)}
					{props.invoiceSummary.invoiceDetails.locations["towedFrom"]
						.placeId && (
							<GeoAlt
								className="locationButton"
								data-testid="towedFromLink"
								size={30}
								onClick={() => {
									openMap(
										props.invoiceSummary.invoiceDetails.locations["towedFrom"]
									);
								}}
								style={{
									cursor: "pointer",
								}}
							/>
						)}
					<br />
					{props.invoiceSummary.invoiceDetails.locations["towedFrom"]
						.specificLocation !== "" &&
						props.invoiceSummary.invoiceDetails.locations["towedFrom"]
							.specificLocation !== undefined &&
						props.invoiceSummary.invoiceDetails.locations["towedFrom"]
							.specificLocation !== null
						? "Stall/Lot: " +
						props.invoiceSummary.invoiceDetails.locations["towedFrom"]
							.specificLocation
						: ""}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Towed To Location:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{getDisplayAddress(
						props.invoiceSummary.invoiceDetails.locations.towedTo
					)}
					{props.invoiceSummary.invoiceDetails.locations["towedTo"].placeId && (
						<GeoAlt
							className="locationButton"
							data-testid="towedToLink"
							size={30}
							onClick={() => {
								openMap(
									props.invoiceSummary.invoiceDetails.locations["towedTo"]
								);
							}}
							style={{
								cursor: "pointer",
							}}
						/>
					)}
					<br />
					{props.invoiceSummary.invoiceDetails.locations["towedTo"]
						.specificLocation !== "" &&
						props.invoiceSummary.invoiceDetails.locations["towedTo"]
							.specificLocation !== undefined &&
						props.invoiceSummary.invoiceDetails.locations["towedTo"]
							.specificLocation !== null
						? "Stall/Lot: " +
						props.invoiceSummary.invoiceDetails.locations["towedTo"]
							.specificLocation
						: ""}
				</Col>

				<Col className="summaryLabel" md={4} lg={4}>
					Police File&nbsp;#:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails.policeFile}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Police Department:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails.policeDept}
				</Col>
				<Col className="summaryLabel" md={4} lg={4}>
					Invoice Created By:
				</Col>
				<Col className="summaryField" md={2} lg={2}>
					{props.invoiceSummary.invoiceDetails?.createdBy !== null
						? props.invoiceSummary.invoiceDetails.createdBy.fullName
						: ""}
				</Col>
				<Col className="summaryLabel" md={3} lg={3}>
					Invoice Submitted By:
				</Col>
				<Col className="summaryField" md={3} lg={3}>
					{props.invoiceSummary.invoiceDetails?.submittedBy !== null
						? props.invoiceSummary.invoiceDetails.submittedBy.fullName
						: ""}
				</Col>
			</Row>
		</>
	);
}
export function NoVinReasonText(props: any) {
	return props.invoiceDetails.reasonForNoVIN &&
		props.invoiceDetails.reasonForNoVIN !== "" &&
		props.invoiceDetails.reasonForNoVIN !== "Other"
		? props.invoiceDetails.reasonForNoVIN
		: [
			props.invoiceDetails.reasonForNoVIN === "Other"
				? props.invoiceDetails.noVINReasonDetails
				: "",
		];
}
