import { useTypedSelector } from "../../redux/store";
import { UserPermissions } from "../../types/UserPermissions";

export function GetUserPermissions() {
  const login = useTypedSelector((state) => state.login);
  const permissions = useTypedSelector((state) => state.userPermissions);
  return login.signedIn ? permissions : null;
}

export function isAuthorized(
  permissions: UserPermissions,
  requiredPerms: string[],
  requireAll = true
): Boolean {
  if (!requiredPerms || requiredPerms.length === 0) return true;
  if (!permissions) return false;
  let authorized: Boolean;

  let permissionsArray = Object.entries(permissions)
    .filter(([key, value]) => {
      return value === true;
    })
    .flat(1)
    .filter((value) => value !== true);

  requireAll
    ? (authorized = requiredPerms.every((permission) =>
        permissionsArray.includes(permission)
      ))
    : (authorized = requiredPerms.some((permission) =>
        permissionsArray.includes(permission)
      ));

  return authorized;
}
