import React, { useEffect, useState } from "react";
import { Button, Col, Form, Container } from "react-bootstrap";
import {
  getTitleForSpecialLineItemsSelection,
  iLineItem,
  LineType,
} from "../../types/LineItems";
import { SpecialItemsTable } from "./SpecialItemsTable";
import { Error } from "../Alerts/Error";
import {
  convertDatetoMilliseconds,
  convertDateToStringFormat,
} from "../../utils/Utils";
import "./SpecialItemsTable.css";

import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export function SpecialItems(props: any) {
  const emptyStringArray: string[] = [];
  const [errorMessage, setErrorMessage] = useState(emptyStringArray);
  const [itemsAreValid, setItemsAreValid] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("");
  const currentDateMilliseconds = convertDatetoMilliseconds(
    convertDateToStringFormat(new Date())
  );
  const serviceDateMilliseconds = convertDatetoMilliseconds(
    props.invoiceDetails.serviceDate
  );
  const applySelect = (e: any) => {
    setCurrentSelection(e.target.value);
  };

  const addSelectedLineItem = () => {
    if (currentSelection === "") return;
    let itemToAdd: iLineItem = {
      ...props.lineOptions.find((i: iLineItem) => i.title === currentSelection),
    };
    let itemExists: iLineItem = {
      ...props.specialLineItems.find(
        (i: iLineItem) => i.name === itemToAdd.name
      ),
    };

    if (itemToAdd && itemExists.id && !itemToAdd.allowMultiple) {
      return;
    }

    if (itemToAdd) {
      if (itemToAdd.lineType === LineType.FlatRate) {
        itemToAdd.qty = 1;
        itemToAdd.lineTotal = itemToAdd.qty * itemToAdd.rate.baseRate;
      } else if (itemToAdd.lineType === LineType.Other) {
        // Establish field as blank; validations will require a value.
        itemToAdd.qty = undefined;
      } else {
        itemToAdd.qty = 0;
      }

      itemToAdd.id = uuidv4().substring(0, 8);
      props.setSpecialLineItems([...props.specialLineItems, itemToAdd]);
    }
  };

  const { isValid } = props;

  useEffect(() => {
    let allItemsValid = true;
    props.specialLineItems.forEach((f: iLineItem) => {
      let fromDateMilliseconds = convertDatetoMilliseconds(f.lineData.fromDate);
      let toDateMilliseconds = convertDatetoMilliseconds(f.lineData.toDate);

      if (
        (f.lineType !== LineType.Other && f.qty === 0) ||
        (f.lineType === LineType.Other && (f.qty as unknown) === "") ||
        f.qty === undefined
      ) {
        allItemsValid = false;
      }
      if (
        (f.lineType === LineType.Other &&
          (f.lineData.otherDescription === "" ||
            f.lineData.otherDescription === undefined)) ||
        f.lineTotal === undefined
      ) {
        allItemsValid = false;
      }
      if (f.lineType !== LineType.Other && Object.keys(f.lineData).length > 0) {
        if (
          fromDateMilliseconds > currentDateMilliseconds ||
          toDateMilliseconds > currentDateMilliseconds
        ) {
          allItemsValid = false;
        }
        if (
          fromDateMilliseconds === null ||
          toDateMilliseconds === null ||
          isNaN(fromDateMilliseconds) ||
          isNaN(toDateMilliseconds) ||
          fromDateMilliseconds === 0 ||
          toDateMilliseconds === 0
        ) {
          allItemsValid = false;
        }
        if (fromDateMilliseconds > toDateMilliseconds) {
          allItemsValid = false;
        }
      }
    });
    isValid(allItemsValid);
    setItemsAreValid(allItemsValid);
  }, [
    props.specialLineItems,
    isValid,
    currentDateMilliseconds,
    serviceDateMilliseconds,
    itemsAreValid,
  ]);

  useEffect(() => {
    let errors: string[] = [];
    if (props.showErrors && !itemsAreValid) {
      props.specialLineItems.forEach((f: iLineItem) => {
        if (
          (f.lineType !== LineType.Other && f.qty === 0) ||
          f.qty === undefined ||
          (f.lineType === LineType.Other &&
            (f.lineData.otherDescription === undefined ||
              f.lineData.otherDescription === "" ||
              (f.qty as unknown) === ""))
        ) {
          return errors.push(
            "All selected line items field must have entered."
          );
        }
      });
      props.specialLineItems.forEach((f: iLineItem) => {
        let fromDateMilliseconds = convertDatetoMilliseconds(
          f.lineData.fromDate
        );
        let toDateMilliseconds = convertDatetoMilliseconds(f.lineData.toDate);

        if (
          f.lineType !== LineType.Other &&
          Object.keys(f.lineData).length > 0
        ) {
          if (
            fromDateMilliseconds > currentDateMilliseconds ||
            toDateMilliseconds > currentDateMilliseconds
          ) {
            return errors.push("Storage Date cannot be future dated");
          }

          if (
            fromDateMilliseconds === null ||
            toDateMilliseconds === null ||
            isNaN(fromDateMilliseconds) ||
            isNaN(toDateMilliseconds) ||
            fromDateMilliseconds === 0 ||
            toDateMilliseconds === 0
          ) {
            return errors.push("Storage Date must be entered");
          }
          if (fromDateMilliseconds > toDateMilliseconds) {
            return errors.push(
              "Out of Storage cannot be prior to In Storage date"
            );
          }
        }
      });
    }
    errors = errors.filter((v, i, a) => a.indexOf(v) === i);
    setErrorMessage([...errors]);
  }, [
    props.showErrors,
    itemsAreValid,
    props.specialLineItems,
    currentDateMilliseconds,
    serviceDateMilliseconds,
  ]);

  return (
    <Container className="invoiceLinesContainer">
      <h5>Special Line Items:</h5>

      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            onChange={applySelect}
            data-testid="selectSpecialLineItem"
          >
            <option key="blankChoice" hidden value="">
              Choose an Item to Add...
            </option>
            {props.lineOptions
              .filter((i: iLineItem) => !i.isStandard)
              .map((line: iLineItem, index: number) => {
                return (
                  <option
                    key={index}
                    value={line.title}
                    data-testid={line.name + "Option"}
                  >
                    {getTitleForSpecialLineItemsSelection(line)}
                  </option>
                );
              })}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Button
          variant="outline-primary"
          onClick={addSelectedLineItem}
          data-testid="addSpecialLineItemButton"
          id="AddSpecialItem"
        >
          Add Item
        </Button>
      </Col>
      <SpecialItemsTable
        lineOptions={props.lineOptions}
        specialLineItems={props.specialLineItems}
        setSpecialLineItems={props.setSpecialLineItems}
      />
      <div>
        <p></p>
      </div>

      <a
        href={process.env.PUBLIC_URL + "/towRates2024.pdf"}
        rel="noreferrer"
        target="_blank"
      >
        <Button
          className="btn outline-primary"
          variant="outline-primary"
          size="sm"
        >
          {" "}
          * Refer to Rate Sheet&nbsp;&nbsp;
          <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" />
        </Button>
      </a>

      {errorMessage !== [""] &&
        errorMessage.map((error) => {
          return <Error message={error} key={error} />;
        })}
    </Container>
  );
}
