import { SeverityLevel } from "@microsoft/applicationinsights-common";
import moment from "moment";
import { useEffect, useState } from "react";
import { appInsights } from "../AppInsights";

export const currencyFormat = function (num: any) {
  const factor = 10 ** 2;
  const result = Math.round(Number(num) * factor) / factor;
  return (
    "$" +
    result.toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

export const GST_RATE = 0.05;
export const GST_EFFECTIVE_DATE = "2023-04-01";

export const calculateGst = function (num: any) {
  return (
    currencyFormat(Number(num) * GST_RATE)
  );
}

export const showGstInformation = (submittedDate: string = "") => {
  if(submittedDate === "" || !submittedDate) return true;
  const gstEffectiveDate = new Date(GST_EFFECTIVE_DATE);
  return getDiffDays(gstEffectiveDate, new Date(submittedDate)) > 0;
}

export const calculateGstFromTotal = function (total: any) {
  return (
    currencyFormat(Number(total) - (Number(total) / (1 + GST_RATE)))
  );
}

export const displayRate = function (rateToDisplay: any) {
  return Math.floor(rateToDisplay) === rateToDisplay
    ? currencyFormatNoCents(rateToDisplay)
    : currencyFormat(rateToDisplay);
};

export default currencyFormat;

export const currencyFormatNoCents = function (num: any) {
  return (
    "$" +
    Number(num)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};
function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}
export function distanceInKmBetweenEarthCoordinates(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  var earthRadiusKm = 6371;

  var dLat = degreesToRadians(lat2 - lat1);
  var dLon = degreesToRadians(lon2 - lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}
export function convertDateToStringFormat(date: Date): string {
  return date.toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}
export function convertDateToDisplayFormatWithTime(date: string, addOffset: boolean = true): string {
  try {
    if (!date || date === "") return "";
    if(addOffset) date += ".000Z";
    return new Date(date).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  } catch (error: any) {
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: "error in date convert func: " + date + " : " + error,
        severityLevel: SeverityLevel.Information,
      });
    }
    console.log("error in date convert func :", error);
    return date;
  }
}

export function convertDateToDisplayFormat(date: string): string {
  try {
    if (!date || date === "") return "";
    return new Date(date + ".000Z").toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } catch (error: any) {
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: "error in date convert func: " + date + " : " + error,
        severityLevel: SeverityLevel.Information,
      });
    }
    console.log("error in date convert func :", error);
    return date;
  }
}

export function displayDateWithTimeNoYear(date: string): string {
  try {
    if (!date || date === "") return "";
    return new Date(date + ".000Z").toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    });
  } catch (error: any) {
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: "error in date convert func: " + date + " : " + error,
        severityLevel: SeverityLevel.Information,
      });
    }
    console.log("error in date convert func :", error);
    return date;
  }
}

export function displayVersionStatusAndTime(
  status: string,
  date: string | null
): string {
  if (date) {
    return status + "-" + convertDateToDisplayFormat(date);
  } else return "";
}

export const percentageFormat = function (num: any) {
  var percentageAmount = Number(num) * 100;
  return percentageAmount.toString() + "%";
};

export function LogToAppInsights(description: string, severity: SeverityLevel) {
  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message: description,
      severityLevel: severity,
    });
  }
}

export function convertDatetoMilliseconds(date: string): any {
  return new Date(date).getTime();
}
export function getLocalDateString(dateToConvert: any): string {
  // this returns a date string with the current date and local time that's not offset by timezone
  return new Date(
    Date.parse(dateToConvert) + -1 * new Date().getTimezoneOffset() * 60000
  ).toISOString();
}

export function convertDateToShortForm(dateToConvert: any) {
  var monthString = (dateToConvert.getMonth() + 1).toString();
  if (monthString.length < 2) {
    monthString = "0" + monthString;
  }

  var dateString = dateToConvert.getDate().toString();
  if (dateString.length < 2) {
    dateString = "0" + dateString;
  }

  var shortFormDate =
    dateToConvert.getFullYear().toString() +
    "-" +
    monthString +
    "-" +
    dateString;

  return shortFormDate;
}

export function sortBy(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    a[key] = a[key] ? a[key] : " ";
    b[key] = b[key] ? b[key] : " ";
    return a[key] || b[key]
      ? !a[key]
        ? -1
        : !b[key]
        ? 1
        : a[key].toString().localeCompare(b[key].toString()) *
          (sortOrder === "asc" ? 1 : -1)
      : 0;
  };
}

export function sortByDate(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    var fieldA = new Date(a[key]);
    var fieldB = new Date(b[key]);
    var viewIOSString =
      fieldA.toISOString().localeCompare(fieldB.toISOString()) *
      (sortOrder === "asc" ? 1 : -1);
    return fieldA || fieldB ? (!fieldA ? -1 : !fieldB ? 1 : viewIOSString) : 0;
  };
}

export function sortByNumber(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    return a[key] || b[key]
      ? !a[key]
        ? -1
        : !b[key]
        ? 1
        : (parseFloat(a[key].toString()) - parseFloat(b[key].toString())) *
          (sortOrder === "asc" ? 1 : -1)
      : 0;
  };
}

export function sortByNumberThenDate(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    var fieldA = parseFloat(a[key].toString());
    var fieldB = parseFloat(b[key].toString());
    var dateA = new Date(a["submittedDate"]).toISOString();
    var dateB = new Date(b["submittedDate"]).toISOString();
    return (
      (fieldA - fieldB) * (sortOrder === "asc" ? 1 : -1) ||
      dateB.localeCompare(dateA)
    );
  };
}

export function sortByStringThenDate(key: any, sortOrder: string) {
  return function (a: any, b: any) {
    var fieldA: string = (a[key] = a[key] ? a[key] : " ");
    var fieldB: string = (b[key] = b[key] ? b[key] : " ");
    var itemOrder: string[] = [];

    if (key === "statusDisplay") {
      itemOrder = [
        "Action Required",
        "Draft",
        "Submitted",
        "Resubmitted",
        "Paid",
        "None",
      ];
    }
    var dateA = new Date(a["submittedDate"]).toISOString();
    var dateB = new Date(b["submittedDate"]).toISOString();
    var sortByString =
      itemOrder.length === 0
        ? fieldB.localeCompare(fieldA)
        : itemOrder.indexOf(fieldA) - itemOrder.indexOf(fieldB);
    var result =
      sortByString * (sortOrder === "asc" ? 1 : -1) ||
      dateB.localeCompare(dateA);

    return result;
  };
}

export function getDiffDays(date1: Date, date2: Date): number {
  var fromDate = moment(date1);
  var toDate = moment(date2);
  return toDate.diff(fromDate, "days");
}

export enum ScreenSize {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  var screenSize = ScreenSize.xl;
  if (width < 1200) screenSize = ScreenSize.lg;
  if (width < 992) screenSize = ScreenSize.md;
  if (width < 768) screenSize = ScreenSize.sm;
  if (width < 576) screenSize = ScreenSize.xs;

  return {
    width,
    height,
    screenSize,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

export function nullOrString(n: any): string | null {
  if (n) return n;
  return null;
}

export function isNullorEmpty(stringToCheck: any) {
  return stringToCheck === null || stringToCheck.length === 0;
}

export function openMap(location: any) {
  window.open(
    "https://www.google.com/maps/search/?api=1&query=" +
      location.formattedAddressFull +
      "&query_place_id=" +
      location.placeId
  );
}

export function formatPhoneNumber(str: string) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[2] + ") " + match[3] + "-" + match[4];
  }

  return "";
}

export function firstLetterUppercase(str: string): string {
  str = str.toLowerCase();
  let arr = str.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys[0];
}
