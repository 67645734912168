const initialState = {
	redirect: "",
};

const redirectReducer = (
	state = initialState,
	action: { type: any; redirect?: string }
) => {
	let newState = { ...state };
	switch (action.type) {
		case "SET_REDIRECT": {
			action.redirect === undefined
				? (newState.redirect = "")
				: (newState.redirect = action.redirect);
			return newState;
		}
		case "CLEAR_REDIRECT": {
			return initialState;
		}
		default:
			return state;
	}
};

export default redirectReducer;
