import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function uploadPhoto(imageFile: File) {
  var baseUrl = getBaseApiUrl() + "InvoicePhoto/uploadPhoto";

  const token = await getAccessToken();
  let data = new FormData();
  data.append("imageFile", imageFile);
  return axios
    .post(baseUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
