import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import "../Header/Header.css";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "../../redux/store";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/auth/authConfig";
import { useDispatch } from "react-redux";
import {
	AuthenticationResult,
	EventMessage,
	EventType,
} from "@azure/msal-browser";
import { getMapsAPIKey } from "../../api/apiMaps";
import { AxiosResponse } from "axios";
import { ToastErrors } from "../Toast/ToastErrors";
import { AssignSecurity, isAuthenticated } from "../../utils/ReduxInit";
import { getScanditLicenseKey } from "../../api/apiScandit";
import { UserPermissions } from "../../types/UserPermissions";

export function Login() {
	const history = useHistory();
	const dispatch = useDispatch();

	const redirect = useTypedSelector((state) => state.redirect);
	const reduxLogin = useTypedSelector((state) => state.login);

	const emptyStringArray: string[] = [];
	const [errorMessages, setErrorMessages] = useState(emptyStringArray);

	const [displaySpinner, setDisplaySpinner] = useState(false);

	// auth declares
	const { instance } = useMsal();
	// AAD login
	const handleLogin = () => {
		try {
			instance
				.loginRedirect(loginRequest)
				.catch((e) => {
					console.log("Error with authentication: " + e);
				})
				.then((response) => {
					console.log("Login successful" + response);
				});
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		const callbackId = instance.addEventCallback(
			async (message: EventMessage) => {
				// Update UI or interact with EventMessage here
				if (
					message.eventType === EventType.LOGIN_SUCCESS ||
					message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
				) {
					setDisplaySpinner(true);
					const payload: any = message.payload;
					if (
						payload.idTokenClaims.acr === "b2c_1a_vendorpilot_passwordreset"
					) {
						instance.logout();
						return;
					}
					var userPermissions: UserPermissions = await AssignSecurity(
						message.payload,
						setDisplaySpinner,
						errorMessages,
						setErrorMessages,
						history,
						dispatch,
						redirect
					);
					if (userPermissions.canSaveInvoice) {
						let scanditKey: AxiosResponse<any> = await getScanditLicenseKey();
						(window as any)["scanditKey"] = {
							scanditKeyValue: scanditKey?.data,
						};
					}
					let apiKey: AxiosResponse<any> = await getMapsAPIKey();
					// let apiKey: any = { data: "YOUR-API-KEY" }; //Switch with above line to use Raw key instead
					(window as any)["apiConfig"] = {
						googleMapsApiData: apiKey?.data,
					};
				}

				if (message.eventType === EventType.LOGIN_FAILURE) {
					setErrorMessages([
						...errorMessages,
						"The sign-on action could not be completed.",
					]);
				}

				const authResult = message.payload as AuthenticationResult;

				if (
					message.eventType === EventType.LOGIN_SUCCESS &&
					authResult.account
				) {
					const account = authResult.account;
					instance.setActiveAccount(account);
				}
			}
		);
		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instance]);

	return (
		<>
			<ToastErrors
				title="Auth Errors"
				setMessages={setErrorMessages}
				messages={errorMessages}
			/>

			{isAuthenticated(reduxLogin) === false ? (
				<Button
					className="header-btn text-nowrap"
					size="sm"
					variant="outline-primary"
					id="HeaderLoginButton"
					data-testid="HeaderLoginButton"
					onClick={handleLogin}
					disabled={displaySpinner}
				>
					{displaySpinner && (
						<Spinner
							id="loginSpinner"
							animation="border"
							role="status"
							className="spinner"
						/>
					)}
					{!displaySpinner && "Sign In"}
				</Button>
			) : (
				""
			)}
		</>
	);
}
