import React, { useMemo, useState } from "react";
import {
	Alert,
	Button,
	Col,
	Form,
	Modal,
	OverlayTrigger,
	Row,
	Spinner,
	Tooltip,
} from "react-bootstrap";
import { updateVehicleVIN } from "../../api/apiInvoice";
import { Search } from "react-bootstrap-icons";
import {
	reasonForNoVINValidator,
	validateNoVINReasonDetails,
	validateVehicleMake,
	validateVehicleModel,
	validateVehicleYear,
	validateVin,
} from "../../utils/Validators/VehicleValidator";
import { Error } from "../Alerts/Error";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";
import { defaultVinData, ReasonForNoVIN, VinData } from "../../types/Invoice";
import { RenderRadioButtons } from "../VehicleInformation/VehicleInformation";
import { decodeVinForEmployee } from "../../api/apiVinDecoder";
import { firstLetterUppercase } from "../../utils/Utils";
import { ToastErrors } from "../Toast/ToastErrors";
import { ToastSuccess } from "../Toast/ToastSuccess";
interface ModalProps {
	show: any;
	onHide: any;
	invoiceSummary: any;
	setInvoiceSummary: any;
	setNewVersionCreated: any;
}
export function ModalEditVinNumber(props: ModalProps) {
	const [editedVinNumber, setEditedVinNumber] = useState(
		props.invoiceSummary.invoiceDetails.vehicleVIN || ""
	);
	const [editedReasonForNoVin, setEditedReasonForNoVin] = useState(
		props.invoiceSummary.invoiceDetails.reasonForNoVIN || ""
	);
	const [editedNoVinReasonDetails, setEditedNoVinReasonDetails] = useState(
		props.invoiceSummary.invoiceDetails.noVINReasonDetails || ""
	);
	const [editedVinData, setEditedVinData] = useState(
		props.invoiceSummary.invoiceDetails.vinData || defaultVinData
	);
	const [editedVinDecoded, setEditedVinDecoded] = useState(
		props.invoiceSummary.invoiceDetails.vinDecoded
	);
	const [decodedVehicleMake, setDecodedVehicleMake] = useState(
		props.invoiceSummary.invoiceDetails.vehicleMake || ""
	);
	const [decodedVehicleModel, setDecodedVehicleModel] = useState(
		props.invoiceSummary.invoiceDetails.vehicleModel || ""
	);
	const [decodedVehicleYear, setDecodedVehicleYear] = useState(
		props.invoiceSummary.invoiceDetails.vehicleYear || ""
	);
	const [VINAvailable, setVINAvailable] = useState(
		!props.invoiceSummary.invoiceDetails.reasonForNoVIN ||
			props.invoiceSummary.invoiceDetails.reasonForNoVIN === ""
	);
	const [vinDecodeLoading, setVinDecodeLoading] = useState(false);
	const [vinMessage, setVinMessage] = useState("");
	const [reasonForNoVinMessage, setReasonForNoVinMessage] = useState("");
	const [noVINReasonDetailsMessage, setNoVINReasonDetailsMessage] =
		useState("");
	const [isLoading, setIsLoading] = useState(false);
	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [failureMessages, setFailureMessages] = useState(emptyStringArray);
	var errorExists = false;

	const ValidatorFunctions: ValidatorFunction[] = useMemo(
		() => [
			{
				fieldname: "vehicleVIN",
				validator: validateVin,
				stateSetter: setVinMessage,
			},
			{
				fieldname: "reasonForNoVIN",
				validator: reasonForNoVINValidator,
				stateSetter: setReasonForNoVinMessage,
			},
			{
				fieldname: "noVINReasonDetails",
				validator: validateNoVINReasonDetails,
				stateSetter: setNoVINReasonDetailsMessage,
			},
		],
		[]
	);
	function handleError() {
		if (
			vinMessage !== "" ||
			noVINReasonDetailsMessage !== "" ||
			reasonForNoVinMessage !== ""
		) {
			errorExists = true;
		} else errorExists = false;
	}
	function handleOnChangeRadio(event: any) {
		const { name } = event.target;
		if (name === "VinAvailable-yes") {
			setVINAvailable(true);
			setReasonForNoVinMessage("");
		}
		if (name === "VinAvailable-no") {
			setVINAvailable(false);
		}
	}
	const DecodeVin = async (vin: string) => {
		setVinDecodeLoading(true);
		var result = await decodeVinForEmployee(vin);

		if (result && result.data && result.status === 200) {
			setSuccessMessages([
				...successMessages,
				"VIN information has been decoded",
			]);

			var vinData: VinData = {
				extendedVehicleCode: result.data.extendedVehicleCode,
				make:
					validateVehicleMake(result.data.make) === ""
						? firstLetterUppercase(result.data.make)
						: "",
				model:
					validateVehicleModel(result.data.model) === ""
						? firstLetterUppercase(result.data.model)
						: "",
				modelYear:
					validateVehicleYear(result.data.modelYear) === ""
						? result.data.modelYear
						: "",
				curbWeight: result.data.curbWeight,
				price: result.data.price,
			};
			setEditedVinData(vinData);
			setEditedVinDecoded(true);
			setDecodedVehicleMake(vinData.make);
			setDecodedVehicleModel(vinData.model);
			setDecodedVehicleYear(vinData.modelYear);
		} else if (result && result.status === 204) {
			setFailureMessages(["No vehicle information available for this VIN"]);
			setEditedVinDecoded(false);
			setEditedVinData(defaultVinData);
			setDecodedVehicleMake(props.invoiceSummary.invoiceDetails.vehicleMake);
			setDecodedVehicleModel(props.invoiceSummary.invoiceDetails.vehicleModel);
			setDecodedVehicleYear(props.invoiceSummary.invoiceDetails.vehicleYear);
		} else {
			setFailureMessages(["Error decoding VIN"]);
			setEditedVinDecoded(false);
			setEditedVinData(defaultVinData);
			setDecodedVehicleMake(props.invoiceSummary.invoiceDetails.vehicleMake);
			setDecodedVehicleModel(props.invoiceSummary.invoiceDetails.vehicleModel);
			setDecodedVehicleYear(props.invoiceSummary.invoiceDetails.vehicleYear);
		}
		setEditedVinNumber(vin);
		setVinDecodeLoading(false);
	};

	function handleVinDecode(vin: string) {
		if (validateVin(vin) === "") {
			DecodeVin(vin);
		}
	}
	function handleOnChange(event: any) {
		const { name, value } = event.target;
		let fieldErrorMessage = "Error";

		var validator = ValidatorFunctions.find(
			(element) => element.fieldname === name
		);

		if (validator !== undefined) {
			if (validator.fieldname === "noVINReasonDetails") {
				fieldErrorMessage = validateNoVINReasonDetails(
					value,
					editedReasonForNoVin
				);
			} else {
				fieldErrorMessage = validator.validator(value);
			}
			validator.stateSetter(fieldErrorMessage);
		}

		switch (name) {
			case "vehicleVIN":
				setEditedVinNumber(value.trim().toUpperCase());
				setEditedReasonForNoVin("");
				setEditedNoVinReasonDetails("");
				setEditedVinDecoded(false);
				setReasonForNoVinMessage("");
				setNoVINReasonDetailsMessage("");
				break;
			case "reasonForNoVIN":
				if (value !== "Other") {
					setEditedReasonForNoVin(value);
					setEditedVinNumber("");
					setEditedNoVinReasonDetails("");
					setEditedVinDecoded(false);
					setNoVINReasonDetailsMessage("");
					setEditedVinData(defaultVinData);
					setVinMessage("");
				} else {
					setEditedReasonForNoVin(value);
					setEditedVinNumber("");
					setEditedNoVinReasonDetails("");
					setEditedVinDecoded(false);
					setEditedVinData(defaultVinData);
					setVinMessage("");
				}
				break;

			case "noVINReasonDetails":
				setEditedNoVinReasonDetails(value);
				setEditedVinNumber("");
				setEditedVinDecoded(false);
				setVinMessage("");
				setEditedVinData(defaultVinData);
				break;

			default:
				break;
		}
		handleError();
	}

	function HandleOnCloseChange(event: any) {
		props.onHide(false);
		setIsLoading(false);
	}

	async function HandleSave() {
		handleError();
		if (!errorExists) {
			setIsLoading(true);
			const result: any = await updateVehicleVIN(
				props.invoiceSummary.invoiceDetails.id,
				editedVinNumber,
				editedReasonForNoVin,
				editedNoVinReasonDetails,
				editedVinDecoded,
				JSON.stringify(editedVinData),
				decodedVehicleMake,
				decodedVehicleModel,
				decodedVehicleYear
			);
			if (result !== undefined) {
				props.setInvoiceSummary({
					...props.invoiceSummary,
					invoiceDetails: {
						...props.invoiceSummary.invoiceDetails,
						vehicleVIN: editedVinNumber,
						noVINReasonDetails: editedNoVinReasonDetails,
						ReasonForNoVIN: editedReasonForNoVin,
						vinDecoded: editedVinDecoded,
						vinData: editedVinData,
					},
				});
				if (editedVinDecoded) {
					props.setInvoiceSummary({
						...props.invoiceSummary,
						invoiceDetails: {
							...props.invoiceSummary.invoiceDetails,
							vehicleMake: decodedVehicleMake,
							vehicleModel: decodedVehicleModel,
							vehicleYear: decodedVehicleYear,
						},
					});
				}
				props.setNewVersionCreated(true);
			}
			props.onHide(false);
		}
		setIsLoading(false);
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			animation={false}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton={false}>
				<Modal.Title id="contained-modal-title-vcenter">
					<h4>VIN Number</h4>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ToastSuccess
					title="VIN Success"
					setMessages={setSuccessMessages}
					messages={successMessages}
				/>
				<ToastErrors
					title="VIN Failure"
					setMessages={setFailureMessages}
					messages={failureMessages}
				/>
				<p>Please update Vin details here:</p>
				<p />

				<Form.Group controlId="formEntryVINAvailable">
					{RenderRadioButtons(
						"Vin Available",
						"VinAvailable",
						VINAvailable,
						handleOnChangeRadio
					)}
				</Form.Group>
				{VINAvailable && (
					<Form.Group controlId="formEntryVehicleVIN">
						<div>
							<Form.Label className="formLabelText">VIN</Form.Label>
							<div className="inline">
								<Form.Control
									type="text"
									placeholder="VIN"
									data-testid="formEntryVehicleVIN"
									onChange={handleOnChange}
									name="vehicleVIN"
									value={editedVinNumber}
									isValid={editedVinDecoded}
								/>
								{!vinDecodeLoading ? (
									<OverlayTrigger
										overlay={<Tooltip id="decodeToolTip">Decode VIN</Tooltip>}
										transition={false}
									>
										{({ ref, ...triggerHandler }) => (
											<Button
												{...triggerHandler}
												ref={ref}
												id="vinSearchButton"
												className="vinSearchButton"
												variant="outline-primary"
												data-testid={"vinSearchButton"}
												name={"Decode Vin"}
												onClick={() => {
													handleVinDecode(editedVinNumber);
												}}
											>
												<Search size={20} />
											</Button>
										)}
									</OverlayTrigger>
								) : (
									<Spinner
										id="vinDecodeLoadingSpinner"
										animation="border"
										role="status"
										className="spinner"
									/>
								)}
							</div>
							{vinMessage !== "" && <Error message={vinMessage} />}
						</div>
						{editedVinDecoded && (
							<Alert variant="info" id="decodedVehicleInfoContainer">
								<div className="vehicleInfoheader">
									Vehicle Information Retrieved:
								</div>

								<Row xs={2} sm={2} md={2} lg={2}>
									<Col className="summaryLabel" md={3} lg={3}>
										Make&nbsp;:
									</Col>
									<Col className="summaryField" md={6} lg={6}>
										{decodedVehicleMake}
									</Col>
								</Row>
								<Row xs={2} sm={2} md={2} lg={2}>
									<Col className="summaryLabel" md={3} lg={3}>
										Model&nbsp;:
									</Col>
									<Col className="summaryField" md={6} lg={6}>
										{decodedVehicleModel}
									</Col>
								</Row>
								<Row xs={2} sm={2} md={2} lg={2}>
									<Col className="summaryLabel" md={3} lg={3}>
										Year&nbsp;:
									</Col>
									<Col className="summaryField" md={6} lg={6}>
										{decodedVehicleYear}
									</Col>
								</Row>
							</Alert>
						)}
					</Form.Group>
				)}
				{!VINAvailable && (
					<Form.Group controlId="formEntryReasonForNoVin">
						<div className="formLabelText">
							<Form.Label className="formVINLabelText">
								Reason for No VIN
							</Form.Label>
							<Form.Control
								className="formReasonForNoVIN"
								as="select"
								placeholder=""
								data-testid="formEntryReasonForNoVin"
								onChange={handleOnChange}
								onBlur={handleOnChange}
								name="reasonForNoVIN"
								value={editedReasonForNoVin}
							>
								{Object.keys(ReasonForNoVIN).map((reason, index) => {
									return (
										<option key={index}>
											{Object.values(ReasonForNoVIN)[index]}
										</option>
									);
								})}
							</Form.Control>
							{reasonForNoVinMessage !== "" && (
								<Error message={reasonForNoVinMessage} />
							)}
						</div>
					</Form.Group>
				)}
				{!VINAvailable && editedReasonForNoVin === "Other" && (
					<Form.Group controlId="formEntryNoVINReasonDetails">
						<div className="formLabelText">
							<Form.Label className="formVINLabelText">
								Reason Details
							</Form.Label>
							<Form.Control
								className="formNoVINReasonDetails"
								as="textarea"
								rows={2}
								type="text"
								placeholder=""
								data-testid="formEntryNoVINReasonDetails"
								onChange={handleOnChange}
								onBlur={handleOnChange}
								name="noVINReasonDetails"
								maxLength={500}
								value={editedNoVinReasonDetails}
							/>
							{noVINReasonDetailsMessage !== "" && (
								<Error message={noVINReasonDetailsMessage} />
							)}
						</div>
					</Form.Group>
				)}
			</Modal.Body>

			<Modal.Footer className="left">
				<Button
					variant="outline-secondary"
					onClick={HandleOnCloseChange}
					data-testid="closeVinNumberButton"
				>
					Close
				</Button>
				<Button
					variant="primary"
					onClick={HandleSave}
					data-testid="saveVinNumberButton"
					disabled={vinDecodeLoading}
				>
					{isLoading && <Spinner animation="border" role="status" />}
					{!isLoading && "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
