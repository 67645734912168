import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Prompt } from "react-router";

import {
	defaultNewInvoiceDetails,
	emptyAnnotations,
	AnnotationType,
	PayeeInformation,
	AnnotationStatus,
} from "../../types/Invoice";
import { Error } from "../../components/Alerts/Error";
import "./InvoiceDetails.css";
import { iLineItem } from "../../types/LineItems";
import currencyFormat, {
	calculateGst,
	convertDateToStringFormat,
	GST_RATE,
	isNullorEmpty,
	LogToAppInsights,
	showGstInformation,
} from "../../utils/Utils";

import { getRatesWithinEffectiveDateApi } from "../../api/ratesApi";
import { rateDataTransformer } from "../../utils/RateDataTransformer";
import { getInvoiceByInvoiceId, postInvoice } from "../../api/apiInvoice";

import { VehicleInformation } from "../../components/VehicleInformation/VehicleInformation";
import { VehicleStatus } from "../../components/VehicleStatus/VehicleStatus";
import { TowerInformation } from "../../components/TowerInformation/TowerInformation";
import { InvoiceLine } from "../../components/InvoiceLines/InvoiceLine";
import { SpecialItems } from "../../components/SpecialItems/SpecialItems";
import { AccidentInformation } from "../../components/AccidentInformation/AccidentInformation";
import { ImageUpload } from "../../components/ImageUpload/ImageUpload";
import { postAction } from "../../types/postAction";
import { invoiceDataTransformer } from "../../utils/InvoiceDataTransformer";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { Photo } from "../../types/Photo";
import {
	AnnotationDisplay,
	AnnotationHeader,
	AnnotationFooter,
} from "../../components/AnnotationDisplay/AnnotationDisplay";
import { InvoiceStatusDecider } from "../../utils/InvoiceStatusDecider";

import { ToastErrors } from "../../components/Toast/ToastErrors";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "../../AppInsights";
import { WeightClassSelector } from "../../components/ServiceClass/WeightClassSelector";
import { useTypedSelector } from "../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";
import { GetUserPermissions } from "../../utils/auth/authUtilities";
import { UserDetails } from "../../types/UserDetails";
import useUnsavedChangesWarning from "../../utils/UnsavedChangesHook";

export function InvoiceDetails(props: any) {
	const history = useRef(useHistory());
	const permissions = GetUserPermissions();
	const login = useTypedSelector((state) => state.login);
	const hasPayeeNumber = login.payeeNumber !== "" && login.payeeNumber !== null;
	const payeeInformation: PayeeInformation = {
		payeeName: login.companyName,
		payeeNumber: login.payeeNumber,
	};
	const { invoiceId } = useParams<{ invoiceId: string }>();
	const { claimNumber } = useParams<{ claimNumber: string }>();
	const [invoiceDetails, setInvoiceDetails] = useState(
		defaultNewInvoiceDetails
	);

	const [invoiceTotalBeforeGst, setInvoiceTotalBeforeGst] = useState(0);

	const [vehicleInformationValid, setVehicleInformationValid] = useState(false);
	const [vehicleStatusValid, setVehicleStatusValid] = useState(false);
	const [towerInformationValid, setTowerInformationValid] = useState(false);
	const [basicItemsValid, setBasicItemsValid] = useState(false);
	const [specialItemsValid, setSpecialItemsValid] = useState(false);
	const [photosValid, setPhotosValid] = useState(false);
	const [accidentInformationValid, setAccidentInformationValid] =
		useState(false);

	const defaultLineItems: iLineItem[] = [];
	const [lineOptions, setLineOptions] = useState(defaultLineItems);
	const [specialLineItems, setSpecialLineItems] = useState(defaultLineItems);
	const [basicLineItems, setBasicLineItems] = useState(defaultLineItems);
	const emptyPhotos: Photo[] = [];
	const [photosBase64, setPhotosBase64] = useState(emptyPhotos);
	const [annotations, setAnnotations] = useState(emptyAnnotations);

	const [lineItemsError, setLineItemsError] = useState("");
	const [showAllFieldErrors, setShowAllFieldErrors] = useState(false);
	const [allFieldsValid, setAllFieldsValid] = useState(true);
	const [loading, setLoading] = useState(true);
	const [isDraft, setIsDraft] = useState(false);
	const [apiUnavailable, setApiUnavailable] = useState(false);
	const [submitSaveUploadButtonDisable, setSubmitSaveUploadButtonDisable] =
		useState(false);
	const emptyStringArray: string[] = [];
	const [submitErrorMessages, setSubmitErrorMessages] =
		useState(emptyStringArray);

	const [copyFromClaimNumber, setCopyFromClaimNumber] = useState(claimNumber);
	const [allLineOptions, setAllLineOptions] = useState(defaultLineItems);

	const { isChanged, onDirty, onPristine } = useUnsavedChangesWarning();
	const notInitialRender = useRef(false);
	const dataLoaded = useRef(false);

	const [submitSaveMessage, setSubmitSaveMessage] = useState("");
	const [showSubmitSaveMessage, setShowSubmitSaveMessage] = useState(false);
	var vendorInfo: UserDetails = {
		authUserId: login.authUserId,
		fullName: login.fullName,
		email: login.email,
	};
	const reduxConnectionStatus = useTypedSelector(
		(state) => state.connectionStatus
	);
	const hasPayeeNumberErrorMessage =
		"Uh-oh! Looks like your company's Payee Number hasn't been set in our system. Please contact SGI support.";

	useEffect(() => {
		let invoiceTotal =
			specialLineItems.reduce(
				(accu: number, item: iLineItem) => accu + item.lineTotal,
				0
			) +
			basicLineItems.reduce(
				(accu: number, item: iLineItem) => accu + item.lineTotal,
				0
			);
		setInvoiceTotalBeforeGst(invoiceTotal);
		// add GST
		if(showGstInformation(invoiceDetails.submittedDate)) invoiceTotal = invoiceTotal + (invoiceTotal * GST_RATE);
		
		invoiceTotal = Math.round((invoiceTotal + Number.EPSILON) * 100) / 100;
		
		if (invoiceTotal !== invoiceDetails.requestedPayment) {
			setInvoiceDetails({ ...invoiceDetails, requestedPayment: invoiceTotal });
		}
	}, [
		submitSaveUploadButtonDisable,
		specialLineItems,
		basicLineItems,
		invoiceDetails,
	]);

	useEffect(() => {
		if (!reduxConnectionStatus.connected) {
			setSubmitSaveMessage("Internet Connection required");
			setShowSubmitSaveMessage(false);
		}
		if (reduxConnectionStatus.connected) {
			setSubmitSaveMessage("");
			setShowSubmitSaveMessage(false);
		}
	}, [reduxConnectionStatus.connected]);

	useEffect(() => {
		if (
			specialLineItems.length === 0 &&
			basicLineItems.length === 0 &&
			showAllFieldErrors
		)
			setLineItemsError("At least one line item must be added.");
		else setLineItemsError("");
	}, [specialLineItems, basicLineItems, showAllFieldErrors]);

	if (lineOptions === defaultLineItems && invoiceId === undefined) {
		setInvoiceDetails({
			...invoiceDetails,
			createdBy: vendorInfo,
			photos: [],
		});
		getApiRates(convertDateToStringFormat(new Date()));
	}
	if (invoiceDetails.createdBy === null) {
		setInvoiceDetails({
			...invoiceDetails,
			createdBy: vendorInfo,
		});
	}
	async function getApiRates(date: string) {
		const apiRates = await getRatesWithinEffectiveDateApi(date);
		if (apiRates == null) {
			setApiUnavailable(true);
		} else {
			const transformedData = rateDataTransformer(apiRates);
			setAllLineOptions(transformedData);
			var lineOptionsByWeightClass = transformedData.filter(
				(x) => x.weightClass === invoiceDetails.weightClass
			);
			setLineOptions(lineOptionsByWeightClass);
			var defaultSelected: iLineItem[] = [];
			setBasicLineItems(defaultSelected);
		}
	}
	useEffect(() => {
		async function loadSavedData() {
			window.scrollTo({ top: 0, behavior: "smooth" });

			if (invoiceId !== undefined) {
				const result: any = await getInvoiceByInvoiceId(invoiceId);
				const serviceDate = result.data.serviceDate
					? result.data.serviceDate
					: convertDateToStringFormat(new Date());
				const apiRates = await getRatesWithinEffectiveDateApi(serviceDate);
				if (
					apiRates &&
					result !== undefined &&
					(result.data.statusDisplay === "Draft" ||
						result.data.statusDisplay === "Action Required") &&
					result.data.payeeNumber === props.reduxLogin.payeeNumber
				) {
					var mapData = invoiceDataTransformer(result);
					var basicLine = mapData.invoiceLineItems.filter(
						(x) => x.isStandard === true
					);
					var specialLine = mapData.invoiceLineItems.filter(
						(x) => x.isStandard !== true
					);
					var returnedPhotosBase64 = JSON.parse(
						mapData.invoiceDetails.photosBase64
					);
					setIsDraft(true);
					setInvoiceDetails({ ...mapData.invoiceDetails });
					setAnnotations(mapData.invoiceAnnotations);
					const transformedData = rateDataTransformer(apiRates);
					var lineOptionsByWeightClass = transformedData.filter(
						(x) => x.weightClass === mapData.invoiceDetails.weightClass
					);
					setAllLineOptions(transformedData);
					setLineOptions(lineOptionsByWeightClass);
					setBasicLineItems(basicLine);
					setSpecialLineItems(specialLine);
					setPhotosBase64(returnedPhotosBase64);
					dataLoaded.current = true;
				} else {
					setApiUnavailable(true);
				}
			}
			setLoading(false);
		}
		loadSavedData();
	}, [invoiceId, props.reduxLogin.payeeNumber]);
	useEffect(() => {
		if (!allFieldsValid && isDraft) {
			setShowAllFieldErrors(true);
		}
	}, [allFieldsValid, isDraft]);

	useEffect(() => {
		setAllFieldsValid(
			towerInformationValid &&
				vehicleInformationValid &&
				vehicleStatusValid &&
				basicItemsValid &&
				specialItemsValid &&
				accidentInformationValid &&
				photosValid
		);
	}, [
		towerInformationValid,
		vehicleInformationValid,
		vehicleStatusValid,
		basicItemsValid,
		specialItemsValid,
		accidentInformationValid,
		photosValid,
	]);

	async function handleOnSubmit(event: any) {
		if (!reduxConnectionStatus.connected) {
			setShowSubmitSaveMessage(true);
			return;
		}

		event.preventDefault();
		setSubmitSaveUploadButtonDisable(true);

		if (allFieldsValid) {
			const status = InvoiceStatusDecider(invoiceDetails.status, "Submit");
			const allLineItems = basicLineItems.concat(specialLineItems);

			var newAnnotations = annotations.map((element) => {
				return {
					...element,
					status:
						element.status === AnnotationStatus.Requested
							? AnnotationStatus.Complete
							: element.status,
				};
			});
			setAnnotations(newAnnotations);

			try {
				const response: any = await postInvoice(
					props.vendorId,
					{ ...invoiceDetails, submittedBy: vendorInfo },
					allLineItems,
					photosBase64,
					postAction.Submit,
					newAnnotations,
					status,
					payeeInformation
				);

				if (
					response === null ||
					response === undefined ||
					response.status < 200 ||
					response.status > 300
				) {
					if (appInsights !== undefined) {
						appInsights.trackTrace({
							message: "Error submitting invoice: " + response,
							severityLevel: SeverityLevel.Error,
						});
					}
					setSubmitErrorMessages([
						"Error submitting invoice. Please try again or contact SGI support.",
					]);
				} else {
					onPristine();
					props.setConfirmationNumber(response.data.invoiceId);
					history.current.push("/Invoices/SubmitInvoiceConfirmationPage");
				}
			} catch (error) {
				LogToAppInsights(
					"Error when handling invoice submission response: " + error,
					SeverityLevel.Error
				);
			}
		} else {
			setIsDraft(false);
			setShowAllFieldErrors(true);
		}
		setSubmitSaveUploadButtonDisable(false);
	}

	async function handleSave(event: any) {
		if (!reduxConnectionStatus.connected) {
			setShowSubmitSaveMessage(true);
			return;
		}

		setSubmitSaveUploadButtonDisable(true);

		const allLineItems = basicLineItems.concat(specialLineItems);
		const status = InvoiceStatusDecider(invoiceDetails.status, "Save");

		try {
			const response: any = await postInvoice(
				props.vendorId,
				invoiceDetails,
				allLineItems,
				photosBase64,
				postAction.Save,
				annotations,
				status,
				payeeInformation
			);
			if (
				response === null ||
				response === undefined ||
				response.status < 200 ||
				response.status > 300
			) {
				setSubmitErrorMessages([
					"Error saving invoice. Please try again or contact SGI support.",
				]);
			} else {
				onPristine();
				history.current.push("/Invoices/List");
			}
		} catch {
			LogToAppInsights(
				"Error when handling invoice Saving response",
				SeverityLevel.Error
			);
		}

		setSubmitSaveUploadButtonDisable(false);
	}

	useEffect(() => {
		if (notInitialRender.current && (!invoiceId || dataLoaded.current)) {
			onDirty();
		} else {
			notInitialRender.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceDetails]);

	if (loading) {
		return <PageSpinner />;
	} else if (apiUnavailable) {
		return <ApiUnavailable />;
	} else
		return (
			<Container className="invoiceDetailsContainer">
				<Prompt
					when={isChanged}
					message="You have unsaved changes, are you sure you want to leave?"
				/>
				<ToastErrors
					title="Invoice Submission"
					setMessages={setSubmitErrorMessages}
					messages={submitErrorMessages}
				/>
				<Row>
					<div className="invoiceSubmissionHeader">Invoice Submission</div>
					{annotations.length !== 0 && <AnnotationHeader />}
					{!hasPayeeNumber && (
						<Error
							message={hasPayeeNumberErrorMessage}
							dataTestid="noPayeeErrTop"
						/>
					)}
				</Row>
				<Form onSubmit={handleOnSubmit}>
					<TowerInformation
						isValid={setTowerInformationValid}
						showErrors={showAllFieldErrors}
						invoiceDetails={invoiceDetails}
						basicLineItems={basicLineItems}
						setBasicLineItems={setBasicLineItems}
						specialLineItems={specialLineItems}
						setSpecialLineItems={setSpecialLineItems}
						setInvoiceDetails={setInvoiceDetails}
						refreshTowRates={getApiRates}
						isDraft={isDraft}
						isVehicleInformationValid={setVehicleInformationValid}
						isVehicleStatusValid={setVehicleStatusValid}
						claimNumber={copyFromClaimNumber}
						setClaimNumber={setCopyFromClaimNumber}
					/>
					<hr className="separator" />
					{!invoiceDetails.subsequentTow && (
						<>
							<VehicleInformation
								isValid={setVehicleInformationValid}
								showErrors={showAllFieldErrors}
								invoiceDetails={invoiceDetails}
								setInvoiceDetails={setInvoiceDetails}
								isDraft={isDraft}
							/>
							<hr className="separator" />
							<VehicleStatus
								isValid={setVehicleStatusValid}
								showErrors={showAllFieldErrors}
								invoiceDetails={invoiceDetails}
								setInvoiceDetails={setInvoiceDetails}
								isDraft={isDraft}
							/>
							<hr className="separator" />
						</>
					)}

					<AccidentInformation
						isValid={setAccidentInformationValid}
						showErrors={showAllFieldErrors}
						invoiceDetails={invoiceDetails}
						setInvoiceDetails={setInvoiceDetails}
						isDraft={isDraft}
					/>
					<hr className="separator" />
					<Form.Group className="weightSelector">
						<WeightClassSelector
							invoiceDetails={invoiceDetails}
							setInvoiceDetails={setInvoiceDetails}
							isDraft={isDraft}
							setLineOptions={setLineOptions}
							basicLineItems={basicLineItems}
							setBasicLineItems={setBasicLineItems}
							allLineOptions={allLineOptions}
							setSpecialLineItems={setSpecialLineItems}
							specialLineItems={specialLineItems}
						/>
					</Form.Group>
					<hr className="separator" />
					<Form.Group>
						<Row>
							<InvoiceLine
								isValid={setBasicItemsValid}
								showErrors={showAllFieldErrors}
								lineOptions={lineOptions}
								selectedLineItems={basicLineItems}
								setSelectedLineItems={setBasicLineItems}
								invoiceDetails={invoiceDetails}
								isDraft={isDraft}
							/>
							<AnnotationDisplay
								component={AnnotationType.BasicItems}
								annotations={annotations}
								setAnnotations={setAnnotations}
								lineOptions={lineOptions}
							/>
						</Row>
					</Form.Group>
					<hr className="separator" />
					<Form.Group>
						<Row>
							<SpecialItems
								isValid={setSpecialItemsValid}
								showErrors={showAllFieldErrors}
								lineOptions={lineOptions}
								specialLineItems={specialLineItems}
								setSpecialLineItems={setSpecialLineItems}
								invoiceDetails={invoiceDetails}
								isDraft={isDraft}
							/>
							<AnnotationDisplay
								component={AnnotationType.SpecialItems}
								annotations={annotations}
								setAnnotations={setAnnotations}
								lineOptions={lineOptions}
							/>
						</Row>
					</Form.Group>
					{lineItemsError !== "" && <Error message={lineItemsError} />}
					<hr className="separator" />

					<Row>
						<ImageUpload
							invoiceDetails={invoiceDetails}
							setInvoiceDetails={setInvoiceDetails}
							photosBase64={photosBase64}
							setPhotosBase64={setPhotosBase64}
							setSubmitSaveUploadButtonDisable={
								setSubmitSaveUploadButtonDisable
							}
							submitSaveUploadButtonDisable={submitSaveUploadButtonDisable}
							showErrors={showAllFieldErrors}
							isValid={setPhotosValid}
						/>
					</Row>
					{showGstInformation(invoiceDetails.submittedDate) && 
					<Row>
						<Col>
							<h5 className="InvoiceTotal" data-testid="InvoiceGstTotal">
								GST (5%): {calculateGst(invoiceTotalBeforeGst)}
							</h5>
						</Col>
					</Row>
					}
					
					<Row>
						<Col>
							<h5 className="InvoiceTotal" data-testid="InvoiceTotal">
								Invoice Total: {currencyFormat(invoiceDetails.requestedPayment)}
							</h5>
						</Col>
					</Row>
					<hr className="separator" />
					{!isNullorEmpty(submitSaveMessage) && showSubmitSaveMessage && (
						<Row className="flexBasis100 flexEnd">
							<Col className="maxWidth600">
								<Alert className="StatusAlert mb-3 ml-0 mt-0" variant="danger">
									<FontAwesomeIcon
										className="mr-2"
										icon={faExclamationTriangle}
									/>
									<span>{submitSaveMessage}</span>
								</Alert>
							</Col>
						</Row>
					)}
					<Row className="flexRight alignRight">
						{annotations.length !== 0 && <AnnotationFooter />}
						{permissions?.canSaveInvoice && (
							<Col className="maxWidth400">
								<Form.Group controlId="formEntrySaveButton">
									<Button
										id="formEntrySaveButton"
										data-testid="formEntrySaveButton"
										variant={
											reduxConnectionStatus.connected
												? "outline-primary"
												: "outline-secondary"
										}
										disabled={submitSaveUploadButtonDisable}
										onClick={handleSave}
									>
										Save Invoice and Exit
									</Button>
								</Form.Group>
							</Col>
						)}
						{permissions?.canSubmitInvoice && (
							<Col className="maxWidth300 alignRight">
								<Form.Group controlId="formEntrySubmitButton">
									<Button
										id="formEntrySubmitButton"
										data-testid="formEntrySubmitButton"
										disabled={submitSaveUploadButtonDisable || !hasPayeeNumber}
										variant={
											reduxConnectionStatus.connected
												? "outline-primary"
												: "outline-secondary"
										}
										onClick={handleOnSubmit}
									>
										Submit Invoice
									</Button>
									{!allFieldsValid && showAllFieldErrors && !isDraft && (
										<Error message="Field corrections required before submitting" />
									)}
								</Form.Group>
							</Col>
						)}
						{!hasPayeeNumber && (
							<Error
								message={hasPayeeNumberErrorMessage}
								dataTestid="noPayeeErrBottom"
							/>
						)}
					</Row>
				</Form>
			</Container>
		);
}
