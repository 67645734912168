import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { updateStatus } from "../../api/apiInvoice";
import { ReasonForDeletion } from "../../types/Invoice";
import { validateDeletionReasonDetails } from "../../utils/Validators/DeletionConfirmationValidator";
import { Error } from "../Alerts/Error";
interface ModalProps {
  show: any;
  onHide: any;
  invoiceId: any;
  setToastErrors: any;
  plate: any;
  towerReference: any;
  claimNumber: any;
}
export function ModalDeletion(props: ModalProps) {
  const history = useHistory();
  const [deletionReasonDetails, setDeletionReasonDetails] = useState("");
  const [reasonForDeletion, setReasonForDeletion] = useState("No Claim");
  var errorExists = false;
  const [deletionErrorMessage, setDeletionErrorMessage] = useState("");

  function handleError(deletionContents: string) {
    var errorMessage = "";
    errorMessage = validateDeletionReasonDetails(deletionContents);
    if (errorMessage !== "") {
      setDeletionErrorMessage(errorMessage);
      errorExists = true;
    } else {
      setDeletionErrorMessage("");
      errorExists = false;
    }
  }

  function handleOnChange(event: any) {
    const { name, value } = event.target;

    if (name === "reasonForDeletion") {
      setReasonForDeletion(value);
      if (value === ReasonForDeletion.Other) {
        handleError(deletionReasonDetails);
      } else {
        setDeletionErrorMessage("");
      }
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Invoice Deletion Confirmation</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="deletionComfirmationBody">
          <h5>Are you sure you want to delete this invoice?</h5>
          {props.towerReference && (
            <h6 className="DeleteInvoice">
              Towing Invoice #: <span>{props.towerReference}</span>
            </h6>
          )}
          {props.plate && (
            <h6 className="DeleteInvoice">
              Plate #: <span>{props.plate}</span>
            </h6>
          )}
          {props.claimNumber && (
            <h6 className="DeleteInvoice">
              Claim #: <span>{props.claimNumber}</span>
            </h6>
          )}
        </div>
        <p />
        <Form.Group controlId="formEntryReasonForDeletion">
          <Form.Label className="formLabelText">
            Please enter reason for deletion:
          </Form.Label>
          <Form.Control
            as="select"
            placeholder=""
            data-testid="formEntryReasonForDeletion"
            onChange={handleOnChange}
            onBlur={handleOnChange}
            name="reasonForDeletion"
            defaultValue="No Claim"
          >
            {Object.keys(ReasonForDeletion).map((reasonForDeletion, index) => {
              return (
                <option key={index}>
                  {Object.values(ReasonForDeletion)[index]}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        {reasonForDeletion === "Other" && (
          <Form.Group controlId="deletionReasonDetails">
            <Form.Control
              as="textarea"
              rows={4}
              maxLength={500}
              type="textarea"
              placeholder=""
              onChange={(e) => {
                HandleChange(e);
              }}
              data-testid="deletionReasonDetails"
              name="deletionReasonDetails"
              value={deletionReasonDetails}
            />
          </Form.Group>
        )}
        &nbsp;
      </Modal.Body>
      {deletionErrorMessage !== "" && <Error message={deletionErrorMessage} />}
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleOnCancel}
          data-testid="cancelDeletionButton"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            HandleDeletion();
          }}
          data-testid="detionButton"
        >
          Marked Invoice As Deleted
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleChange(event: any) {
    setDeletionReasonDetails(event.target.value);
    handleError(event.target.value);
  }

  function HandleOnCancel(event: any) {
    setDeletionReasonDetails("");
    setReasonForDeletion("");
    props.onHide(false);
    setDeletionErrorMessage("");
  }

  async function HandleDeletion() {
    if (reasonForDeletion === ReasonForDeletion.Other) {
      handleError(deletionReasonDetails);
    }
    var response: any;
    if (!errorExists) {
      try {
        response = await updateStatus(
          props.invoiceId,
          6,
          reasonForDeletion,
          deletionReasonDetails
        );
        props.onHide(false);
      } catch (error) {
        props.setToastErrors(["Error deleting invoice"]);
      }
      if (response) {
        history.push("/Employee/Invoices/List");
      } else {
        props.setToastErrors(["Error deleting invoice"]);
      }
    }
  }
}
