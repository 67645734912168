import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import "./TowerInvoiceSummary.css";
import { getInvoiceByInvoiceId } from "../../api/apiInvoice";
import { defaultNewInvoice } from "../../types/Invoice";
import { TowerInvoiceSummaryDetails } from "./TowerInvoiceSummaryDetails";
import { invoiceDataTransformer } from "../../utils/InvoiceDataTransformer";
import { convertDateToDisplayFormatWithTime } from "../../utils/Utils";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";

export function TowerInvoiceSummary(props: any) {
  const history = useHistory();
  const [apiUnavailable, setApiUnavailable] = useState(false);
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [invoiceSummary, setInvoiceSummary] = useState(defaultNewInvoice);
  const urlInvoiceSubsequent =
    "/Invoices/SubmitSubsequentInvoice/" +
    invoiceSummary.invoiceDetails.claimNumber;

  useEffect(() => {
    async function getData() {
      const invoiceSummaryData: any = await getInvoiceByInvoiceId(invoiceId);
      if (invoiceSummaryData) {
        const transformedSummaryData =
          invoiceDataTransformer(invoiceSummaryData);
        setInvoiceSummary(transformedSummaryData);
      } else {
        setApiUnavailable(true);
      }
    }
    getData();
  }, [invoiceId]);

  function HandlePrint() {
    window.print();
  }

  if (apiUnavailable) {
    return <ApiUnavailable />;
  } else
    return (
      <>
        {invoiceSummary !== defaultNewInvoice && (
          <Container className="invoiceSummaryContainer">
            <Row className="invoiceSummaryRow">
              <div className="returnButtonDiv">
                {invoiceSummary.invoiceDetails.claimNumber &&
                  invoiceSummary.invoiceDetails.status !== 1 &&
                  invoiceSummary.invoiceDetails.status !== 6 && (
                    <div className="printButtonDiv">
                      <Button
                        id="towerInvoiceSubsequentButton"
                        data-testid="towerInvoiceSubsequentButton"
                        variant="outline-primary"
                        onClick={() => history.push(urlInvoiceSubsequent)}
                      >
                        Add Subsequent Invoice
                      </Button>
                    </div>
                  )}
                <div className="printButtonDiv">
                  <Button
                    id="printButton"
                    data-testid="printButton"
                    variant="outline-primary"
                    onClick={HandlePrint}
                  >
                    Print
                  </Button>
                </div>
                <Button
                  id="returnButton"
                  data-testid="ReturnToListButton"
                  variant="outline-primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Return to List
                </Button>
              </div>
            </Row>
            <Row className="invoiceSummaryRow">
              <div className="invoiceSummaryHeader">Invoice Summary</div>
            </Row>
            {invoiceSummary.invoiceDetails.paidDate && (
              <>
                <Row className="invoiceSummaryRow">
                  <div
                    className="invoiceSummaryPaidDate"
                    data-testid="PaidDateField"
                  >
                    {" "}
                    <b>Paid:</b>{" "}
                    {convertDateToDisplayFormatWithTime(
                      invoiceSummary.invoiceDetails.paidDate
                    )}
                  </div>
                </Row>
              </>
            )}
            {invoiceSummary.invoiceDetails.deletedDate && (
              <>
                <Row className="invoiceSummaryRow">
                  <div
                    className="invoiceSummaryDeletedDate"
                    data-testid="DeletedDateField"
                  >
                    {" "}
                    <b>Deleted:</b>{" "}
                    {convertDateToDisplayFormatWithTime(
                      invoiceSummary.invoiceDetails.deletedDate
                    )}
                  </div>
                </Row>
              </>
            )}
            <TowerInvoiceSummaryDetails invoiceSummary={invoiceSummary} />
          </Container>
        )}
      </>
    );
}
