
export function StorageListTransformer(inventoryList: any[]): any[] {
  return inventoryList.map(item => {
    return {
      claimNumber: item.claimNumber,
      vendor: item.payeeName,
      vin: item.vehicleVIN,
      plate: item.vehiclePlate,
      vehicleDetails: `${item.vehicleYear} ${item.vehicleMake} ${item.vehicleModel}`,
      dateOfTow: item.serviceDate,
      daysInCompound: item.totalImpoundmentDays,
      location: JSON.parse(item.locations)?.towedTo?.formattedAddressFull
    }
  })
}