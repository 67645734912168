import { iBulletinList } from "../types/BulletinList";

export function mapToBulletinList(apiData: any): iBulletinList[] {
	var returnData: iBulletinList[] = [];

	apiData.forEach((item: any) => {
		var newReturnData: iBulletinList = {
			title: item.title,
            createdOn: item.createdOn,
            uri: item.id,
			id: item.id,
			extension: item.extension
		};

		returnData.push(newReturnData);
	});

	return returnData;
}
