import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import "./File.css"

interface Props {
  required?: boolean;
  onFileSelect: any;
  multiple?: boolean;
  selectedFile: any;
  testId?: string;
  accept?: string;
  onFileRemove(): any;
  useDescription?: boolean;
  descriptionPlaceholder?: string;
  descriptionRequired?: boolean;
  onDescriptionChange?(index: number, value: string): any;
}

const File = ({
  required = false,
  onFileSelect,
  selectedFile,
  onFileRemove,
  accept,
  testId,
  useDescription = false,
  descriptionRequired = false,
  descriptionPlaceholder = "Description",
  onDescriptionChange,
}: Props) => {
  library.add(faWindowClose);

  return (
    <div className="fileWrapper" data-testid="form-attachment">
      <div className="inputWrapper">
        Click here to select a file, or drag and drop
        <input
          required={required}
          accept={accept}
          data-testid={testId}
          type="file"
          name="fileUpload"
          id="fileUpload"
          className="file"
          multiple={false}
          onChange={(e: any) => {
            const files: File[] = Array.from(e.target.files ?? []);
            onFileSelect(files[0]);
          }}
        />
      </div>

      {selectedFile !== undefined && (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <span style={{ whiteSpace: "nowrap", padding: " 0 0.5rem" }}>
            {selectedFile?.name ?? ""}
          </span>
          {useDescription && (
            <Form.Control
              required={descriptionRequired}
              style={{
                marginLeft: "1em",
              }}
              data-testid="file-description-0"
              placeholder={descriptionPlaceholder}
              onChange={(e: any) => onDescriptionChange?.(0, e.target.value)}
            />
          )}
          <div
            style={{
              display: "inline-flex",
              borderRadius: "50%",
              overflow: "hidden",
              margin: "0 0.7em",
              flexShrink: 0,
            }}
            onClick={() => onFileRemove()}
            data-testid="file-remove"
          >
            <FontAwesomeIcon className="delete-button" icon="window-close" />
          </div>
        </div>
      )}
      <Form.Control.Feedback
        type="invalid"
        data-testid={testId ? testId + "-error" : undefined}
      >
        Attachment is required
      </Form.Control.Feedback>
    </div>
  );
};

export default File;
