import React from "react";
import { Row, Table } from "react-bootstrap";
import { LineDifference, DifferenceType } from "../../utils/diffInvoices";
import EmployeeInvoiceDeletedLineItemDiff from "../EmployeeInvoiceLineItemDiffs/EmployeeInvoiceDeletedLineItemDiff";

export function EmployeeSummaryLineDiffs(props: any) {
  return (
    <>
      {props.lineDiffs.length > 0 &&
        props.lineDiffs.filter(
          (item: LineDifference) => item.diffType === DifferenceType.Deleted
        ).length > 0 && (
          <>
            <Row>
              <h5 data-testid="LineDiffs-Title">
                Deleted from Previous Submission:
              </h5>
            </Row>
            <Row className="InvoiceSummaryLineItems">
              <Table className="InvoiceSummaryLineItemTable">
                <thead>
                  <tr>
                    <th>Work Item</th>
                    <th>Action</th>
                    <th>Previous Units</th>
                  </tr>
                </thead>
                <tbody>
                  {props.lineDiffs.map((lineDiff: LineDifference) => {
                    return (
                      <EmployeeInvoiceDeletedLineItemDiff
                        lineDiff={lineDiff}
                        key={lineDiff.name + lineDiff.id}
                        annotations={props.annotations}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </>
        )}
    </>
  );
}
