import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { iLineItem } from "../../types/LineItems";
import { Error } from "../Alerts/Error";
import {
	convertDatetoMilliseconds,
	convertDateToStringFormat,
} from "../../utils/Utils";
import "./InvoiceLine.css";
import { InvoiceLineItem } from "./InvoiceLineItem/InvoiceLineItem";

export function InvoiceLine(props: any) {
	const emptyStringArray: string[] = [];
	const [errorMessage, setErrorMessage] = useState(emptyStringArray);
	const [itemsAreValid, setItemsAreValid] = useState(false);

	const currentDateMilliseconds = convertDatetoMilliseconds(
		convertDateToStringFormat(new Date())
	);
	const serviceDateMilliseconds = convertDatetoMilliseconds(
		props.invoiceDetails.serviceDate
	);
	let displayLineItems = props.lineOptions.filter(
		(i: iLineItem) => i.isStandard
	);

	const { isValid } = props;

	useEffect(() => {
		let allItemsValid = props.selectedLineItems.length > 0;
		props.selectedLineItems.forEach((f: iLineItem) => {
			let fromDateMilliseconds = convertDatetoMilliseconds(f.lineData.fromDate);
			let toDateMilliseconds = convertDatetoMilliseconds(f.lineData.toDate);
			if (f.qty === 0 || f.qty === undefined) {
				allItemsValid = false;
			}
			if (Object.keys(f.lineData).length > 0) {
				if (
					fromDateMilliseconds > currentDateMilliseconds ||
					toDateMilliseconds > currentDateMilliseconds
				) {
					allItemsValid = false;
				}
				if (
					fromDateMilliseconds === null ||
					toDateMilliseconds === null ||
					isNaN(fromDateMilliseconds) ||
					isNaN(toDateMilliseconds) ||
					fromDateMilliseconds === 0 ||
					toDateMilliseconds === 0
				) {
					allItemsValid = false;
				}
				if (fromDateMilliseconds > toDateMilliseconds) {
					allItemsValid = false;
				}
			}
		});
		isValid(allItemsValid);
		setItemsAreValid(allItemsValid);
	}, [
		props.selectedLineItems,
		isValid,
		currentDateMilliseconds,
		serviceDateMilliseconds,
	]);

	useEffect(() => {
		let errors: string[] = [];
		if (props.showErrors && !itemsAreValid) {
			props.selectedLineItems.forEach((f: iLineItem) => {
				if (f.qty === 0 || f.qty === undefined) {
					return errors.push(
						"All selected line items must have units entered."
					);
				}
			});
			props.selectedLineItems.forEach((f: iLineItem) => {
				let fromDateMilliseconds = convertDatetoMilliseconds(
					f.lineData.fromDate
				);
				let toDateMilliseconds = convertDatetoMilliseconds(f.lineData.toDate);

				if (Object.keys(f.lineData).length > 0) {
					if (
						fromDateMilliseconds > currentDateMilliseconds ||
						toDateMilliseconds > currentDateMilliseconds
					) {
						return errors.push("Storage Date cannot be future dated");
					}

					if (
						fromDateMilliseconds === null ||
						toDateMilliseconds === null ||
						isNaN(fromDateMilliseconds) ||
						isNaN(toDateMilliseconds) ||
						fromDateMilliseconds === 0 ||
						toDateMilliseconds === 0
					) {
						return errors.push("Storage Date must be entered");
					}
					if (fromDateMilliseconds > toDateMilliseconds) {
						return errors.push(
							"Out of Storage cannot be prior to In Storage date"
						);
					}
				}
			});
		}
		errors = errors.filter((v, i, a) => a.indexOf(v) === i);
		setErrorMessage([...errors]);
	}, [
		props.showErrors,
		itemsAreValid,
		props.selectedLineItems,
		currentDateMilliseconds,
		serviceDateMilliseconds,
	]);

	return (
		<Container className="invoiceLinesContainer">
			<h5>Basic Line Items:</h5>
			<Table striped bordered hover id="LineItemTable">
				<thead>
					<tr>
						<th></th>
						<th>Work Item</th>
						<th>Rate/Unit(s)</th>
						{/* <th className="right">Rate/Unit</th> */}
						<th className="right">Total</th>
					</tr>
				</thead>
				<tbody>
					{displayLineItems.map((line: iLineItem) => {
						if (
							props.selectedLineItems.filter(
								(x: iLineItem) => line.name === x.name && line.id === ""
							).length > 0
						) {
							line = props.selectedLineItems.find(
								(x: iLineItem) => line.name === x.name && line.id === ""
							);
						}
						return (
							<InvoiceLineItem
								lineItem={line}
								lineOptions={props.lineOptions}
								selectedLineItems={props.selectedLineItems}
								setSelectedLineItems={props.setSelectedLineItems}
								key={line.title + line.weightClass}
							/>
						);
					})}
				</tbody>
			</Table>
			{errorMessage !== [""] &&
				errorMessage.map((error) => {
					return <Error message={error} key={error} />;
				})}
		</Container>
	);
}
