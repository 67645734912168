import React from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import {
  iLineItem,
  getUnits,
  LineType,
  getUnitsForSpecialLineItemsSelection,
} from "../../../types/LineItems";
import { calcRate } from "../../../utils/rateCalc";
import {
  convertDateToStringFormat,
  currencyFormat,
  getDiffDays,
  useWindowDimensions,
  ScreenSize,
  displayRate,
} from "../../../utils/Utils";
import "./InvoiceLineItem.css";
import "./reactDatePickerCustom.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { v4 as uuidv4 } from "uuid";

export function InvoiceLineItem(props: any) {
  const { screenSize } = useWindowDimensions();

  function onChangeCheckbox(event: any) {
    const { name, id } = event.target;
    var selectedLineItem: iLineItem;
    var deSelected: boolean;

    selectedLineItem = props.lineOptions.filter((lineItem: iLineItem) => {
      return lineItem.name === name;
    })[0];
    // remove item if it already existed
    deSelected =
      props.selectedLineItems.filter(
        (lineItem: iLineItem) => lineItem.id === id
      ).length > 0;
    if (deSelected) {
      props.setSelectedLineItems(
        props.selectedLineItems.filter(
          (lineItem: iLineItem) => lineItem.id !== id
        )
      );
    } else {
      if (selectedLineItem.id === "") {
        selectedLineItem.id = uuidv4().substring(0, 8);
      }

      if (selectedLineItem.lineType === LineType.FlatRate) {
        selectedLineItem.qty = 1;
        selectedLineItem.lineTotal = calcRate(selectedLineItem);
        selectedLineItem.lineData = {};
        selectedLineItem.allowMultiple = false;
      } else if (
        selectedLineItem.lineType === LineType.PerDayFromToDatesWithMax
      ) {
        selectedLineItem.qty = 0;
        selectedLineItem.lineTotal = 0;
        selectedLineItem.lineData = {
          fromDate: null,
          toDate: null,
        };
        selectedLineItem.allowMultiple = false;
      } else {
        selectedLineItem.qty = 0;
        selectedLineItem.lineTotal = 0;
        selectedLineItem.lineData = {};
        selectedLineItem.allowMultiple = false;
      }

      props.setSelectedLineItems(
        props.selectedLineItems.concat(props.lineItem)
      );
    }
  }

  function onChangeDatePicker(
    dateRange: any,
    lineItemName: string,
    lineItemId: string
  ) {
    //this onChange is trigger when there is at least one character in the day, month & year fields of one of the dates in the
    // <DateRangePicker>.  Ie if we type in 01/01/2020, it will trigger as soon as we get to the first 2 and then on each character after that
    // when we call.toLocalDateString and pass in the year 2, it will change it to 2002
    //Added a minDate parameter to DateRangePicker so the onChange is only called for dates after this minDate (01/01/2020)
    const dayQty =
      !dateRange || !dateRange[0] || !dateRange[1]
        ? 0
        : getDiffDays(dateRange[0], dateRange[1]) >= 0
        ? getDiffDays(dateRange[0], dateRange[1]) + 1
        : 0;
    const fromDate =
      !dateRange || !dateRange[0]
        ? null
        : convertDateToStringFormat(dateRange[0]);
    const toDate =
      !dateRange || !dateRange[1]
        ? null
        : convertDateToStringFormat(dateRange[1]);

    let lineOptionChanged = {
      ...props.lineOptions.filter((l: iLineItem) => {
        return l.name === lineItemName;
      })[0],
    };

    let lineAlreadySelected =
      props.selectedLineItems.filter(
        (l: iLineItem) => l.name === lineItemName && l.id === lineItemId
      ).length > 0;

    if (lineAlreadySelected) {
      let lineItemChanged = {
        ...props.selectedLineItems.filter((l: iLineItem) => {
          return l.id === lineItemId;
        })[0],
      };

      if (lineItemChanged.id === "") {
        lineItemChanged.id = uuidv4().substring(0, 8);
      }
      lineItemChanged.allowMultiple = false;
      lineItemChanged.lineData = {
        ...lineItemChanged.lineData,
        fromDate: fromDate,
        toDate: toDate,
      };
      lineItemChanged.qty = dayQty;
      lineItemChanged.lineTotal = calcRate(lineItemChanged);
      props.setSelectedLineItems(
        props.selectedLineItems.map((l: iLineItem) =>
          l.name === lineItemName && l.id === lineItemId
            ? {
                ...l,
                qty: lineItemChanged.qty,
                lineData: lineItemChanged.lineData,
                lineTotal: lineItemChanged.lineTotal,
              }
            : l
        )
      );
    } else {
      //Line is not selected
      let lineItemChanged = { ...lineOptionChanged };

      if (lineItemChanged.id === "") {
        lineItemChanged.id = uuidv4().substring(0, 8);
      }

      lineItemChanged.lineData = {
        fromDate: fromDate,
        toDate: toDate,
      };
      lineItemChanged.allowMultiple = false;
      lineItemChanged.qty = dayQty;
      lineItemChanged.lineTotal = calcRate(lineItemChanged);

      props.setSelectedLineItems(
        props.selectedLineItems.concat(lineItemChanged)
      );
    }
  }

  function onChangeUnits(event: any) {
    const { name, id, value } = event.target;
    if (/^[0-9]{0,9}(?:\.(?:[0-9]{0,2}))?$/u.test(value)) {
      let lineItemChanged = props.lineOptions.filter((l: iLineItem) => {
        return l.name === name;
      })[0];
      lineItemChanged.allowMultiple = false;
      lineItemChanged.qty = parseInt(value === "" ? 0 : value);
      lineItemChanged.lineTotal = calcRate(lineItemChanged);

      let lineAlreadySelected =
        props.selectedLineItems.filter((l: iLineItem) => l.id === id).length >
        0;
      if (lineAlreadySelected) {
        if (lineItemChanged.qty === 0) {
          props.setSelectedLineItems(
            props.selectedLineItems.filter(
              (lineItem: iLineItem) => lineItem.id !== id
            )
          );
        } else {
          props.setSelectedLineItems(
            props.selectedLineItems.map((l: iLineItem) =>
              l.id === id
                ? {
                    ...l,
                    qty: lineItemChanged.qty,
                    lineTotal: lineItemChanged.lineTotal,
                  }
                : l
            )
          );
        }
      } else {
        if (lineItemChanged.id === "") {
          lineItemChanged.id = uuidv4().substring(0, 8);
        }
        props.setSelectedLineItems(
          props.selectedLineItems.concat(lineItemChanged)
        );
      }
    }
  }

  const popover = (
    <Popover id={props.lineItem.name}>
      <Popover.Content>{props.lineItem.description}</Popover.Content>
    </Popover>
  );

  return (
    <tr>
      <td>
        <Form.Check
          type="checkbox"
          data-testid={props.lineItem.name + props.lineItem.id}
          className="lineItem"
          name={props.lineItem.name}
          id={props.lineItem.id}
          onChange={onChangeCheckbox}
          checked={
            props.selectedLineItems.filter(
              (obj: iLineItem) => obj.id === props.lineItem.id
            ).length > 0
              ? true
              : false
          }
        />
      </td>
      {screenSize >= ScreenSize.md ? (
        <>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={popover}
            transition={false}
            delay={{ show: 500, hide: 0 }}
          >
            {({ ref, ...triggerHandler }) => (
              <td {...triggerHandler} ref={ref}>
                {props.lineItem.title}
              </td>
            )}
          </OverlayTrigger>
          <td>
            {props.lineItem.lineType === LineType.PerDayFromToDatesWithMax &&
              props.selectedLineItems.filter(
                (l: iLineItem) => l.id === props.lineItem.id
              ).length > 0 && (
                <div className="inputQuantity">
                  <Form.Label className="lineItemLabel">
                    {props.selectedLineItems.filter(
                      (l: iLineItem) => l.id === props.lineItem.id
                    ).length > 0
                      ? props.selectedLineItems.filter(
                          (obj: iLineItem) => obj.id === props.lineItem.id
                        )[0].qty
                      : ""}{" "}
                    {getUnits(props.lineItem.lineType)} @{" "}
                    {displayRate(props.lineItem.rate.baseRate)}{" "}
                    {getUnitsForSpecialLineItemsSelection(
                      props.lineItem.lineType
                    )}{" "}
                  </Form.Label>
                  <Form.Label>
                    {props.selectedLineItems.filter(
                      (l: iLineItem) => l.id === props.lineItem.id
                    ).length > 0
                      ? props.selectedLineItems.filter(
                          (obj: iLineItem) => obj.id === props.lineItem.id
                        )[0].lineTotal === props.lineItem.rate.maxCharge &&
                        "maximum applied"
                      : ""}
                  </Form.Label>
                </div>
              )}
            {props.lineItem.lineType !== LineType.PerDayFromToDatesWithMax &&
              props.lineItem.lineType !== LineType.FlatRate && (
                <div className="inputQuantity">
                  <Form.Control
                    type="text"
                    className="lineItemUnit"
                    data-testid={
                      props.lineItem.name + props.lineItem.id + "Unit"
                    }
                    onChange={onChangeUnits}
                    name={props.lineItem.name}
                    id={props.lineItem.id}
                    value={
                      props.selectedLineItems.filter(
                        (l: iLineItem) => l.id === props.lineItem.id
                      ).length > 0
                        ? props.selectedLineItems.filter(
                            (obj: iLineItem) => obj.id === props.lineItem.id
                          )[0].qty
                        : 0
                    }
                  />
                  <Form.Label className="lineItemLabel">
                    {getUnits(props.lineItem.lineType)}@{" "}
                    {displayRate(props.lineItem.rate.baseRate)}{" "}
                    {getUnitsForSpecialLineItemsSelection(
                      props.lineItem.lineType
                    )}{" "}
                    {props.selectedLineItems.filter(
                      (l: iLineItem) => l.id === props.lineItem.id
                    ).length > 0
                      ? props.selectedLineItems.filter(
                          (obj: iLineItem) => obj.id === props.lineItem.id
                        )[0].lineTotal === props.lineItem.rate.maxCharge &&
                        props.lineItem.rate.maxCharge > 0 &&
                        " * maximum applied"
                      : ""}
                  </Form.Label>
                </div>
              )}

            {props.lineItem.lineType === LineType.FlatRate &&
              "Flat Rate: " + displayRate(props.lineItem.rate.baseRate)}

            {props.lineItem.lineType === LineType.PerDayFromToDatesWithMax ? (
              <>
                <Form.Group controlId="storageDates">
                  <DateRangePicker
                    className="lineItemUnitDate"
                    placeholder=""
                    minDate={new Date("2020/01/01")}
                    id={props.lineItem.id}
                    data-testid={
                      props.lineItem.name + props.lineItem.id + "DateRange"
                    }
                    onChange={(e: any) => {
                      onChangeDatePicker(
                        e,
                        props.lineItem.name,
                        props.lineItem.id
                      );
                    }}
                    name={props.lineItem.name + "DateRange"}
                    calendarAriaLabel={
                      props.lineItem.name + props.lineItem.id + "DateRange"
                    }
                    data-lineitemname={props.lineItem.name}
                    value={[
                      getDateFromDateValue(
                        props.lineItem.name,
                        props.lineItem.id
                      ),
                      getDateToDateValue(
                        props.lineItem.name,
                        props.lineItem.id
                      ),
                    ]}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
          </td>
          {/* The Line Item id is not instantly updated to compare the line items */}
          {props.selectedLineItems.filter(
            (obj: iLineItem) => obj.id === props.lineItem.id
          ).length > 0 ? (
            <>
              <td className="right">
                <div
                  data-testid={
                    props.lineItem.name + props.lineItem.id + "Total"
                  }
                >
                  {currencyFormat(
                    props.selectedLineItems.filter(
                      (obj: iLineItem) => obj.id === props.lineItem.id
                    )[0].lineTotal
                  )}{" "}
                </div>
              </td>
            </>
          ) : (
            <>
              <td></td>
            </>
          )}
        </>
      ) : (
        //small screen width
        <td>
          <table id="LineItemDetails">
            <tbody>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popover}
                transition={false}
                delay={{ show: 500, hide: 0 }}
              >
                {({ ref, ...triggerHandler }) => (
                  <tr>
                    <td {...triggerHandler} ref={ref}>
                      {props.lineItem.title}
                    </td>
                  </tr>
                )}
              </OverlayTrigger>
              {props.selectedLineItems.filter(
                (obj: iLineItem) => obj.id === props.lineItem.id
              ).length > 0 ? (
                <tr>
                  <td>
                    {props.lineItem.lineType ===
                      LineType.PerDayFromToDatesWithMax &&
                      props.selectedLineItems.filter(
                        (l: iLineItem) => l.id === props.lineItem.id
                      ).length > 0 && (
                        <div className="inputQuantity">
                          <Form.Label className="lineItemLabel">
                            {props.selectedLineItems.filter(
                              (l: iLineItem) => l.id === props.lineItem.id
                            ).length > 0
                              ? props.selectedLineItems.filter(
                                  (obj: iLineItem) =>
                                    obj.id === props.lineItem.id
                                )[0].qty
                              : ""}{" "}
                            {getUnits(props.lineItem.lineType)} @{" "}
                            {displayRate(props.lineItem.rate.baseRate)}{" "}
                            {getUnitsForSpecialLineItemsSelection(
                              props.lineItem.lineType
                            )}{" "}
                          </Form.Label>
                        </div>
                      )}
                    {props.lineItem.lineType !==
                      LineType.PerDayFromToDatesWithMax &&
                      props.lineItem.lineType !== LineType.FlatRate && (
                        <div className="inputQuantity">
                          <Form.Control
                            type="text"
                            className="lineItemUnit"
                            data-testid={
                              props.lineItem.name + props.lineItem.id + "Unit"
                            }
                            onChange={onChangeUnits}
                            name={props.lineItem.name}
                            id={props.lineItem.id}
                            value={
                              props.selectedLineItems.filter(
                                (l: iLineItem) => l.id === props.lineItem.id
                              ).length > 0
                                ? props.selectedLineItems.filter(
                                    (obj: iLineItem) =>
                                      obj.id === props.lineItem.id
                                  )[0].qty
                                : 0
                            }
                          />
                          <Form.Label className="lineItemLabel">
                            {getUnits(props.lineItem.lineType)} @{" "}
                            {displayRate(props.lineItem.rate.baseRate)}{" "}
                            {getUnitsForSpecialLineItemsSelection(
                              props.lineItem.lineType
                            )}
                          </Form.Label>
                        </div>
                      )}

                    {props.lineItem.lineType ===
                    LineType.PerDayFromToDatesWithMax ? (
                      <>
                        <Form.Group controlId="storageDates">
                          <DateRangePicker
                            className="lineItemUnitDate"
                            placeholder=""
                            minDate={new Date("2020/01/01")}
                            data-testid={
                              props.lineItem.name +
                              props.lineItem.id +
                              "dateRange"
                            }
                            onChange={(e: any) => {
                              onChangeDatePicker(
                                e,
                                props.lineItem.name,
                                props.lineItem.id
                              );
                            }}
                            name="dateRange"
                            calendarAriaLabel={
                              props.lineItem.name +
                              props.lineItem.id +
                              "DateRange"
                            }
                            data-lineitemname={props.lineItem.name}
                            value={[
                              getDateFromDateValue(
                                props.lineItem.name,
                                props.lineItem.id
                              ),
                              getDateToDateValue(
                                props.lineItem.name,
                                props.lineItem.id
                              ),
                            ]}
                          />
                        </Form.Group>
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ) : (
                <>
                  <tr></tr>
                </>
              )}
              {props.selectedLineItems.filter(
                (obj: iLineItem) => obj.id === props.lineItem.id
              ).length > 0 ? (
                <>
                  <tr>
                    <td>
                      <div
                        data-testid={
                          props.lineItem.name + props.lineItem.id + "Total"
                        }
                      >
                        {props.lineItem.lineType === LineType.FlatRate &&
                          "Flat Rate: " +
                            displayRate(
                              props.selectedLineItems.filter(
                                (obj: iLineItem) => obj.id === props.lineItem.id
                              )[0].lineTotal
                            )}
                        {props.lineItem.lineType !== LineType.FlatRate &&
                          "Total: " +
                            displayRate(
                              props.selectedLineItems.filter(
                                (obj: iLineItem) => obj.id === props.lineItem.id
                              )[0].lineTotal
                            )}

                        {props.selectedLineItems.filter(
                          (obj: iLineItem) => obj.id === props.lineItem.id
                        )[0].lineTotal === props.lineItem.rate.maxCharge &&
                          props.lineItem.lineType ===
                            LineType.PerDayFromToDatesWithMax &&
                          "  (Maximum applied)"}
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr></tr>
                  <tr></tr>
                </>
              )}
            </tbody>
          </table>
        </td>
      )}
    </tr>
  );

  function getDateFromDateValue(lineName: string, lineId: string): any {
    return props.selectedLineItems.filter(
      (l: iLineItem) => l.name === lineName && l.id === lineId
    ).length > 0 &&
      props.selectedLineItems.filter(
        (obj: iLineItem) => obj.name === lineName && obj.id === lineId
      )[0].lineData.fromDate
      ? new Date(
          props.selectedLineItems.filter(
            (obj: iLineItem) => obj.name === lineName && obj.id === lineId
          )[0].lineData.fromDate
        )
      : null;
  }

  function getDateToDateValue(lineName: string, lineId: string): any {
    return props.selectedLineItems.filter(
      (l: iLineItem) => l.name === lineName && l.id === lineId
    ).length > 0 &&
      props.selectedLineItems.filter(
        (obj: iLineItem) => obj.name === lineName && obj.id === lineId
      )[0].lineData.toDate
      ? new Date(
          props.selectedLineItems.filter(
            (obj: iLineItem) => obj.name === lineName && obj.id === lineId
          )[0].lineData.toDate
        )
      : null;
  }
}
