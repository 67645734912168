import { Button, Modal } from "react-bootstrap";
interface ModalProps {
  show: any;
  onHide: any;
  setToastMessage: any;
  changeFromWeightClass: any;
  changeToWeightClass: any;
  setCurrentWeightClass: any;
  handleWeightChangeConfirmed: any;
  handleWeightChangeDenied: any;
}
export function ModalConfirmWeightClassChange(props: ModalProps) {
  return (
    <Modal
      data-testid="weightClassChange"
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="RemoveInventory"
        >
          <h4>Confirm Service Class Change</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="RemoveInventoryBody">
          Are you sure you want to change the Service Class on this invoice to{" "}
          {getWeightClassDescription(props.changeToWeightClass)}?
        </div>
        <p />
        <div className="RemoveInventoryBodyBold">
          Existing Line Items will be removed!
        </div>
      </Modal.Body>

      <Modal.Footer className="left">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={HandleCancel}
          data-testid="noButton"
        >
          No
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={HandleConfirm}
          data-testid="yesButton"
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function getWeightClassDescription(weightClass: any) {
    switch (weightClass) {
      case 0:
        return "Light Duty";
      case 1:
        return "Medium Duty";
      case 2:
        return "Heavy Duty";
    }
    return "unknown";
  }

  function HandleCancel(event: any) {
    props.onHide(false);
    props.handleWeightChangeDenied();
  }

  async function HandleConfirm() {
    props.onHide(false);
    props.handleWeightChangeConfirmed();
    props.setCurrentWeightClass(props.changeToWeightClass);
    props.setToastMessage("Line Items have been reset");
  }
}
