import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { updateClaimNumber } from "../../api/apiInvoice";
import { validateClaimNumber } from "../../utils/Validators/ClaimNumberValidator";
import { Error } from "../Alerts/Error";
interface ModalProps {
  show: any;
  onHide: any;
  invoiceSummary: any;
  setInvoiceSummary: any;
  setNewVersionCreated: any;
}
export function ModalClaimNumber(props: ModalProps) {
  const [editedClaimNumber, setEditedClaimNumber] = useState(
    props.invoiceSummary.invoiceDetails.claimNumber
  );
  const [isLoading, setIsLoading] = useState(false);
  var errorExists = false;
  const [editedClaimNoErrorMessage, setEditedClaimNoErrorMessage] =
    useState("");
  function handleError(claimNumber: string) {
    var errorMessage = "";
    errorMessage = validateClaimNumber(claimNumber);
    if (errorMessage !== "") {
      setEditedClaimNoErrorMessage(errorMessage);
      errorExists = true;
    } else {
      setEditedClaimNoErrorMessage("");
      errorExists = false;
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>SGI Claim Number</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please update SGI Claim Number here:</p>
        <p />
        <Form.Group controlId="editedClaimNumber">
          <Form.Control
            type="text"
            placeholder=""
            onChange={(e) => {
              HandleChange(e);
            }}
            data-testid="editedClaimNumber"
            name="editedClaimNumber"
            value={editedClaimNumber}
          />
        </Form.Group>
      </Modal.Body>
      {editedClaimNoErrorMessage !== "" && (
        <Error message={editedClaimNoErrorMessage} />
      )}
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleOnCloseChange}
          data-testid="closeClaimNumberButton"
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={HandleSave}
          data-testid="saveClaimNumberButton"
        >
          {isLoading && <Spinner animation="border" role="status" />}
          {!isLoading && "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleChange(event: any) {
    setEditedClaimNumber(event.target.value);
    handleError(event.target.value);
  }

  function HandleOnCloseChange(event: any) {
    setEditedClaimNumber(props.invoiceSummary.invoiceDetails.claimNumber);
    setEditedClaimNoErrorMessage("");
    props.onHide(false);
    setIsLoading(false);
  }

  async function HandleSave() {
    handleError(editedClaimNumber);

    if (!errorExists) {
      setIsLoading(true);
      const result: any = await updateClaimNumber(
        props.invoiceSummary.invoiceDetails.id,
        editedClaimNumber
      );
      if (result !== undefined) {
        props.setInvoiceSummary({
          ...props.invoiceSummary,
          invoiceDetails: {
            ...props.invoiceSummary.invoiceDetails,
            claimNumber: editedClaimNumber,
          },
        });
        props.setNewVersionCreated(true);
      }
      props.onHide(false);
    }
    setIsLoading(false);
  }
}
