import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import {
	Annotation,
	iInvoiceDetails,
	PayeeInformation,
} from "../types/Invoice";
import { iLineItem } from "../types/LineItems";
import { postAction } from "../types/postAction";
import { Photo } from "../types/Photo";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { AssignedToUser } from "../types/AssignedToUser";

export async function postInvoice(
	vendorId: string,
	invoiceDetails: iInvoiceDetails,
	allLineItems: iLineItem[],
	photosBase64: Photo[],
	action: postAction,
	annotations: Annotation[],
	status: number,
	payeeInformation: PayeeInformation
) {
	var baseUrl = "";

	if (action === postAction.Submit) {
		baseUrl = getBaseApiUrl() + "Invoices/Submit";
	} else if (action === postAction.Save) {
		baseUrl = getBaseApiUrl() + "Invoices/Save";
	}

	const token = await getAccessToken();
	const vendorIdToSave = !invoiceDetails.vendorId
		? vendorId
		: invoiceDetails.vendorId;
	const invoice = {
		invoiceId: invoiceDetails.id,
		vendorId: vendorIdToSave,
		vehicleplate: invoiceDetails.plate.trim(),
		reasonForNoVehiclePlate: invoiceDetails.reasonForNoVehiclePlate,
		vehicleYear: invoiceDetails.vehicleYear,
		vehicleMake: invoiceDetails.vehicleMake,
		vehicleModel: invoiceDetails.vehicleModel,
		vehicleVIN: invoiceDetails.vehicleVIN,
		vehicleColour: invoiceDetails.vehicleColour,
		driveable: invoiceDetails.driveable,
		keys: invoiceDetails.keys,
		towerReference: invoiceDetails.towerReference,
		serviceDate: invoiceDetails.serviceDate,
		towerName: invoiceDetails.towerName,
		claimNumber: invoiceDetails.claimNumber,
		requestedPayment: invoiceDetails.requestedPayment,
		vendorPlate: invoiceDetails.vendorPlate,
		lineItems: JSON.stringify(allLineItems),
		policeFile: invoiceDetails.policeFile,
		policeDept: invoiceDetails.policeDept,
		reasonForTow: invoiceDetails.reasonForTow,
		locations: JSON.stringify(invoiceDetails.locations),
		towedFromAccident: invoiceDetails.towedFromAccident,
		photos: JSON.stringify(photosBase64),
		photoGuid: invoiceDetails.photoGuid,
		reasonForNoVIN: invoiceDetails.reasonForNoVIN,
		noVINReasonDetails: invoiceDetails.noVINReasonDetails,
		vinScanned: invoiceDetails.vinScanned,
		vinDecoded: invoiceDetails.vinDecoded,
		vinData: JSON.stringify(invoiceDetails.vinData),
		subsequentTow: invoiceDetails.subsequentTow,
		status: status,
		createdBy: invoiceDetails.createdBy
			? JSON.stringify(invoiceDetails.createdBy)
			: null,
		submittedBy: invoiceDetails.submittedBy
			? JSON.stringify(invoiceDetails.submittedBy)
			: null,
		annotations: JSON.stringify(annotations),
		payeeName: payeeInformation.payeeName,
		payeeNumber: payeeInformation.payeeNumber,
		weightClass: invoiceDetails.weightClass,
	};

	let data = new FormData();
	data.append("invoiceDto", JSON.stringify(invoice));

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function patchInvoiceWithAnnotations(
	invoiceId: string,
	annotations: Annotation[],
	status: number
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);

	data.append("annotations", JSON.stringify(annotations));
	data.append("status", JSON.stringify(status));

	baseUrl = getBaseApiUrl() + "Invoices/AnnotationsPatch/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
export async function deletePhotoFromInvoice(photoGuid: string) {
	var baseUrl = "";
	baseUrl = getBaseApiUrl() + "InvoicePhoto/deletePhoto";
	const token = await getAccessToken();
	return axios
		.delete(baseUrl + "?guid=" + photoGuid, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
export async function getPhotoByGuid(photoGuid: string) {
	var baseUrl = "";
	baseUrl = getBaseApiUrl() + "InvoicePhoto/downloadPhoto";
	const token = await getAccessToken();
	return axios
		.get(baseUrl + "?guid=" + photoGuid, {
			responseType: "arraybuffer",
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
export async function getInvoiceByInvoiceId(invoiceId: string) {
	const baseUrl = getBaseApiUrl() + "Invoices/invoice/";
	const token = await getAccessToken();

	return axios
		.get(baseUrl + invoiceId, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function deleteDraftInvoiceByInvoiceId(invoiceId: string) {
	const baseUrl = getBaseApiUrl() + "Invoices/invoice/";
	const token = await getAccessToken();

	return axios
		.delete(baseUrl + invoiceId, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getInvoicesByVendor(vendorId: string) {
	const baseUrl = getBaseApiUrl() + "Invoices/vendor";
	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/${vendorId}`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getInvoiceByClaimNumber(
	claimNumber: string,
	vendorId: string
) {
	const baseUrl = getBaseApiUrl() + "Invoices/invoice";
	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/${vendorId}/${claimNumber}`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getVehicleInventoriesByVendor(
	vendorId: string,
	internalEmployee: boolean = false
) {
	const baseUrl = getBaseApiUrl() + "Invoices/vehicleInventory";
	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/${vendorId}/${internalEmployee}`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getVehicleInventories() {
	const baseUrl = getBaseApiUrl() + "Invoices/allVehicleInventory";
	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getAllInvoices() {
	const baseUrl = getBaseApiUrl() + "Invoices";

	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getActiveInvoicesMainDetails() {
	const baseUrl = getBaseApiUrl() + "Invoices/GetActiveInvoicesMainDetails";
	const token = await getAccessToken();
	return axios
		.get(`${baseUrl}/`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getAllActiveInvoices() {
	const baseUrl = getBaseApiUrl() + "Invoices/activeInvoices";

	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function getInvoiceHistory() {
	const baseUrl = getBaseApiUrl() + "Invoices/invoiceHistory";

	const token = await getAccessToken();

	return axios
		.get(`${baseUrl}/`, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function assignInvoice(
	invoiceId: string,
	assignedToSGIEmployee: AssignedToUser
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);
	data.append("assignedToSGIEmployee", JSON.stringify(assignedToSGIEmployee));

	baseUrl = getBaseApiUrl() + "Invoices/AssignInvoice/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function updateStatus(
	invoiceId: string,
	status: number,
	reasonForDeletion: string,
	deletionReasonDetails: string
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);

	data.append("status", status.toString());
	data.append("reasonForDeletion", reasonForDeletion);
	data.append("deletionReasonDetails", deletionReasonDetails);

	baseUrl = getBaseApiUrl() + "Invoices/UpdateStatus/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function updateClaimNumber(
	invoiceId: string,
	claimNumber: string
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);

	data.append("claimNumber", claimNumber);

	baseUrl = getBaseApiUrl() + "Invoices/UpdateClaimNumber/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function updateVehiclePlate(
	invoiceId: string,
	vehiclePlate: string
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);

	data.append("vehiclePlate", vehiclePlate);

	baseUrl = getBaseApiUrl() + "Invoices/UpdateVehiclePlate/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function updateVehicleVIN(
	invoiceId: string,
	vehicleVin: string,
	ReasonForNoVIN: string,
	NoVINReasonDetails: string,
	VinDecoded: string,
	VinData: string,
	Make: string,
	Model: string,
	Year: string
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);
	data.append("vehicleVIN", vehicleVin);
	data.append("ReasonForNoVIN", ReasonForNoVIN);
	data.append("NoVINReasonDetails", NoVINReasonDetails);
	data.append("VinDecoded", VinDecoded);
	data.append("VinData", VinData);
	data.append("Make", Make);
	data.append("Model", Model);
	data.append("Year", Year);
	baseUrl = getBaseApiUrl() + "Invoices/UpdateVehicleVin/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
export async function updateRemovedFromInventory(
	invoiceId: string,
	removedFromInventory: boolean,
	payeeNumber: string
) {
	var baseUrl = "";

	let data = new FormData();

	data.append("invoiceId", invoiceId);
	data.append("removedFromInventory", JSON.stringify(removedFromInventory));
	data.append("payeeNumber", payeeNumber);
	baseUrl = getBaseApiUrl() + "Invoices/UpdateRemovedFromInventory/";
	const token = await getAccessToken();

	return axios
		.post(baseUrl, data, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export const markSearched = async (invoiceId: string) => {
	var baseUrl = `${getBaseApiUrl()}Invoices/invoice/${invoiceId}/MarkSearched`;
	const token = await getAccessToken();

	return axios
		.post(baseUrl, null, {
			headers: {
				Accept: "*/*",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
};
