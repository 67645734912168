import React from "react";
import { Dropdown } from "react-bootstrap";
import "./Header.css";
import { useTypedSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/loginActions";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export function HeaderDropDownButton() {
  const login = useTypedSelector((state) => state.login);
  const dispatch = useDispatch();

  const { instance } = useMsal();

  const isAADAuthenticated = useIsAuthenticated();

  function processSignOut() {
    dispatch(logout());
    if (isAADAuthenticated) {
      instance.logout();
    }
  }

  function getInitials(name: string) {
    let splitNames = name.match(/\S+\s*/g);
    return (
      splitNames &&
      splitNames[0].charAt(0).toUpperCase() +
        splitNames[splitNames.length - 1].charAt(0).toUpperCase()
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        size="sm"
        className="headerLoginDropDown"
        data-testid="headerLoginDropDown"
        variant="light"
      >
        <span className="UserProfileIcon">
          {login.username !== undefined ? getInitials(login.username) : ""}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Item onClick={processSignOut} data-testid="LogoutOption">
          Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
