import React from "react";
import { Container, Table, Col, Row } from "react-bootstrap";
import VehicleInfoDisplayOnly from "../../components/VehicleInfoDisplayOnly/VehicleInfoDisplayOnly";

import currencyFormat, {
  openMap,
  useWindowDimensions,
  ScreenSize,
  calculateGst,
  calculateGstFromTotal,
  showGstInformation,
} from "../../utils/Utils";

import {
  getRateUnitsForInvoiceSummary,
  iLineItem,
  LineType,
  WeightClass,
} from "../../types/LineItems";
import { Photo } from "../../types/Photo";
import { ImageTableItem } from "../../components/ImageUpload/ImageTableItem";
import { ImageTableItemMobile } from "../../components/ImageUpload/ImageTableItemMobile";
import { getDisplayAddress } from "../../types/Location";
import { GeoAlt, Check2 } from "react-bootstrap-icons";
import { ReasonForDeletionDisplay } from "../../components/ReasonForDeletionDisplay/ReasonForDeletionDisplay";

export function TowerInvoiceSummaryDetails(props: any) {
  const { screenSize } = useWindowDimensions();

  return (
    <>
      {props.invoiceSummary.invoiceDetails.status === 6 && (
        <div>
          <ReasonForDeletionDisplay
            reasonForDeletion={
              props.invoiceSummary.invoiceDetails.reasonForDeletion
            }
            deletionReasonDetails={
              props.invoiceSummary.invoiceDetails.deletionReasonDetails
            }
          />
        </div>
      )}
      <Container className="invoiceSummaryDetailsContainer">
        <Row xs={2} sm={2} md={4} lg={4}>
          <Col className="summaryLabel" md={4} lg={4}>
            Vendor Name:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.payeeName}
          </Col>
          <Col className="summaryLabel " md={3} lg={3}>
            Status:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {props.invoiceSummary.invoiceDetails.statusDisplay}
          </Col>
          <Col className="summaryLabel" md={4} lg={4}>
            Created By:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails?.createdBy !== null
              ? props.invoiceSummary.invoiceDetails.createdBy.fullName
              : ""}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            Submitted By:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {props.invoiceSummary.invoiceDetails?.submittedBy !== null
              ? props.invoiceSummary.invoiceDetails.submittedBy.fullName
              : ""}
          </Col>

          <VehicleInfoDisplayOnly
            invoiceDetails={props.invoiceSummary.invoiceDetails}
          />

          <Col className="summaryLabel" md={4} lg={4}>
            Towing Invoice&nbsp;#:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.towerReference}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            Tower Name/Truck #:
          </Col>
          <Col className="summaryField" md={3} lg={3} data-test-id="towerName">
            {props.invoiceSummary.invoiceDetails.towerName}
          </Col>

          <Col className="summaryLabel" md={4} lg={4}>
            Date of Tow Service:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.serviceDate !== null
              ? props.invoiceSummary.invoiceDetails.serviceDate
              : ""}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            SGI Claim #:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {props.invoiceSummary.invoiceDetails.claimNumber}
          </Col>

          <Col className="summaryLabel" md={4} lg={4}>
            Vendor vehicle Plate&nbsp;#:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.vendorPlate}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}></Col>
          <Col className="summaryField" md={3} lg={3}></Col>

          <Col className="summaryLabel" md={4} lg={4}>
            Reason For Tow:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.reasonForTow}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            Towed From Accident?:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {props.invoiceSummary.invoiceDetails.towedFromAccident === "Y"
              ? "Yes"
              : "No"}
          </Col>

          <Col className="summaryLabel" md={4} lg={4}>
            Towed From Location:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {getDisplayAddress(
              props.invoiceSummary.invoiceDetails.locations.towedFrom
            )}
            {props.invoiceSummary.invoiceDetails.locations["towedFrom"]
              .placeId && (
              <GeoAlt
                data-testid="towedFromLink"
                size={30}
                onClick={() => {
                  openMap(
                    props.invoiceSummary.invoiceDetails.locations["towedFrom"]
                  );
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
            <br />
            {props.invoiceSummary.invoiceDetails.locations["towedFrom"]
              .specificLocation !== "" &&
            props.invoiceSummary.invoiceDetails.locations["towedFrom"]
              .specificLocation !== undefined &&
            props.invoiceSummary.invoiceDetails.locations["towedFrom"]
              .specificLocation !== null
              ? "Stall/Lot: " +
                props.invoiceSummary.invoiceDetails.locations["towedFrom"]
                  .specificLocation
              : ""}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            Towed To Location:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {getDisplayAddress(
              props.invoiceSummary.invoiceDetails.locations.towedTo
            )}
            {props.invoiceSummary.invoiceDetails.locations["towedTo"]
              .placeId && (
              <GeoAlt
                data-testid="towedToLink"
                size={30}
                onClick={() => {
                  openMap(
                    props.invoiceSummary.invoiceDetails.locations["towedTo"]
                  );
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
            <br />
            {props.invoiceSummary.invoiceDetails.locations["towedTo"]
              .specificLocation !== "" &&
            props.invoiceSummary.invoiceDetails.locations["towedTo"]
              .specificLocation !== undefined &&
            props.invoiceSummary.invoiceDetails.locations["towedTo"]
              .specificLocation !== null
              ? "Stall/Lot: " +
                props.invoiceSummary.invoiceDetails.locations["towedTo"]
                  .specificLocation
              : ""}
          </Col>
          {props.invoiceSummary.invoiceDetails.removedFromInventory && (
            <>
              <Col className="summaryLabel" md={4} lg={4}></Col>
              <Col className="summaryField" md={2} lg={2}></Col>
              <Col className="summaryLabel" md={3} lg={3}>
                Removed From Inventory:
              </Col>
              <Col className="summaryField" md={3} lg={3}>
                <Check2 data-testid="removedFromInventory" />
              </Col>
            </>
          )}
          <Col className="summaryLabel" md={4} lg={4}>
            Police File&nbsp;#:
          </Col>
          <Col className="summaryField" md={2} lg={2}>
            {props.invoiceSummary.invoiceDetails.policeFile}
          </Col>
          <Col className="summaryLabel" md={3} lg={3}>
            Police Department:
          </Col>
          <Col className="summaryField" md={3} lg={3}>
            {props.invoiceSummary.invoiceDetails.policeDept}
          </Col>
        </Row>

        <br></br>
        <Row className="InvoiceSummaryLineItems">
          {screenSize >= ScreenSize.md ? (
            <>
              <Table className="InvoiceSummaryLineItemTable">
                <thead>
                  <tr>
                    <th>
                      Work Item (
                      {
                        WeightClass[
                          props.invoiceSummary.invoiceDetails?.weightClass ===
                          undefined
                            ? 0
                            : props.invoiceSummary.invoiceDetails.weightClass
                        ]
                      }
                      )
                    </th>
                    <th>Rate/Unit(s)</th>

                    <th className="right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {props.invoiceSummary.invoiceLineItems.map(
                    (line: iLineItem) => {
                      return (
                        <tr key={line.name + line.id}>
                          <td>{line.title}</td>
                          <td>
                            <table className="table-borderless">
                              <tbody>
                                <tr>
                                  <td>{getRateUnitsForInvoiceSummary(line)}</td>
                                </tr>
                                {line.lineType ===
                                LineType.PerDayFromToDatesWithMax ? (
                                  <>
                                    <tr>
                                      <td>From: {line.lineData.fromDate}</td>
                                    </tr>
                                    <tr>
                                      <td>To: {line.lineData.toDate}</td>
                                    </tr>
                                  </>
                                ) : (
                                  <tr>
                                    <td></td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>

                          <td className="right">
                            <div data-testid={line.name + line.id + "Total"}>
                              {currencyFormat(line.lineTotal)}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
              <Row>
                <Col className="referRateSheetLabel">* Refer to Rate Sheet</Col>
              </Row>
            </>
          ) : (
            <>
              <Table
                striped
                className="InvoiceSummaryLineItemTable"
                id="InvoiceSummaryLineItemTableMobile"
              >
                <tbody>
                  {props.invoiceSummary.invoiceLineItems.map(
                    (line: iLineItem) => {
                      return (
                        <tr key={line.name + line.id}>
                          <tr>
                            <b>
                              {line.title} (
                              {
                                WeightClass[
                                  props.invoiceSummary.invoiceDetails
                                    ?.weightClass === undefined
                                    ? 0
                                    : props.invoiceSummary.invoiceDetails
                                        .weightClass
                                ]
                              }
                              )
                            </b>
                          </tr>
                          <tr>
                            <td>{getRateUnitsForInvoiceSummary(line)}</td>
                          </tr>

                          {line.lineType ===
                          LineType.PerDayFromToDatesWithMax ? (
                            <>
                              <tr>
                                From: {line.lineData.fromDate}&nbsp;&nbsp;&nbsp;
                                To: {line.lineData.toDate}
                              </tr>
                            </>
                          ) : (
                            ""
                          )}
                          {line.lineType !== LineType.FlatRate && (
                            <tr>
                              <div data-testid={line.name + line.id + "Total"}>
                                Total: {currencyFormat(line.lineTotal)}
                              </div>
                            </tr>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
              <Row>
                <Col className="referRateSheetLabel">* Refer to Rate Sheet</Col>
              </Row>
            </>
          )}
        </Row>
        <br></br>
        <Row className="InvoiceSummaryLineItems">
          {props.invoiceSummary.invoiceDetails.photos !== undefined &&
            props.invoiceSummary.invoiceDetails.photos.length > 0 && (
              <>
                <Table striped className="InvoiceSummaryPhotoTable">
                  <thead>
                    <tr key="title">
                      <th>Filename</th>
                      <th>Size</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody key="tbody">
                    {props.invoiceSummary.invoiceDetails.photos.map(
                      (image: Photo, index: number) => {
                        return (
                          <tr key={image.name + index}>
                            {screenSize >= ScreenSize.md ? (
                              <ImageTableItem item={image} />
                            ) : (
                              <ImageTableItemMobile item={image} />
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </>
            )}
        </Row>
        {
          showGstInformation(props.invoiceSummary.invoiceDetails.submittedDate) &&
          <Row>
						<Col>
							<h5 className="InvoiceTotal" data-testid="InvoiceGstTotal">
								GST (5%): {calculateGstFromTotal(props.invoiceSummary.invoiceDetails.requestedPayment)}
							</h5>
						</Col>
				  </Row>
        }
        
        <Row>
          <Col></Col>
          <Col>
            <div className="InvoiceTotal" data-testid="InvoiceTotal">
              Invoice Total:{" "}
              {currencyFormat(
                props.invoiceSummary.invoiceDetails.requestedPayment
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
