import { faBookOpen } from "@fortawesome/pro-light-svg-icons";
import { useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { SortOrder } from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { getInvoiceHistory } from "../../api/apiInvoice";
import { getAllVersions, getVersionsByDateRange } from "../../api/apiInvoiceVersions";
import CustomTable from "../../components/CustomTable/CustomTable";
import TableButton from "../../components/CustomTable/TableButton";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { defaultInvoiceList } from "../../types/InvoiceList";
import { defaultInvoiceVersions } from "../../types/InvoiceVersions";
import { mapToIInvoiceList } from "../../utils/InvoiceListTransformer";
import { InvoiceVersionsTransformer } from "../../utils/InvoiceVersionsTransformer";
import {
	convertDateToDisplayFormatWithTime,
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";
import "./EmployeeInvoiceHistory.css";
import InvoiceStatus from "../../components/InvoiceStatus/InvoiceStatus";
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment, { Moment } from "moment";
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';

export interface IDateRange {
	startDate: Moment;
	endDate: Moment;
}

export default function EmployeeInvoiceHistory() {
	const history = useHistory();
	const [invoiceList, setInvoiceList] = useState(defaultInvoiceList);
	const [invoiceVersionList, setInvoiceVersionList] = useState(
		defaultInvoiceVersions
	);
	const [isCSVLoading, setIsCSVLoading] = useState(false);
	const { screenSize } = useWindowDimensions();

	const emptyStringArray: string[] = [];
	const [errorMessages, setErrorMessages] = useState(emptyStringArray);
	const [dateRange, setDateRange] = useState<IDateRange>({
		startDate: moment().add(-365, 'd'),
		endDate: moment().add(0, 'd')
	});
	const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
		null,
	);

	const csvLinkRef = useRef<
		CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
	>(null);
	const getInvoiceVersions = async () => {
		
		setIsCSVLoading(true);
		var result: any;
		try {
			result = await getVersionsByDateRange(dateRange.startDate.format(), dateRange.endDate.format());
		} catch (error) {
			setErrorMessages(["Error downloading invoices."]);
		}
		if (result) {
			const transformedInvoiceVersionsData = InvoiceVersionsTransformer(
				result.data
			);
			setInvoiceVersionList(transformedInvoiceVersionsData);
			if (csvLinkRef && csvLinkRef.current) {
				csvLinkRef.current.link.click();
			}
		} else {
			setErrorMessages(["Error downloading invoices"]);
		}
		setIsCSVLoading(false);
	};

	const getAllFilteredInvoices = async () => {
		return await getInvoiceHistory();
	};

	function noDataIndication() {
		return "There are no invoices to display.";
	}

	const defaultSorted: [{ dataField: any; order: SortOrder }] = [
		{
			dataField: "paidDate",
			order: "desc",
		},
	];

	function viewButtonFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<div className="buttonCellWrapper">
				<TableButton
					icon={faBookOpen}
					onClick={() =>
						history.push("/Invoices/EmployeeInvoiceSummary/" + row.invoiceId)
					}
					tooltipText="View Invoice"
					id={row.invoiceId}
					datatestid={row.invoiceId}
				/>
			</div>
		);
	}

	function dateSorter(cell: string) {
		return new Date(cell).toISOString();
	}

	function dateFormatter(cell: string) {
		var formattedDate = convertDateToDisplayFormatWithTime(cell);
		var n = 1;
		return formattedDate.replace(/,/g, (v) => (n++ === 2 ? "\n" : v));
	}

	function requestedPaymentFormatter(cell: any) {
		return "$" + cell.toFixed(2);
	}
	function statusFormatter(cell: any) {
		return <InvoiceStatus status={cell} />;
	}

	const columns = [
		{
			dataField: "payeeName",
			text: "Vendor",
			sort: true,
		},
		{
			dataField: "towerReference",
			text: "Vendor Invoice",
			sort: true,
			classes: "text-truncate",
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "claimNumber",
			text: "Claim",
			sort: true,
			headerClasses: screenSize < ScreenSize.lg ? "" : "width10",
		},
		{
			dataField: "requestedPayment",
			text: "Amount",
			sort: true,
			formatter: requestedPaymentFormatter,
			classes: "right amount",
			headerClasses: "center",
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "submittedDate",
			text: "Submitted",
			sort: true,
			formatter: dateFormatter,
			sortValue: dateSorter,
		},
		{
			dataField: "statusDisplay",
			text: "Hidden status for searching",
			hidden: true,
		},
		{
			dataField: "statusDisplay",
			text: "Status",
			sort: true,
			formatter: statusFormatter,
		},
		{
			dataField: "paidDate",
			text: "Paid",
			sort: true,
			formatter: dateFormatter,
			sortValue: dateSorter,
		},
		{
			dataField: "deletedDate",
			text: "Deleted",
			sort: true,
			formatter: dateFormatter,
			sortValue: dateSorter,
		},
		{
			dataField: "viewButton",
			text: "",
			formatter: viewButtonFormatter,
			headerClasses: "icons",
		},
	];

	const handleDateRangeChange = (datesPicked: {
		startDate: Moment | null;
		endDate: Moment | null;
	}) => {
		setDateRange({
			startDate: datesPicked.startDate!,
			endDate: datesPicked.endDate!,
		});
	};

	return (
		<Container className="CustomTableContainer">
			<ToastErrors
				title="Error"
				setMessages={setErrorMessages}
				messages={errorMessages}
			/>
			<Row>
				<Col lg="8">
					<div className="Title">
						<h3>Invoice History</h3>
					</div>
				</Col>
				<Col lg="4">
					<DateRangePicker
						startDate={dateRange?.startDate}
						startDateId="startDate"
						endDate={dateRange?.endDate}
						endDateId="endDate"
						onDatesChange={handleDateRangeChange}
						focusedInput={focusedInput}
						onFocusChange={setFocusedInput}
						isOutsideRange={() => false}						
					/>
					<div
						onClick={getInvoiceVersions}
						className="csvButton btn btn-outline-primary"
						data-testid="csvButton"
					>
						{isCSVLoading && (
							<Spinner
								animation="border"
								role="status"
								className="csvSpinner"
							/>
						)}
						{!isCSVLoading && "Download Invoices"}
					</div>
					<CSVLink data={invoiceVersionList.map((line: any, key: any) => { return line; })}
						filename="invoice-versions.csv"
						className="hidden"
						ref={csvLinkRef}
						target="_blank"
					/>
				</Col>
			</Row>
			<CustomTable
				noDataIndication={noDataIndication}
				defaultSorted={defaultSorted}
				columns={columns}
				data={invoiceList}
				setData={setInvoiceList}
				defaultData={defaultInvoiceList}
				dataGetter={getAllFilteredInvoices}
				dataMapper={mapToIInvoiceList}
				keyField={"invoiceId"}
			/>
		</Container>
	);
}
