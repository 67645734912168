export function InvoiceStatusDecider(
  oldStatus: number,
  action: string
): number {
  if (action === "Save") {
    if (oldStatus === 0 || oldStatus === 1)
      // none or draft
      return 1;
    // draft
    else if (oldStatus === 3)
      // action required
      return 3;
    else return 0; // undefined case
  } else if (action === "Submit") {
    if (oldStatus === 0 || oldStatus === 1)
      //none or draft
      return 2;
    // submitted
    else if (oldStatus === 3) {
      // action required
      return 5; // resubmitted
    } else return 0; // undefined case
  } else return 0;
}
