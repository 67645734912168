import { Button, Modal } from "react-bootstrap";
interface ModalProps {
  show: any;
  onHide: any;
  vendorDetailChangeField: any;
  setChangesConfirmed: any;
  setModalChangeConfirmationShow: any;
}
export function ModalVendorDetailChangeConfirmation(props: ModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Vendor Detail Change Confirmation</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.vendorDetailChangeField.payeeNumber.changed && (
          <>
            <p>Payee Number changed from</p>
            <p>
              {props.vendorDetailChangeField.payeeNumber.oldValue} to{" "}
              {props.vendorDetailChangeField.payeeNumber.newValue}
            </p>
          </>
        )}
        {props.vendorDetailChangeField.compoundLocation.changed && (
          <p>Compound Location changed</p>
        )}
        <p />
      </Modal.Body>
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleChangeNotConfirmed}
          data-testid="changeNotConfirmedButton"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={HandleChangesConfirmed}
          data-testid="changesConfirmedButton"
        >
          Confirm Change
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleChangesConfirmed(event: any) {
    props.setChangesConfirmed(true);
    props.setModalChangeConfirmationShow(false);
    props.onHide(false);
  }

  function HandleChangeNotConfirmed(event: any) {
    props.setChangesConfirmed(false);
    props.setModalChangeConfirmationShow(false);
    props.onHide(false);
  }
}
