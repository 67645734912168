import { useCallback, useEffect, useState } from "react";
import {
	Container,
	Table,
	Button,
	Badge,
	Row,
	Col,
	Form,
} from "react-bootstrap";
import { useTypedSelector } from "../../redux/store";
import { getInvoicesByVendor } from "../../api/apiInvoice";
import { useHistory } from "react-router-dom";
import { defaultInvoiceList, iInvoiceList } from "../../types/InvoiceList";
import { mapToIInvoiceList } from "../../utils/InvoiceListTransformer";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { GetUserPermissions } from "../../utils/auth/authUtilities";
import "./TowerDashboard.css";
import currencyFormat, {
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";
import { InvoiceStatus } from "../../types/Invoice";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";

export default function TowerDashboard() {
	const login = useTypedSelector((state) => state.login);
	const permissions = GetUserPermissions();
	const [loading, setLoading] = useState(true);
	const [invoiceList, setInvoiceList] = useState(defaultInvoiceList);
	const history = useHistory();
	const [apiUnavailable, setApiUnavailable] = useState(false);
	const [totalDDValue, setTotalDDValue] = useState({
		string: "Last Month",
		number: 30,
	});

	const incomeBadges = { growth: "#28A745", fall: "#e35641" };

	const [averageDDValue, setAverageDDValue] = useState({
		string: "Last 7 days",
		number: 7,
	});
	const [unpaidDDValue, setUnpaidDDValue] = useState({
		string: "Last 7 days",
		number: 7,
	});
	const [tableDDValue, setTableDDValue] = useState({
		string: "Last 7 days",
		number: 7,
	});
	const setsToShow = [
		{ status: InvoiceStatus.Draft, color: "#6C757D" },
		{ status: InvoiceStatus.Action_Required, color: "#FD7E14" },
		{ status: InvoiceStatus.Submitted, color: "#007BFF" },
		{ status: InvoiceStatus.Resubmitted, color: "#17A2B8" },
		{ status: InvoiceStatus.Paid, color: "#28A745" },
		{ status: InvoiceStatus.Deleted, color: "#e35641" },
	];

	const { screenSize } = useWindowDimensions();

	const emptyStringArray: string[] = [];

	const [, setSubmitErrorMessages] = useState(emptyStringArray);

	const reduxConnectionStatus = useTypedSelector(
		(state) => state.connectionStatus
	);

	const loadInvoices = useCallback(async () => {
		var result: any;
		try {
			result = await getInvoicesByVendor(login.vendorId);
		} catch (error) {
			setLoading(false);
			setInvoiceList(defaultInvoiceList);
			setApiUnavailable(true);
		}

		if (result !== undefined) {
			var mapData = mapToIInvoiceList(result.data);

			setLoading(false);
			setInvoiceList(mapData);
		} else {
			setLoading(false);
			setInvoiceList(defaultInvoiceList);
			setApiUnavailable(true);
		}
	}, [login.vendorId]);

	useEffect(() => {
		if (login.username !== "") {
			loadInvoices();
		} else {
			setInvoiceList(defaultInvoiceList);
		}
	}, [login, loadInvoices]);

	function routeToUrl(routeUrl: any) {
		if (!reduxConnectionStatus.connected) {
			setSubmitErrorMessages(["Internet Connection Required"]);
		}

		if (reduxConnectionStatus.connected) {
			history.push(routeUrl);
		}
	}
	function unpaidInvoice(daysBeforeToday: number) {
		var endDate = new Date();
		endDate.setDate(endDate.getDate() - daysBeforeToday);
		var filteredUnpaidList =
			invoiceList.filter(
				(i) =>
					(i.statusDisplay === InvoiceStatus.Submitted ||
						i.statusDisplay === InvoiceStatus.Resubmitted ||
						i.statusDisplay === InvoiceStatus.Action_Required) &&
					i.submittedDate &&
					new Date(i.submittedDate).getTime() >= endDate.getTime()
			) ?? [];
		return {
			unpaidTotal: filteredUnpaidList.reduce(
				(accu: number, item: iInvoiceList) => accu + item.requestedPayment,
				0
			),
			length: filteredUnpaidList.length,
		};
	}
	function averageEarning(daysBeforeToday: number) {
		var filterPaidInvoices = filterInvoice("Paid", daysBeforeToday, 0);
		var invoicesPaidBetweenTSpan = filterInvoice(
			"Paid",
			2 * daysBeforeToday,
			daysBeforeToday
		);

		var currentAverage = 0;
		var previousAverage = 0;
		var calcAvgPercent = 0;

		currentAverage =
			filterPaidInvoices.length > 0
				? filterPaidInvoices.reduce(
						(accu: number, item: iInvoiceList) => accu + item.requestedPayment,
						0
				  ) / filterPaidInvoices.length ?? 1
				: 0;

		previousAverage =
			invoicesPaidBetweenTSpan.length > 0
				? invoicesPaidBetweenTSpan.reduce(
						(accu: number, item: iInvoiceList) => accu + item.requestedPayment,
						0
				  ) / invoicesPaidBetweenTSpan.length ?? 1
				: 0;

		calcAvgPercent =
			((currentAverage - previousAverage) /
				(previousAverage === 0 ? 1 : previousAverage)) *
			100;

		return {
			currentAvg: currentAverage.toFixed(2),
			differencePercent:
				currentAverage >= previousAverage
					? "+" + calcAvgPercent.toFixed(2)
					: "" + calcAvgPercent.toFixed(2),
			color:
				currentAverage >= previousAverage
					? incomeBadges.growth
					: incomeBadges.fall,
		};
	}
	function dailyInvoice() {
		var today = new Date();
		today.setHours(0, 0, 0, 0);
		var yesterday = new Date(today);
		yesterday.setHours(0, 0, 0, 0);
		yesterday.setDate(today.getDate() - 1);
		var todayInvoices =
			invoiceList.filter(
				(i) =>
					i.statusDisplay === InvoiceStatus.Submitted &&
					i.submittedDate &&
					new Date(
						new Date(i.submittedDate + ".000Z").setHours(0, 0, 0, 0)
					).getTime() === today.getTime()
			) ?? [];

		var yesterdayInvoices =
			invoiceList.filter(
				(i) =>
					i.statusDisplay === InvoiceStatus.Submitted &&
					i.submittedDate &&
					new Date(
						new Date(i.submittedDate + ".000Z").setHours(0, 0, 0, 0)
					).getTime() === yesterday.getTime()
			) ?? [];

		var difference = todayInvoices.length - yesterdayInvoices.length;
		return {
			length: todayInvoices.length,
			difference:
				todayInvoices.length >= yesterdayInvoices.length
					? "+" + difference
					: "" + difference,
			color:
				todayInvoices.length >= yesterdayInvoices.length
					? incomeBadges.growth
					: incomeBadges.fall,
		};
	}
	function filterInvoice(
		status: string,
		priorNoDaysBeforeToday: number,
		daysBeforeToday: number
	) {
		var TempDate = new Date();
		var startDate = new Date(TempDate + ".000Z");
		startDate.setDate(startDate.getDate() - daysBeforeToday);
		startDate.setHours(0, 0, 0, 0);
		var endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() - priorNoDaysBeforeToday);
		endDate.setHours(0, 0, 0, 0);
		if (status === "Paid") {
			return (
				invoiceList.filter(
					(i) =>
						i.statusDisplay === InvoiceStatus.Paid &&
						i.paidDate &&
						new Date(
							new Date(i.paidDate + ".000Z").setHours(0, 0, 0, 0)
						).getTime() >= endDate.getTime()
				) ?? []
			);
		} else if (status === "Draft") {
			return invoiceList.filter((i) => i.statusDisplay === status) ?? [];
		} else
			return (
				invoiceList.filter(
					(i) =>
						i.statusDisplay === status &&
						i.submittedDate &&
						new Date(
							new Date(i.submittedDate + ".000Z").setHours(0, 0, 0, 0)
						).getTime() >= endDate.getTime()
				) ?? []
			);
	}

	function TotalEarning(daysBeforeToday: number) {
		var totalFilterInvoices = filterInvoice("Paid", daysBeforeToday, 0);
		return {
			total: totalFilterInvoices.reduce(
				(accu: number, item: iInvoiceList) => accu + item.requestedPayment,
				0
			),
			length: totalFilterInvoices.length,
		};
	}
	function dashboardDropdown(value: any, setValue: any): any {
		return (
			<Form.Group controlId="dashboardDropDownSection">
				<Form.Control
					as="select"
					size="sm"
					placeholder={value.string}
					className="dashboardDropdown"
					data-testid="dashboardDropDown"
					onChange={(e: any) => {
						setValue({
							string: JSON.parse(e.target.value).string,
							number: JSON.parse(e.target.value).number,
						});
					}}
					name="dashboardDropDown"
				>
					<option hidden>{value.string}</option>
					<option
						key={'{"string":"Last 7 days","number":7}'}
						data-testid={"last7DaysDropDownButton"}
						value={'{"string":"Last 7 days","number":7}'}
					>
						Last 7 days
					</option>
					<option
						key={'{"string":"last 2 weeks","number":14}'}
						data-testid="last2weeksDropDownButton"
						value={'{"string":"last 2 weeks","number":14}'}
					>
						Last 2 weeks
					</option>
					<option
						key={'{"string":"Last month","number":30}'}
						data-testid="lastMonthDropDownButton"
						value={'{"string":"Last Month","number":30}'}
					>
						Last Month
					</option>
				</Form.Control>
			</Form.Group>
		);
	}
	if (apiUnavailable) {
		return <ApiUnavailable />;
	} else
		return (
			<>
				{loading ? (
					<PageSpinner />
				) : (
					<Container className="dashboardContainer">
						<div>
							<div className="title" data-testid="TowerDashboardTitle">
								<h6 className="greeting">Welcome {login.companyName}! </h6>
								<h1 className="display-4">Dashboard </h1>
							</div>
							{screenSize <= ScreenSize.sm && permissions.canSaveInvoice && (
								<div className="createButtonDashboardDiv">
									<Button
										className="invoiceButton"
										data-testid="InvoiceButton"
										size="sm"
										variant="outline-primary"
										onClick={() => {
											routeToUrl("/Invoices/List");
										}}
									>
										View Invoices
									</Button>
									<Button
										className="customCreateButton"
										data-testid="CreateButton"
										size="lg"
										variant="outline-primary"
										onClick={() => {
											routeToUrl("/Invoices/SubmitInvoice");
										}}
									>
										Create
									</Button>
								</div>
							)}
						</div>
						<div className="widgetsContainer">
							<Row>
								<Col>
									<Row>
										<div
											className="shadow-sm p-3 mb-5 bg-white rounded Widget"
											data-testid="TowerDashboardWidget"
											id="totalEarningCard"
										>
											<h5 className="display-5">Total earnings </h5>
											<h4 className="display-4">
												{currencyFormat(
													TotalEarning(totalDDValue.number).total
												)}
											</h4>
											<h6 className="description">
												Based on previous
												{" " + TotalEarning(totalDDValue.number).length + " "}
												invoices
											</h6>
											{dashboardDropdown(totalDDValue, setTotalDDValue)}
										</div>
									</Row>
									<Row>
										<div
											className="shadow-sm p-3 mb-5 bg-white rounded Widget"
											data-testid="TowerDashboardWidget"
											id="averageEarningCard"
										>
											<h5 className="display-5">Average earnings </h5>
											<h4 className="display-4">
												${averageEarning(averageDDValue.number).currentAvg}
											</h4>
											<h6 className="description">
												{" "}
												<Badge
													pill
													style={{
														backgroundColor: averageEarning(
															averageDDValue.number
														).color,
														color: "white",
													}}
												>
													{
														averageEarning(averageDDValue.number)
															.differencePercent
													}
												</Badge>{" "}
												% from {" " + averageDDValue.string.toLowerCase()}
											</h6>
											{dashboardDropdown(averageDDValue, setAverageDDValue)}
										</div>
									</Row>
								</Col>
								<Col>
									<Row>
										<div
											className="shadow-sm p-3 mb-5 bg-white rounded Widget"
											data-testid="TowerDashboardWidget"
											id="unpaidInvoiceCard"
										>
											<h5 className="display-5">Unpaid invoices</h5>
											<h4 className="display-4">
												{currencyFormat(
													unpaidInvoice(unpaidDDValue.number).unpaidTotal
												)}
											</h4>
											<h6 className="description">
												Based on previous
												{" " + unpaidInvoice(unpaidDDValue.number).length + " "}
												invoices
											</h6>
											{dashboardDropdown(unpaidDDValue, setUnpaidDDValue)}
										</div>
									</Row>
									<Row>
										<div
											className="shadow-sm p-3 mb-5 bg-white rounded Widget"
											data-testid="TowerDashboardWidget"
											id="dailyInvoiceCard"
										>
											<h5 className="display-5">Daily Invoice </h5>
											<h4 className="display-4">{dailyInvoice().length}</h4>
											<h6 className="description">
												<Badge
													pill
													style={{
														backgroundColor: dailyInvoice().color,
														color: "white",
													}}
												>
													{dailyInvoice().difference}
												</Badge>{" "}
												from yesterday
											</h6>
										</div>
									</Row>
								</Col>
								<div className="tableCol">
									<Col>
										<div
											className="tableWidget shadow-sm mb-5 bg-white rounded"
											data-testid="tableWidget"
										>
											<Table hover>
												<thead
													className="TowerDashboardHeader"
													data-testid="TowerDashboardTableHeader"
												>
													<tr>
														<td style={{ textAlign: "left" }}>
															<h5>Invoices</h5>
														</td>
														<td style={{ float: "right" }}>
															{dashboardDropdown(tableDDValue, setTableDDValue)}
														</td>
													</tr>
												</thead>
												<tbody>
													{setsToShow.map((status) => {
														return (
															<tr key={status.status}>
																<td data-testid="tableStatus">
																	<h6>{status.status}</h6>
																</td>
																<td
																	data-testid="tableValue"
																	style={{ textAlign: "right" }}
																>
																	<h6>
																		<Badge
																			pill
																			style={{
																				backgroundColor: status.color,
																				color: "white",
																			}}
																		>
																			{
																				filterInvoice(
																					status.status,
																					tableDDValue.number,
																					0
																				).length
																			}
																		</Badge>
																	</h6>
																</td>
																<td></td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</div>
									</Col>
								</div>
								<br />
							</Row>
						</div>
					</Container>
				)}
			</>
		);
}
