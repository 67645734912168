import React, { useEffect, useMemo, useState } from "react";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";
import { Col, Form, Row } from "react-bootstrap";
import { Error } from "../Alerts/Error";
import {
	validateTowerInvoice,
	validateTowerName,
	validateVendorPlate,
} from "../../utils/Validators/TowerInvoiceValidator";
import { validateDate } from "../../utils/Validators/ServiceDateValidator";
import { convertDateToStringFormat } from "../../utils/Utils";
import DatePicker from "react-date-picker";
import "./TowerInformation.css";
import ClaimNumberField from "./ClaimNumberField";
import { ModalConfirmTowDateChange } from "../Modals/ConfirmTowDateChange";
import { ToastSuccess } from "../Toast/ToastSuccess";
import { iLineItem } from "../../types/LineItems";

export function TowerInformation(props: any) {
	const [serviceDateMessage, setServiceDateMessage] = useState("");
	const [towerInvoiceMessage, setTowerInvoiceMessage] = useState("");
	const [vendorPlateMessage, setVendorPlateMessage] = useState("");
	const [towerNameMessage, setTowerNameMessage] = useState("");
	const [, setToastMessage] = useState("");
	const emptyStringArray: string[] = [];
	const [changeServiceDateTo, setChangeServiceDateTo] = useState(
		props.invoiceDetails.serviceDate
	);
	const [currentServiceDate, setCurrentServiceDate] = useState(
		props.invoiceDetails.serviceDate
	);
	const defaultLineItems: iLineItem[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [displayAsEditable, setDisplayAsEditable] = useState(false);
	const [claimNumberValid, setClaimNumberValid] = useState(true);
	const [confirmDateChangeModalShow, setConfirmDateChangeModalShow] =
		useState(false);
	const ValidatorFunctions: ValidatorFunction[] = useMemo(
		() => [
			{
				fieldname: "serviceDate",
				validator: validateDate,
				stateSetter: setServiceDateMessage,
			},
			{
				fieldname: "towerReference",
				validator: validateTowerInvoice,
				stateSetter: setTowerInvoiceMessage,
			},
			{
				fieldname: "vendorPlate",
				validator: validateVendorPlate,
				stateSetter: setVendorPlateMessage,
			},
			{
				fieldname: "towerName",
				validator: validateTowerName,
				stateSetter: setTowerNameMessage,
			},
		],
		[]
	);

	function onChangeServiceDate(event: any) {
		setChangeServiceDateTo(event ? convertDateToStringFormat(event) : "");
		if (
			props.specialLineItems?.length === 0 &&
			props.basicLineItems?.length === 0
		) {
			let fieldErrorMessage = "Error";
			fieldErrorMessage = validateDate(event);
			setServiceDateMessage(fieldErrorMessage);

			if (event) {
				let serviceDate = convertDateToStringFormat(event);

				fieldErrorMessage = validateDate(serviceDate);
				setServiceDateMessage(fieldErrorMessage);
				props.setInvoiceDetails({
					...props.invoiceDetails,
					serviceDate: serviceDate,
				});
				props.refreshTowRates(serviceDate);
			} else {
				props.setInvoiceDetails({
					...props.invoiceDetails,
					serviceDate: null,
				});
			}
		} else {
			// show modal
			setConfirmDateChangeModalShow(true);
		}
	}
	function handleOnChange(event: any) {
		const { name, value } = event.target;
		let fieldErrorMessage = "Error";

		var validator = ValidatorFunctions.find(
			(element) => element.fieldname === name
		);

		if (validator !== undefined) {
			fieldErrorMessage = validator.validator(value);
			validator.stateSetter(fieldErrorMessage);
		}
		props.setInvoiceDetails({
			...props.invoiceDetails,
			[name]: value,
		});
	}

	const handleServiceDateChangeDenied = () => {};

	const handleServiceDateChangeConfirmed = () => {
		props.setBasicLineItems(defaultLineItems);
		props.setSpecialLineItems(defaultLineItems);
		props.setInvoiceDetails({
			...props.invoiceDetails,
			serviceDate: changeServiceDateTo,
		});
		props.refreshTowRates(changeServiceDateTo);
		//props.setLineOptions(lineOptionsByWeightClass);
	};

	const { isValid } = props;

	useEffect(() => {
		setDisplayAsEditable(
			props.invoiceDetails.status === 1 || props.invoiceDetails.status === 0
		);
	}, [props.invoiceDetails.status]);

	useEffect(() => {
		let allFieldsValid = true;
		ValidatorFunctions.forEach((f) => {
			if (f.validator(props.invoiceDetails[f.fieldname]) !== "") {
				allFieldsValid = false;
			}
		});
		isValid(allFieldsValid && claimNumberValid);
	}, [ValidatorFunctions, isValid, props.invoiceDetails, claimNumberValid]);

	useEffect(() => {
		if (props.showErrors)
			ValidatorFunctions.forEach((f) => {
				// need a special case here for draft
				if (props.isDraft) {
					if (f.fieldname === "serviceDate") {
						if (!isNaN(Date.parse(props.invoiceDetails[f.fieldname]))) {
							var fieldErrorMessage = validateDate(
								props.invoiceDetails[f.fieldname]
							);
							setServiceDateMessage(fieldErrorMessage);
						} else {
							setServiceDateMessage("");
						}
					} else {
						if (props.invoiceDetails[f.fieldname] === "") {
							f.stateSetter("");
						} else {
							let fieldErrorMessage = f.validator(
								props.invoiceDetails[f.fieldname]
							);
							f.stateSetter(fieldErrorMessage);
						}
					}
				} else {
					let fieldErrorMessage = f.validator(
						props.invoiceDetails[f.fieldname]
					);
					f.stateSetter(fieldErrorMessage);
				}
			});
	}, [
		props.showErrors,
		ValidatorFunctions,
		props.invoiceDetails,
		props.isDraft,
	]);

	return (
		<>
			<ClaimNumberField
				invoiceDetails={props.invoiceDetails}
				setInvoiceDetails={props.setInvoiceDetails}
				displayAsEditable={displayAsEditable}
				isValid={setClaimNumberValid}
				showErrors={props.showErrors}
				isDraft={props.isDraft}
				isVehicleInformationValid={props.isVehicleInformationValid}
				isVehicleStatusValid={props.isVehicleStatusValid}
				claimNumber={props.claimNumber}
				setClaimNumber={props.setClaimNumber}
			/>
			<ToastSuccess
				title="Tow Service Date Change"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<ModalConfirmTowDateChange
				show={confirmDateChangeModalShow}
				onHide={() => setConfirmDateChangeModalShow(false)}
				setToastMessage={setToastMessage}
				setCurrentDate={setCurrentServiceDate}
				changeFromDate={currentServiceDate}
				changeToDate={changeServiceDateTo}
				handleDateChangeConfirmed={handleServiceDateChangeConfirmed}
				handleDateChangeDenied={handleServiceDateChangeDenied}
			/>
			<Row>
				<Col>
					<Form.Group controlId="formEntryTowerReference">
						<Form.Label className="formLabelText">Towing Invoice #</Form.Label>
						<Form.Control
							type="text"
							placeholder="If Available"
							data-testid="formEntryTowerReference"
							onChange={handleOnChange}
							name="towerReference"
							value={props.invoiceDetails.towerReference}
						/>
						{towerInvoiceMessage !== "" && (
							<Error message={towerInvoiceMessage} />
						)}
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="formEntryTowerName">
						<Form.Label className="formLabelText">
							Tow Operator Name or Truck #
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="If Available"
							data-testid="formEntryTowerName"
							onChange={handleOnChange}
							name="towerName"
							value={props.invoiceDetails.towerName}
						/>
						{towerNameMessage !== "" && <Error message={towerNameMessage} />}
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col>
					<Form.Group controlId="formEntryServiceDate">
						<Form.Label className="formLabelText">
							Date of Tow Service
						</Form.Label>
						<br />
						<DatePicker
							data-testid="formEntryServiceDate"
							onChange={onChangeServiceDate}
							maxDate={new Date("2099/12/31")}
							minDate={new Date("1950/12/31")}
							name="serviceDate"
							calendarAriaLabel={"serviceDate"}
							yearAriaLabel="serviceDateYear"
							monthAriaLabel="serviceDateMonth"
							dayAriaLabel="serviceDateDay"
							clearAriaLabel="serviceDateClearButton"
							value={
								props.invoiceDetails.serviceDate !== null
									? new Date(props.invoiceDetails.serviceDate)
									: null
							}
						/>
						{serviceDateMessage !== "" && (
							<Error message={serviceDateMessage} />
						)}
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="formEntryVendorPlate">
						<Form.Label className="formLabelText">
							Vendor Vehicle Plate #
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="If Available"
							data-testid="formEntryVendorPlate"
							onChange={handleOnChange}
							name="vendorPlate"
							value={props.invoiceDetails.vendorPlate}
						/>
						{vendorPlateMessage !== "" && (
							<Error message={vendorPlateMessage} />
						)}
					</Form.Group>
				</Col>
			</Row>
		</>
	);
}
