import { FeedbackType } from "./Feedback";

export interface iFeedbackList {
  content: string;
  email: string;
  feedbackType: FeedbackType;
  name: string;
  submittedDate: string;
}

export const defaultFeedbackList: iFeedbackList[] = [];
