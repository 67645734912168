import React, { useEffect, useMemo, useState } from "react";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { Error } from "../Alerts/Error";
import {
  validatePlate,
  validateVin,
  validateVehicleYear,
  validateVehicleMake,
  validateVehicleModel,
  validateVehicleColour,
  validateNoVINReasonDetails,
  reasonForNoVINValidator,
  reasonForNoVehiclePlateValidator,
} from "../../utils/Validators/VehicleValidator";
import "./VehicleInformation.css";
import { ReasonForNoVehiclePlate } from "../../types/Invoice";

import { VinField } from "./VinField";

export function VehicleInformation(props: any) {
  const [vehicleYearMessage, setVehicleYearMessage] = useState("");
  const [vehicleMakeMessage, setVehicleMakeMessage] = useState("");
  const [vehicleModelMessage, setVehicleModelMessage] = useState("");
  const [vehicleColourMessage, setVehicleColourMessage] = useState("");
  const [vinMessage, setVinMessage] = useState("");
  const [vinDecodeLoading, setVinDecodeLoading] = useState(false);
  const [plateMessage, setPlateMessage] = useState("");
  const [reasonForNoVinMessage, setReasonForNoVinMessage] = useState("");
  const [reasonForNoVehiclePlateMessage, setReasonForNoVehiclePlateMessage] =
    useState("");
  const [noVINReasonDetailsMessage, setNoVINReasonDetailsMessage] =
    useState("");

  const [VehiclePlateAvailable, setVehiclePlateAvailable] = useState(
    props.invoiceDetails.reasonForNoVehiclePlate === "" ||
      typeof props.invoiceDetails.reasonForNoVehiclePlate === "undefined"
  );
  const [VINAvailable, setVINAvailable] = useState(
    props.invoiceDetails.reasonForNoVIN === ""
  );

  const ValidatorFunctions: ValidatorFunction[] = useMemo(
    () => [
      {
        fieldname: "vehicleVIN",
        validator: validateVin,
        stateSetter: setVinMessage,
      },
      {
        fieldname: "vehicleYear",
        validator: validateVehicleYear,
        stateSetter: setVehicleYearMessage,
      },
      {
        fieldname: "vehicleMake",
        validator: validateVehicleMake,
        stateSetter: setVehicleMakeMessage,
      },
      {
        fieldname: "vehicleModel",
        validator: validateVehicleModel,
        stateSetter: setVehicleModelMessage,
      },
      {
        fieldname: "vehicleColour",
        validator: validateVehicleColour,
        stateSetter: setVehicleColourMessage,
      },
      {
        fieldname: "plate",
        validator: validatePlate,
        stateSetter: setPlateMessage,
      },
      {
        fieldname: "reasonForNoVIN",
        validator: reasonForNoVINValidator,
        stateSetter: setReasonForNoVinMessage,
      },
      {
        fieldname: "noVINReasonDetails",
        validator: validateNoVINReasonDetails,
        stateSetter: setNoVINReasonDetailsMessage,
      },
      {
        fieldname: "reasonForNoVehiclePlate",
        validator: reasonForNoVehiclePlateValidator,
        stateSetter: setReasonForNoVehiclePlateMessage,
      },
    ],
    []
  );

  function getYearSelections() {
    let startYear = 1900;
    let endYear = new Date().getFullYear() + 2;
    let allYears = [""];
    for (let x = endYear; x >= startYear; x--) {
      allYears.push(x.toString());
    }
    return allYears;
  }

  function handleOnChange(event: any) {
    const { name, value } = event.target;
    let fieldErrorMessage = "Error";

    var validator = ValidatorFunctions.find(
      (element) => element.fieldname === name
    );

    if (validator !== undefined) {
      if (validator.fieldname === "noVINReasonDetails") {
        fieldErrorMessage = validateNoVINReasonDetails(
          value,
          props.invoiceDetails.reasonForNoVIN
        );
      } else {
        fieldErrorMessage = validator.validator(value);
      }
      validator.stateSetter(fieldErrorMessage);
    }

    switch (name) {
      case "vehicleVIN":
        props.setInvoiceDetails({
          ...props.invoiceDetails,
          [name]: value.trim().toUpperCase(),
          reasonForNoVIN: "",
          noVINReasonDetails: "",
          vinScanned: false,
          vinDecoded: false,
        });
        setReasonForNoVinMessage("");
        setNoVINReasonDetailsMessage("");

        break;
      case "reasonForNoVIN":
        if (value !== "Other") {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
            noVINReasonDetails: "",
            vehicleVIN: "",
            vinScanned: false,
          });
          setNoVINReasonDetailsMessage("");
          setVinMessage("");
        } else {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
            vinScanned: false,
          });
          setVinMessage("");
        }
        break;
      case "plate":
        if (value !== "") {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
            reasonForNoVehiclePlate: "",
          });
          setReasonForNoVehiclePlateMessage("");
        } else {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
          });
        }
        break;
      case "reasonForNoVehiclePlate":
        if (value !== "") {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
            plate: "",
          });
          setPlateMessage("");
        } else {
          props.setInvoiceDetails({
            ...props.invoiceDetails,
            [name]: value,
          });
          setPlateMessage("");
        }
        break;
      default:
        props.setInvoiceDetails({
          ...props.invoiceDetails,
          [name]: value,
        });
        break;
    }
  }

  function handleOnChangeRadio(event: any) {
    const { name } = event.target;

    if (name === "PlateAvailable-yes") {
      setVehiclePlateAvailable(true);
      props.setInvoiceDetails({
        ...props.invoiceDetails,
        reasonForNoVehiclePlate: "",
      });
      setReasonForNoVehiclePlateMessage("");
    }

    if (name === "PlateAvailable-no") {
      setVehiclePlateAvailable(false);
    }

    if (name === "VinAvailable-yes") {
      setVINAvailable(true);
      props.setInvoiceDetails({
        ...props.invoiceDetails,
        noVINReasonDetails: "",
        vehicleVIN: "",
        reasonForNoVIN: "",
        vinScanned: false,
        vinDecoded: false,
      });
      setReasonForNoVinMessage("");
    }
    if (name === "VinAvailable-no") {
      props.setInvoiceDetails({
        ...props.invoiceDetails,
        noVINReasonDetails: "",
        vehicleVIN: "",
        reasonForNoVIN: "",
        vinScanned: false,
        vinDecoded: false,
      });
      setVINAvailable(false);
    }
  }

  const { isValid } = props;

  useEffect(() => {
    let allFieldsValid = true;
    ValidatorFunctions.forEach((field) => {
      switch (field.fieldname) {
        case "noVINReasonDetails":
          if (
            validateNoVINReasonDetails(
              props.invoiceDetails[field.fieldname],
              props.invoiceDetails.reasonForNoVIN
            ) !== ""
          ) {
            allFieldsValid = false;
          }
          break;
        case "vehicleVIN":
          if (props.invoiceDetails.reasonForNoVIN === "") {
            if (validateVin(props.invoiceDetails[field.fieldname]) !== "") {
              allFieldsValid = false;
            }
          }
          break;
        case "reasonForNoVIN":
          if (props.invoiceDetails.vehicleVIN === "") {
            if (
              reasonForNoVINValidator(props.invoiceDetails[field.fieldname]) !==
              ""
            ) {
              allFieldsValid = false;
            }
          }
          break;
        case "plate":
          if (props.invoiceDetails.reasonForNoVehiclePlate === "") {
            if (validatePlate(props.invoiceDetails[field.fieldname]) !== "") {
              allFieldsValid = false;
            }
          }
          break;
        case "reasonForNoVehiclePlate":
          if (props.invoiceDetails.plate === "") {
            if (
              reasonForNoVehiclePlateValidator(
                props.invoiceDetails[field.fieldname]
              ) !== ""
            ) {
              allFieldsValid = false;
            }
          }
          break;
        default:
          if (field.validator(props.invoiceDetails[field.fieldname]) !== "") {
            allFieldsValid = false;
          }
          break;
      }
    });
    isValid(allFieldsValid);
  }, [ValidatorFunctions, isValid, props.invoiceDetails]);

  useEffect(() => {
    if (props.showErrors)
      ValidatorFunctions.forEach((f) => {
        // need a special case here for draft
        if (f.fieldname === "vehicleVIN") {
          if (
            props.invoiceDetails.reasonForNoVIN !== "" ||
            (props.isDraft && props.invoiceDetails.vehicleVIN === "")
          ) {
            setVinMessage("");
          } else {
            setVinMessage(validateVin(props.invoiceDetails[f.fieldname]));
          }
        } else {
          if (f.fieldname === "noVINReasonDetails") {
            setNoVINReasonDetailsMessage(
              validateNoVINReasonDetails(
                props.invoiceDetails[f.fieldname],
                props.invoiceDetails.reasonForNoVIN
              )
            );
          } else {
            if (props.isDraft && props.invoiceDetails[f.fieldname] === "") {
              f.stateSetter("");
            } else {
              let fieldErrorMessage = f.validator(
                props.invoiceDetails[f.fieldname]
              );
              f.stateSetter(fieldErrorMessage);
            }
          }
        }
      });
  }, [
    props.showErrors,
    ValidatorFunctions,
    props.invoiceDetails,
    props.isDraft,
  ]);

  return (
    <>
      <Row>
        <Col sm={4}>
          <Form.Group controlId="formEntryVehiclePlateRadioButtons">
            {RenderRadioButtons(
              "Towed Vehicle Plate Available",
              "PlateAvailable",
              VehiclePlateAvailable,
              handleOnChangeRadio
            )}
          </Form.Group>
        </Col>
        {VehiclePlateAvailable && (
          <Col sm={4}>
            <Form.Group controlId="formEntryVehiclePlate">
              <div className="formLabelText">
                <Form.Label className="formPlateLabelText">
                  Towed Vehicle Plate #
                </Form.Label>
                <Form.Control
                  className="formPlateEntry"
                  type="text"
                  placeholder="Plate #"
                  data-testid="formEntryVehiclePlate"
                  onChange={handleOnChange}
                  name="plate"
                  value={props.invoiceDetails.plate}
                />
                {plateMessage !== "" && <Error message={plateMessage} />}
              </div>
            </Form.Group>
          </Col>
        )}
        {!VehiclePlateAvailable && (
          <Col>
            <Form.Group controlId="formEntryReasonForNoVehiclePlate">
              <div className="formLabelText">
                <Form.Label className="formReasonForNoPlateLabelText">
                  Reason for No Plate
                </Form.Label>
                <Form.Control
                  className="formReasonForNoPlate"
                  as="select"
                  placeholder=""
                  data-testid="formEntryReasonForNoVehiclePlate"
                  onChange={handleOnChange}
                  onBlur={handleOnChange}
                  name="reasonForNoVehiclePlate"
                  value={props.invoiceDetails.reasonForNoVehiclePlate}
                >
                  {Object.keys(ReasonForNoVehiclePlate).map((reason, index) => {
                    return (
                      <option key={index}>
                        {Object.values(ReasonForNoVehiclePlate)[index]}
                      </option>
                    );
                  })}
                </Form.Control>
                {reasonForNoVehiclePlateMessage !== "" && (
                  <Error message={reasonForNoVehiclePlateMessage} />
                )}
              </div>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row className="shortRow">&nbsp;</Row>
      <VinField
        VINAvailable={VINAvailable}
        invoiceDetails={props.invoiceDetails}
        handleOnChange={handleOnChange}
        vinMessage={vinMessage}
        setVinMessage={setVinMessage}
        reasonForNoVinMessage={reasonForNoVinMessage}
        noVINReasonDetailsMessage={noVINReasonDetailsMessage}
        handleOnChangeRadio={handleOnChangeRadio}
        setInvoiceDetails={props.setInvoiceDetails}
        setVinDecodeLoading={setVinDecodeLoading}
      />
      <Row>
        <Col sm={2}>
          <Form.Group controlId="formEntryVehicleYear">
            <Form.Label className="formLabelText">Year</Form.Label>
            {"  "}
            {vinDecodeLoading && <Spinner animation="border" role="status" />}
            <Form.Control
              as="select"
              placeholder="YYYY"
              data-testid="formEntryVehicleYear"
              onChange={handleOnChange}
              onBlur={handleOnChange}
              name="vehicleYear"
              disabled={vinDecodeLoading}
              isValid={
                props.invoiceDetails.vinDecoded &&
                props.invoiceDetails.vehicleYear ===
                  props.invoiceDetails.vinData.modelYear
              }
              value={props.invoiceDetails.vehicleYear}
            >
              {getYearSelections().map((year) => {
                return <option key={year}>{year}</option>;
              })}
            </Form.Control>
            {vehicleYearMessage !== "" && (
              <Error message={vehicleYearMessage} />
            )}
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group controlId="formEntryVehicleMake">
            <Form.Label className="formLabelText">Make</Form.Label>
            {"  "}
            {vinDecodeLoading && <Spinner animation="border" role="status" />}
            <Form.Control
              type="text"
              placeholder=""
              data-testid="formEntryVehicleMake"
              onChange={handleOnChange}
              name="vehicleMake"
              disabled={vinDecodeLoading}
              isValid={
                props.invoiceDetails.vinDecoded &&
                props.invoiceDetails.vehicleMake ===
                  props.invoiceDetails.vinData.make
              }
              value={props.invoiceDetails.vehicleMake}
            />
            {vehicleMakeMessage !== "" && (
              <Error message={vehicleMakeMessage} />
            )}
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group controlId="formEntryVehicleModel">
            <Form.Label className="formLabelText">Model</Form.Label> {"  "}
            {vinDecodeLoading && <Spinner animation="border" role="status" />}
            <Form.Control
              type="text"
              placeholder=""
              data-testid="formEntryVehicleModel"
              onChange={handleOnChange}
              name="vehicleModel"
              disabled={vinDecodeLoading}
              isValid={
                props.invoiceDetails.vinDecoded &&
                props.invoiceDetails.vehicleModel ===
                  props.invoiceDetails.vinData.model
              }
              value={props.invoiceDetails.vehicleModel}
            />
            {vehicleModelMessage !== "" && (
              <Error message={vehicleModelMessage} />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formEntryVehicleColour">
            <Form.Label className="formLabelText">Colour</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional"
              data-testid="formEntryVehicleColour"
              onChange={handleOnChange}
              name="vehicleColour"
              value={props.invoiceDetails.vehicleColour}
            />
            {vehicleColourMessage !== "" && (
              <Error message={vehicleColourMessage} />
            )}
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}

export function RenderRadioButtons(
  title: string,
  labelName: string,
  status: boolean,
  handleOnChangeRadio: any
) {
  return (
    <div className="radioWrapper formLabelText">
      <Row>{title}</Row>
      <Row>
        <Form.Check
          type="radio"
          placeholder=""
          data-testid={"formEntry" + labelName + "-yes"}
          onChange={handleOnChangeRadio}
          name={labelName + "-yes"}
          className="availableRadioButton"
          label="Yes"
          checked={status}
        />
        &nbsp;&nbsp;&nbsp;
        <Form.Check
          type="radio"
          placeholder=""
          data-testid={"formEntry" + labelName + "-no"}
          onChange={handleOnChangeRadio}
          name={labelName + "-no"}
          className="availableRadioButton"
          label="No"
          checked={!status}
        />
      </Row>
    </div>
  );
}
