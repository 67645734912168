import { defaultLines } from "../testUtils/mockedLineData";
import { AssignedToUser } from "./AssignedToUser";
import { UserDetails } from "./UserDetails";
import { iLineItem, WeightClass } from "./LineItems";
import { Locations, defaultNewLocation } from "./Location";
import { Photo } from "./Photo";

//reasonfortow
export enum ReasonForTow {
  NoReason = "",
  SingleVehicleAccident = "Single-Vehicle Accident",
  Wildlife = "Wildlife",
  MultiVehicleCollision = "Multi-Vehicle Collision",
  TheftRecovered = "Theft/Recovered",
  Fire = "Fire",
  Rollover = "Rollover",
  AbandonedVehicle = "Abandoned Vehicle",
  SalvageReturn = "Salvage Return",
  RepairFacilityTow = "Repair Facility Tow",
  SalvageMove = "Salvage Move",
  ClaimAssessment = "Claim Assessment",
  WaterRecovery = "Water Recovery"
}

export const SGI_PICK_UP = "SGI Pick-Up";

export function mapStringToReasonForTow(input: string): ReasonForTow {
  switch (input) {
    case "SingleVehicleAccident":
      return ReasonForTow.SingleVehicleAccident;
    case "Wildlife":
      return ReasonForTow.Wildlife;
    case "MultiVehicleCollision":
      return ReasonForTow.MultiVehicleCollision;
    case "TheftRecovered":
      return ReasonForTow.TheftRecovered;
    case "Fire":
      return ReasonForTow.Fire;
    case "Rollover":
      return ReasonForTow.Rollover;
    case "AbandonedVehicle":
      return ReasonForTow.AbandonedVehicle;
    case "Salvage Return":
      return ReasonForTow.SalvageReturn;
    case "Repair Facility Tow":
      return ReasonForTow.RepairFacilityTow;
    case "Salvage Move":
      return ReasonForTow.SalvageMove;
    case "ClaimAssessment":
      return ReasonForTow.ClaimAssessment;
    default:
      return ReasonForTow.NoReason;
  }
}

export enum ReasonForNoVIN {
  NoReason = "",
  VINDoesNotExist = "VIN does not exist",
  VINNotLocated = "VIN not able to be located",
  VINNotLegible = "VIN not legible",
  Other = "Other",
}

export function mapStringToReasonForNoVIN(input: string): ReasonForNoVIN {
  switch (input) {
    case "VINDoesNotExist":
      return ReasonForNoVIN.VINDoesNotExist;
    case "VINNotLocated":
      return ReasonForNoVIN.VINNotLocated;
    case "VINNotLegible":
      return ReasonForNoVIN.VINNotLegible;
    case "Other":
      return ReasonForNoVIN.Other;
    default:
      return ReasonForNoVIN.Other;
  }
}

export enum ReasonForNoVehiclePlate {
  NoReason = "",
  VehiclePlateBurnt = "Burnt",
  VehiclePlateMissing = "Missing",
  VehiclePlatePermits = "Permit",
}

export function mapStringToReasonForNoVehiclePlate(
  input: string
): ReasonForNoVehiclePlate {
  switch (input) {
    case "VehiclePlateBurnt":
      return ReasonForNoVehiclePlate.VehiclePlateBurnt;
    case "VehiclePlateMising":
      return ReasonForNoVehiclePlate.VehiclePlateMissing;
    case "VehiclePlatePermits":
      return ReasonForNoVehiclePlate.VehiclePlatePermits;

    default:
      return ReasonForNoVehiclePlate.VehiclePlateMissing;
  }
}

export enum InvoiceStatus {
  None = "None",
  Draft = "Draft",
  Submitted = "Submitted",
  Action_Required = "Action Required",
  Paid = "Paid",
  Resubmitted = "Resubmitted",
  Deleted = "Deleted",
}

export enum AnnotationStatus {
  Draft = "Draft",
  Requested = "Requested",
  Complete = "Complete",
}

export interface Annotation {
  name: string;
  id: string;
  title: string;
  annotationText: string;
  annotatedValue: string;
  rejectedComment: string;
  status: AnnotationStatus;
}

export enum AnnotationType {
  BasicItems = "BasicItems",
  SpecialItems = "SpecialItems",
}

export enum ReasonForDeletion {
  NoClaim = "No Claim",
  Duplicate = "Duplicate",
  Other = "Other",
}

export const emptyAnnotations: Annotation[] = [];

export const defaultNewAnnotations: Annotation[] = [
  {
    name: "",
    id: "",
    title: "",
    annotationText: "",
    annotatedValue: "",
    rejectedComment: "",
    status: AnnotationStatus.Draft,
  },
];

export const initialAssignedToUser: AssignedToUser = {
  email: "",
  fullName: "",
};

export interface iInvoice {
  invoiceDetails: iInvoiceDetails;

  //placeholder for line details - to be expanded when required
  invoiceLineItems: iLineItem[];

  invoiceAnnotations: Annotation[];
}

export interface iInvoiceIdentifier{
  id: string;
  versionTimestamp: string | null;
  status: number;
  statusDisplay: string;
}

export interface PayeeInformation {
  payeeNumber: string;
  payeeName: string;
}

export interface VinData {
  extendedVehicleCode: string;
  make: string;
  model: string;
  modelYear: string;
  curbWeight: string;
  price: string;
}

export const defaultVinData: VinData = {
  extendedVehicleCode: "",
  make: "",
  model: "",
  modelYear: "",
  curbWeight: "",
  price: "",
};

export interface iInvoiceDetails {
  id: string;
  vendorId: string;
  plate: string;
  reasonForNoVehiclePlate: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVIN: string;
  vehicleColour: string;
  driveable: string;
  keys: string;
  towerReference: string;
  serviceDate: string | null;
  towerName: string;
  claimNumber: string;
  requestedPayment: number;
  vendorPlate: string;
  policeFile: string;
  policeDept: string;
  reasonForTow: ReasonForTow | string;
  locations: Locations;
  towedFromAccident: string;

  createdBy: UserDetails | null;
  submittedBy: UserDetails | null;
  submittedDate: string | undefined;
  status: number;
  statusDisplay: string;

  photos: Photo[];
  photosBase64: string;
  photoGuid: string;

  reasonForNoVIN: string;
  noVINReasonDetails: string;
  vinScanned: boolean;
  vinDecoded: boolean;
  vinData: VinData;

  assignedToSGIEmployee: AssignedToUser;
  paidDate: string | null;
  payeeNumber: string;
  payeeName: string;

  versionTimestamp: string | null;
  removedFromInventory: boolean;
  subsequentTow: boolean;
  weightClass: WeightClass;
  reasonForDeletion: ReasonForDeletion | null;
  deletionReasonDetails: string;
  deletedDate: string | null;
}

export const defaultNewInvoiceDetails: iInvoiceDetails = {
  id: "",
  vendorId: "",
  plate: "",
  reasonForNoVehiclePlate: "",
  vehicleYear: "",
  vehicleMake: "",
  vehicleModel: "",
  vehicleVIN: "",
  vehicleColour: "",
  driveable: "U",
  keys: "U",
  towerReference: "",
  serviceDate: null,
  towerName: "",
  claimNumber: "",
  requestedPayment: 0.0,
  vendorPlate: "",
  policeFile: "",
  policeDept: "",
  reasonForTow: ReasonForTow.NoReason,
  locations: {
    towedFrom: defaultNewLocation,
    towedTo: defaultNewLocation,
  },
  towedFromAccident: "U",
  createdBy: { authUserId: "", email: "", fullName: "" },
  submittedBy: { authUserId: "", email: "", fullName: "" },
  submittedDate: "",
  status: 0,
  statusDisplay: "",
  photos: [],
  photosBase64: "",
  photoGuid: "",
  reasonForNoVIN: "",
  noVINReasonDetails: "",
  vinScanned: false,
  vinDecoded: false,
  vinData: defaultVinData,
  assignedToSGIEmployee: { email: "", fullName: "" },
  paidDate: null,
  payeeNumber: "",
  payeeName: "",
  versionTimestamp: null,
  removedFromInventory: false,
  subsequentTow: false,
  weightClass: 0,
  reasonForDeletion: null,
  deletionReasonDetails: "",
  deletedDate: null,
};

export const defaultNewInvoice: iInvoice = {
  invoiceDetails: defaultNewInvoiceDetails,
  invoiceLineItems: defaultLines,
  invoiceAnnotations: emptyAnnotations,
};
