import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import "./EmployeeStorageList.css";
import { 
	getVehicleInventoriesByVendor,
	getVehicleInventories
 } from "../../api/apiInvoice";
import { getAllVendors } from "../../api/apiVendor";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import { CSVLink } from "react-csv";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { StorageListTransformer } from "../../utils/StorageListTransformer";
import { ToastInfo } from "../../components/Toast/ToastInfo";

export default function EmployeeStorageList() {
	const { screenSize } = useWindowDimensions();
	const defaultTowerList: any[] = [];
	const defaultInventoryList: any[] = [];
	const [towerList, setTowerList] = useState(defaultTowerList);
	const [inventoryList, setInventoryList] = useState(defaultInventoryList);
	const [storageList, setStorageList] = useState(
		defaultInventoryList
	);
	const [loading, setLoading] = useState(false);
	const [loadingRows, setLoadingRows] = useState(false);
	const [selectedTower, setSelectedTower] = useState("");
	const [, setApiUnavailable] = useState(false);
	const emptyStringArray: string[] = [];
	const [errorMessages, setErrorMessages] = useState(emptyStringArray);
	const [infoMessages, setInfoMessage] = useState<string[]>([]);
	const [isCSVLoading, setIsCSVLoading] = useState(false);

	const csvLinkRef = useRef<
		CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
	>(null);

	const getStorageList = async () => {
		setIsCSVLoading(true);

		const inventoryResult = await getVehicleInventories();
		if(inventoryResult) {
			if (inventoryResult.data?.length > 0) {
				const transformedStorageListData = StorageListTransformer(
					inventoryResult.data
				);
				setStorageList(transformedStorageListData);
				if (csvLinkRef && csvLinkRef.current) {
					csvLinkRef.current.link.click();
				}
			} else {
				setInfoMessage(["No data found."]);
			}
		} else {
			setErrorMessages(["Error downloading invoices"]);
		}
		setIsCSVLoading(false);
	};

	async function handleOnTowerChange(event: any) {
		setSelectedTower(event.target.value);
		var towerInfo = towerList.filter(
			(towerInfo) => towerInfo.companyName === event.target.value
		)[0];
		setLoadingRows(true);
		getVehicleInventoriesByVendor(towerInfo?.vendorId, true)
			.then((result) => {
				result ? setInventoryList(result.data) : setInventoryList([]);
				setLoadingRows(false);
		});
	}

	const columns = [
		{
			dataField: "claimNumber",
			text: "Claim Number",
			sort: true,
		},
		{
			dataField: "vehicleVIN",
			text: "VIN",
			sort: true,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "vehiclePlate",
			text: "Plate",
			sort: true,
		},

		{
			dataField: "vehicleDetails",
			text: "Vehicle Details",
		},
		{
			dataField: "serviceDate",
			text: "Date of Tow",
			sort: true,
		},
		{
			dataField: "totalImpoundmentDays",
			text: "Days in Compound",
			sort: true,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "locations",
			text: "Location",
			sort: true,
			hidden: screenSize < ScreenSize.md,
		},
	];

	const loadData = useCallback(async () => {
		setLoading(true);
		try {
			const result: any = await getAllVendors();
			if (result.status === 200) {
				setTowerList(result.data);
			}
		} catch (error) {
			setApiUnavailable(true);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <PageSpinner />;
	} else
		return (
			<Container className="StorageListContainer">
				<ToastErrors
					title="Error"
					setMessages={setErrorMessages}
					messages={errorMessages}
				/>
				<ToastInfo
					title="Info"
					setMessages={setInfoMessage}
					messages={infoMessages}
					/>					
				<Row>
					<Col lg="4">
						<div className="Title" data-testid="StorageListTitle">
							<h3>Storage List</h3>
						</div>
					</Col>
					<Col lg="4">
						<div
							onClick={getStorageList}
							className="csvStorageButton btn btn-outline-primary"
							data-testid="csvStorageButton"
						>
							{isCSVLoading && (
								<Spinner
									animation="border"
									role="status"
									className="csvStorageSpinner"
								/>
							)}
							{!isCSVLoading && "Download Storage List"}
						</div>
						<CSVLink
							data={storageList.map((line: any, key: any) => {
								return line;
							})}
							filename="tow-storage-list.csv"
							className="hidden"
							ref={csvLinkRef}
							target="_blank"
						/>
					</Col>
					<Col lg="4">
						<Form.Group controlId="storageFormServiceDate">
							<Form.Label className="formLabelText">Select Tower</Form.Label>
							<br />
							<Form.Control
								as="select"
								placeholder=""
								data-testid="formEntryStorageSelectTower"
								onChange={handleOnTowerChange}
								name="storageSelectTower"
								value={selectedTower}
							>
								{towerList.map((tower, index) => {
									return <option key={index}>{tower.companyName}</option>;
								})}
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					{loadingRows ? (
						<PageSpinner />
					) : (
						<Table>
							<thead data-testid="StorageListLineHeader">
								{screenSize >= ScreenSize.md ? (
									<tr>
										{columns.map((col: any) => {
											return <th key={col.dataField}>{col.text}</th>;
										})}
									</tr>
								) : (
									<tr>
										{columns
											.filter((i) => !i.hidden)
											.map((col: any) => {
												return <th key={col.dataField}>{col.text}</th>;
											})}
									</tr>
								)}
							</thead>
							<tbody data-testid="StorageListLineItems">
								{inventoryList.length !== 0 ? (
									inventoryList.map((inventory: any) => {
										return (
											<React.Fragment key={inventory.id}>
												{
													<tr key={inventory.vendorId}>
														{columns.map((col: any) => {
															if (col.dataField === "vehicleDetails") {
																return (
																	<td>
																		{inventory.vehicleColour +
																			" " +
																			inventory.vehicleYear +
																			" " +
																			inventory.vehicleMake +
																			" " +
																			inventory.vehicleModel}
																	</td>
																);
															} else if (col.dataField === "locations") {
																return (
																	<td>
																		{
																			JSON.parse(inventory.locations).towedTo
																				.formattedAddressFull
																		}
																	</td>
																);
															} else return <td>{inventory[col.dataField]}</td>;
														})}
													</tr>
												}
											</React.Fragment>
										);
									})
								) : (
									<tr>No Vehicles in Inventory</tr>
								)}
							</tbody>
						</Table>
					)}
				</Row>
			</Container>
		);
}
