import React, { useEffect, useState } from "react";
import { Button, Row, Table, Image, Col, Alert } from "react-bootstrap";
import { getPhotoByGuid } from "../../api/apiInvoice";
import { Photo } from "../../types/Photo";
import { ImageTableItem } from "../ImageUpload/ImageTableItem";
import { PageSpinnerComponent } from "../PageSpinner/PageSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export function EmployeeSummaryPhotos(props: any) {
	const emptyStringArray: string[] = [];
	const [pictures, setPictures] = useState(emptyStringArray);
	const [photoDownloadError, setPhotoDownloadError] = useState("");

	var photos: Array<any> = [];

	if (props.invoiceSummary.invoiceDetails.photos) {
		photos = Array.from(props.invoiceSummary.invoiceDetails.photos);
	}

	async function fetchData() {
		const promiseArray = [] as Promise<any>[];
		props.setIsLoadingFullSizePhotos(true);
		photos.forEach(async (file: any, index: number) => {
			var imagePromise = new Promise<any>(async (resolve, reject) => {
				let response: any;
				try {
					response = await getPhotoByGuid(file.id);
				} catch (errors) {
					setPhotoDownloadError("Error downloading full sized photos");
					props.setToastErrors(["Error downloading full sized photos"]);
				}

				if (response) {
					let base64ImageString = Buffer.from(response.data, "binary").toString(
						"base64"
					);

					const newFullSizePhoto: any = {
						src: "data:image/png;base64," + base64ImageString,
						imageName: file.name,
					};

					resolve(newFullSizePhoto);
				} else {
					reject("Error downloading full sized photos");
					setPhotoDownloadError("Error downloading full sized photos");
					props.setToastErrors(["Error downloading full sized photos"]);
				}
			});
			promiseArray.push(imagePromise);
		});
		Promise.allSettled(promiseArray).then((values) => {
			let newPictures: any = pictures;
			values.forEach((result) => {
				if (result.status !== "rejected") {
					newPictures.push(result.value);
				}
			});
			setPictures([...newPictures]);
			props.setIsLoadingFullSizePhotos(false);
		});
	}

	useEffect(() => {
		if (pictures.length === 0) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function toggleFullSizePhotos() {
		props.setShowFullSizePhotos(!props.showFullSizePhotos);
	}

	return (
		<>
			<br />

			{(props.invoiceSummary.invoiceDetails.photos !== undefined
				&& props.invoiceSummary.invoiceDetails.photos.length === 0
				&& props.invoiceSummary.invoiceDetails.photoGuid !== "00000000-0000-0000-0000-000000000000")
				&&
				<Row>
					<div className="photoHeaderDiv">
						<div className="photoTextDiv">
							<h5>Photos:</h5>
						</div>
						<div>Unable to download photos</div>
					</div>
				</Row>
			}
			<Row>
				{!props.viewingCurrentVersion && (
					<>
						<div className="photoHeaderDiv">
							<div className="photoTextDiv">
								<h5>Photos:</h5>
							</div>
							<div>
								Photos can be viewed for the current invoice version only.
							</div>
							<br />
							<br />
						</div>
					</>
				)}
				{props.invoiceSummary.invoiceDetails.photos !== undefined &&
					props.invoiceSummary.invoiceDetails.photos.length > 0 &&
					props.viewingCurrentVersion && (
						<>
							<br />
							<div className="photoHeaderDiv">
								<div className="photoTextDiv">
									<h5>Photos:</h5>
								</div>
								<div className="photoButtonDiv">
									<Button
										id="fullSizePhotos"
										className="ShowFullSizePhotoButton"
										data-testid="fullSizePhotos"
										variant="outline-primary"
										onClick={toggleFullSizePhotos}
									>
										Show{" "}
										{props.showFullSizePhotos ? "Thumbnail " : "Full Sized "}
										Photos
									</Button>
								</div>
							</div>
							<br />
							<br />

							{!props.showFullSizePhotos && (
								<Table
									striped
									bordered
									hover
									data-testid="thumbnailPhotosTable"
								>
									<thead>
										<tr key="title">
											<th></th>
											<th>Filename</th>
											<th>Size</th>
											<th>Type</th>
											<th></th>
										</tr>
									</thead>
									<tbody key="tbody">
										{props.invoiceSummary.invoiceDetails.photos.map(
											(image: Photo, index: number) => {
												return (
													<tr key={image.name + index}>
														<td></td>
														<ImageTableItem item={image} />
													</tr>
												);
											}
										)}
									</tbody>
								</Table>
							)}
						</>
					)}
			</Row>
			{props.showFullSizePhotos && photoDownloadError && (
				<Row className="flexBasis100 flexEnd">
					<Col className="maxWidth600">
						<Alert className="StatusAlert mb-3 ml-0 mt-0" variant="danger">
							<FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
							<span>{photoDownloadError}</span>
						</Alert>
					</Col>
				</Row>
			)}
			{props.showFullSizePhotos &&
				!photoDownloadError &&
				props.viewingCurrentVersion && (
					<Row>
						{props.isLoadingFullSizePhotos && <PageSpinnerComponent />}
						<div data-testid="fullSizePhotosDiv">
							{pictures.map((image: any, index: number) => {
								return (
									<div key={"photoDiv" + index}>
										<Image
											src={image.src}
											rounded
											thumbnail
											data-testid="fullSizeImage"
											key={index}
										/>
										&nbsp;
										<b>FileName:</b> {image.imageName}
										<br />
										<br />
									</div>
								);
							})}
							<br />
						</div>
					</Row>
				)}
		</>
	);
}
