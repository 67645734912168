import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./Modal.css";

interface ModalProps {
  show: any;
  onHide: any;
  title: string;
  body: string;
  yesButtonText: string;
  onYesClick: any;
  noButtonText: string;
  onNoClick: any;
}

export function ModalYesNoConfirmation(props: ModalProps) {
  return (
    <Modal
      className="ModalYesNoConfirmation"
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="ModalYesNoConfirmation" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ModalYesNoConfirmation">
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer className="ModalYesNoConfirmation">
        <Button
          variant="outline-primary"
          onClick={(e) => {
            if (props.onHide) {
              props.onHide(e);
            }
            if (props.onNoClick) {
              props.onNoClick();
            }
          }}
          data-testid="noConfirmationButton"
        >
          {props.noButtonText}
        </Button>
        <Button
          variant="outline-primary"
          onClick={(e) => {
            if (props.onHide) {
              props.onHide(e);
            }
            if (props.onYesClick) {
              props.onYesClick();
            }
          }}
          data-testid="yesConfirmationButton"
        >
          {props.yesButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
