import React, { useEffect, useMemo, useState } from "react";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";
import { Col, Form, Row } from "react-bootstrap";
import { Error } from "../Alerts/Error";
import { Locations } from "./Locations";
import {
	policeDeptValidator,
	policeFileValidator,
} from "../../utils/Validators/PoliceFieldValidators";
import { ReasonForTow, SGI_PICK_UP } from "../../types/Invoice";
import { reasonForTowValidator } from "../../utils/Validators/ReasonForTowValidator";

export function AccidentInformation(props: any) {
	const [policeFileMessage, setPoliceFileMessage] = useState("");
	const [policeDeptMessage, setPoliceDeptMessage] = useState("");
	const [reasonForTowMessage, setReasonForTowMessage] = useState("");
	const [towedFromAccidentMessage, setTowedFromAccidentMessage] = useState("");
	const [locationsValid, setLocationsValid] = useState(false);
	const [reasonForTow, setReasonForTow] = useState(
		convertEnumToObj(ReasonForTow)
	);
	const [noPoliceFileAvailable, setNoPoliceFileAvailable] = useState(
		props.invoiceDetails.policeFile !== ""
	);
	const ValidatorFunctions: ValidatorFunction[] = useMemo(
		() => [
			{
				fieldname: "policeFile",
				validator: policeFileValidator,
				stateSetter: setPoliceFileMessage,
			},
			{
				fieldname: "policeDept",
				validator: policeDeptValidator,
				stateSetter: setPoliceDeptMessage,
			},
			{
				fieldname: "reasonForTow",
				validator: reasonForTowValidator,
				stateSetter: setReasonForTowMessage,
			},
		],
		[]
	);

	function handleOnChange(event: any) {
		const { name, value } = event.target;
		let fieldErrorMessage = "Error";

		if (name === "policeFile") {
			if (value !== "") {
				setNoPoliceFileAvailable(true);
				setPoliceFileMessage(policeFileValidator(value));

				props.setInvoiceDetails({
					...props.invoiceDetails,
					[name]: value,
				});
			} else {
				setNoPoliceFileAvailable(false);
				setPoliceDeptMessage("");

				setPoliceFileMessage(policeFileValidator(value));

				props.setInvoiceDetails({
					...props.invoiceDetails,
					[name]: value,
					policeDept: "",
				});
			}
		} else if (name === "policeDept") {
			// need to call this with an extra parameter
			setPoliceDeptMessage(
				policeDeptValidator(value, props.invoiceDetails.policeFile)
			);

			props.setInvoiceDetails({
				...props.invoiceDetails,
				[name]: value,
			});
		} else {
			var validator = ValidatorFunctions.find(
				(element) => element.fieldname === name
			);

			if (validator !== undefined) {
				fieldErrorMessage = validator.validator(value);
				validator.stateSetter(fieldErrorMessage);
			}
			props.setInvoiceDetails({
				...props.invoiceDetails,
				[name]: value,
			});
		}
	}

	function handleOnChangeRadio(event: any) {
		const { name } = event.target;
		let valueToStore = "Y";

		if (name === "towedFromAccident-no") valueToStore = "N";

		setTowedFromAccidentMessage("");

		props.setInvoiceDetails({
			...props.invoiceDetails,
			towedFromAccident: valueToStore,
		});
	}

	function convertEnumToObj(reasonForTowEnum: any) {
		let reasonForTowObj: any = {};
		Object.keys(reasonForTowEnum).map((reason, index) => {
			reasonForTowObj[reason] = Object.values(reasonForTowEnum)[index];
			return reasonForTowObj;
		});
		return reasonForTowObj;
	}

	const { isValid } = props;

	useEffect(() => {
		let allFieldsValid =
			props.invoiceDetails.towedFromAccident !== "U" ||
			props.invoiceDetails.reasonForTow === SGI_PICK_UP;
		ValidatorFunctions.forEach((f) => {
			if (f.fieldname === "policeDept") {
				// need a special case here as this has a special validator
				// need to call this with an extra parameter
				if (
					policeDeptValidator(
						props.invoiceDetails[f.fieldname],
						props.invoiceDetails.policeFile
					) !== ""
				) {
					allFieldsValid = false;
				}
			} else {
				if (f.validator(props.invoiceDetails[f.fieldname]) !== "") {
					allFieldsValid = false;
				}
			}
		});

		isValid(allFieldsValid && locationsValid);
	}, [ValidatorFunctions, isValid, props.invoiceDetails, locationsValid]);

	useEffect(() => {
		if (props.showErrors)
			ValidatorFunctions.forEach((f) => {
				if (props.isDraft && props.invoiceDetails[f.fieldname] === "") {
					f.stateSetter("");
				} else {
					let fieldErrorMessage = f.validator(
						props.invoiceDetails[f.fieldname]
					);
					f.stateSetter(fieldErrorMessage);
					if (
						!props.isDraft &&
						props.invoiceDetails.towedFromAccident === "U" &&
						props.invoiceDetails.reasonForTow !== SGI_PICK_UP
					) {
						setTowedFromAccidentMessage("You must make a selection.");
					} else {
						setTowedFromAccidentMessage("");
					}
				}
			});
	}, [
		props.showErrors,
		ValidatorFunctions,
		props.invoiceDetails,
		props.isDraft,
	]);

	useEffect(() => {
		if (props.invoiceDetails.subsequentTow) {
			setReasonForTow(
				Object.assign(
					{},
					{
						...reasonForTow,
						SGIPickup: SGI_PICK_UP,
					}
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.invoiceDetails]);

	return (
		<>
			<Row>
				<Col>
					<Form.Group controlId="formEntryReasonForTow">
						<Form.Label className="formLabelText">Reason For Tow</Form.Label>
						<Form.Control
							as="select"
							placeholder=""
							data-testid="formEntryReasonForTow"
							onChange={handleOnChange}
							onBlur={handleOnChange}
							name="reasonForTow"
							value={props.invoiceDetails.reasonForTow}
						>
							{Object.keys(reasonForTow).map((reason, index) => {
								return <option key={reason}>{reasonForTow[reason]}</option>;
							})}
						</Form.Control>
						{reasonForTowMessage !== "" && (
							<Error
								data-testid="formEntryReasonForTowError"
								message={reasonForTowMessage}
							/>
						)}
					</Form.Group>
				</Col>
				<Col>
					<div className="radioWrapper formLabelText">
						<Row>Towed From Accident?</Row>
						<Row>
							<Form.Check
								type="radio"
								placeholder=""
								data-testid="formEntryTowedFromAccident-yes"
								onChange={handleOnChangeRadio}
								name="towedFromAccident-yes"
								label="Yes"
								checked={props.invoiceDetails.towedFromAccident === "Y"}
							/>
							&nbsp;&nbsp;&nbsp;
							<Form.Check
								type="radio"
								placeholder=""
								data-testid="formEntryTowedFromAccident-no"
								onChange={handleOnChangeRadio}
								name="towedFromAccident-no"
								label="No"
								checked={props.invoiceDetails.towedFromAccident === "N"}
							/>
						</Row>
					</div>
					{towedFromAccidentMessage !== "" && (
						<Error message={towedFromAccidentMessage} />
					)}
				</Col>
			</Row>
			<Locations
				isValid={setLocationsValid}
				showErrors={props.showErrors}
				invoiceDetails={props.invoiceDetails}
				setInvoiceDetails={props.setInvoiceDetails}
				isDraft={props.isDraft}
			/>
			<Row>
				<Col>
					<Form.Group controlId="formEntryPoliceFile">
						<Form.Label className="formLabelText">Police File #</Form.Label>
						<Form.Control
							type="text"
							placeholder="If Available"
							data-testid="formEntryPoliceFile"
							onChange={handleOnChange}
							name="policeFile"
							value={props.invoiceDetails.policeFile}
						/>
						{policeFileMessage !== "" && <Error message={policeFileMessage} />}
					</Form.Group>
				</Col>
				<Col>
					{noPoliceFileAvailable && (
						<Form.Group controlId="formEntryPoliceDept">
							<Form.Label className="formLabelText">
								Police Department
							</Form.Label>
							<Form.Control
								type="text"
								placeholder=""
								data-testid="formEntryPoliceDept"
								onChange={handleOnChange}
								name="policeDept"
								value={props.invoiceDetails.policeDept}
							/>
							{policeDeptMessage !== "" && (
								<Error message={policeDeptMessage} />
							)}
						</Form.Group>
					)}
				</Col>
			</Row>
		</>
	);
}
