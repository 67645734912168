import { iVendorList } from "../types/VendorList";

export function mapToIVendorList(apiData: any): iVendorList[] {
  var returnData: iVendorList[] = [];

  // map api data received
  apiData.forEach((item: any) => {
    var result: iVendorList = {
      vendorId: item.vendorId,
      companyName: item.companyName,
      payeeNumber: item.payeeNumber,
      address: item.address,
      phoneNumber: item.phoneNumber,
      email: item.email,
    };

    returnData.push(result);
  });

  return returnData;
}
