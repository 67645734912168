import { iVendorList } from "../types/VendorList";

export const mockedVendorListData: iVendorList[] = [
  {
    vendorId: "123",
    payeeNumber: "123456",
    companyName: "Mocked Towing Ltd.",
    address: "123 Main Street, Small Town, SK",
    phoneNumber: "3061232345",
    email: "test@test",
  },
  {
    vendorId: "789",
    payeeNumber: "7891011",
    companyName: "Mocked Pull Me Out Ltd.",
    address: "456 Second Street, Small Town, SK",
    phoneNumber: "3067894512",
    email: "test2@test",
  },
];
