import { Row, Col, Form, Button } from "react-bootstrap";
import { RenderRadioButtons } from "./VehicleInformation";
import { Error } from "../Alerts/Error";
import { ReasonForNoVIN, VinData } from "../../types/Invoice";
import { decodeVin } from "../../api/apiVinDecoder";
import { ModalBarcodeScanner } from "./ModalBarcodeScanner/ModalBarcodeScanner";
import { useEffect, useState } from "react";
import {
	validateVehicleMake,
	validateVehicleModel,
	validateVehicleYear,
	validateVin,
} from "../../utils/Validators/VehicleValidator";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import { ToastErrors } from "../Toast/ToastErrors";
import { firstLetterUppercase } from "../../utils/Utils";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "../../AppInsights";
import { UpcScan } from "react-bootstrap-icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTypedSelector } from "../../redux/store";

export function VinField(props: any) {
	const [showCamera, setShowCamera] = useState(false);
	const [scannerData, setScannerData] = useState("");
	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [failureMessages, setFailureMessages] = useState(emptyStringArray);
	const flags = useFlags();
	const reduxConnectionStatus = useTypedSelector(
		(state) => state.connectionStatus
	);

	const DecodeVin = async (vin: string) => {
		props.setVinDecodeLoading(true);
		var result = await decodeVin(vin);

		if (result && result.data && result.status === 200) {
			setSuccessMessages([
				...successMessages,
				"VIN information has been decoded and entered on your invoice",
			]);

			var vinData: VinData = {
				extendedVehicleCode: result.data.extendedVehicleCode,
				make:
					validateVehicleMake(result.data.make) === ""
						? firstLetterUppercase(result.data.make)
						: "",
				model:
					validateVehicleModel(result.data.model) === ""
						? firstLetterUppercase(result.data.model)
						: "",
				modelYear:
					validateVehicleYear(result.data.modelYear) === ""
						? result.data.modelYear
						: "",
				curbWeight: result.data.curbWeight,
				price: result.data.price,
			};

			props.setInvoiceDetails({
				...props.invoiceDetails,
				vehicleMake: vinData.make,
				vehicleModel: vinData.model,
				vehicleYear: vinData.modelYear,
				vinData: vinData,
				vehicleVIN: vin,
				vinDecoded: true,
			});
		} else if (result && result.status === 204) {
			setFailureMessages(["No vehicle information available for this VIN"]);
			props.setInvoiceDetails({
				...props.invoiceDetails,
				vinDecoded: false,
			});
		} else {
			setFailureMessages([
				reduxConnectionStatus.connected
					? "Error decoding VIN"
					: "Internet Connection Required",
			]);
			props.setInvoiceDetails({
				...props.invoiceDetails,
				vinDecoded: false,
			});
		}
		props.setVinDecodeLoading(false);
	};

	function handleVinOnBlur(event: any) {
		const { value } = event.target;
		if (validateVin(value) === "") {
			DecodeVin(value);
		}
	}

	function onBarcodeScanButtonClick() {
		setShowCamera(!showCamera);

		setScannerData("");

		props.setInvoiceDetails({
			...props.invoiceDetails,
			vinScanned: false,
		});
	}

	useEffect(() => {
		if (
			props.invoiceDetails.vehicleVIN !== "" &&
			props.invoiceDetails.vinScanned &&
			!props.invoiceDetails.vinDecoded
		) {
			DecodeVin(props.invoiceDetails.vehicleVIN);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.invoiceDetails.vinScanned]);

	useEffect(() => {
		if (scannerData !== "") {
			setShowCamera(false);

			//Remove leading 'I' character on imported vehicle VINs..
			var scannedString: string = scannerData.replaceAll("I", "");

			if (scannedString.length > 17) {
				scannedString = scannedString.substring(1);
			}

			if (validateVin(scannedString) === "") {
				props.setInvoiceDetails({
					...props.invoiceDetails,
					vehicleVIN: scannedString,
					reasonForNoVIN: "",
					noVINReasonDetails: "",
					vinScanned: true,
					vinDecoded: false,
				});
				props.setVinMessage("");
				setSuccessMessages(["Vin Successfully Scanned"]);
			} else {
				if (appInsights !== undefined) {
					appInsights.trackTrace({
						message: "Scanner found an invalid VIN: " + scannerData,
						severityLevel: SeverityLevel.Information,
					});
				}
				setFailureMessages([
					"Scanned barcode does not contain a VIN. " +
						JSON.stringify(scannerData) +
						" was found. Please try again.",
				]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scannerData]);

	return (
		<>
			<ToastSuccess
				title="VIN Success"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<ToastErrors
				title="VIN Failure"
				setMessages={setFailureMessages}
				messages={failureMessages}
			/>
			<ModalBarcodeScanner
				scannerData={scannerData}
				setScannerData={setScannerData}
				show={showCamera}
				onHide={() => setShowCamera(false)}
			/>
			<Row>
				<Col sm={4}>
					<Form.Group controlId="formEntryVINAvailable">
						{RenderRadioButtons(
							"Vin Available",
							"VinAvailable",
							props.VINAvailable,
							props.handleOnChangeRadio
						)}
					</Form.Group>
				</Col>
				{props.VINAvailable && (
					<Col sm={6}>
						<Form.Group controlId="formEntryVehicleVIN">
							<div>
								<Form.Label className="formLabelText">VIN</Form.Label>
								<div className="inline">
									<Form.Control
										type="text"
										placeholder="VIN"
										data-testid="formEntryVehicleVIN"
										onBlur={handleVinOnBlur}
										onChange={props.handleOnChange}
										name="vehicleVIN"
										value={props.invoiceDetails.vehicleVIN}
										isValid={props.invoiceDetails.vinDecoded}
									/>
									&nbsp;
									{flags.vendorPaymentUiVinScanner && (
										<Button
											data-testid="barcodeScanner"
											onClick={onBarcodeScanButtonClick}
											variant="outline-primary"
											size="sm"
											className={
												"barcodeScannerButton " +
												(props.invoiceDetails.vinScanned && "successButton")
											}
										>
											<UpcScan />
										</Button>
									)}
								</div>
								{props.vinMessage !== "" && (
									<Error message={props.vinMessage} />
								)}
							</div>
						</Form.Group>
					</Col>
				)}
				{!props.VINAvailable && (
					<Col sm={4}>
						<Form.Group controlId="formEntryReasonForNoVin">
							<div className="formLabelText">
								<Form.Label className="formVINLabelText">
									Reason for No VIN
								</Form.Label>
								<Form.Control
									className="formReasonForNoVIN"
									as="select"
									placeholder=""
									data-testid="formEntryReasonForNoVin"
									onChange={props.handleOnChange}
									onBlur={props.handleOnChange}
									name="reasonForNoVIN"
									value={props.invoiceDetails.reasonForNoVIN}
								>
									{Object.keys(ReasonForNoVIN).map((reason, index) => {
										return (
											<option key={index}>
												{Object.values(ReasonForNoVIN)[index]}
											</option>
										);
									})}
								</Form.Control>
								{props.reasonForNoVinMessage !== "" && (
									<Error message={props.reasonForNoVinMessage} />
								)}
							</div>
						</Form.Group>
					</Col>
				)}
			</Row>
			{!props.VINAvailable &&
				props.invoiceDetails.reasonForNoVIN === "Other" &&
				(!props.invoiceDetails.vehicleVIN ||
					props.invoiceDetails.vehicleVIN === "") && (
					<>
						<Row>
							<Col sm={4}></Col>
							<Col>
								<Form.Group controlId="formEntryNoVINReasonDetails">
									<div className="formLabelText">
										<Form.Label className="formVINLabelText">
											Reason Details
										</Form.Label>
										<Form.Control
											className="formNoVINReasonDetails"
											as="textarea"
											rows={2}
											type="text"
											placeholder=""
											data-testid="formEntryNoVINReasonDetails"
											onChange={props.handleOnChange}
											name="noVINReasonDetails"
											value={props.invoiceDetails.noVINReasonDetails}
										/>
										{props.noVINReasonDetailsMessage !== "" && (
											<Error message={props.noVINReasonDetailsMessage} />
										)}
									</div>
								</Form.Group>
							</Col>
						</Row>
					</>
				)}
		</>
	);
}
