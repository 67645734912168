import { useEffect, useState } from "react";
import { validateClaimNumber } from "../../utils/Validators/ClaimNumberValidator";
import VehicleInfoDisplayOnly from "../VehicleInfoDisplayOnly/VehicleInfoDisplayOnly";
import { Col, Form, Row, Alert, Spinner } from "react-bootstrap";
import { Error } from "../Alerts/Error";
import { useTypedSelector } from "../../redux/store";
import { getInvoiceByClaimNumber } from "../../api/apiInvoice";
import {
	clearSubsequentTowData,
	combineInvoicesForSubsequentTow,
} from "../../utils/InvoiceDataTransformer";
import { ModalSubsequentConfirmation } from "../../components/Modals/ModalSubsequentConfirmation";
import { defaultNewInvoiceDetails } from "../../types/Invoice";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../AppInsights";

export default function ClaimNumberField(props: any) {
	const [claimNumberMessage, setClaimNumberMessage] = useState("");
	const [subsequentTowLoading, setSubsequentTowLoading] = useState(false);
	const [newInvoiceDetails, setNewInvoiceDetails] = useState(
		defaultNewInvoiceDetails
	);
	const [modalShow, setModalShow] = useState(false);

	const reduxLogin = useTypedSelector((state) => state.login);

	const FindMatchingClaimNumber = async (
		claimNumber: string,
		showModal: boolean
	) => {
		setSubsequentTowLoading(true);
		var result = await getInvoiceByClaimNumber(
			claimNumber,
			reduxLogin.vendorId
		);
		if (
			result &&
			result.data &&
			result.status === 200 &&
			result.data.status === 6
		) {
			props.setInvoiceDetails({
				...props.invoiceDetails,
				subsequentTow: false,
				claimNumber: claimNumber,
			});
		} else if (
			result &&
			result.data &&
			result.status === 200 &&
			result.data.status !== 6
		) {
			setNewInvoiceDetails({
				...combineInvoicesForSubsequentTow(props.invoiceDetails, result.data),
				subsequentTow: true,
				claimNumber: claimNumber,
			});
			setModalShow(showModal);
		} else {
			if (appInsights !== undefined) {
				appInsights.trackTrace({
					message: "No matching claim number found: " + claimNumber,
					severityLevel: SeverityLevel.Information,
				});
			}
		}
		setSubsequentTowLoading(false);
	};

	function HandleSubsequentConfirmed() {
		props.setInvoiceDetails(newInvoiceDetails);
	}

	function HandleSubsequentRejected() {
		props.setInvoiceDetails({
			...props.invoiceDetails,
			subsequentTow: false,
			claimNumber: "",
		});
	}

	useEffect(() => {
		if (props.claimNumber !== undefined)
			FindMatchingClaimNumber(props.claimNumber, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.claimNumber]);

	useEffect(() => {
		if (props.claimNumber !== undefined) {
			HandleSubsequentConfirmed();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newInvoiceDetails]);

	function handleChangeClaimNumber(event: any) {
		const { value } = event.target;

		if (value !== props.claimNumber) {
			props.setClaimNumber(undefined);
		}

		var errorMessages = validateClaimNumber(value);
		setClaimNumberMessage(errorMessages);

		if (errorMessages === "" && value !== "") {
			FindMatchingClaimNumber(value, true);
		}
		if (props.invoiceDetails.subsequentTow) {
			props.setInvoiceDetails({
				...clearSubsequentTowData(props.invoiceDetails),
				subsequentTow: false,
				claimNumber: value,
			});
		} else {
			props.setInvoiceDetails({
				...props.invoiceDetails,
				subsequentTow: false,
				claimNumber: value,
			});
		}
	}

	useEffect(() => {
		props.isValid(validateClaimNumber(props.invoiceDetails.claimNumber) === "");

		if (props.invoiceDetails.subsequentTow) {
			props.isVehicleInformationValid(true);
			props.isVehicleStatusValid(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.isValid,
		props.invoiceDetails.claimNumber,
		props.invoiceDetails.subsequentTow,
	]);

	useEffect(() => {
		props.isValid(validateClaimNumber(props.invoiceDetails.claimNumber) === "");
		if (props.showErrors && props.isDraft) {
			if (props.invoiceDetails.claimNumber === "") {
				setClaimNumberMessage("");
			} else {
				setClaimNumberMessage(
					validateClaimNumber(props.invoiceDetails.claimNumber)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.showErrors, props.invoiceDetails, props.isDraft]);

	return (
		<>
			<ModalSubsequentConfirmation
				title="Subsequent Tow"
				invoiceDetails={newInvoiceDetails}
				buttonYesText="Yes, subsequent tow"
				buttonNoText="No"
				onYesButtonClick={HandleSubsequentConfirmed}
				onNoButtonClick={HandleSubsequentRejected}
				show={modalShow}
				onHide={() => {
					setModalShow(false);
				}}
			/>
			<Row>
				<Col>
					<Form.Group controlId="formEntrySGIClaimNumber" id="claimNumber">
						<Form.Label className="formLabelText">
							SGI Claim # {"  "}
							{subsequentTowLoading && (
								<Spinner animation="border" role="status" />
							)}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder="If Available"
							data-testid="formEntrySGIClaimNumber"
							onChange={handleChangeClaimNumber}
							isValid={props.invoiceDetails.subsequentTow}
							name="claimNumber"
							value={props.invoiceDetails.claimNumber}
							disabled={!props.displayAsEditable || subsequentTowLoading}
						/>
					</Form.Group>
					{claimNumberMessage !== "" && (
						<div id="claimNumberMessage">
							<Error message={claimNumberMessage} />
						</div>
					)}
				</Col>
			</Row>

			{props.invoiceDetails.subsequentTow && (
				<>
					<Alert variant="info" id="subsequentTowInfoWrapper">
						<div className="subsequentTowMessageTitle">
							Subsequent Tow
							<div className="subsequentTowMessageBody">
								The following vehicle matches this claim number:
							</div>
						</div>
						<Row xs={2} sm={2} md={4} lg={4}>
							<VehicleInfoDisplayOnly invoiceDetails={props.invoiceDetails} />
						</Row>
					</Alert>
				</>
			)}
			{!props.invoiceDetails.subsequentTow && <br></br>}
		</>
	);
}
