export interface UserPermissions {
  canSubmitInvoice: boolean;
  canSaveInvoice: boolean;
  canViewOwnInvoice: boolean;
  canViewCompanyInvoices: boolean;
  canViewAllInvoices: boolean;
  canPayInvoice: boolean;
  canAnnotateInvoice: boolean;
  canViewFeedback: boolean;
  canSubmitFeedback: boolean;
  canViewInventory: boolean;
  canViewDashboard: boolean;
  canEditProfile: boolean;
  canEditPayee: boolean;
  canManageBulletin: boolean;
  canViewBulletin: boolean;
  canMarkSearchedInvoice: boolean;
  canSendGISInvoice: boolean;
  canAssignInvoice: boolean;
  canDeleteInvoice: boolean;
  canViewRateList: boolean;
  canViewStorageList: boolean;
  canMarkAsPaidInvoice: boolean;
  canViewHistoryList: boolean;
  canViewVendorList: boolean;
}

export const defaultUserPermissions: UserPermissions = {
  canSubmitInvoice: false,
  canSaveInvoice: false,
  canViewOwnInvoice: true,
  canViewCompanyInvoices: false,
  canViewAllInvoices: false,
  canPayInvoice: false,
  canAnnotateInvoice: false,
  canViewFeedback: false,
  canSubmitFeedback: false,
  canViewInventory: false,
  canViewDashboard: false,
  canEditProfile: false,
  canEditPayee: false,
  canManageBulletin: false,
  canViewBulletin: true,
  canMarkSearchedInvoice: false,
  canSendGISInvoice: false,
  canAssignInvoice: false,
  canDeleteInvoice: false,
  canViewRateList: false,
  canViewStorageList: false,
  canMarkAsPaidInvoice: false,
  canViewHistoryList: false,
  canViewVendorList: false
};

export const defaultVendorPermissions: UserPermissions = {
  canSubmitInvoice: true,
  canSaveInvoice: true,
  canViewOwnInvoice: true,
  canViewCompanyInvoices: true,
  canViewAllInvoices: false,
  canPayInvoice: false,
  canAnnotateInvoice: false,
  canViewFeedback: false,
  canSubmitFeedback: true,
  canViewInventory: true,
  canViewDashboard: true,
  canEditProfile: true,
  canEditPayee: false,
  canManageBulletin: false,
  canViewBulletin: true,
  canMarkSearchedInvoice: false,
  canSendGISInvoice: false,
  canAssignInvoice: false,
  canDeleteInvoice: false,
  canViewRateList: false,
  canViewStorageList: false,
  canMarkAsPaidInvoice: false,
  canViewHistoryList: false,
  canViewVendorList: false
};

export const defaultEmployeePermissions: UserPermissions = {
  canSubmitInvoice: false,
  canSaveInvoice: false,
  canViewOwnInvoice: false,
  canViewCompanyInvoices: false,
  canViewAllInvoices: true,
  canPayInvoice: true,
  canAnnotateInvoice: true,
  canViewFeedback: true,
  canSubmitFeedback: true,
  canViewInventory: false,
  canViewDashboard: false,
  canEditProfile: false,
  canEditPayee: true,
  canManageBulletin: true,
  canViewBulletin: true,
  canMarkSearchedInvoice: true,
  canSendGISInvoice: true,
  canAssignInvoice: true,
  canDeleteInvoice: true,
  canViewRateList: true,
  canViewStorageList: true,
  canMarkAsPaidInvoice: true,
  canViewHistoryList: true,
  canViewVendorList: true
};
