import { Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import {
	GetUserPermissions,
	isAuthorized,
} from "../../utils/auth/authUtilities";

interface AuthorizedParams {
	requiredPermissions: string[];
	redirectOnUnAuthorized?: Boolean;
	requireAll?: boolean;
	children: JSX.Element;
}
const Authorized = ({
	requiredPermissions,
	redirectOnUnAuthorized,
	children,
	requireAll = true,
}: AuthorizedParams) => {
	const isAuthenticated = useIsAuthenticated();
	let permissions = GetUserPermissions();
	if (
		isAuthenticated &&
		isAuthorized(permissions, requiredPermissions, requireAll)
	) {
		return children;
	} else if (redirectOnUnAuthorized) {
		return <Redirect to={"/PageNotAuthorized"} />;
	} else {
		return <div data-testid="unauthorizedComponent" />;
	}
};

export default Authorized;
