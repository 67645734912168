export interface iTowerInventoryList {
	vehiclePlate: string;
	vehicleVIN: string;
	serviceDate: string;
	vehicleColour: string;
	vehicleYear: string;
	vehicleMake: string;
	vehicleModel: string;
	invoiceId: string;
	removedFromInventory: boolean;
	payeeNumber: string;
	locations: string;
	totalImpoundmentDays: number;
}

export const defaultTowerInventoryList: iTowerInventoryList[] = [];
