import { useState } from "react";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import BulletinForm from "./BulletinForm";
import {
  getAllBulletin,
  downloadBulletinFile,
  deleteBulletin,
} from "../../api/apiBulletin";
import LoadingOverlay from "react-loading-overlay";
import CustomTable from "../../components/CustomTable/CustomTable";
import { SortOrder } from "react-bootstrap-table-next";
import { defaultBulletinList } from "../../types/BulletinList";
import { mapToBulletinList } from "../../utils/BulletinListTransformer";
import { convertDateToDisplayFormatWithTime } from "../../utils/Utils";
import TableButton from "../../components/CustomTable/TableButton";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import "./index.css";

const BulletinList = () => {
  const [isOpenModel, setIsOpenModel] = useState<boolean>(false);
  const [tableLoading] = useState(false);
  const [bulletinList, setBulletinList] = useState(defaultBulletinList);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [successMessages, setSuccessMessages] = useState<string[]>([]);

  const openAddBulletinModel = () => {
    setIsOpenModel(true);
  };

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "createdOn",
      order: "desc",
    },
  ];

  const createdOnDateFormatter = (cell: string) => {
    var formattedDate = convertDateToDisplayFormatWithTime(cell);

    return formattedDate;
  };

  const handleDownloadBulletin = async (e: any, id: string, ext: string) => {
    e.preventDefault();

    const response = await downloadBulletinFile(id);

    if (response && response.data) {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${id}${ext}`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const handleDeleteBulletin = async (id: string) => {
    const response = await deleteBulletin(id);

    if (response !== undefined && response.status == 200) {
      setBulletinList(bulletinList.filter((x) => x.id !== id));
      setSuccessMessages(["Bulletin has been deleted successfully."]);
    } else {
      setErrorMessages([
        "There was an error uploading bulletin. Please contact administrator.",
      ]);
    }
  };

  const handleSubmit = async (error: boolean) => {
    if (error) {
      setErrorMessages([
        "There was an error during bulletin upload. Please contact administrator.",
      ]);
    }
    else {
      setIsOpenModel(false);

      const refreshedData = await getAllBulletin();
      setBulletinList(mapToBulletinList(refreshedData.data));

      setSuccessMessages(["Bulletin has been successfully created."]);
    }
  }

  const actionsFormatter = (e: any, row: any, rowIndex: any) => {
    return (
      <div className="buttonCellWrapper">
        <TableButton
          icon={faTrash}
          onClick={() => handleDeleteBulletin(row.id)}
          tooltipText="Delete Bulletin"
          id={`delete-${row.invoiceId}`}
          datatestid={`delete-${row.invoiceId}`}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "id",
      sort: true,
      headerClasses: "title-column",
      hidden: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerClasses: "title-column",
    },
    {
      dataField: "uri",
      text: "Url",
      sort: true,
      formatter: (cell: string, row: any) => {

        return (
          <a
            href={cell}
            data-testid={`bulletin-file-link-${cell}`}
            title="Open in a new tab"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => handleDownloadBulletin(e, cell, row.extension)}
          >
            {cell}
          </a>
        );
      },
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
      formatter: createdOnDateFormatter,
      headerClasses: "created-on-column",
    },
    {
      dataField: "actions",
      text: "",
      formatter: actionsFormatter,
      headerClasses: "actions-column",
    },
    {
      dataField: "extension",
      text: "Extension",
      hidden: true
    },
  ];

  const getAllBulletins = async () => {
    const result = await getAllBulletin();

    return result;
  };

  return (
    <Container className="CustomTableContainer">
      <ToastErrors
        title="Bulletin Info"
        setMessages={setErrorMessages}
        messages={errorMessages}
      />
      <ToastSuccess
        title="Bulletin Info"
        setMessages={setSuccessMessages}
        messages={successMessages}
      />
      <Row>
        <Col lg="9">
          <div className="Title">
            <h3>Bulletin List</h3>
          </div>
        </Col>
        <Col lg="3">
          <Button
            variant="outline-primary"
            data-testid="addBulletin"
            onClick={openAddBulletinModel}
          >
            Add bulletin
          </Button>
        </Col>
        <Modal
          size="lg"
          show={isOpenModel}
          onHide={() => setIsOpenModel(false)}
          animation={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header>
            <h4>Add New bulletin</h4>
          </Modal.Header>
          <Modal.Body>
            <BulletinForm
              handleCancel={() => setIsOpenModel(false)}
              handleSubmit={handleSubmit}
            />
          </Modal.Body>
        </Modal>
      </Row>
      <Row>
        <LoadingOverlay active={tableLoading} spinner text="Loading ...">
          <CustomTable
            noDataIndication={() => "There are no bulletins to display."}
            defaultSorted={defaultSorted}
            columns={columns}
            data={bulletinList}
            setData={setBulletinList}
            defaultData={defaultBulletinList}
            dataGetter={getAllBulletins}
            dataMapper={mapToBulletinList}
            keyField={"bulletinId"}
          />
        </LoadingOverlay>
      </Row>
    </Container>
  );
};

export default BulletinList;
