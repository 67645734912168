import React from "react";
import "./Alert.css";
import { Alert, Col, Container, Row } from "react-bootstrap";
interface Props {
  message: string;
  dataTestid?: string;
}
export function Error(props: Props) {
  return (
    <Alert variant="danger">
      <Container className="alertContainer">
        <Row className="alertContainerRow">
          <Col className="alertMessage" id="alertError">
            <span data-testid={props.dataTestid}>{props.message}</span>
          </Col>
        </Row>
      </Container>
    </Alert>
  );
}
