import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	configure,
	BarcodePicker as ScanditSDKBarcodePicker,
} from "scandit-sdk";

const style = {
	top: "0",
	bottom: "0",
	left: "0",
	right: "0",
	margin: "auto",
	maxWidth: "1280px",
	maxHeight: "80%",
};
class BarcodePicker extends Component {
	static propTypes = {
		visible: PropTypes.bool,
		playSoundOnScan: PropTypes.bool,
		vibrateOnScan: PropTypes.bool,
		scanningPaused: PropTypes.bool,
		guiStyle: PropTypes.string,
		videoFit: PropTypes.string,
		scanSettings: PropTypes.object,
		enableCameraSwitcher: PropTypes.bool,
		enableTorchToggle: PropTypes.bool,
		enableTapToFocus: PropTypes.bool,
		enablePinchToZoom: PropTypes.bool,
		accessCamera: PropTypes.bool,
		camera: PropTypes.object,
		cameraSettings: PropTypes.object,
		targetScanningFPS: PropTypes.number,
		onScan: PropTypes.func,
		onError: PropTypes.func,
		setVinData: PropTypes.string,
		setErrorMessage: PropTypes.string,
	};
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}
	componentDidMount() {
		// Configure the library and activate it with a license key

		configure(window["scanditKey"]?.scanditKeyValue, {
			engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build",
		})
			.catch((error) => {
				console.error(error);
			})
			.then(() => {
				ScanditSDKBarcodePicker.create(this.ref.current, this.props).then(
					(barcodePicker) => {
						this.barcodePicker = barcodePicker;
						if (this.props.onScan != null) {
							barcodePicker.on("scan", (v) => {
								this.props.setVinData(v.barcodes[0].data);
							});
						}
						if (this.props.onError != null) {
							barcodePicker.on("scanError", (err) => {
								this.props.setErrorMessage(err.message);
							});
						}
					}
				);
			});
	}
	componentWillUnmount() {
		if (this.barcodePicker != null) {
			this.barcodePicker.destroy();
		}
	}
	componentDidUpdate(prevProps) {
		// These are just some examples of how to react to some possible property changes
		if (
			JSON.stringify(prevProps.scanSettings) !==
			JSON.stringify(this.props.scanSettings)
		) {
			this.barcodePicker.applyScanSettings(this.props.scanSettings);
		}
		if (prevProps.visible !== this.props.visible) {
			this.barcodePicker.setVisible(this.props.visible);
		}
	}
	render() {
		return <div style={style} ref={this.ref}></div>;
	}
}
export default BarcodePicker;
