import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getVendorByOrganizationId } from "../api/apiVendor";
import { getPermissions } from "../api/apiPermissions";
import { setUserPermissions } from "../redux/actions/permissionActions";
import { appInsights } from "../AppInsights";
import { login } from "../redux/actions/loginActions";
import { clearRedirect } from "../redux/actions/redirectActions";
import { CompoundLocation, emptyCompoundLocation } from "../types/Account";
import { MockUser } from "../types/MockedUserTypes";
import {
  defaultUserPermissions,
  UserPermissions,
} from "../types/UserPermissions";

export const defaultOrgId: string = "00000000-0000-0000-0000-000000000000";

export function isAuthenticated(reduxLogin: any): boolean {
  return reduxLogin.signedIn;
}

export async function AssignSecurity(
  payload: any,
  setDisplaySpinner: any,
  errorMessages: any,
  setErrorMessages: any,
  history: any,
  dispatch: any,
  redirect: any
) {
  var apiPermissions: UserPermissions = defaultUserPermissions;
  if (payload) {
    var email: string = payload.idTokenClaims.email;
    var permissions = getAllPermissionsFromPayload(payload);
    var orgPermissions = getOrgPermissions(permissions);
    var organizationId: string = getOrganizationId(orgPermissions); // Assumes only 1 Organization
    apiPermissions = await SetReduxUserPermissions(organizationId); // Does not use the list of permissions above. Does an API call to Core Auth.

    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message:
          "Login with " +
          email.substring(0, 15) +
          ", orgId: " +
          organizationId.substring(0, 5),
        severityLevel: SeverityLevel.Information,
      });
    }

    var vendorResponse = undefined;
    vendorResponse = await getVendorByOrganizationId(organizationId);
    if (vendorResponse !== undefined) {
      var {
        vendorId,
        payee,
        companyName,
        compoundLocation,
      }: {
        vendorId: string;
        payee: string;
        companyName: string;
        compoundLocation: CompoundLocation;
      } = mapLoginVariables(vendorResponse);

      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message:
            "Vendor info:" +
            email.substring(0, 15) +
            ", vendorId: " +
            vendorId.substring(0, 5) +
            ", payeeNumber: " +
            payee.substring(0, 5) +
            ", companyName: " +
            companyName.substring(0, 15),
          severityLevel: SeverityLevel.Information,
        });
      }

      const loginPackage: MockUser = setLoginInfo(
        payload,
        vendorId,
        organizationId,
        payee,
        email,
        companyName,
        compoundLocation
      );

      // set our login state object
      dispatch(login(loginPackage));
      loginRedirect(redirect, dispatch, history, apiPermissions);
    } else {
      setErrorMessages([
        ...errorMessages,
        "Error accessing application, please try again later.",
      ]);
    }
  }

  setDisplaySpinner(false);
  return apiPermissions;

  async function SetReduxUserPermissions(orgId: string) {
    // Sets a dictionary of booleans in Redux, indicating which permissions are present.
    // Performs an API call to Core Auth using the orgId in getPermissions().
    // Do we need to do that API call considering we get the permissions in the login payload?
    // Would need to instead parse them and convert into same boolean format, which probably isn't worth the
    // trouble at this point.
    var userPermissions: any = {};
    await getPermissions(orgId)
      .then((result) => {
        if (result !== undefined) {
          if (result?.data) {
            userPermissions = result.data;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(setUserPermissions(userPermissions));
    return userPermissions;
  }
}

export function loginRedirect(
  redirect: any,
  dispatch: any,
  history: any,
  userPermissions: any
) {
  if (userPermissions?.canViewDashboard) {
    if (redirect.redirect !== "") {
      var storedRedirect = redirect.redirect;
      dispatch(clearRedirect());
      history.push(storedRedirect);
    } else {
      history.push("/Invoices/Dashboard");
    }
  } else if (userPermissions?.canViewAllInvoices) {
    history.push("/Employee/Invoices/List");
  }
}

export function getAllPermissionsFromPayload(payload: any) {
  // Gets the list of all permissions in the login payload
  return payload.idTokenClaims?.permissions as string[];
}

export function getOrgPermissions(permissions: string[]) {
  // Gets the list of permissions associated with any OrganizationIds
  // Can return multiple sets of OrganizationIds
  let orgPermissions: any =
    permissions?.filter(
      (permission: string) =>
        permission.startsWith("org:") && !permission.startsWith("org:*")
    ) ?? [];
  return orgPermissions;
}

export function getOrganizationId(orgPermissions: string[]) {
  // Pulls out the OrganizationId from the list of org-associated permissions.
  // Returns the first OrgId or a default one for clerk if none.
  // NOTE: Future Enhancement - allow for multiple orgs
  let availableOrgs: any = [
    ...Array.from(new Set(orgPermissions.map((o: any) => o.slice(4, 40)))),
  ];

  let orgId: string = availableOrgs.length ? availableOrgs[0] : defaultOrgId;

  return orgId;
}

function setLoginInfo(
  payload: any,
  vendorId: string,
  organizationId: string,
  payee: string,
  email: string,
  companyName: string,
  compoundLocation: CompoundLocation
): MockUser {
  return {
    username: payload.idTokenClaims.name,
    vendorId: vendorId,
    organizationId: organizationId,
    fullName:
      payload.idTokenClaims.given_name +
      " " +
      payload.idTokenClaims.family_name,
    payeeNumber: payee,
    email: email,
    companyName: companyName,
    compoundLocation: compoundLocation,
    authUserId: payload.idTokenClaims.auth_user_id,
  };
}

function mapLoginVariables(response: any) {
  var vendorId: string = response.data.vendorId ?? "";
  var payee: string = response.data.payeeNumber ?? "";
  var companyName: string = response.data.companyName ?? "";

  var compoundLocation: CompoundLocation;

  if (
    response.data.compoundLocation === undefined ||
    response.data.compoundLocation === null
  ) {
    compoundLocation = emptyCompoundLocation;
  } else {
    compoundLocation = {
      streetAddress: response.data.compoundLocation.streetAddress,
      city: response.data.compoundLocation.city,
      province: response.data.compoundLocation.province,
      country: response.data.compoundLocation.country,
      postalCode: response.data.compoundLocation.postalCode,
      placeId: response.data.compoundLocation.placeId,
      googleResults: response.data.compoundLocation.googleResults,
    };
  }
  return {
    vendorId,
    payee,
    companyName,
    compoundLocation,
  };
}
