import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { ITransferFile } from "../types/TransferFile";

export async function getTransferSummary() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(baseUrl + "legacyTransferStatus/GetTransferSummary", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function markFileAsManuallyProc(transferFileTypes: ITransferFile) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();
  return axios
    .put(baseUrl + "legacyTransferStatus/UpdateTransfer", transferFileTypes, {
      headers: {
        Accept: "application/json",
        ContentType: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTransferSummaryByOrganization(organizationId: string) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(
      baseUrl + "legacyTransferStatus/GetTransferSummary/" + organizationId,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}
