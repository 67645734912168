import React from "react";
import { ScanSettings, Barcode } from "scandit-sdk";
import BarcodePicker from "./BarcodePicker";

export function BarcodeScanner(props: any) {
  return (
    <BarcodePicker
      playSoundOnScan={true}
      vibrateOnScan={true}
      enableTapToFocus={true}
      enableTorchToggle={true}
      guiStyle={"viewfinder"}
      scanSettings={
        new ScanSettings({
          codeDuplicateFilter: 2000,
          enabledSymbologies: [
            Barcode.Symbology.CODE128,
            Barcode.Symbology.CODE39,
            Barcode.Symbology.EAN13,
            Barcode.Symbology.EAN8,
            Barcode.Symbology.FIVE_DIGIT_ADD_ON,
            Barcode.Symbology.INTERLEAVED_2_OF_5,
            Barcode.Symbology.QR,
            Barcode.Symbology.TWO_DIGIT_ADD_ON,
            Barcode.Symbology.UPCA,
            Barcode.Symbology.UPCE,
          ],
        })
      }
      onScan={() => {}}
      setVinData={props.setScannerData}
      onError={(error: any) => {
        props.setErrorMessage(error.message);
        console.error(error.message);
      }}
    />
  );
}
