import { convertDatetoMilliseconds, convertDateToStringFormat } from "../Utils";

export function validateDate(serviceDate: string): string {
	const serviceDateMilliseconds = convertDatetoMilliseconds(serviceDate);
	const currentDateMilliseconds = convertDatetoMilliseconds(
		convertDateToStringFormat(new Date())
	);

	// The date format from the calendar control is MMM DD, YYYY
	if (!serviceDate) {
		return "Date of Tow Service must be entered";
	}
	if (isNaN(serviceDateMilliseconds)) {
		return "Invalid date format";
	}

	// new Date(0) and new Date("1970-01-01") formats to Wed Dec 31 1969 18:00:00 GMT-0600 (Central Standard Time)
	const unixTimeStampDateMilliseconds = convertDatetoMilliseconds(
		convertDateToStringFormat(new Date(0))
	);

	if (serviceDateMilliseconds <= unixTimeStampDateMilliseconds) {
		return "Date is not reasonable";
	}

	if (serviceDateMilliseconds > currentDateMilliseconds) {
		return "Date of Tow Service cannot be future dated";
	}

	return "";
}
