import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getAllVersionsByInvoiceId(invoiceId: string) {
  const baseUrl = getBaseApiUrl() + "Invoices/invoiceVersions/";
  const token = await getAccessToken();

  return axios
    .get(baseUrl + invoiceId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllVersionsIdentifiersByInvoiceId(invoiceId: string) {
  const baseUrl = getBaseApiUrl() + "Invoices/invoiceVersionsIdentifiers/";
  const token = await getAccessToken();

  return axios
    .get(baseUrl + invoiceId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}


export async function getSpecificInvoiceVersionByInvoiceId(
  invoiceId: string,
  versionTimestamp: string | null
) {
  const baseUrl = getBaseApiUrl() + "Invoices/invoiceVersions";
  const token = await getAccessToken();
  ///status/{status}/versionTimestamp/{versionTimestamp}
  return axios
    .get(`${baseUrl}/${invoiceId}/${"versionTimestamp"}/${versionTimestamp}`, {
      //baseUrl + invoiceId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllVersions() {
  const baseUrl = getBaseApiUrl() + "Invoices/invoiceVersions";

  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}/`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getVersionsByDateRange(startDate: string, endDate: string) {
  const baseUrl = getBaseApiUrl() + `Invoices/invoiceVersions?startDate=${startDate}&endDate=${endDate}`;

  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
