import { AssignedToUser } from "./AssignedToUser";
import { ReasonForDeletion, ReasonForTow } from "./Invoice";
import { WeightClass } from "./LineItems";

export const initialAssignedToUser: AssignedToUser = {
  email: "",
  fullName: "",
};

export interface iInvoiceVersions {
  id: string;
  versionTimestamp: string | null;
  vendorId: string;
  plate: string;
  reasonForNoVehiclePlate: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVIN: string;
  vehicleColour: string;
  driveable: string;
  keys: string;
  towerReference: string;
  serviceDate: string | null;
  towerName: string;
  claimNumber: string;
  requestedPayment: number;
  vendorPlate: string;
  policeFile: string;
  policeDept: string;
  reasonForTow: ReasonForTow;
  towedFrom: string;
  towedTo: string;
  towedFromAccident: string;
  statusDisplay: string;
  photoGuid: string;
  reasonForNoVIN: string;
  noVINReasonDetails: string;
  vinScanned: boolean;
  assignedToSGIEmployeeName: string;
  assignedToSGIEmployeeEmail: string;
  submittedDate: string | null;
  paidDate: string | null;
  lastUpdatedBy: string;
  payeeNumber: string;
  payeeName: string;
  invoiceLineItems: string | undefined;
  invoiceAnnotations: string;
  removedFromInventory: boolean;
  subsequentTow: boolean;
  weightClass: WeightClass;
  reasonForDeletion: ReasonForDeletion | null;
  deletionReasonDetails: string;
  deletedDate: string | null;
}

export const defaultInvoiceVersions: iInvoiceVersions[] = [];
