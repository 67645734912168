import loginReducer from "./loginReducer";
import { combineReducers } from "redux";
import redirectReducer from "./redirectReducer";
import connectionStatusReducer from "./connectionStatusReducer";
import permissionsReducer from "./permissionsReducer";
const rootReducer = combineReducers({
	login: loginReducer,
	redirect: redirectReducer,
	connectionStatus: connectionStatusReducer,
	userPermissions: permissionsReducer,
	// Add reducers here
	// key: reducer you've imported at the top.
});
export default rootReducer;
