import React, { useCallback, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { Address } from "../../types/Location";
import { parseAddressComponents } from "./mapSearch";
import { GeoAltFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import CustomMapStyle from "./CustomMapStyle";
import { iInvoiceDetails, SGI_PICK_UP } from "../../types/Invoice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { validateLocation } from "../../utils/Validators/LocationValidator";
import { useTypedSelector } from "../../redux/store";

const mapContainerStyle = {
	height: "400px",
	width: "700px",
};

const Regina = {
	lat: 50.44,
	lng: -104.61,
};

export function CustomMap(props: any) {
	const reduxState = useTypedSelector((state) => state.login);
	const compoundLocation = reduxState.compoundLocation.googleResults;
	const defaultCompoundLocation = {
		lat:
			compoundLocation && !isNaN(compoundLocation.latitude)
				? compoundLocation.latitude
				: Regina.lat,
		lng:
			compoundLocation && !isNaN(compoundLocation.longitude)
				? compoundLocation.longitude
				: Regina.lng,
	};
	const flags = useFlags();
	const options: any = {
		styles: CustomMapStyle,
		disableDefaultUI: true,
		zoomControl: true,
	};
	const invoiceLocation = {
		lat: props.invoiceDetails.locations[props.locationField].latitude,
		lng: props.invoiceDetails.locations[props.locationField].longitude,
	};
	const nullPosition = {
		lat: -1,
		lng: -1,
	};
	const center = {
		lat: invoiceLocation.lat,
		lng: invoiceLocation.lng,
	};
	const ignoreLocationValidation =
		props.locationField === "towedTo" &&
		props.invoiceDetails.reasonForTow === SGI_PICK_UP;
	const validLocation =
		props.invoiceDetails.locations[props.locationField].latitude !== 0 &&
		props.invoiceDetails.locations[props.locationField].longitude !== 0 &&
		validateLocation(
			props.invoiceDetails.locations[props.locationField],
			ignoreLocationValidation
		) === "";
	const [selected, setSelected] = useState({ lat: 0, lng: 0 });

	const mapRef = useRef();
	const onMapLoad = useCallback((map) => {
		mapRef.current = map;
	}, []);

	function getMarkerPosition() {
		if (
			validateLocation(
				props.invoiceDetails.locations[props.locationField],
				ignoreLocationValidation
			) === ""
		) {
			return invoiceLocation;
		} else {
			return nullPosition;
		}
	}

	const onClickGeoLocator = useCallback((map) => {
		props.setErrorMessage("");
		var lat = 0;
		var lng = 0;
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				lat = position.coords.latitude;
				lng = position.coords.longitude;
				var latlng = new google.maps.LatLng(lat, lng);

				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({ location: latlng }, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						props.setInvoiceDetails(
							addLocationToInvoiceDetails(
								results,
								props.invoiceDetails,
								props.locationField,
								true,
								props.invoiceDetails.locations[props.locationField]
									.specificLocation
							)
						);
					} else {
						props.setErrorMessage(
							"Error retrieving location data, please enter a new location"
						);
					}
				});
			});
		} else {
			props.setErrorMessage("Geo Location is not embedded in browser");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setPinLocation = useCallback((map) => {
		props.setErrorMessage("");
		var lat = 0;
		var lng = 0;

		lat = map.latLng.lat();
		lng = map.latLng.lng();
		var latlng = new google.maps.LatLng(lat, lng);

		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: latlng }, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK) {
				props.setPrecisePosition(true);
				props.setInvoiceDetails(
					addLocationToInvoiceDetails(
						results,
						props.invoiceDetails,
						props.locationField,
						true,
						props.invoiceDetails.locations[props.locationField].specificLocation
					)
				);
			} else {
				props.setErrorMessage(
					"Error retrieving location data, please enter a new location"
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<GoogleMap
				id="marker-example"
				mapContainerStyle={mapContainerStyle}
				zoom={props.autoZoom}
				center={
					center.lat !== 0 && center.lng !== 0
						? center
						: defaultCompoundLocation
				}
				onClick={setPinLocation}
				onLoad={onMapLoad}
				options={options}
			>
				{flags.vendorPaymentUiFindMe && (
					<div className="mapOverElements">
						<Button
							variant="light"
							id="geo-locator"
							onClick={onClickGeoLocator}
						>
							<GeoAltFill size={30} />
							<div className="divFontLocation">
								<div>
									<b>
										<i>Find</i>
									</b>
								</div>
								<div>
									<b>
										<i>Me!</i>
									</b>
								</div>
							</div>
						</Button>
					</div>
				)}
				{validLocation ? (
					<Marker
						key={`${invoiceLocation.lat}-${invoiceLocation.lng}`}
						position={getMarkerPosition()}
						draggable={true}
						onClick={() => setSelected(invoiceLocation)}
						onDragEnd={setPinLocation}
					/>
				) : null}

				{selected.lat !== 0 && selected.lng !== 0 ? (
					<InfoWindow
						position={{ lat: selected.lat, lng: selected.lng }}
						onCloseClick={() => setSelected({ lat: 0, lng: 0 })}
					>
						<div>
							<p>Towed {props.locationField.slice(5)}</p>
						</div>
					</InfoWindow>
				) : null}
			</GoogleMap>
		</>
	);
}

export default CustomMap;

export function addLocationToInvoiceDetails(
	results: google.maps.GeocoderResult[] | null,
	invoiceDetails: iInvoiceDetails,
	fieldName: string,
	isPinned: boolean,
	specificLocation: string
) {
	var addrComp = results![0].address_components;
	var addressData: Address = parseAddressComponents(addrComp);
	return {
		...invoiceDetails,
		locations: {
			...invoiceDetails.locations,
			[fieldName]: {
				address: { ...addressData },
				formattedAddressFull: results![0].formatted_address,
				locationTypes: results![0].types,
				placeId: results![0].place_id,
				latitude: results![0].geometry.location.lat(),
				longitude: results![0].geometry.location.lng(),
				isSelectedByPin: isPinned,
				specificLocation: specificLocation,
			},
		},
	};
}
