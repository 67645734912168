import React, { useCallback, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import { Address } from "../../types/Location";
import { parseAddressComponents } from "./CompoundMapSearch";
import { GeoAltFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useFlags } from "launchdarkly-react-client-sdk";
import { validateLocation } from "../../utils/Validators/LocationValidator";
import { Vendor } from "../../types/Vendor";
import CustomMapStyle from "../../components/Maps/CustomMapStyle";

const mapContainerStyle = {
	height: "400px",
	width: "700px",
};

const Regina = {
	lat: 50.44,
	lng: -104.61,
};

export function CompoundMap(props: any) {
	const flags = useFlags();
	const options: any = {
		styles: CustomMapStyle,
		disableDefaultUI: true,
		zoomControl: true,
	};
	const compoundLocation = {
		lat: props.vendorDetails.compoundLocation
			? props.vendorDetails.compoundLocation.googleResults?.latitude
			: Regina.lat,
		lng: props.vendorDetails.compoundLocation
			? props.vendorDetails.compoundLocation.googleResults?.longitude
			: Regina.lng,
	};
	const nullPosition = {
		lat: -1,
		lng: -1,
	};
	const center = {
		lat: compoundLocation.lat,
		lng: compoundLocation.lng,
	};
	const validLocation =
		props.vendorDetails.compoundLocation &&
		props.vendorDetails.compoundLocation.googleResults?.latitude !== 0 &&
		props.vendorDetails.compoundLocation &&
		props.vendorDetails.compoundLocation.googleResults?.longitude !== 0 &&
		validateLocation(
			props.vendorDetails.compoundLocation.googleResults,
			false
		) === "";
	const [selected, setSelected] = useState({ lat: 0, lng: 0 });

	const mapRef = useRef();
	const onMapLoad = useCallback((map) => {
		mapRef.current = map;
	}, []);

	function getMarkerPosition() {
		if (
			validateLocation(
				props.vendorDetails.compoundLocation.googleResults,
				false
			) === ""
		) {
			return compoundLocation;
		} else {
			return nullPosition;
		}
	}

	const onClickGeoLocator = useCallback((map) => {
		props.setErrorMessage("");
		var lat = 0;
		var lng = 0;
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				lat = position.coords.latitude;
				lng = position.coords.longitude;
				var latlng = new google.maps.LatLng(lat, lng);

				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({ location: latlng }, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						props.setVendorDetails(
							addCompoundLocationVendorDetails(
								results,
								props.vendorDetails,
								true
							)
						);

						props.setChangedFields({
							...props.changedFields,
							compoundLocation: {
								...props.changedFields.compoundLocation,
								changed:
									props.currentlySavedCompoundLocation &&
									results![0].place_id !== props.currentlySavedCompoundLocation
										? true
										: false,
							},
						});
					} else {
						props.setErrorMessage(
							"Error retrieving location data, please enter a new location"
						);
					}
				});
			});
		} else {
			props.setErrorMessage("Geo Location is not embedded in browser");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setPinLocation = useCallback((map) => {
		props.setErrorMessage("");
		var lat = 0;
		var lng = 0;

		lat = map.latLng.lat();
		lng = map.latLng.lng();
		var latlng = new google.maps.LatLng(lat, lng);

		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: latlng }, (results, status) => {
			if (status === google.maps.GeocoderStatus.OK) {
				props.setPrecisePosition(true);
				props.setVendorDetails(
					addCompoundLocationVendorDetails(results, props.vendorDetails, true)
				);

				props.setChangedFields({
					...props.changedFields,
					compoundLocation: {
						...props.changedFields.compoundLocation,
						changed:
							props.currentlySavedCompoundLocation &&
							results![0].place_id !== props.currentlySavedCompoundLocation
								? true
								: false,
					},
				});
			} else {
				props.setErrorMessage(
					"Error retrieving location data, please enter a new location"
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<GoogleMap
				id="marker-example"
				mapContainerStyle={mapContainerStyle}
				zoom={props.autoZoom}
				center={center.lat !== 0 && center.lng !== 0 ? center : Regina}
				onClick={setPinLocation}
				onLoad={onMapLoad}
				options={options}
			>
				{flags.vendorPaymentUiFindMe && (
					<div className="mapOverElements">
						<Button
							variant="light"
							id="geo-locator"
							onClick={onClickGeoLocator}
						>
							<GeoAltFill size={30} />
							<div className="divFontLocation">
								<div>
									<b>
										<i>Find</i>
									</b>
								</div>
								<div>
									<b>
										<i>Me!</i>
									</b>
								</div>
							</div>
						</Button>
					</div>
				)}
				{validLocation ? (
					<Marker
						key={`${compoundLocation.lat}-${compoundLocation.lng}`}
						position={getMarkerPosition()}
						draggable={true}
						onClick={() => setSelected(compoundLocation)}
						onDragEnd={setPinLocation}
					/>
				) : null}

				{selected.lat !== 0 && selected.lng !== 0 ? (
					<InfoWindow
						position={{ lat: selected.lat, lng: selected.lng }}
						onCloseClick={() => setSelected({ lat: 0, lng: 0 })}
					>
						<div>
							<p>Compound Location</p>
						</div>
					</InfoWindow>
				) : null}
			</GoogleMap>
		</>
	);
}

export default CompoundMap;

export function addCompoundLocationVendorDetails(
	results: google.maps.GeocoderResult[] | null,
	vendorDetails: Vendor,
	isPinned: boolean
) {
	var addrComp = results![0].address_components;
	var addressData: Address = parseAddressComponents(addrComp);
	return {
		...vendorDetails,
		compoundLocation: {
			...vendorDetails.compoundLocation,
			streetAddress: results![0].formatted_address,
			city: addressData.city,
			province: addressData.province,
			country: addressData.country,
			postalCode: addressData.postalCode,
			placeId: results![0].place_id,
			googleResults: {
				address: { ...addressData },
				formattedAddressFull: results![0].formatted_address,
				locationTypes: results![0].types,
				placeId: results![0].place_id,
				latitude: results![0].geometry.location.lat(),
				longitude: results![0].geometry.location.lng(),
				isSelectedByPin: isPinned,
			},
		},
	};
}
