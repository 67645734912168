import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import { ImageTableItem } from "./ImageTableItem";
import { Photo } from "../../types/Photo";
import { PageSpinnerComponent } from "../PageSpinner/PageSpinner";
import { ImageTableItemMobile } from "./ImageTableItemMobile";
import {
	ScreenSize,
	LogToAppInsights,
	useWindowDimensions,
} from "../../utils/Utils";
import { deletePhotoFromInvoice } from "../../api/apiInvoice";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export function ImageUploadTable(props: any) {
	const { screenSize } = useWindowDimensions();

	const removeItem = (value: number, image: Photo) => async () => {
		try {
			let newImageFiles = [...props.imageFiles];
			newImageFiles = newImageFiles.filter((f) => f.name !== image.name);

			let newPhotos = [...props.invoiceDetails.photos];
			newPhotos.splice(value, 1);

			let newPhotosBase64 = [...props.photosBase64];
			newPhotosBase64.splice(value, 1);

			var response = await deletePhotoFromInvoice(image.id);

			if (response) {
				props.setImageFiles(newImageFiles);
				props.setInvoiceDetails({ ...props.invoiceDetails, photos: newPhotos });
				props.setPhotosBase64(newPhotosBase64);
			} else {
				props.setErrorMessages(["Error trying to delete photo"]);
			}
		} catch (e) {
			props.setErrorMessages(["Error trying to delete photo"]);
			LogToAppInsights(
				"Error during deleting Photos for invoice id: " +
					props.invoiceDetails.id,
				SeverityLevel.Error
			);
		}
	};

	return (
		<>
			{!(props.invoiceDetails && props.invoiceDetails.photos.length === 0) ? (
				<Table striped bordered hover className="imageTable">
					<thead>
						{screenSize >= ScreenSize.md ? (
							<tr key="title">
								<th>({props.invoiceDetails.photos.length})</th>
								<th>Filename</th>
								<th>Size</th>
								<th>Type</th>
								<th></th>
							</tr>
						) : (
							<tr key="title">
								<th>({props.invoiceDetails.photos.length})</th>
								<th></th>
								<th></th>
							</tr>
						)}
					</thead>
					<tbody key="tbody">
						{props.invoiceDetails.photos.map((image: Photo, index: number) => {
							return (
								<tr key={image.name + index}>
									<td>
										<div onClick={removeItem(index, image)}>
											<FontAwesomeIcon
												icon={faMinus}
												data-testid={image.name + "removeButton"}
											/>
										</div>
									</td>
									{screenSize >= ScreenSize.md ? (
										<ImageTableItem item={image} />
									) : (
										<ImageTableItemMobile item={image} />
									)}
								</tr>
							);
						})}
						{props.isLoading && (
							<tr key="spinner">
								<td></td>
								<td>
									<PageSpinnerComponent />
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						)}
					</tbody>
				</Table>
			) : (
				props.isLoading &&
				props.invoiceDetails.photos.length === 0 && (
					<Table striped bordered hover>
						<thead>
							<tr key="title">
								<th></th>
								<th>Filename</th>
								<th>Size</th>
								<th>Type</th>
								<th></th>
							</tr>
						</thead>
						<tbody key="tbody">
							<tr key="spinner">
								<td></td>
								<td>
									<PageSpinnerComponent />
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				)
			)}
		</>
	);
}
