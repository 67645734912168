import { Alert, Nav, Navbar } from "react-bootstrap";
import "./Header.css";
import logo from "../../assets/sgi_logo.png";
import { useHistory } from "react-router-dom";
import { Login } from "../Login/Login";
import { HeaderDropDownButton } from "./HeaderDropDownButton";
import { useTypedSelector } from "../../redux/store";
import { isAuthenticated } from "../../utils/ReduxInit";
import { HeaderNavLinks } from "./HeaderNavLinks";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { GetUserPermissions } from "../../utils/auth/authUtilities";

export function Header() {
  const history = useHistory();
  const reduxState = useTypedSelector((state) => state.login);
  const { screenSize } = useWindowDimensions();
  var navCollapseOrder = "";
  const permissions = GetUserPermissions();

  const reduxConnectionStatus = useTypedSelector(
    (state) => state.connectionStatus
  );

  function routeToHomePage() {
    if (permissions?.canViewDashboard) history.push("/Invoices/Dashboard");
    else if (permissions?.canViewAllInvoices)
      history.push("/Employee/Invoices/List");
  }

  if (!isAuthenticated(reduxState) || screenSize >= ScreenSize.md)
    navCollapseOrder = "order-2";
  else navCollapseOrder = "order-4";

  return (
    <>
      {reduxConnectionStatus.connected === false && (
        <div className="sticky-top">
          <div data-testid="header-banner">
            <Alert className="StatusAlert mb-0 ml-0 mt-0" variant="danger">
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              <span>No Internet Connection</span>
            </Alert>
          </div>
          <Navbar
            collapseOnSelect
            expand={isAuthenticated(reduxState) === false ? true : "md"}
            bg="light"
          >
            <Navbar.Brand className="order-1">
              <img
                data-testid="header-logo"
                src={logo}
                alt="SGI logo"
                onClick={(e) => {
                  routeToHomePage();
                }}
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="ml-auto order-2"
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className={navCollapseOrder}
            >
              <Nav className="container-fluid">
                <HeaderNavLinks />
              </Nav>
            </Navbar.Collapse>
            <Nav.Item className="order-3">
              {!isAuthenticated(reduxState) ? (
                <Login />
              ) : (
                <HeaderDropDownButton />
              )}
            </Nav.Item>
          </Navbar>
        </div>
      )}

      {reduxConnectionStatus.connected === true && (
        <Navbar
          collapseOnSelect
          expand={isAuthenticated(reduxState) === false ? true : "md"}
          bg="light"
          sticky="top"
        >
          <Navbar.Brand className="order-1">
            <img
              data-testid="header-logo"
              src={logo}
              alt="SGI logo"
              onClick={(e) => {
                routeToHomePage();
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ml-auto order-2"
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={navCollapseOrder}
          >
            <Nav className="container-fluid">
              <HeaderNavLinks />
            </Nav>
          </Navbar.Collapse>
          <Nav.Item className="order-3">
            {!isAuthenticated(reduxState) ? (
              <Login />
            ) : (
              <HeaderDropDownButton />
            )}
          </Nav.Item>
        </Navbar>
      )}
    </>
  );
}
