import React, { useEffect, useMemo, useState } from "react";
import { ValidatorFunction } from "../../utils/Validators/ValidatorFunction";
import { Col, Form, Row } from "react-bootstrap";
import { Error } from "../Alerts/Error";
import { validateRadioButton } from "../../utils/Validators/RadioValidator";
import "./VehicleStatus.css";

export function VehicleStatus(props: any) {
  const [driveableMessage, setDriveableMessage] = useState("");
  const [keysMessage, setKeysMessage] = useState("");

  const ValidatorFunctions: ValidatorFunction[] = useMemo(
    () => [
      {
        fieldname: "driveable",
        validator: validateRadioButton,
        stateSetter: setDriveableMessage,
      },
      {
        fieldname: "keys",
        validator: validateRadioButton,
        stateSetter: setKeysMessage,
      },
    ],
    []
  );

  function handleOnChange(event: any) {
    const { name } = event.target;
    var valueToStore: string = "U";
    var nameToStore: string = "";

    switch (name) {
      case "driveable-yes":
        valueToStore = "Y";
        nameToStore = "driveable";
        setDriveableMessage("");
        break;
      case "driveable-no":
        valueToStore = "N";
        nameToStore = "driveable";
        setDriveableMessage("");
        break;
      case "keys-yes":
        valueToStore = "Y";
        nameToStore = "keys";
        setKeysMessage("");
        break;
      case "keys-no":
        valueToStore = "N";
        nameToStore = "keys";
        setKeysMessage("");
        break;
    }

    props.setInvoiceDetails({
      ...props.invoiceDetails,
      [nameToStore]: valueToStore,
    });
  }

  const { isValid } = props;

  useEffect(() => {
    let allFieldsValid = true;
    ValidatorFunctions.forEach((field) => {
      if (field.validator(props.invoiceDetails[field.fieldname]) !== "") {
        allFieldsValid = false;
      }
    });
    isValid(allFieldsValid);
  }, [ValidatorFunctions, isValid, props.invoiceDetails]);

  useEffect(() => {
    if (props.showErrors)
      ValidatorFunctions.forEach((f) => {
        // need a special case here for draft
        if (props.isDraft) {
          if (props.invoiceDetails[f.fieldname] === "U") {
            f.stateSetter("");
          } else {
            let fieldErrorMessage = f.validator(
              props.invoiceDetails[f.fieldname]
            );
            f.stateSetter(fieldErrorMessage);
          }
        } else {
          let fieldErrorMessage = f.validator(
            props.invoiceDetails[f.fieldname]
          );
          f.stateSetter(fieldErrorMessage);
        }
      });
  }, [
    props.showErrors,
    ValidatorFunctions,
    props.invoiceDetails,
    props.isDraft,
  ]);

  return (
    <>
      <Row>
        <Col>
          <div className="radioWrapper formLabelText">
            <Row>Driveable?</Row>
            <Row>
              <Form.Check
                type="radio"
                placeholder=""
                data-testid="formEntryDriveable-yes"
                onChange={handleOnChange}
                name="driveable-yes"
                label="Yes"
                checked={props.invoiceDetails.driveable === "Y"}
              />
              &nbsp;&nbsp;&nbsp;
              <Form.Check
                type="radio"
                placeholder=""
                data-testid="formEntryDriveable-no"
                onChange={handleOnChange}
                name="driveable-no"
                label="No"
                checked={props.invoiceDetails.driveable === "N"}
              />
            </Row>
          </div>
          {driveableMessage !== "" && <Error message={driveableMessage} />}
        </Col>
        <Col>
          <div className="radioWrapper formLabelText">
            <Row>Have keys?</Row>

            <Row>
              <Form.Check
                type="radio"
                placeholder=""
                data-testid="formEntryHaveKeys-yes"
                onChange={handleOnChange}
                name="keys-yes"
                label="Yes"
                checked={props.invoiceDetails.keys === "Y"}
              />
              &nbsp;&nbsp;&nbsp;
              <Form.Check
                type="radio"
                placeholder=""
                data-testid="formEntryHaveKeys-no"
                onChange={handleOnChange}
                name="keys-no"
                label="No"
                checked={props.invoiceDetails.keys === "N"}
              />
            </Row>
          </div>
          {keysMessage !== "" && <Error message={keysMessage} />}
        </Col>
      </Row>
    </>
  );
}
