import {
	emptyAnnotations,
	initialAssignedToUser,
	ReasonForDeletion,
	ReasonForNoVIN,
	ReasonForTow,
} from "../types/Invoice";
import { iInvoiceVersions } from "../types/InvoiceVersions";

export function InvoiceVersionsTransformer(
	apiInvoiceVersions: any
): iInvoiceVersions[] {
	var returnData: iInvoiceVersions[] = [];

	// map api data received
	apiInvoiceVersions.forEach((item: any) => {
		var locations = JSON.parse(item.locations);
		var towedFrom = getLocationAddress("towedFrom", locations);
		var towedTo = getLocationAddress("towedTo", locations);

		var assignedToSGIEmployee = initialAssignedToUser;

		if (item.assignedToSGIEmployee != null) {
			try {
				assignedToSGIEmployee = JSON.parse(item.assignedToSGIEmployee);
			} catch {}
		}

		function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
			myEnum: T,
			enumValue: string
		): keyof T {
			let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
			return keys[0];
		}

		var annotations = item.annotations
			? JSON.parse(item.annotations)
			: emptyAnnotations;

		var newAnnotations = getAnnotations(annotations);
		var newReturnData: any = {
			id: item.invoiceId,
			versionTimestamp: item.versionDateTime,
			vendorId: item.vendorId,
			plate: item.vehiclePlate,
			reasonForNoVehiclePlate: item.reasonForNoVehiclePlate,
			vehicleYear: item.vehicleYear,
			vehicleMake: item.vehicleMake,
			vehicleModel: item.vehicleModel,
			vehicleVIN: item.vehicleVIN,
			vehicleColour: item.vehicleColour,
			driveable: item.driveable,
			keys: item.keys,
			towerReference: item.towerReference,

			serviceDate: item.serviceDate,

			towerName: item.towerName,
			claimNumber: item.claimNumber,
			vendorPlate: item.vendorPlate,
			requestedPayment: item.requestedPayment,

			policeFile: item.policeFile,
			policeDept: item.policeDept,
			towedFromAccident: item.towedFromAccident,
			statusDisplay: item.statusDisplay,
			reasonForTow:
				ReasonForTow[getEnumKeyByEnumValue(ReasonForTow, item.reasonForTow)],
			towedFrom: towedFrom,
			towedTo: towedTo,
			photoGuid: item.photoGuid,
			reasonForNoVIN:
				ReasonForNoVIN[
					getEnumKeyByEnumValue(ReasonForNoVIN, item.reasonForNoVIN)
				],
			noVINReasonDetails: item.noVINReasonDetails || "",
			vinScanned: item.vinScanned || false,
			assignedToSGIEmployeeName: assignedToSGIEmployee.fullName,
			assignedToSGIEmployeeEmail: assignedToSGIEmployee.email,
			submittedDate: item.submittedDate,
			paidDate: item.paidDate,
			lastUpdatedBy: item.lastUpdatedBy,
			payeeNumber: item.payeeNumber,
			payeeName: item.payeeName,
			invoiceAnnotations: newAnnotations?.toString(),
			removedFromInventory: item.removedFromInventory,
			subsequentTow: item.subsequentTow || false,
			weightClass: item.weightClass,
			reasonForDeletion:
				ReasonForDeletion[
					getEnumKeyByEnumValue(ReasonForDeletion, item.reasonForDeletion)
				],
			deletionReasonDetails: item.deletionReasonDetails,
			deletedDate: item.data?.deletedDate,
		};

		// for each line item, generate a separate column
		var lineItems: any[] = JSON.parse(item.lineItems);
		lineItems.forEach((line) => {
			newReturnData[`${line.name}:Description`] = getLineItem(line);
			newReturnData[`${line.name}:Quantity`] = line.qty;
			newReturnData[`${line.name}:LineTotal`] = line.lineTotal;
		});

		returnData.push(newReturnData);
	});
	return returnData;
}

function getLocationAddress(l: string, locs: any) {
	var address: string =
		locs[l].address?.streetNumber +
		" " +
		locs[l].address?.street +
		" " +
		locs[l].address?.city +
		" " +
		locs[l].address?.province +
		" " +
		locs[l].address?.country +
		" " +
		locs[l].address?.postalCode;
	return address;
}

function getLineItem(lineItem: any): string | undefined {
	var newItem: string =
		"[title: " +
		lineItem.title +
		" isStandard: " +
		lineItem.isStandard +
		" lineTotal: " +
		lineItem.lineTotal +
		" allowMultiple: " +
		lineItem.allowMultiple +
		"]";

	return newItem;
}

function getAnnotations(annotations: any): string[] {
	var returnAnnotations: string[] = [];
	for (var j in annotations) {
		var newItem: string =
			"[name: " +
			annotations[j].name +
			" id: " +
			annotations[j].id +
			" title: " +
			annotations[j].title +
			" annotationText: " +
			annotations[j].annotationText +
			" annotatedValue: " +
			annotations[j].annotatedValue +
			" rejectedComment: " +
			annotations[j].rejectedComment +
			"]";
		returnAnnotations.push(newItem);
	}
	return returnAnnotations;
}
