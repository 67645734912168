import { Route, Redirect } from "react-router-dom";
import NotAuthorized from "../components/Common/NotAuthorized";
import { HomePage } from "../containers/HomePage/HomePage";

function PublicRoutes() {
  var returnRoutes: any[] = [];

  returnRoutes.push(
    <Route exact path="/" key="root">
      <HomePage />
    </Route>
  );
  returnRoutes.push(
    <Route
      exact
      path="/PageNotAuthorized"
      component={NotAuthorized}
      key="notAuthorized"
    />
  );

  /* This is the default catch-all route and must be last in list.
  DO NOT place your route below this, or it will not be recognized.*/

  returnRoutes.push(
    <Route key="default">
      <Redirect to="/" />
    </Route>
  );

  return returnRoutes;
}

export default PublicRoutes;
