import React, { useEffect, useState } from "react";
import { House, Map } from "react-bootstrap-icons";
import {
	Col,
	Form,
	Row,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { Error } from "../Alerts/Error";
import "./AccidentInformation.css";
import { ModalMap } from "../Modals/ModalMap";
import Search from "../Maps/mapSearch";
import { useLoadScript } from "@react-google-maps/api";
import { PageSpinner } from "../PageSpinner/PageSpinner";
import {
	validateLocation,
	validateLocationResult,
} from "../../utils/Validators/LocationValidator";
import {
	distanceInKmBetweenEarthCoordinates,
	useWindowDimensions,
} from "../../utils/Utils";
import { useTypedSelector } from "../../redux/store";
import { addLocationToInvoiceDetails } from "../Maps/Maps";
import { getMapsZoomLevel } from "../../utils/MapUtils";
import { SGI_PICK_UP } from "../../types/Invoice";

export function Locations(props: any) {
	const [towedFromMessage, setTowedFromMessage] = useState("");
	const [towedToMessage, setTowedToMessage] = useState("");
	const [libraries]: any = useState(["places"]);

	const { isLoaded, loadError } = useLoadScript({
		libraries,
		googleMapsApiKey: (window as any)["apiConfig"]?.googleMapsApiData,
	});

	const [modalShow, setModalShow] = useState(false);
	const [modalHeading, setModalHeading] = useState("");
	const [locationField, setLocationField] = useState("");
	const [preciseTowedFromPosition, setPreciseTowedFromPosition] =
		useState(false);
	const [preciseTowedToPosition, setPreciseTowedToPosition] = useState(false);
	const [areaBoundsFromLocation, setAreaBoundsFromLocation] = useState({
		northEast: { lat: 0, lng: 0 },
		southWest: { lat: 0, lng: 0 },
	});
	const [areaBoundsToLocation, setAreaBoundsToLocation] = useState({
		northEast: { lat: 0, lng: 0 },
		southWest: { lat: 0, lng: 0 },
	});
	const { width } = useWindowDimensions();
	const reduxState = useTypedSelector((state) => state.login);
	const [specificFromLocation, setSpecificFromLocation] = useState(
		props.invoiceDetails.locations["towedFrom"].specificLocation
	);
	const [specificToLocation, setSpecificToLocation] = useState(
		props.invoiceDetails.locations["towedTo"].specificLocation
	);
	const { isValid } = props;

	useEffect(() => {
		let allFieldsValid = true;
		let isSgiPickupReason = props.invoiceDetails.reasonForTow === SGI_PICK_UP;
		if (
			validateLocation(props.invoiceDetails.locations.towedFrom, false) !==
				"" ||
			validateLocation(
				props.invoiceDetails.locations.towedTo,
				isSgiPickupReason
			) !== ""
		)
			allFieldsValid = false;
		isValid(allFieldsValid);
	}, [isValid, props.invoiceDetails]);

	useEffect(() => {
		if (props.showErrors) {
			let isSgiPickupReason = props.invoiceDetails.reasonForTow === SGI_PICK_UP;
			if (
				props.isDraft &&
				props.invoiceDetails.locations.towedFrom.formattedAddressFull === ""
			)
				setTowedFromMessage("");
			else {
				setTowedFromMessage(
					validateLocation(props.invoiceDetails.locations.towedFrom, false)
				);
			}
			if (
				props.isDraft &&
				props.invoiceDetails.locations.towedTo.formattedAddressFull === ""
			)
				setTowedToMessage("");
			else {
				setTowedToMessage(
					validateLocation(
						props.invoiceDetails.locations.towedTo,
						isSgiPickupReason
					)
				);
			}
		}
	}, [
		props.showErrors,
		props.invoiceDetails.locations,
		props.invoiceDetails.reasonForTow,
		props.isDraft,
	]);

	useEffect(() => {
		if (
			props.invoiceDetails.reasonForTow === SGI_PICK_UP &&
			props.invoiceDetails.subsequentTow &&
			validateLocation(props.invoiceDetails.locations.towedFrom, false) !== ""
		) {
			copyCompound("towedFrom", setTowedFromMessage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.invoiceDetails.reasonForTow,
		props.invoiceDetails.subsequentTow,
		props.invoiceDetails.locations,
	]);

	function getMarkerPosition(name: string): any {
		if (name === "towedFrom") {
			return preciseTowedFromPosition;
		}
		return preciseTowedToPosition;
	}

	function setMarkerPosition(name: string): any {
		if (name === "towedFrom") {
			return setPreciseTowedFromPosition;
		}
		return setPreciseTowedToPosition;
	}

	function copyCompound(fieldName: string, propsName: any) {
		var geocoder = new google.maps.Geocoder();
		var specificLocation = "";
		if (fieldName === "towedFrom") {
			specificLocation = specificFromLocation;
		} else {
			specificLocation = specificToLocation;
		}
		if (reduxState.compoundLocation) {
			geocoder.geocode(
				{ placeId: reduxState.compoundLocation.placeId },
				(results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						props.setInvoiceDetails(
							addLocationToInvoiceDetails(
								results,
								props.invoiceDetails,
								fieldName,
								reduxState.compoundLocation.googleResults.isSelectedByPin,
								specificLocation
							)
						);
						if (
							reduxState.compoundLocation.googleResults.isSelectedByPin ===
							false
						) {
							propsName(validateLocationResult(results));
						}
					}
				}
			);
		}
	}

	function addSpecificLocation(fieldName: string, event: any) {
		const { value } = event.target;
		if (fieldName === "towedFrom") {
			setSpecificFromLocation(value);
		} else {
			setSpecificToLocation(value);
		}
		props.setInvoiceDetails({
			...props.invoiceDetails,
			locations: {
				...props.invoiceDetails.locations,
				[fieldName]: {
					address: props.invoiceDetails.locations[fieldName].address,
					formattedAddressFull:
						props.invoiceDetails.locations[fieldName].formattedAddressFull,
					locationTypes:
						props.invoiceDetails.locations[fieldName].locationTypes,
					placeId: props.invoiceDetails.locations[fieldName].placeId,
					latitude: props.invoiceDetails.locations[fieldName].latitude,
					longitude: props.invoiceDetails.locations[fieldName].longitude,
					isSelectedByPin:
						props.invoiceDetails.locations[fieldName].isSelectedByPin,
					specificLocation: value,
				},
			},
		});
	}

	function customZoomLevel(name: string): number {
		let towedArea = null;
		if (name === "towedFrom") {
			towedArea = areaBoundsFromLocation;
		} else {
			towedArea = areaBoundsToLocation;
		}
		let distance = distanceInKmBetweenEarthCoordinates(
			towedArea.northEast.lat,
			towedArea.northEast.lng,
			towedArea.southWest.lat,
			towedArea.southWest.lng
		);
		return getMapsZoomLevel(distance);
	}

	function getErrorSetter(name: string): any {
		if (name === "towedFrom") {
			return setTowedFromMessage;
		} else if (name === "towedTo") {
			return setTowedToMessage;
		}
	}
	if (loadError) {
		return <>Error Loading Google Maps</>;
	} else if (!isLoaded) return <PageSpinner />;
	else
		return (
			<>
				<ModalMap
					title={"Select a location"}
					locationField={locationField}
					heading={"Select " + modalHeading + " Location"}
					body={"body"}
					show={modalShow}
					onHide={() => setModalShow(false)}
					invoiceDetails={props.invoiceDetails}
					setInvoiceDetails={props.setInvoiceDetails}
					setErrorMessage={getErrorSetter(locationField)}
					autoZoom={customZoomLevel(locationField)}
					precisePosition={getMarkerPosition(locationField)}
					setPrecisePosition={setMarkerPosition(locationField)}
				/>
				<Row className="SpaceLine">&nbsp;</Row>
				<Row>
					<Col>
						<Form.Group controlId="formEntryTowedFrom">
							<Form.Label className="formLabelText">
								Towed From Location{" "}
								<OverlayTrigger
									overlay={<Tooltip id="mapTooltip">Open Map</Tooltip>}
									transition={false}
								>
									{({ ref, ...triggerHandler }) => (
										<Button
											{...triggerHandler}
											ref={ref}
											variant="outline-primary"
											onClick={() => {
												setModalShow(true);
												setModalHeading("Towed From");
												setLocationField("towedFrom");
											}}
											data-testid="addTowedFromLocationButton"
										>
											<Map size={20 + Math.min(width / 100, 10)} />
										</Button>
									)}
								</OverlayTrigger>{" "}
								<OverlayTrigger
									overlay={<Tooltip id="compoundTooltip">My Compound</Tooltip>}
									transition={false}
								>
									{({ ref, ...triggerHandler }) => (
										<Button
											{...triggerHandler}
											ref={ref}
											variant="outline-primary"
											onClick={() => {
												copyCompound("towedFrom", setTowedFromMessage);
											}}
											data-testid="addCompoundTowedToLocationButton"
										>
											<House size={20 + Math.min(width / 100, 10)} />
										</Button>
									)}
								</OverlayTrigger>
							</Form.Label>

							<Search
								name="towedFrom"
								invoiceDetails={props.invoiceDetails}
								setInvoiceDetails={props.setInvoiceDetails}
								errorMessage={towedFromMessage}
								setErrorMessage={setTowedFromMessage}
								setAreaBounds={setAreaBoundsFromLocation}
								setPrecisePosition={setPreciseTowedFromPosition}
							/>
							{towedFromMessage !== "" && (
								<Error
									data-testid="formEntryTowedFromLocationError"
									message={towedFromMessage}
								/>
							)}
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="formEntrySpecificFromLocation">
							<Form.Label className="formLabelText">Stall / Lot</Form.Label>
							<Form.Control
								className="formEntrySpecificFromLocation"
								type="text"
								placeholder="Optional"
								data-testid="formEntrySpecificFromLocation"
								onChange={(e) => {
									addSpecificLocation("towedFrom", e);
								}}
								name="specificFromLocation"
								maxLength={10}
								value={
									props.invoiceDetails.locations["towedFrom"].specificLocation
								}
							/>
							<Form.Text id="specificLocationHelpBlock" muted>
								Maximum of 10 characters
							</Form.Text>
						</Form.Group>
					</Col>
				</Row>
				<Row className="SpaceLine">&nbsp;</Row>
				<Row>
					<Col>
						<Form.Group controlId="formEntryTowedTo">
							<Form.Label className="formLabelText">
								Towed To Location{" "}
								<OverlayTrigger
									overlay={<Tooltip id="mapTooltip">Open Map</Tooltip>}
									transition={false}
								>
									{({ ref, ...triggerHandler }) => (
										<Button
											{...triggerHandler}
											ref={ref}
											variant="outline-primary"
											onClick={() => {
												setModalShow(true);
												setModalHeading("Towed To");
												setLocationField("towedTo");
											}}
											data-testid="addTowedToLocationButton"
										>
											<Map size={20 + Math.min(width / 100, 10)} />
										</Button>
									)}
								</OverlayTrigger>{" "}
								<OverlayTrigger
									overlay={<Tooltip id="compoundTooltip">My Compound</Tooltip>}
									transition={false}
								>
									{({ ref, ...triggerHandler }) => (
										<Button
											{...triggerHandler}
											ref={ref}
											variant="outline-primary"
											onClick={() => {
												copyCompound("towedTo", setTowedToMessage);
											}}
											data-testid="addCompoundTowedToLocationButton"
										>
											<House size={20 + Math.min(width / 100, 10)} />
										</Button>
									)}
								</OverlayTrigger>
							</Form.Label>

							<Search
								name="towedTo"
								invoiceDetails={props.invoiceDetails}
								setInvoiceDetails={props.setInvoiceDetails}
								errorMessage={towedToMessage}
								setErrorMessage={setTowedToMessage}
								setAreaBounds={setAreaBoundsToLocation}
								setPrecisePosition={setPreciseTowedToPosition}
							/>
							{towedToMessage !== "" && <Error message={towedToMessage} />}
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="formEntrySpecificToLocation">
							<Form.Label className="formLabelText">Stall / Lot</Form.Label>
							<Form.Control
								className="formEntrySpecificToLocation"
								type="text"
								placeholder="Optional"
								data-testid="formEntrySpecificToLocation"
								onChange={(e) => {
									addSpecificLocation("towedTo", e);
								}}
								name="specificToLocation"
								maxLength={10}
								value={
									props.invoiceDetails.locations["towedTo"].specificLocation
								}
							/>
							<Form.Text id="specificLocationHelpBlock" muted>
								Maximum of 10 characters
							</Form.Text>
						</Form.Group>
					</Col>
				</Row>
				<Row className="SpaceLine">&nbsp;</Row>
			</>
		);
}
