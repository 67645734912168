import { useLocalAPIAndAuth } from "../localFlag";

export function getBaseApiUrl(): string {
	if (useLocalAPIAndAuth) return "https://localhost:5001/v1/claimsinvoice/";
	else {
		const { REACT_APP_API_BASEURL }: { REACT_APP_API_BASEURL: string } = (
			window as any
		)["runConfig"];

		return REACT_APP_API_BASEURL;
	}
}
