import { useEffect, useState } from "react";
import { getAllBulletin } from "../../api/apiBulletin";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { Container } from "react-bootstrap";
import { Bulletin } from "../../types/Bulletin";
import { downloadBlob } from "./BulletinUtils";
import { downloadBulletinFile } from "../../api/apiBulletin";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";

import "./PublicBulletinList.css";

const PublicBulletinList = () => {
  const [bulletins, setBulletins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    const loadBulletins = async () => {
      const response = await getAllBulletin();
      if (response !== undefined) {

        let bulletins = response.data;
        bulletins.sort((a:Bulletin, b:Bulletin) => (a.createdOn < b.createdOn) ? 1 : -1);
        setBulletins(bulletins);
        setIsLoading(false);
      } else {
        setErrorMessages(["Failed to retrieve bulletins."]);
      }
    };

    loadBulletins();
  }, []);

  const handleDownload = async (e: any, bulletin: Bulletin) => {
    e.preventDefault();

    const response = await downloadBulletinFile(bulletin.id);

    if(response === undefined)
    {
      setErrorMessages(["Failed to download bulletin."]);
    }
    else {
      downloadBlob(response.data, `${bulletin.id}${bulletin.extension}`);
    }
  }

  return (
    <Container className="public-bulletin-container">
      <div className="Title">
        <h3>Bulletins</h3>
      </div>
      <ToastErrors
        title="Bulletin Info"
        setMessages={setErrorMessages}
        messages={errorMessages}
      />
      {isLoading ? (
					<PageSpinner />
				) : (
        <ul>
          {bulletins.map((x: any) => {
            return <li><a style={{cursor: "pointer"}} onClick={(e) => handleDownload(e, x)} href={x.id}>{x.title}</a></li>;
          })}
        </ul>
      )}
    </Container>
  );
};

export default PublicBulletinList;
