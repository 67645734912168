import {
	faExclamationTriangle,
	faImages,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { ToastErrors } from "../Toast/ToastErrors";
import "./ImageUpload.css";
import { ImageUploadTable } from "./ImageUploadTable";
import { Photo } from "../../types/Photo";
import { formatToBase64, resizeFile } from "../../utils/PhotoTransforms";
import { uploadPhoto } from "../../api/apiPhotos";
import { Error } from "../Alerts/Error";
import { useTypedSelector } from "../../redux/store";
import { appInsights } from "../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { GetUserPermissions } from "../../utils/auth/authUtilities";
import { SGI_PICK_UP } from "../../types/Invoice";

export function ImageUpload(props: any) {
	const emptyStringArray: string[] = [];
	const [errorMessages, setErrorMessages] = useState(emptyStringArray);
	const [mandatoryMessage, setMandatoryMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const emptyFiles: File[] = [];
	const [imageFiles, setImageFiles] = useState(emptyFiles);
	const [showUploadPhotoMessage, setShowUploadPhotoMessage] = useState(false);
	const [uploadPhotoMessage, setUploadPhotoMessage] = useState("");

	const reduxConnectionStatus = useTypedSelector(
		(state) => state.connectionStatus
	);

	const permissions = GetUserPermissions();

	const login = useTypedSelector((state) => state.login);

	useEffect(() => {
		if (isLoading === true) {
			props.setSubmitSaveUploadButtonDisable(true);
		} else {
			props.setSubmitSaveUploadButtonDisable(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (!reduxConnectionStatus.connected) {
			setUploadPhotoMessage("Internet Connection required");
			setShowUploadPhotoMessage(false);
		}
		if (reduxConnectionStatus.connected) {
			setUploadPhotoMessage("");
			setShowUploadPhotoMessage(false);
		}
	}, [reduxConnectionStatus.connected]);

	function imageValidations(images: File[], errors: string[]) {
		let currentLength = images.length;
		images = images.filter(
			(i) =>
				i.type === "image/png" ||
				i.type === "image/jpeg" ||
				i.type === "image/gif"
		);
		if (images.length < currentLength) {
			errors.push(
				"Invalid photo format. Accepted formats are JPEG, PNG, and GIF."
			);
		}

		currentLength = images.length;
		images = images.filter((i) => i.size < 10485760);
		if (images.length < currentLength) {
			errors.push("Photo is too large. Photo cannot be larger than 10 MB. ");
		}

		currentLength = images.length;
		images = images.filter(
			(i) =>
				!props.invoiceDetails.photos.find(
					(p: Photo) => p.name === i.name && p.size === i.size
				)
		);
		if (images.length < currentLength) {
			errors.push("Duplicate photo. Photo not added. ");
		}

		if (props.invoiceDetails.photos.length + images.length > 20)
			errors.push("Maximum of 20 photos allowed. Please try again.");
		while (props.invoiceDetails.photos.length + images.length > 20)
			images.pop();

		let totalSize = props.invoiceDetails.photos.reduce(
			(sum: number, b: Photo) => sum + b.size,
			0
		);

		if (images.reduce((sum, b) => sum + b.size, 0) + totalSize > 104857600)
			errors.push("Maximum total upload of 100MB allowed. Please try again.");
		while (images.reduce((sum, b) => sum + b.size, 0) + totalSize > 104857600)
			images.pop();

		if (appInsights !== undefined) {
			appInsights.trackTrace({
				message:
					"Photo Upload for " +
					images.length.toString() +
					" photos requested by " +
					login.email,
				severityLevel: SeverityLevel.Information,
			});
		}
		setErrorMessages([...errorMessages, ...errors]);

		return images;
	}

	async function handleAddImages(event: any) {
		let images: File[] = Array.from(event.target.files);
		let errors: string[] = [];

		setIsLoading(true);

		images = imageValidations(images, errors);

		setImageFiles([...imageFiles, ...images]);

		const promiseArray = [] as Promise<Photo[]>[];

		images.forEach(async (f: File, index: number) => {
			var imagePromise = new Promise<Photo[]>(async (resolve, reject) => {
				let storageGuid: any = await uploadPhoto(f);
				let thumbnail = await resizeFile(f);
				let thumbnailBase64 = await formatToBase64(f);

				if (storageGuid && thumbnail && thumbnailBase64) {
					const photoThumbnail: Photo = {
						id: storageGuid.data,
						name: f.name,
						size: f.size,
						type: f.type,
						thumbnail: thumbnail,
					};

					const photoBase64: Photo = {
						id: storageGuid.data,
						name: f.name,
						size: f.size,
						type: f.type,
						thumbnail: thumbnailBase64,
					};

					const newPhoto: Photo[] = [photoThumbnail, photoBase64];

					resolve(newPhoto);
				} else {
					reject("Error uploading file");
					errors.push("Error uploading photo " + f.name);
					setErrorMessages([...errorMessages, ...errors]);
				}
			});
			promiseArray.push(imagePromise);
		});

		Promise.allSettled(promiseArray).then((values) => {
			let newPhotos: any = props.invoiceDetails.photos;
			let newPhotosBase64: any = props.photosBase64;
			values.forEach((result) => {
				if (result.status !== "rejected") {
					newPhotos.push(result.value[0]);
					newPhotosBase64.push(result.value[1]);
				}
			});
			props.setInvoiceDetails({
				...props.invoiceDetails,
				photos: newPhotos,
			});
			props.setPhotosBase64(newPhotosBase64);
			setIsLoading(false);
		});

		event.target.value = "";
	}

	let upload = React.useRef<HTMLInputElement>(null);

	function handleUploadClick(event: any) {
		if (!reduxConnectionStatus.connected) {
			setShowUploadPhotoMessage(true);
			return;
		}

		if (upload && upload.current) {
			upload.current.click();
		}
	}

	useEffect(() => {
		let isNotValid =
			props.invoiceDetails.photos.length < 1 &&
			props.invoiceDetails.reasonForTow !== SGI_PICK_UP;
		props.isValid(!isNotValid);

		if (props.showErrors && isNotValid) {
			var message = props.invoiceDetails.subsequentTow
				? "A photo of the subsequent tow is mandatory"
				: "Photos of the VIN, Plate, and Scene(s) are mandatory";
			setMandatoryMessage(message);
		} else setMandatoryMessage("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.showErrors,
		props.invoiceDetails,
		props.invoiceDetails.subsequentTow,
	]);

	return (
		<>
			<Container className="imageUploadContainer">
				<ToastErrors
					title="Photo Upload"
					setMessages={setErrorMessages}
					messages={errorMessages}
				/>
				<h5>Photos:</h5>
				{showUploadPhotoMessage && (
					<Col className="maxWidth600">
						<Alert className="StatusAlert mb-3 ml-0 mt-0" variant="danger">
							<FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
							<span>{uploadPhotoMessage}</span>
						</Alert>
					</Col>
				)}
				<Col>
					<Form.Group>
						{permissions?.canSaveInvoice && (
							<div className="button">
								<label htmlFor="single">
									<OverlayTrigger
										overlay={
											<Tooltip id="tooltip-photo">
												{props.invoiceDetails.subsequentTow
													? "A photo of the subsequent tow is mandatory"
													: "Photos of the VIN, Plate, and Scene(s) are mandatory"}
											</Tooltip>
										}
										transition={false}
									>
										{({ ref, ...triggerHandler }) => (
											<Button
												{...triggerHandler}
												ref={ref}
												variant="outline-primary"
												onClick={handleUploadClick}
												data-testid="uploadPhotosButton"
												disabled={props.submitSaveUploadButtonDisable}
											>
												Upload Photos&nbsp;&nbsp;
												<FontAwesomeIcon icon={faImages} size="2x" />
											</Button>
										)}
									</OverlayTrigger>
									<input
										type="file"
										name="formFile"
										id="single"
										data-testid="uploadPhotosInputField"
										ref={upload}
										onChange={handleAddImages}
										multiple
									/>
								</label>
							</div>
						)}
					</Form.Group>
				</Col>
				<ImageUploadTable
					invoiceDetails={props.invoiceDetails}
					setInvoiceDetails={props.setInvoiceDetails}
					imageFiles={imageFiles}
					setImageFiles={setImageFiles}
					photosBase64={props.photosBase64}
					setPhotosBase64={props.setPhotosBase64}
					isLoading={isLoading}
					setErrorMessages={setErrorMessages}
				/>
				{props.showErrors && mandatoryMessage !== "" && (
					<Error
						data-testid="formPhotoInvalidError"
						message={mandatoryMessage}
					/>
				)}
			</Container>
		</>
	);
}
