import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./Modal.css";

interface ModalProps {
  show: any;
  onHide: any;
  title: string;
  body: string;
  buttonText: string;
  onButtonClick: any;
}

export function ModalConfirmation(props: ModalProps) {
  return (
    <Modal
      className="ModalConfirmation"
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="confirmedModal" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirmedModal">
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer className="confirmedModal">
        <Button
          variant="outline-primary"
          onClick={(e) => {
            if (props.onHide) {
              props.onHide(e);
            }
            if (props.onButtonClick) {
              props.onButtonClick();
            }
          }}
          data-testid="addSpecialLineItemButton"
        >
          {props.buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
