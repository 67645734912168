import React from "react";
import { Spinner } from "react-bootstrap";
import "./PageSpinner.css";

export function PageSpinner() {
  return (
    <Spinner animation="border" role="status" className="loader">
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}

export function PageSpinnerComponent() {
  return (
    <Spinner animation="border" role="status" className="loaderComponent">
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}
