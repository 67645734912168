export interface iVendorList {
  vendorId: string;
  payeeNumber: string;
  companyName: string;
  address: string;
  phoneNumber: string;
  email: string;
}

export interface iVendorListSortSettings {
  vendorListSortColumn: string;
  vendorListSortOrder: string;
}

export const defaultVendorList: iVendorList[] = [];
