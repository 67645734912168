import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getRates() {
  const baseUrl = getBaseApiUrl() + "Rates/All";
  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}/`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getRatesWithinEffectiveDateApi(date: string) {
  const baseUrl = getBaseApiUrl() + `Rates/RatesWithinEffectiveDate/${date}`;
  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}/`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}