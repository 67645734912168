import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Error } from "../../components/Alerts/Error";
import "./EmployeeInvoiceSummary.css";
import {
	assignInvoice,
	getInvoiceByInvoiceId,
	patchInvoiceWithAnnotations,
	updateStatus,
} from "../../api/apiInvoice";
import {
	Annotation,
	AnnotationStatus,
	defaultNewInvoice,
	emptyAnnotations,
	iInvoice,
	initialAssignedToUser,
	iInvoiceIdentifier
} from "../../types/Invoice";
import { EmployeeInvoiceSummaryDetails } from "../../components/EmployeeInvoiceSummary/EmployeeInvoiceSummaryDetails";
import { invoiceDataTransformer, invoiceIdentifierDataTransformer } from "../../utils/InvoiceDataTransformer";
import { useTypedSelector } from "../../redux/store";
import { ToastInfo } from "../../components/Toast/ToastInfo";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { AssignedToUser } from "../../types/AssignedToUser";
import { ModalConfirmation } from "../../components/Modals/ModalConfirmation";
import { convertDateToDisplayFormatWithTime } from "../../utils/Utils";
import {
	getAllVersionsByInvoiceId,
	getSpecificInvoiceVersionByInvoiceId,
	getAllVersionsIdentifiersByInvoiceId
} from "../../api/apiInvoiceVersions";
import diffInvoiceLines, {
	LineDifference,
	sortInvoiceIdentifierVersions,
	sortInvoiceVersions,
	sortLineDiffs,
} from "../../utils/diffInvoices";
import {
	PageSpinner,
	PageSpinnerComponent,
} from "../../components/PageSpinner/PageSpinner";
import { waitFor } from "@testing-library/dom";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";
import { GetUserPermissions } from "../../utils/auth/authUtilities";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ModalSendToLegacy } from "../../components/Modals/ModalSendToLegacy";
import { ModalDeletion } from "../../components/Modals/ModalDeletion";

export function EmployeeInvoiceSummary(props: any) {
	const history = useHistory();
	var errorExists = false;
	const { invoiceId } = useParams<{ invoiceId: string }>();
	const [invoiceSummary, setInvoiceSummary] = useState(defaultNewInvoice);
	const [versionTimeStamp, setVersionTimeStamp] = useState("");
	const [showVersionTimeStamp, setShowVersionTimeStamp] = useState(true);
	const [annotations, setAnnotations] = useState(emptyAnnotations);
	const [annotationError, setAnnotationError] = useState("");
	const [assignedToSGIEmployee, setAssignedToSGIEmployee] = useState(
		initialAssignedToUser
	);
	const login = useTypedSelector((state) => state.login);
	const [loaded, setLoaded] = useState(false);
	const emptyStringArray: string[] = [];
	const emptyInvoiceIdentifierArray: iInvoiceIdentifier[] = [];
	const [toastMessages, setToastMessages] = useState(emptyStringArray);
	const [toastErrors, setToastErrors] = useState(emptyStringArray);
	const [modalShow, setModalShow] = useState(false);
	const [modalDeletionShow, setModalDeletionShow] = useState(false);
	const [modalSendToLegacyShow, setModalSendToLegacyShow] = useState(false);
	const [disableEdit, setDisableEdit] = useState(false);
	const [disableEditClaim, setDisableEditClaim] = useState(false);
	const [disableAssign, setDisableAssign] = useState(false);
	const [displayAsEditable, setDisplayAsEditable] = useState(false);
	const [displayAsEditableClaim, setDisplayAsEditableClaim] = useState(false);
	const [disableMarkAsPaid, setDisableMarkAsPaid] = useState(false);
	const emptyLineDiffArray: LineDifference[] = [];
	const [lineDiffs, setLineDiffs] = useState(emptyLineDiffArray);
	const [showFullSizePhotos, setShowFullSizePhotos] = useState(false);
	const [isLoadingFullSizePhotos, setIsLoadingFullSizePhotos] = useState(false);
	const [apiUnavailable, setApiUnavailable] = useState(false);
	const flags = useFlags();
	const [sortedInvoiceVersions, setSortedInvoiceVersions] = useState(emptyInvoiceIdentifierArray);
	const [newVersionCreated, setNewVersionCreated] = useState(false);
	const [viewingCurrentVersion, setViewingCurrentVersion] = useState(false);
	const [inProgressAnnotations, setInProgressAnnotations] =
		useState(emptyAnnotations);

	var currShowHideValue = false;
	const permissions = GetUserPermissions();
	const [disableMarkAsDelete, setDisableMarkAsDelete] = useState(false);

	useEffect(() => {
		async function getData() {
			if (viewingCurrentVersion) {
				setInProgressAnnotations(annotations);
			}

			var invoiceSummaryData: any;
			try {
				if (versionTimeStamp === "") {
					invoiceSummaryData = await getInvoiceByInvoiceId(invoiceId);
				} else {
					invoiceSummaryData = await getSpecificInvoiceVersionByInvoiceId(
						invoiceId,
						versionTimeStamp
					);
				}
			} catch (error) {
				setApiUnavailable(true);
			}

			if (invoiceSummaryData) {
				const transformedSummaryData = invoiceDataTransformer(invoiceSummaryData);
				// new invoices won't have an annotations array - the value will be null/undefined
				const annotationsToStore =
					transformedSummaryData.invoiceAnnotations === undefined ||
						transformedSummaryData.invoiceAnnotations === null
						? emptyAnnotations
						: transformedSummaryData.invoiceAnnotations;

				setInvoiceSummary(transformedSummaryData);
				setAnnotations(annotationsToStore);
				setAssignedToSGIEmployee(
					transformedSummaryData.invoiceDetails.assignedToSGIEmployee
				);

				let allInvoiceVersions: iInvoiceIdentifier[] = sortedInvoiceVersions;
				if (allInvoiceVersions.length === 0) {
					allInvoiceVersions = await getSortedInvoiceVersionsList();
					setInvoiceVersionsList(allInvoiceVersions);
				}

				const actionRequiredVersion = allInvoiceVersions.find((x) => x.status === 3 && (versionTimeStamp === "" || (x.versionTimestamp ?? "") < versionTimeStamp));

				if (transformedSummaryData.invoiceDetails.status === 5 && actionRequiredVersion !== undefined) {

					let actionRequiredInvoiceData: iInvoice | null = null;
					try {
						const actionRequiredDataResponse = await getSpecificInvoiceVersionByInvoiceId(
							invoiceId,
							actionRequiredVersion.versionTimestamp
						);
						actionRequiredInvoiceData = invoiceDataTransformer(actionRequiredDataResponse);
					} catch (error) {
						setApiUnavailable(true);
					}

					// call the line diffs function, if different
					// store results in state and set display flag
					if (actionRequiredInvoiceData) {
						var processedLineDiffs = diffInvoiceLines(
							transformedSummaryData.invoiceLineItems,
							actionRequiredInvoiceData.invoiceLineItems
						);

						// sort the line diffs
						var sortedLineDiffs = sortLineDiffs(
							processedLineDiffs,
							transformedSummaryData.invoiceLineItems
						);

						// store the line diffs to state.
						setLineDiffs(sortedLineDiffs);
					}
				} else {
					setLineDiffs(emptyLineDiffArray);
				}
			} else {
				setApiUnavailable(true);
			}
			setLoaded(true);
		}
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceId, versionTimeStamp]);

	const populateSortedInvoiceVersionsList = async () => {
		const allVersions = await getSortedInvoiceVersionsList();

		setSortedInvoiceVersions(sortInvoiceIdentifierVersions(allVersions));
		setNewVersionCreated(false);
		if (allVersions.length > 0) {
			assignViewingCurrentVersion(
				allVersions[0].versionTimestamp
			);
		}
	}

	const setInvoiceVersionsList = (versions: iInvoiceIdentifier[]) => {
		setSortedInvoiceVersions(sortInvoiceIdentifierVersions(versions));
		setNewVersionCreated(false);

		if (versions.length > 0) {
			assignViewingCurrentVersion(
				versions[0].versionTimestamp
			);
		}
	}

	const getSortedInvoiceVersionsList = async (): Promise<iInvoiceIdentifier[]> => {
		var allVersionsUntransformed = await getAllVersionsIdentifiersByInvoiceId(invoiceId);

		if (allVersionsUntransformed?.data?.length > 0) {
			var allVersions: iInvoiceIdentifier[] = [];
			allVersionsUntransformed.data.forEach((version: any) => {
				const untransformedData = {
					data: version,
				};

				allVersions.push(invoiceIdentifierDataTransformer(untransformedData));
			});

			return allVersions;
		} else {
			setApiUnavailable(true);
		}

		return [];
	}

	useEffect(() => {
		if (newVersionCreated) {
			// populateSortedVersionsList();
			populateSortedInvoiceVersionsList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newVersionCreated]);

	useEffect(() => {
		if (loaded) {
			if (invoiceSummary.invoiceDetails.assignedToSGIEmployee.email === "") {
				HandleAssignToSelf();
			}
		}
		// don't add assignedToSGIEmployee to dependency array or we can't unassign
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded]);

	useEffect(() => {
		setDisableMarkAsPaid(
			invoiceSummary.invoiceDetails.status === 4 ||
			invoiceSummary.invoiceDetails.status === 3 ||
			invoiceSummary.invoiceDetails.status === 6 ||
			assignedToSGIEmployee["email"] !== login.email ||
			!permissions.canPayInvoice
		);
		setDisableEdit(
			invoiceSummary.invoiceDetails.status === 4 ||
			invoiceSummary.invoiceDetails.status === 3 ||
			invoiceSummary.invoiceDetails.status === 6 ||
			assignedToSGIEmployee["email"] !== login.email ||
			(invoiceSummary.invoiceDetails.status === 5 &&
				!flags.vendorPaymentShowInvoiceHistory) ||
			!permissions.canAnnotateInvoice
		);
		setDisableEditClaim(
			invoiceSummary.invoiceDetails.status === 4 ||
			invoiceSummary.invoiceDetails.status === 3 ||
			invoiceSummary.invoiceDetails.status === 6 ||
			assignedToSGIEmployee["email"] !== login.email
		);
		setDisableAssign(
			invoiceSummary.invoiceDetails.status === 4 ||
			invoiceSummary.invoiceDetails.status === 6 ||
			!(permissions.canAnnotateInvoice && permissions.canPayInvoice)
		);
		setDisplayAsEditable(
			(invoiceSummary.invoiceDetails.status !== 4 &&
				invoiceSummary.invoiceDetails.status !== 3 &&
				invoiceSummary.invoiceDetails.status !== 5 &&
				invoiceSummary.invoiceDetails.status !== 6) ||
			(invoiceSummary.invoiceDetails.status === 5 &&
				flags.vendorPaymentShowInvoiceHistory)
		);
		setDisplayAsEditableClaim(
			invoiceSummary.invoiceDetails.status !== 4 &&
			invoiceSummary.invoiceDetails.status !== 3 &&
			invoiceSummary.invoiceDetails.status !== 6 &&
			(permissions.canAnnotateInvoice || permissions.canPayInvoice)
		);
		setDisableMarkAsDelete(
			invoiceSummary.invoiceDetails.status === 4 ||
			invoiceSummary.invoiceDetails.status === 6 ||
			(invoiceSummary.invoiceDetails.status === 6 &&
				flags.vendorPaymentEnableClerkToDeleteInvoices) ||
			assignedToSGIEmployee["email"] !== login.email ||
			!permissions.canPayInvoice
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		assignedToSGIEmployee,
		invoiceSummary.invoiceDetails.status,
		login.email,
	]);

	function assignViewingCurrentVersion(selectedTimeStamp: any) {
		if (
			selectedTimeStamp === versionTimeStamp ||
			selectedTimeStamp === "current" ||
			versionTimeStamp === ""
		) {
			setViewingCurrentVersion(true);
			if (inProgressAnnotations !== emptyAnnotations) {
				setAnnotations(inProgressAnnotations);
			}
		} else {
			setViewingCurrentVersion(false);
		}
	}

	async function beforePrint() {
		var documentReference = window.document;
		currShowHideValue = showFullSizePhotos;
		setShowFullSizePhotos(true);
		setShowVersionTimeStamp(false);
		HideElement(documentReference, "additionalInfoButton");
		HideElement(documentReference, "annotateButton");
		HideElement(documentReference, "locationButton");
		HideElement(documentReference, "ShowFullSizePhotoButton");
		HideElement(documentReference, "saveChangesButton");
		HideElement(documentReference, "sendChangesButton");
		HideElement(documentReference, "toast");
	}

	async function afterPrint() {
		var documentReference = window.document;
		RestoreElementVisibility(documentReference, "ShowFullSizePhotoButton");
		RestoreElementVisibility(documentReference, "additionalInfoButton");
		RestoreElementVisibility(documentReference, "annotateButton");
		RestoreElementVisibility(documentReference, "locationButton");
		RestoreElementVisibility(documentReference, "saveChangesButton");
		RestoreElementVisibility(documentReference, "sendChangesButton");
		RestoreElementVisibility(documentReference, "toast");
		setShowFullSizePhotos(currShowHideValue);
		setShowVersionTimeStamp(true);
	}

	window.onbeforeprint = beforePrint;
	window.onafterprint = afterPrint;

	async function HandlePrint() {
		await beforePrint();
		//next line is waiting for the photos to be rendered
		//without that line it doesn't display images properly
		await waitFor(() => 0);
		window.print();
		await afterPrint();
	}

	function HideElement(documentReference: Document, buttonToHide: string) {
		var buttonsToHide = documentReference.getElementsByClassName(buttonToHide);
		Array.from(buttonsToHide).forEach((element) => {
			element.setAttribute("hidden", "true");
		});
	}

	function RestoreElementVisibility(
		documentReference: Document,
		buttonToRestore: string
	) {
		var buttonsToRestore =
			documentReference.getElementsByClassName(buttonToRestore);
		Array.from(buttonsToRestore).forEach((element) => {
			element.removeAttribute("hidden");
		});
	}

	async function HandleAssignToSelf() {
		if (!(permissions.canAnnotateInvoice || permissions.canPayInvoice)) {
			return;
		}

		var assignedToSelf: AssignedToUser = {
			email: login.email,
			fullName: login.fullName,
		};

		var response: any;
		try {
			response = await assignInvoice(invoiceId, assignedToSelf);
		} catch (error) {
			setToastErrors(["Error assigning invoice"]);
		}

		if (response) {
			setAssignedToSGIEmployee(assignedToSelf);
			setInvoiceSummary({
				...invoiceSummary,
				invoiceDetails: {
					...invoiceSummary.invoiceDetails,
					assignedToSGIEmployee: assignedToSelf,
				},
			});
			setToastMessages(["Invoice has been assigned to you"]);
			setNewVersionCreated(true);
		} else {
			setToastErrors(["Error assigning invoice"]);
		}
	}

	async function HandleUnassign() {
		var response: any;
		try {
			response = await assignInvoice(invoiceId, initialAssignedToUser);
		} catch (error) {
			setToastErrors(["Error assigning invoice"]);
		}

		if (response) {
			setAssignedToSGIEmployee(initialAssignedToUser);
			setInvoiceSummary({
				...invoiceSummary,
				invoiceDetails: {
					...invoiceSummary.invoiceDetails,
					assignedToSGIEmployee: initialAssignedToUser,
				},
			});
			setNewVersionCreated(true);
		} else {
			setToastErrors(["Error un-assigning invoice"]);
		}
	}

	async function HandlePaid() {
		setModalShow(true);
	}
	async function HandleDelete() {
		setModalDeletionShow(true);
	}
	async function HandlePaidConfirmation() {
		var response: any;
		try {
			response = await updateStatus(invoiceId, 4, "", "");
		} catch (error) {
			setToastErrors(["Error paying invoice"]);
		}
		if (response) {
			history.push("/Employee/Invoices/List");
		} else {
			setToastErrors(["Error paying invoice"]);
		}
	}

	async function HandleSendToGIS() {
		setModalSendToLegacyShow(true);
	}

	async function HandleSave() {
		// call api Save  (no change to status  - pass in old status code)
		HandleError();
		if (errorExists === false) {
			var response: any;
			try {
				response = await patchInvoiceWithAnnotations(
					invoiceId,
					annotations,
					invoiceSummary.invoiceDetails.status
				);
			} catch (error) {
				setToastErrors(["Error saving  invoice"]);
			}

			if (response) {
				//return to list
				history.goBack();
			} else {
				setToastErrors(["Error saving  invoice"]);
			}
		}
	}

	function HandleError(): void {
		// ensure that all annotations have text
		annotations.forEach((annotation) => {
			if (
				annotation.annotationText === "" ||
				annotation.annotatedValue === ""
			) {
				setAnnotationError(
					"Annotation Explanation and Suggested Value cannot be blank"
				);
				errorExists = true;
			} else {
				setAnnotationError("");
			}
		});
	}

	async function HandleSubmit() {
		HandleError();
		if (errorExists === false) {
			var draftAnnotations: Annotation[] = annotations.filter(
				(a) => a.status !== AnnotationStatus.Complete
			);
			var newAnnotations = draftAnnotations.map((element) => {
				return {
					...element,
					status:
						element.status === AnnotationStatus.Draft
							? AnnotationStatus.Requested
							: element.status,
				};
			});
			setAnnotations(newAnnotations);

			var response: any;
			try {
				// call api Save  (3 = Annotated)
				response = await patchInvoiceWithAnnotations(
					invoiceId,
					newAnnotations,
					3
				);
			} catch (error) {
				setToastErrors(["Error submitting  invoice"]);
			}

			if (response) {
				//return to list
				history.goBack();
			} else {
				setToastErrors(["Error submitting  invoice"]);
			}
		}
	}

	if (apiUnavailable) {
		return <ApiUnavailable />;
	} else
		return (
			<>
				<ModalConfirmation
					title="Please Confirm"
					body="Marking an invoice as paid will remove it from the current invoice list"
					buttonText="Mark Invoice as Paid"
					onButtonClick={HandlePaidConfirmation}
					show={modalShow}
					onHide={() => setModalShow(false)}
				/>
				{
					permissions.canSendGISInvoice &&
					<ModalSendToLegacy
						invoiceId={invoiceId}
						annotations={annotations}
						claimNumber={invoiceSummary.invoiceDetails.claimNumber}
						show={modalSendToLegacyShow}
						onHide={() => setModalSendToLegacyShow(false)}
					/>
				}
				{
					permissions.canDeleteInvoice &&
					<ModalDeletion
						show={modalDeletionShow}
						onHide={setModalDeletionShow}
						invoiceId={invoiceId}
						plate={invoiceSummary.invoiceDetails.plate}
						towerReference={invoiceSummary.invoiceDetails.towerReference}
						claimNumber={invoiceSummary.invoiceDetails.claimNumber}
						setToastErrors={setToastErrors}
					/>
				}
				{!loaded && <PageSpinner />}
				{loaded && (
					<Container className="invoiceSummaryContainer">
						<ToastErrors
							title="Error"
							setMessages={setToastErrors}
							messages={toastErrors}
						/>
						<ToastInfo
							title="Invoice Assigned"
							setMessages={setToastMessages}
							messages={toastMessages}
						/>
						<Row className="invoiceSummaryButtonRow">
							<div className="buttonsDiv">
								{
									permissions.canAssignInvoice &&
									<>
										{assignedToSGIEmployee["email"] === "" && (
											<div className="buttonDiv">
												<Button
													id="assignButton"
													data-testid="assignButton"
													variant="outline-primary"
													onClick={HandleAssignToSelf}
													disabled={disableAssign || !viewingCurrentVersion}
												>
													Assign To Me
												</Button>
											</div>
										)}
										{assignedToSGIEmployee["email"] !== "" && (
											<div className="buttonDiv">
												<Button
													id="unassignButton"
													data-testid="unassignButton"
													variant="outline-primary"
													onClick={HandleUnassign}
													disabled={disableAssign || !viewingCurrentVersion}
												>
													Unassign
												</Button>
											</div>
										)}
									</>
								}
								{(flags.enableTowappGisAttachmentProcessing && permissions.canSendGISInvoice) && (
									<div className="buttonDiv">
										<Button
											id="sendToGISButton"
											data-testid="sendToGISButton"
											variant="outline-primary"
											onClick={HandleSendToGIS}
											disabled={disableMarkAsPaid || !viewingCurrentVersion}
										>
											Send To GIS
										</Button>
									</div>
								)}
								{permissions.canMarkAsPaidInvoice &&
									<div className="buttonDiv">
										<Button
											id="paidButton"
											data-testid="paidButton"
											variant="outline-primary"
											onClick={HandlePaid}
											disabled={disableMarkAsPaid || !viewingCurrentVersion}
										>
											Mark as Paid
										</Button>
									</div>
								}
								{(flags.vendorPaymentEnableClerkToDeleteInvoices && permissions.canDeleteInvoice) && (
									<div className="buttonDiv">
										<Button
											id="deleteButton"
											data-testid="deletButton"
											variant="outline-primary"
											onClick={HandleDelete}
											disabled={disableMarkAsDelete || !viewingCurrentVersion}
										>
											Delete
										</Button>
									</div>
								)}
								<div className="buttonDiv">
									<Button
										id="printButton"
										data-testid="printButton"
										variant="outline-primary"
										onClick={HandlePrint}
										disabled={isLoadingFullSizePhotos}
									>
										{isLoadingFullSizePhotos && <PageSpinnerComponent />}
										Print
									</Button>
								</div>
								<div className="buttonDiv">
									<Button
										id="returnButton"
										data-testid="ReturnToListButton"
										variant="outline-primary"
										onClick={() => {
											history.goBack();
										}}
									>
										Return to List
									</Button>
								</div>
							</div>
						</Row>

						<Row className="invoiceSummaryRow">
							<div className="invoiceSummaryHeader">
								<h5>Invoice Summary</h5>
							</div>
						</Row>
						{invoiceSummary.invoiceDetails.paidDate && (
							<>
								<Row className="invoiceSummaryRow">
									<div
										className="invoiceSummaryHeader"
										data-testid="PaidDateField"
									>
										<h6>
											<b>Paid:</b>{" "}
											{convertDateToDisplayFormatWithTime(
												invoiceSummary.invoiceDetails.paidDate
											)}
										</h6>
									</div>
								</Row>
								<br />
							</>
						)}
						{invoiceSummary.invoiceDetails.deletedDate && (
							<>
								<Row className="invoiceSummaryRow">
									<div
										className="invoiceSummaryHeader"
										data-testid="DeletedDateField"
									>
										<h6>
											<b>Deleted:</b>{" "}
											{convertDateToDisplayFormatWithTime(
												invoiceSummary.invoiceDetails.deletedDate
											)}
										</h6>
									</div>
								</Row>
								<br />
							</>
						)}
						<EmployeeInvoiceSummaryDetails
							invoiceSummary={invoiceSummary}
							setInvoiceSummary={setInvoiceSummary}
							annotations={annotations}
							setAnnotations={setAnnotations}
							annotationError={annotationError}
							setAnnotationError={setAnnotationError}
							disableEdit={disableEdit}
							displayAsEditable={displayAsEditable}
							sortedInvoiceVersions={sortedInvoiceVersions}
							lineDiffs={lineDiffs}
							disableEditClaim={disableEditClaim}
							displayAsEditableClaim={displayAsEditableClaim}
							showFullSizePhotos={showFullSizePhotos}
							setShowFullSizePhotos={setShowFullSizePhotos}
							isLoadingFullSizePhotos={isLoadingFullSizePhotos}
							setIsLoadingFullSizePhotos={setIsLoadingFullSizePhotos}
							setToastErrors={setToastErrors}
							setVersionTimeStamp={setVersionTimeStamp}
							setNewVersionCreated={setNewVersionCreated}
							showVersionTimeStamp={showVersionTimeStamp}
							setShowVersionTimeStamp={setShowVersionTimeStamp}
							viewingCurrentVersion={viewingCurrentVersion}
						/>

						{!disableEdit && annotations.length !== 0 && viewingCurrentVersion && (
							<Row className="invoiceSaveSubmitRow">
								<div className="buttonsDiv">
									<div className="buttonDiv">
										<Button
											id="saveButton"
											data-testid="saveButton"
											variant="outline-primary"
											onClick={HandleSave}
											disabled={disableEdit}
											className="saveChangesButton"
										>
											Save Requested Changes
										</Button>
									</div>
									<Button
										id="submitButton"
										data-testid="submitButton"
										variant="outline-primary"
										onClick={HandleSubmit}
										disabled={disableEdit}
										className="sendChangesButton"
									>
										Send Requested Changes to Tower
									</Button>
									{annotationError !== "" && (
										<Error message={annotationError} />
									)}
								</div>
							</Row>
						)}
					</Container>
				)}
			</>
		);
}
