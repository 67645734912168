export function GetB2CPolicyInfo() {
	return {
		names: {
			signUpSignIn:
				(window as any)["runConfig"]?.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY ??
				process.env.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
			forgotPassword:
				(window as any)["runConfig"]?.REACT_APP_MSAL_FORGOTPASSWORD_POLICY ??
				process.env.REACT_APP_MSAL_FORGOTPASSWORD_POLICY,
			editProfile:
				(window as any)["runConfig"]?.REACT_APP_MSAL_EDITPROFILE_POLICY ??
				process.env.REACT_APP_MSAL_EDITPROFILE_POLICY,
		},
		authorities: {
			signUpSignIn: {
				authority:
					((window as any)["runConfig"]?.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT ??
						process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT) +
						(window as any)["runConfig"]?.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY ??
					process.env.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
			},
			forgotPassword: {
				authority:
					((window as any)["runConfig"]?.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT ??
						process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT) +
						(window as any)["runConfig"]
							?.REACT_APP_MSAL_FORGOTPASSWORD_POLICY ??
					process.env.REACT_APP_MSAL_FORGOTPASSWORD_POLICY,
			},
			editProfile: {
				authority:
					((window as any)["runConfig"]?.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT ??
						process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT) +
						(window as any)["runConfig"]?.REACT_APP_MSAL_EDITPROFILE_POLICY ??
					process.env.REACT_APP_MSAL_EDITPROFILE_POLICY,
			},
		},
		authorityDomain:
			(window as any)["runConfig"]?.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN ??
			process.env.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN,
	};
}

export default GetB2CPolicyInfo;
