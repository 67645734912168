import React, { useState } from "react";
import {
	Button,
	Col,
	Container,
	Modal,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import {
	convertDateToDisplayFormatWithTime,
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";
import CustomTable from "../../components/CustomTable/CustomTable";
import TableButton from "../../components/CustomTable/TableButton";
import { faDownload, faPen } from "@fortawesome/free-solid-svg-icons";
import { SortOrder } from "react-bootstrap-table-next";
import { defaultTransferFileSummary } from "../../types/TransferFile";
import {
	getTransferSummary,
	markFileAsManuallyProc,
} from "../../api/apiTransferStatus";
import { transferFileSummaryTransformer } from "../../utils/transferFileSummaryTransformer";
import { getInvoicePdf } from "../../api/apiInvoicePdf";
import { getPhotoByGuid } from "../../api/apiInvoice";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { selectFilter } from "react-bootstrap-table2-filter";

export default function ManageLegacyTransfer(props: any) {
	const [transferFileSummary, setTransferFileSummary] = useState(
		defaultTransferFileSummary
	);
	const selectOptions: { [index: string]: string } = {
		Pending: "Pending",
		ManuallyProcessed: "ManuallyProcessed",
		Success: "Success",
		Failed: "Failed",
	};
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [rowToManuallyComplete, setRowToManuallyComplete] = useState(Object);
	const [rowIndexToManuallyComplete, setRowIndexToManuallyComplete] =
		useState(Object);
	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [failureMessages, setFailureMessages] = useState(emptyStringArray);
	const { screenSize } = useWindowDimensions();

	function buttonsFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<div className="buttonCellWrapper">
				{!row?.errorDetails?.includes("virus") && (
					<TableButton
						icon={faDownload}
						onClick={() => downloadFile(row)}
						tooltipText="Download File"
						id={`downloadIcon${rowIndex}`}
						datatestid={`downloadIcon${rowIndex}`}
					/>
				)}
				{(row.status === "Failed" || row.status === "Pending") && (
					<TableButton
						icon={faPen}
						onClick={() => setFileToManuallyComplete(row, rowIndex)}
						tooltipText="Mark Completed"
						id={`manuallyProcessedIcon${rowIndex}`}
						datatestid={`manuallyProcessedIcon${rowIndex}`}
					/>
				)}
			</div>
		);
	}

	function errorFormatter(cell: string, row: any, rowIndex: any) {
		if (cell?.length > 25) {
			return (
				<OverlayTrigger
					overlay={<Tooltip id="errorDetailsTooltip">{cell}</Tooltip>}
				>
					<div style={{ color: "dimgray" }}>{cell.slice(0, 25)}...</div>
				</OverlayTrigger>
			);
		}
		return <span>{cell}</span>;
	}

	function getNonExpandableRows() {
		return transferFileSummary.map((file) => {
			if (file.errorDetails === "") {
				return file.submittedDate;
			} else return "";
		});
	}

	const setFileToManuallyComplete = async (row: any, rowIndex: any) => {
		setRowToManuallyComplete(row);
		setRowIndexToManuallyComplete(rowIndex);
		setShowConfirmModal(true);
	};

	const markFileManuallyCompleted = async () => {
		let success = false;

		setShowConfirmModal(false);
		rowToManuallyComplete.status = "ManuallyProcessed";
		await markFileAsManuallyProc(rowToManuallyComplete).then((apiResponse) => {
			if (apiResponse?.data) {
				setSuccessMessages(["Manually Processed successfully."]);
				success = true;
			} else {
				setFailureMessages([
					"Error when Manually processed. Please try again later.",
				]);
			}
			if (success) {
				let transferFileTable = document.querySelector("table");

				if (transferFileTable) {
					transferFileTable.rows[
						rowIndexToManuallyComplete + 1
					].cells[6].innerHTML = "ManuallyProcessed";

					let completeButtonWrapper = document.getElementById(
						`manuallyProcessedIcon${rowIndexToManuallyComplete}Wrapper`
					) as HTMLElement;
					completeButtonWrapper.style.visibility = "hidden";
				}
			}
		});
	};

	const downloadFile = async (row: any) => {
		var result: any;
		if (row.formType === "Invoice") {
			result = await getInvoicePdf(row.formId);
		} else {
			result = await getPhotoByGuid(row.formId);
		}

		if (result && result.data && result.status < 300) {
			const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
			var filename = row.fileName;

			if (filename === null || filename === "") {
				if (row.formType === "Invoice") {
					filename = row.formType + "_" + row.formId + ".pdf";
				} else {
					filename = row.formType + "_" + row.formId + ".jpg";
				}
			}

			const link = document.createElement("a");
			link.href = downloadUrl;
			link.setAttribute("download", filename); //any other extension
			document.body.appendChild(link);
			link.click();
			link.remove();
			setSuccessMessages(["Download successfully."]);
		} else {
			setFailureMessages([
				"Error when downloading file. Please try again later.",
			]);
		}
	};

	function noDataIndication() {
		return "There are no entries to display.";
	}

	function dateFormatter(cell: string) {
		var formattedDate = convertDateToDisplayFormatWithTime(cell, false);
		var n = 1;
		return formattedDate.replace(/,/g, (v) => (n++ === 2 ? "\n" : v));
	}

	const defaultSorted: [{ dataField: any; order: SortOrder }] = [
		{
			dataField: "submittedDate",
			order: "desc",
		},
	];
	const columns = [
		{
			dataField: "id",
			text: "Id",
			hidden: true,
			sort: true,
		},
		{
			dataField: "organizationName",
			text: "Organization",
			sort: true,
			headerClasses: screenSize < ScreenSize.lg ? "width30" : "",
			headerStyle: () => {
				return { width: "150px" };
			},
		},
		{
			dataField: "sgiPayeeNumber",
			text: "Payee Number",
			sort: true,
		},
		{
			dataField: "claimNumber",
			text: "Claim",
			sort: true,
		},
		{
			dataField: "formType",
			text: "Type",
			sort: true,
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "fileName",
			text: "File Name",
			sort: true,
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
			hidden: screenSize < ScreenSize.lg,
			withoutEmptyOption: true,
			formatter: (cell: string) => selectOptions[cell],
			filter: selectFilter({
				options: selectOptions,
			}),
		},
		{
			dataField: "errorHttpCode",
			text: "Http Code",
			sort: true,
			hidden: true,
		},
		{
			dataField: "errorDetails",
			text: "Error",
			sort: true,
			formatter: errorFormatter,
			searchable: true,
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "submittedDate",
			text: "Submitted",
			sort: true,
			formatter: dateFormatter,
			hidden: screenSize < ScreenSize.lg,
		},
		{
			dataField: "",
			text: "",
			isDummyField: true,
			formatter: buttonsFormatter,
			hidden: screenSize < ScreenSize.md,
		},
	];

	const expandRow = {
		renderer: (row: any) => (
			<Row>
				<Col>
					{row.status !== "" && (
						<>
							<p data-testid="errorDetails">
								<strong>Status</strong>
							</p>
							<p>{row.status}</p>
						</>
					)}
				</Col>
				<Col>
					{row.errorDetails !== "" && (
						<>
							<p data-testid="errorDetails">
								<strong>Error Details</strong>
							</p>
							<p>{row.errorDetails}</p>
						</>
					)}
				</Col>
				<Col className="buttonPadding">{buttonsFormatter("id", row, "")}</Col>
			</Row>
		),
		showExpandColumn: true,
		expandByColumnOnly: false,
		nonExpandable: getNonExpandableRows(),
	};

	return (
		<>
			<Container className="CustomTableContainer">
				<ToastSuccess
					title="Legacy Transfer Success"
					setMessages={setSuccessMessages}
					messages={successMessages}
				/>
				<ToastErrors
					title="Error Legacy Transfer"
					setMessages={setFailureMessages}
					messages={failureMessages}
				/>
				<Row>
					<div className="tableHeader">
						<h3>Legacy Transfer</h3>
					</div>
				</Row>
				<CustomTable
					noDataIndication={noDataIndication}
					defaultSorted={defaultSorted}
					columns={columns}
					data={transferFileSummary}
					setData={setTransferFileSummary}
					defaultData={defaultTransferFileSummary}
					dataGetter={getTransferSummary}
					dataMapper={transferFileSummaryTransformer}
					expandRow={expandRow}
					keyField="id"
				/>
			</Container>
			<Modal
				show={showConfirmModal}
				onHide={() => setShowConfirmModal(false)}
				backdrop="static"
				keyboard={true}
				centered
				dialogClassName="confirmationModal"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5>Confirm Mark Manually Processed</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Please confirm that you want to mark this file as Manually Processed.
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => setShowConfirmModal(false)}
					>
						Close
					</Button>
					<Button
						variant="primary"
						data-testid="confirmationModalConfirmButton"
						onClick={() => markFileManuallyCompleted()}
					>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
