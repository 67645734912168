export function validatePayeeNumber(PayeeNumToValidate: string) {
  if (PayeeNumToValidate?.trim()?.length === 0) {
    return "Payee Number cannot be blank";
  }
  if (!/^([0-9]{1,9})$/.test(PayeeNumToValidate?.trim())) {
    return "Payee Number must be between 1 to 9 numbers.";
  }
  return "";
}

export function validateCompanyName(CompanyNameToValidate: string) {
  if (
    CompanyNameToValidate === null ||
    CompanyNameToValidate?.trim()?.length === 0
  ) {
    return "";
  }
  if (CompanyNameToValidate.length > 60) {
    return "Display Name must not be more than 60 characters";
  }
  return "";
}

export function validatePhoneNumber(PhoneNumberToValidate: string) {
  if (
    PhoneNumberToValidate === null ||
    PhoneNumberToValidate?.trim()?.length === 0
  ) {
    return "";
  }
  if (
    !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
      PhoneNumberToValidate
    )
  ) {
    return "Please enter in a valid phone number";
  }

  return "";
}
export function validateEmail(EmailToValidate: string) {
  if (EmailToValidate === null || EmailToValidate?.trim()?.length === 0) {
    return "";
  }
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      EmailToValidate
    )
  ) {
    return "Please enter valid email address";
  }
  return "";
}
