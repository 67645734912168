import { Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { iLineItem, WeightClass } from "../../types/LineItems";
import { useEffect, useState } from "react";
import { ModalConfirmWeightClassChange } from "../Modals/ConfirmWeightClassChange";
import { ToastSuccess } from "../Toast/ToastSuccess";

export function WeightClassSelector(props: any) {
	const [modalShow, setModalShow] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [changeWeightClassTo, setChangeWeightClassTo] = useState(
		props.invoiceDetails.weightClass
	);
	const [currentWeightClass, setCurrentWeightClass] = useState(
		props.invoiceDetails.weightClass
	);

	const defaultLineItems: iLineItem[] = [];
	const allLineOptions = props.allLineOptions;

	useEffect(() => {
		if (toastMessage !== "") {
			let success = [];
			success.push(toastMessage);
			setSuccessMessages([...success]);
			setToastMessage("");
		}
	}, [toastMessage]);

	useEffect(() => {
		var lineOptionsByWeightClass = allLineOptions.filter(
			(x: any) => x.weightClass === props.invoiceDetails.weightClass
		);
		props.setLineOptions(lineOptionsByWeightClass);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allLineOptions, props.invoiceDetails.weightClass]);
	const handleWeightChangeDenied = () => {};

	const handleWeightChangeConfirmed = () => {
		var lineOptionsByWeightClass = allLineOptions.filter(
			(x: any) => x.weightClass === changeWeightClassTo
		);
		props.setBasicLineItems(defaultLineItems);
		props.setSpecialLineItems(defaultLineItems);
		props.setInvoiceDetails({
			...props.invoiceDetails,
			weightClass: changeWeightClassTo,
		});
		props.setLineOptions(lineOptionsByWeightClass);
	};

	const handleOnChange = async (newValue: any) => {
		setChangeWeightClassTo(newValue);
		if (
			props.specialLineItems.length === 0 &&
			props.basicLineItems.length === 0
		) {
			var lineOptionsByWeightClass = allLineOptions.filter(
				(x: any) => x.weightClass === newValue
			);
			props.setBasicLineItems(defaultLineItems);
			props.setSpecialLineItems(defaultLineItems);
			props.setInvoiceDetails({
				...props.invoiceDetails,
				weightClass: newValue,
			});
			props.setLineOptions(lineOptionsByWeightClass);
		} else {
			setModalShow(true);
		}
	};
	return (
		<>
			<ToastSuccess
				title="Service Class Change"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<ModalConfirmWeightClassChange
				show={modalShow}
				onHide={() => setModalShow(false)}
				setToastMessage={setToastMessage}
				setCurrentWeightClass={setCurrentWeightClass}
				changeFromWeightClass={currentWeightClass}
				changeToWeightClass={changeWeightClassTo}
				handleWeightChangeConfirmed={handleWeightChangeConfirmed}
				handleWeightChangeDenied={handleWeightChangeDenied}
			/>
			<Row>Service Class</Row>
			<Row>
				<ToggleButtonGroup
					type="radio"
					name="options"
					defaultValue={props.invoiceDetails.weightClass}
					onChange={handleOnChange}
					value={props.invoiceDetails.weightClass}
					data-testid="weightClassSelector"
				>
					<ToggleButton
						id="tbg-radio-1"
						value={WeightClass.light}
						variant="outline-secondary"
						data-testid="weightClass-light"
					>
						Light Duty
					</ToggleButton>
					<ToggleButton
						id="tbg-radio-2"
						value={WeightClass.medium}
						variant="outline-secondary"
						data-testid="weightClass-medium"
					>
						Medium Duty
					</ToggleButton>
					<ToggleButton
						id="tbg-radio-3"
						value={WeightClass.heavy}
						variant="outline-secondary"
						data-testid="weightClass-heavy"
					>
						Heavy Duty
					</ToggleButton>
				</ToggleButtonGroup>
			</Row>
		</>
	);
}
