import { Modal } from "react-bootstrap";
import { getDisplayAddress } from "../../types/Location";
import { CompoundMap } from "./CompoundMap";

interface ModalProps {
  show: any;
  onHide: any;
  heading: string;
  title: string;
  body: string;
  vendorDetails: any;
  setVendorDetails: any;
  setErrorMessage: any;
  autoZoom: number;
  precisePosition: any;
  setPrecisePosition: any;
  changedFields: any;
  setChangedFields: any;
  currentlySavedCompoundLocation: any;
}

export function ModalMap(props: ModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompoundMap
          vendorDetails={props.vendorDetails}
          setVendorDetails={props.setVendorDetails}
          setErrorMessage={props.setErrorMessage}
          autoZoom={props.autoZoom}
          precisePosition={props.precisePosition}
          setPrecisePosition={props.setPrecisePosition}
          changedFields={props.changedFields}
          setChangedFields={props.setChangedFields}
          currentlySavedCompoundLocation={props.currentlySavedCompoundLocation}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="left">
          {props.vendorDetails.compoundLocation &&
          props.vendorDetails.compoundLocation.googleResults?.formattedAddressFull === "" ? (
            <h5>Click on map to set location. </h5>
          ) : (
            <h5>
              {props.vendorDetails.compoundLocation &&
              props.vendorDetails.compoundLocation.googleResults?.formattedAddressFull !== ""
                ? getDisplayAddress(
                    props.vendorDetails.compoundLocation.googleResults
                  )
                : ""}
            </h5>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
