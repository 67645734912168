export interface Bulletin {
  id: string;
  title: string;
  createdOn: Date;
  extension: string;
  contentType: string;
}

export const defaultBulletin: Bulletin = {
  id: "00000000-0000-0000-0000-000000000000",
  title: "",
  createdOn: new Date(),
  contentType: "",
  extension: ""
};
