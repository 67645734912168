import React, { useState } from "react";
import { Exclamation, X } from "react-bootstrap-icons";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import {
  AnnotationType,
  Annotation,
  AnnotationStatus,
} from "../../types/Invoice";
import "./AnnotationDisplay.css";
import { iLineItem } from "../../types/LineItems";
import { ModalTowerFeedback } from "../../components/Modals/ModalTowerFeedback";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";

interface Props {
  component: AnnotationType;
  annotations: Annotation[];
  lineOptions: iLineItem[];
  setAnnotations: any;
}
export function AnnotationDisplay(props: Props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [annotationId, setModalAnnotationId] = useState("");
  const [modalLineDescription, setModalLineDescription] = useState("");
  const { screenSize } = useWindowDimensions();

  const annotationsToDisplay: Annotation[] = filterAnnotationsByType(
    props.component,
    props.annotations,
    props.lineOptions
  );

  function handleClick(event: any, id: string) {
    const { name } = event.target;
    setModalHeading(name);
    setModalLineDescription(
      props.lineOptions.find((x) => x.title === name)?.description ?? ""
    );
    setModalAnnotationId(id);
  }
  return (
    <>
      {annotationsToDisplay.length !== 0 ? (
        <>
          {annotationsToDisplay.map((a: Annotation, index: number) => {
            return (
              <div key={index}>
                <ModalTowerFeedback
                  heading={modalHeading}
                  annotationId={annotationId}
                  lineDescription={modalLineDescription}
                  show={modalShow}
                  annotations={props.annotations}
                  setAnnotations={props.setAnnotations}
                  onHide={setModalShow}
                />
                <div className="annotationContainer" key={a.title}>
                  <Alert
                    variant="info"
                    className="annotationAlert"
                    data-testid="annotationAlertTest"
                    name={a.title}
                  >
                    <Container className="annotationContainer">
                      <Row>
                        {screenSize >= ScreenSize.md && (
                          <Col className="annotationIcon">
                            <Exclamation size={80} />

                            <p />
                            <Row className="annotationDecision">
                              <Button
                                id={"rejectAnnotationButton" + a.name + a.id}
                                className="rejectAnnotationButton"
                                variant="outline-primary"
                                data-testid={
                                  "rejectAnnotationButton" + a.name + a.id
                                }
                                data-title={a.title}
                                name={a.title}
                                onClick={(e) => {
                                  handleClick(e, a.id);
                                  setModalShow(true);
                                }}
                              >
                                Reject <X size={20} />
                              </Button>
                            </Row>
                          </Col>
                        )}
                        <Col>
                          <Row className="annotationTitle">
                            {a.title} - Changes Requested
                          </Row>
                          <Row className="annotationMessage">
                            {a.annotationText}
                          </Row>
                        </Col>
                        <Col className="valueColumn" xs="3">
                          <Row className="annotationTitle">Suggestion</Row>
                          <Row className="annotationValue">
                            {a.annotatedValue}
                          </Row>
                        </Col>
                      </Row>
                      {screenSize < ScreenSize.md && (
                        <Row>
                          <Button
                            id={"rejectAnnotationButton" + a.name + a.id}
                            className="rejectAnnotationButton"
                            variant="outline-primary"
                            data-testid={
                              "rejectAnnotationButton" + a.name + a.id
                            }
                            data-title={a.title}
                            name={a.title}
                            onClick={(e) => {
                              handleClick(e, a.id);
                              setModalShow(true);
                            }}
                          >
                            Reject <X size={20} />
                          </Button>
                        </Row>
                      )}
                      <br />
                      <Row>
                        {a.rejectedComment !== "" &&
                          a.rejectedComment !== null &&
                          a.rejectedComment !== undefined && (
                            <>
                              <p className="annotationTitle">
                                Rejection message:
                              </p>
                              &nbsp; &nbsp;
                              {a.rejectedComment}
                            </>
                          )}
                      </Row>
                    </Container>
                  </Alert>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function filterAnnotationsByType(
  annotationType: AnnotationType,
  annotations: Annotation[],
  lineOptions: iLineItem[]
): Annotation[] {
  switch (annotationType) {
    // match based on name (instead of id) so annotations go to the right section even if the line item is deleted
    case AnnotationType.BasicItems:
      return annotations.filter(
        (a) =>
          lineOptions.find((l) => l.name === a.name && l.isStandard) &&
          a.status === AnnotationStatus.Requested
      );
    case AnnotationType.SpecialItems:
      return annotations.filter(
        (a) =>
          lineOptions.find((l) => l.name === a.name && !l.isStandard) &&
          a.status === AnnotationStatus.Requested
      );
    default:
      return [];
  }
}

export function AnnotationHeader(props: any) {
  return (
    <div className="annotationContainer">
      <Alert
        variant="info"
        className="annotationAlert"
        data-testid="annotationAlertTest"
      >
        <Container className="annotationContainer">
          <Row className="annotationTitle">Attention: Changes Requested</Row>
          <Row>
            <Col className="annotationIcon">
              <Exclamation size="5em" />
            </Col>
            <Col>
              <Row className="annotationMessage">
                <p></p>
                SGI Staff have reviewed your invoice submission and have
                requested some changes. Please review the notes in orange below
                and resubmit the invoice.
              </Row>
            </Col>
          </Row>
        </Container>
      </Alert>
    </div>
  );
}

export function AnnotationFooter(props: any) {
  return (
    <div className="annotationContainer">
      <Alert
        variant="info"
        className="annotationAlert"
        data-testid="annotationAlertTest"
      >
        <Container className="annotationContainer">
          <Row>
            <Col className="annotationIcon">
              <Exclamation size="5em" />
            </Col>
            <Col>
              <Row className="annotationTitle"></Row>
              <Row className="annotationMessage">
                <p></p>
                Please ensure you have made all requested changes to the invoice
                above before re-submitting.
              </Row>
            </Col>
          </Row>
        </Container>
      </Alert>
    </div>
  );
}
