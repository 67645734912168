import React from "react";
import { Form } from "react-bootstrap";
import { ArrowReturnRight, XCircle } from "react-bootstrap-icons";
import { Annotation, AnnotationStatus } from "../../types/Invoice";
import "./EmployeeInvoiceSummaryDetails.css";

export function EmployeeSummaryLineItemsAnnotations(props: any) {
  function handleAnnotatedValueChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    lineId: string
  ): void {
    const value = e.currentTarget.value;

    const index = props.annotations.findIndex(
      (x: { id: any; status: AnnotationStatus }) =>
        x.id === lineId && x.status === AnnotationStatus.Draft
    );
    if (index !== undefined && value.length <= 20) {
      const newAnnotation: Annotation = {
        name: props.annotations[index].name,
        id: props.annotations[index].id,
        title: props.annotations[index].title,
        annotationText: props.annotations[index].annotationText,
        rejectedComment: props.annotations[index].rejectedComment,
        annotatedValue: value,
        status: props.annotations[index].status,
      };
      var annotations = props.annotations.slice(0);
      annotations.splice(index, 1, newAnnotation);
      props.setAnnotations(annotations);
    }
  }
  function handleAnnotationChange(
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    lineId: string
  ): void {
    const { value } = e.currentTarget;

    const index = props.annotations.findIndex(
      (x: { id: any; status: AnnotationStatus }) =>
        x.id === lineId && x.status === AnnotationStatus.Draft
    );

    if (index !== undefined && value.length <= 200) {
      const newAnnotation: Annotation = {
        name: props.annotations[index].name,
        id: props.annotations[index].id,
        title: props.annotations[index].title,
        annotationText: value,
        annotatedValue: props.annotations[index].annotatedValue,
        rejectedComment: props.annotations[index].rejectedComment,
        status: props.annotations[index].status,
      };
      var annotations = props.annotations.slice(0);
      annotations.splice(index, 1, newAnnotation);
      props.setAnnotations(annotations);
    }
  }

  const removeAnnotation = (annotation: Annotation) => () => {
    if (!props.disableEdit) {
      var annotations = props.annotations.slice(0);
      annotations.splice(annotations.indexOf(annotation), 1);
      props.setAnnotations(annotations);
    }
  };

  return (
    <>
      {/* show any draft annotations */}
      {props.annotations
        .filter(
          (item: Annotation) =>
            item.status === AnnotationStatus.Draft && item.id === props.line.id
        )
        .map((annotation: Annotation) => {
          return (
            <tr key={annotation.name + annotation.id}>
              <td></td>
              <td className="employeeAnnotationPadding">
                <table className="table-borderless annotationTextColTable">
                  <tbody>
                    <tr>
                      {props.displayAsEditable &&
                      annotation.status === AnnotationStatus.Draft ? (
                        <td>
                          <div onClick={removeAnnotation(annotation)}>
                            <XCircle
                              onClick={removeAnnotation(annotation)}
                              data-testid={
                                "removeButton." +
                                annotation.name +
                                annotation.id
                              }
                            />
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="annotationTextCol">
                        {props.displayAsEditable &&
                        annotation.status === AnnotationStatus.Draft ? (
                          <Form>
                            <Form.Group
                              controlId={
                                "annotationText." +
                                annotation.name +
                                annotation.id
                              }
                            >
                              <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Describe change"
                                data-testid={
                                  "annotationText." +
                                  annotation.name +
                                  annotation.id
                                }
                                onChange={(e) =>
                                  handleAnnotationChange(e, annotation.id)
                                }
                                name={annotation.name}
                                value={annotation.annotationText}
                                disabled={props.disableEdit}
                              />
                            </Form.Group>
                          </Form>
                        ) : (
                          <div
                            data-testid={
                              "annotationTextDisplay." +
                              annotation.name +
                              annotation.id
                            }
                          >
                            {annotation.annotationText}
                          </div>
                        )}

                        <div>
                          {annotation.rejectedComment !== null &&
                            annotation.rejectedComment !== "" &&
                            annotation.rejectedComment !== undefined && (
                              <div className="vendorReplyPadding">
                                <ArrowReturnRight />
                                <div className="changesHeaderDiv">
                                  Vendor:&nbsp;
                                </div>
                                <div className="changesInlineDiv">
                                  {annotation.rejectedComment}
                                </div>
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="annotatedValueCol">
                {props.displayAsEditable &&
                annotation.status === AnnotationStatus.Draft ? (
                  <Form>
                    <Form.Group
                      controlId={
                        "annotatedValue." + annotation.name + annotation.id
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="suggested Value"
                        data-testid={
                          "annotatedValue." + annotation.name + annotation.id
                        }
                        onChange={(e) =>
                          handleAnnotatedValueChange(e, annotation.id)
                        }
                        name={annotation.name}
                        value={annotation.annotatedValue}
                        disabled={props.disableEdit}
                      />
                    </Form.Group>
                  </Form>
                ) : (
                  <div
                    data-testid={
                      "annotatedValueDisplay." + annotation.name + annotation.id
                    }
                  >
                    {annotation.annotatedValue}
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      {/* show any completed annotations */}
      {props.annotations
        .filter(
          (item: Annotation) =>
            item.status !== AnnotationStatus.Draft && item.id === props.line.id
        )
        .map((annotation: Annotation) => {
          return (
            <tr key={annotation.name + annotation.id}>
              <td></td>

              <td className="employeeAnnotationPadding">
                <ArrowReturnRight />
                <div className="annotationHeaderDiv">SGI:&nbsp;</div>
                <div
                  data-testid={
                    "annotationTextDisplay." + annotation.name + annotation.id
                  }
                  className="annotationInlineDiv"
                >
                  {annotation.annotationText}
                </div>
                <div>
                  {annotation.rejectedComment !== null &&
                    annotation.rejectedComment !== "" &&
                    annotation.rejectedComment !== undefined && (
                      <div className="vendorReplyPadding">
                        <ArrowReturnRight />
                        <div className="changesHeaderDiv">Vendor:&nbsp;</div>
                        <div className="changesInlineDiv">
                          {annotation.rejectedComment}
                        </div>
                      </div>
                    )}
                </div>
              </td>
              <td className="">
                <div
                  data-testid={
                    "annotatedValueDisplay." + annotation.name + annotation.id
                  }
                >
                  {" "}
                  Suggested Value:&nbsp;
                  {annotation.annotatedValue}
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
}
