import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../AppInsights";

export async function handleResponse(response: any) {
  if (response.status >= 200 && response.status < 300) return response;

  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message: "Network response was not ok: " + response,
      severityLevel: SeverityLevel.Error,
    });
  }
  console.error("Network response was not ok.");
  return response;
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  // eslint-disable-next-line no-console
  if (appInsights !== undefined) {
    appInsights.trackTrace({
      message:
        "API call failed: " +
        error +
        " at " +
        error.config.method +
        " " +
        error.config.url,
      severityLevel: SeverityLevel.Error,
    });
  }
  console.error(
    `API call failed. ${error} at ${error?.config?.method} ${error?.config?.url}`
  );
}

const apiUtils = { handleResponse, handleError };
export default apiUtils;
