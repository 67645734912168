import { Col, Row } from "react-bootstrap";

export default function NotAuthorized() {
  return (
    <Col md={{ span: 8, offset: 2 }}>
      <Row>
        <Col>
          <h3>Unauthorized</h3>
        </Col>
      </Row>

      <Row>
        <Col>You are not authorized to view this page</Col>
      </Row>
    </Col>
  );
}
