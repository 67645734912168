import { useState, useEffect } from 'react';

const useUnsavedChangesWarning = (
  msg = 'Wait, You have unsaved changes?'
) => {
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = isChanged
      ? () => isChanged && !!msg
      : null;

    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [isChanged, msg]);

  const onDirty = () => setIsChanged(true);
  const onPristine = () => setIsChanged(false);

  return { isChanged, onDirty, onPristine };
};

export default useUnsavedChangesWarning;