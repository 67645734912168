import React, { useState } from "react";
import { Container, DropdownButton, Dropdown, Button } from "react-bootstrap";
import "./VendorInvoiceList.css";
import { SortOrder } from "react-bootstrap-table-next";
import {
	convertDateToDisplayFormat,
	ScreenSize,
	useWindowDimensions,
} from "../../utils/Utils";
import {
	deleteDraftInvoiceByInvoiceId,
	getInvoicesByVendor,
} from "../../api/apiInvoice";
import { defaultInvoiceList } from "../../types/InvoiceList";
import { useHistory } from "react-router-dom";
import CustomTable from "../../components/CustomTable/CustomTable";
import { mapToIInvoiceList } from "../../utils/InvoiceListTransformer";
import {
	faEdit,
	faTrashAlt,
	faBookOpen,
	faFilePlus,
} from "@fortawesome/pro-light-svg-icons";
import TableButton from "../../components/CustomTable/TableButton";
import InvoiceStatus from "../../components/InvoiceStatus/InvoiceStatus";
import { ModalYesNoConfirmation } from "../../components/Modals/ModalYesNoConfirmation";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import { ToastErrors } from "../../components/Toast/ToastErrors";
import { GetUserPermissions } from "../../utils/auth/authUtilities";
import { useTypedSelector } from "../../redux/store";

export default function VendorInvoiceList() {
	const [invoiceList, setInvoiceList] = useState(defaultInvoiceList);
	const [deleteRow, setDeleteRow] = useState({
		invoiceId: "",
		vehiclePlate: "",
		towerReference: "",
	});
	const [modalShow, setModalShow] = useState(false);

	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [failureMessages, setFailureMessages] = useState(emptyStringArray);

	const { screenSize } = useWindowDimensions();

	const history = useHistory();

	const permissions = GetUserPermissions();

	const [, setSubmitErrorMessages] = useState(emptyStringArray);

	const reduxConnectionStatus = useTypedSelector(
		(state) => state.connectionStatus
	);

	function noDataIndication() {
		return "There are no invoices to display.";
	}

	const defaultSorted: [{ dataField: any; order: SortOrder }] = [
		{
			dataField: "statusDisplay",
			order: "asc",
		},
	];

	const rowClasses = (row: any, rowIndex: number) => {
		if (screenSize >= ScreenSize.md) return;
		return row.statusDisplay.toLowerCase().replace(/\s+/g, "");
	};

	function buttonsFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<div className="buttonCellWrapper">
				{(row.status === 2 ||
					row.status === 4 ||
					row.status === 5 ||
					row.status === 6) && (
					<TableButton
						icon={faBookOpen}
						onClick={() => viewInvoice(row)}
						tooltipText="View Invoice"
						id={`viewInvoiceIcon${rowIndex}`}
						datatestid={`viewInvoiceIcon${rowIndex}`}
					/>
				)}
				{(row.status === 1 || row.status === 3) &&
					(permissions.canSaveInvoice || permissions.canSubmitInvoice) && (
						<>
							<TableButton
								icon={faEdit}
								onClick={() => editInvoice(row)}
								tooltipText="Edit Invoice"
								id={`editInvoiceIcon${rowIndex}`}
								datatestid={`editInvoiceIcon${rowIndex}`}
							/>
						</>
					)}
				{row.claimNumber &&
					row.status !== 1 &&
					row.status !== 6 &&
					permissions.canSaveInvoice && (
						<TableButton
							icon={faFilePlus}
							onClick={() => subsequentInvoice(row)}
							tooltipText="Subsequent Invoice"
							id={`subsequentInvoiceIcon${rowIndex}`}
							datatestid={`subsequentInvoiceIcon${rowIndex}`}
						/>
					)}
				{row.status === 1 && permissions.canSaveInvoice && (
					<TableButton
						icon={faTrashAlt}
						onClick={() => confirmDeleteDraft(row)}
						tooltipText="Delete Invoice"
						id={`deleteInvoiceIcon${rowIndex}`}
						datatestid={`deleteInvoiceIcon${rowIndex}`}
					/>
				)}
			</div>
		);
	}

	function handleDropdown(e: any) {
		e.stopPropagation();
	}

	function dropdownFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<>
				<DropdownButton
					menuAlign="right"
					title="..."
					id="mobileEllipsesTableDropdown"
					drop="up"
					variant="light"
					onClick={handleDropdown}
				>
					{(row.status === 2 ||
						row.status === 4 ||
						row.status === 5 ||
						row.status === 6) && (
						<Dropdown.Item onClick={() => viewInvoice(row)}>
							View Invoice
						</Dropdown.Item>
					)}
					{(row.status === 1 || row.status === 3) &&
						(permissions.canSaveInvoice || permissions.canSubmitInvoice) && (
							<Dropdown.Item onClick={() => editInvoice(row)}>
								Edit Invoice
							</Dropdown.Item>
						)}
					{row.claimNumber &&
						row.status !== 1 &&
						row.status !== 6 &&
						permissions.canSaveInvoice && (
							<Dropdown.Item onClick={() => subsequentInvoice(row)}>
								Create Subsequent
							</Dropdown.Item>
						)}
					{row.status === 1 && permissions.canSaveInvoice && (
						<Dropdown.Item onClick={() => confirmDeleteDraft(row)}>
							Delete Invoice
						</Dropdown.Item>
					)}
				</DropdownButton>
			</>
		);
	}

	const columns = [
		{
			dataField: "towerReference",
			text: "Invoice",
			sort: true,
			headerClasses: screenSize < ScreenSize.md ? "width30" : "",
		},
		{
			dataField: "claimNumber",
			text: "Claim Number",
			sort: true,
		},

		{
			dataField: "vehiclePlate",
			text: "Plate",
			sort: true,
		},
		{
			dataField: "submittedDate",
			text: "Date Submitted",
			sort: true,
			formatter: (cellContent: any) => {
				return convertDateToDisplayFormat(cellContent);
			},
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "requestedPayment",
			text: "Amount",
			sort: true,
			formatter: (cellContent: any) => {
				return "$" + cellContent.toFixed(2);
			},
			hidden: screenSize < ScreenSize.lg,
			classes: "right",
			headerClasses: "right",
		},
		{
			dataField: "statusDisplay",
			text: "Status",
			sort: true,
			formatter: (cellContent: any) => {
				return <InvoiceStatus status={cellContent} />;
			},
			classes: "center",
			headerClasses: "center",
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "buttons",
			text: "",
			formatter: buttonsFormatter,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "dropdownButton",
			text: "",
			classes: "right dropdownCell",
			headerClasses: "dropDownHeader",
			formatter: dropdownFormatter,
			hidden: screenSize >= ScreenSize.md,
		},
		{
			dataField: "statusDisplay",
			text: "Hidden column to allow searching for Status",
			hidden: true,
		},
	];

	const expandRow = {
		renderer: (row: any) => (
			<div>
				<div className="buttonPadding">
					{buttonsFormatter(row.invoiceId, row, "")}
				</div>
				<table className="table-borderless" width="100%">
					<tbody>
						<tr>
							<td>
								<div className="bold">Status</div>
							</td>
							<td>
								<InvoiceStatus status={row.statusDisplay} />
							</td>
						</tr>
						<tr>
							<td>
								<div className="bold">Amount</div>
							</td>
							<td>${row.requestedPayment.toFixed(2)}</td>
						</tr>
						{row.submittedDate && (
							<tr>
								<td>
									<div className="bold">Date Submitted</div>
								</td>
								<td>{convertDateToDisplayFormat(row.submittedDate)}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		),
		showExpandColumn: true,
	};

	function confirmDeleteDraft(row: any) {
		setDeleteRow({
			invoiceId: row.invoiceId,
			vehiclePlate: row.vehiclePlate,
			towerReference: row.towerReference,
		});
		setModalShow(true);
	}

	function viewInvoice(row: any) {
		history.push("/Invoices/TowerInvoiceSummary/" + row.invoiceId);
	}

	function editInvoice(row: any) {
		history.push("/Invoices/SubmitInvoice/" + row.invoiceId);
	}

	function subsequentInvoice(row: any) {
		history.push("/Invoices/SubmitSubsequentInvoice/" + row.claimNumber);
	}

	const handleDeleteConfirmation = async () => {
		var result = await deleteDraftInvoiceByInvoiceId(deleteRow.invoiceId);
		if (result && result.data && result.status < 300) {
			var newInvoiceList = invoiceList.filter(
				(i) => i.invoiceId !== deleteRow.invoiceId
			);
			setInvoiceList([...newInvoiceList]);
			setSuccessMessages(["Invoice deleted successfully."]);
		} else {
			setFailureMessages([
				"Error when deleting invoice. Please try again later.",
			]);
		}
	};

	function getInvoiceIdentifier() {
		if (deleteRow.towerReference !== "")
			return "Invoice Number: " + deleteRow.towerReference;

		if (deleteRow.vehiclePlate !== "")
			return "Plate: " + deleteRow.vehiclePlate;

		return "Invoice ID: " + deleteRow.invoiceId.substr(0, 8);
	}

	function routeToUrl(routeUrl: any) {
		if (!reduxConnectionStatus.connected) {
			setSubmitErrorMessages(["Internet Connection Required"]);
		}

		if (reduxConnectionStatus.connected) {
			history.push(routeUrl);
		}
	}

	return (
		<>
			<ToastSuccess
				title="Invoice Deleted"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<ToastErrors
				title="Error Deleting Invoice"
				setMessages={setFailureMessages}
				messages={failureMessages}
			/>
			<ModalYesNoConfirmation
				title="Delete Invoice"
				body={
					"Are you sure you want to delete this invoice? \n\n" +
					getInvoiceIdentifier()
				}
				yesButtonText="Yes"
				onYesClick={handleDeleteConfirmation}
				noButtonText="No"
				onNoClick={() => setModalShow(false)}
				show={modalShow}
				onHide={() => setModalShow(false)}
			/>
			<Container className="CustomTableContainer">
				{screenSize <= ScreenSize.md &&
					(permissions.canSaveInvoice || permissions.canSubmitInvoice) && (
						<div className="createButtonDiv">
							<Button
								data-testid="CreateButton"
								variant="outline-primary"
								onClick={() => {
									routeToUrl("/Invoices/SubmitInvoice");
								}}
							>
								Create
							</Button>
						</div>
					)}
				<div className="tableHeader">Invoice List</div>

				<CustomTable
					noDataIndication={noDataIndication}
					defaultSorted={defaultSorted}
					expandRow={expandRow}
					columns={columns}
					data={invoiceList}
					setData={setInvoiceList}
					defaultData={defaultInvoiceList}
					dataGetter={getInvoicesByVendor}
					dataMapper={mapToIInvoiceList}
					rowClasses={rowClasses}
					keyField={"invoiceId"}
				/>
			</Container>
		</>
	);
}
