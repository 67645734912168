import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import {
  LineType,
  getUnits,
  iLineItem,
  getRatingDescription,
  getUnitsForSpecialLineItemsSelection,
  LineItemsWithDecimalValues,
} from "../../types/LineItems";
import {
  currencyFormat,
  percentageFormat,
  useWindowDimensions,
  ScreenSize,
  displayRate,
} from "../../utils/Utils";
import "./SpecialItem.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CurrencyInput from "react-currency-input-field";

export function SpecialItem(props: any) {
  const { screenSize } = useWindowDimensions();

  const popover = (
    <Popover id={props.item.name}>
      <Popover.Content>{props.item.description}</Popover.Content>
    </Popover>
  );

  const displayFlatRate = "Flat Rate: " + displayRate(props.item.rate.baseRate);

  const displayPerDayWithMax = props.selectedLineItems.filter(
    (l: iLineItem) => l.id === props.item.id
  ).length > 0 && (
    <div className="inputQuantity">
      <Form.Label className="dateUnitRate">
        {props.item.qty} {getUnits(props.item.lineType)} @{" "}
        {displayRate(props.item.rate.baseRate)}
        {getUnitsForSpecialLineItemsSelection(props.item.lineType)}{" "}
        {props.item.lineTotal === props.item.rate.maxCharge &&
          "Maximum applied"}
      </Form.Label>
      {getRatingDescription(props.item.lineType) !== "" &&
        props.item.lineTotal > props.item.rate.maxCharge && (
          <Form.Label className="lineItemLabel">
            {getRatingDescription(props.item.lineType)}
          </Form.Label>
        )}
    </div>
  );

  const displayDateRangePicker = (
    <Form.Group>
      <DateRangePicker
        className="lineItemUnitDate"
        placeholder=""
        minDate={new Date("2020/01/01")}
        data-testid={props.item.name + props.item.id + "DateRange"}
        id={props.item.id}
        onChange={(e: any) => {
          props.updateDate(e, props.item.name, props.item.id);
        }}
        name={props.item.name + "DateRange"}
        calendarAriaLabel={props.item.name + props.item.id + "DateRange"}
        clearAriaLabel={props.item.name + props.item.id + "ClearDates"}
        data-lineitemname={props.item.name + props.item.id}
        value={[
          getDateFromDateValue(props.item.id),
          getDateToDateValue(props.item.id),
        ]}
      />
    </Form.Group>
  );

  const displayDateRangePickerMobile = (
    <>
      <Form.Group>
        <DateRangePicker
          className="lineItemUnitDate"
          placeholder=""
          minDate={new Date("2020/01/01")}
          data-testid={props.item.name + props.item.id + "dateRange"}
          id={props.item.id}
          onChange={(e: any) => {
            props.updateDate(e, props.item.name, props.item.id);
          }}
          name="dateRange"
          data-lineitemname={props.item.name + props.item.id}
          value={[
            getDateFromDateValue(props.item.id),
            getDateToDateValue(props.item.id),
          ]}
        />
      </Form.Group>
    </>
  );

  const displayPerAmount = (
    <div className="inputQuantity">
      <Form.Control
        type="text"
        className="lineItemUnit"
        data-testid={props.item.name + props.item.id + "Unit"}
        id={props.item.id}
        onChange={props.updateLineQuantity}
        name={props.item.name}
        value={props.item.qty}
      />
      <Form.Label className="lineItemLabel">
        {getUnits(props.item.lineType)}
      </Form.Label>
      <Form.Label>
        @ {percentageFormat(props.item.rate.baseRate)}{" "}
        {getRatingDescription(props.item.lineType)}
      </Form.Label>
    </div>
  );

  const displayPerUnit = (
    <div className="inputQuantity">
      {
        LineItemsWithDecimalValues.includes(props.item.name) &&
        <div className="lineItemCounterButton"
          title="Decrement value by 0.50"
          data-testid={props.item.name + props.item.id + "DecrementCounter"}
          onClick={() => props.updateLineItemDecimalValue(props.item, false)}>
          <FontAwesomeIcon
            icon={faMinusCircle}
          />
        </div>
      }
      <Form.Control
        type="text"
        className="lineItemUnit"
        data-testid={props.item.name + props.item.id + "Unit"}
        id={props.item.id}
        onChange={props.updateLineQuantity}
        name={props.item.name}
        value={props.item.qty}
      />
      {
        LineItemsWithDecimalValues.includes(props.item.name) &&
        <div className="lineItemCounterButton"
          title="Increment value by 0.50"
          data-testid={props.item.name + props.item.id + "IncrementCounter"}
          onClick={() => props.updateLineItemDecimalValue(props.item, true)}>
          <FontAwesomeIcon
            icon={faPlusCircle}
          />
        </div>
      }
      <Form.Label className="lineItemLabel">
        {getUnits(props.item.lineType)}{" "}
        {props.item.lineType !== LineType.Other && (
          <>@ {displayRate(props.item.rate.baseRate)}</>
        )}
      </Form.Label>
      <Form.Label className="lineItemLabel">
        <>{getUnitsForSpecialLineItemsSelection(props.item.lineType)}</>
        {getRatingDescription(props.item.lineType)}
      </Form.Label>
    </div>
  );

  const displayProratedMinutes = (
    <div className="inputQuantity">
      <Form.Control
        type="text"
        className="lineItemUnit"
        data-testid={props.item.name + props.item.id + "Unit"}
        id={props.item.id}
        onChange={props.updateLineQuantity}
        name={props.item.name}
        value={props.item.qty}
      />
      <Form.Label className="lineItemLabel">
        {getUnits(props.item.lineType)}{" "}
        {props.item.lineType !== LineType.Other && (
          <>@ {displayRate(props.item.rate.baseRate)}</>
        )}
      </Form.Label>
      <Form.Label className="lineItemLabel">
        /Hr
        {getRatingDescription(props.item.lineType)}
      </Form.Label>
    </div>
  );

  const displayOtherDescription = (
    <>
      <Form.Control
        as="textarea"
        rows={3}
        placeholder="Please add Description for Other items"
        className={screenSize < ScreenSize.md ? "lineItemOtherDescription" : ""}
        data-testid={props.item.name + props.item.id + "OtherDescription"}
        id={props.item.id}
        onChange={(e) => {
          handleOthersDescription(e);
        }}
        name={props.item.name}
        value={props.item.lineData.otherDescription}
      />
      {screenSize < ScreenSize.md ? <br /> : ""}
    </>
  );

  const displayOtherAmount = (
    <div className="OtherLineItemUnit">
      <div className="OtherTotalDollarSpan">
        {screenSize < ScreenSize.md ? "Total: " : ""}$&nbsp;
        <CurrencyInput
          type="text"
          className={`OtherLineItemTotal`}
          id={props.item.id}
          data-testid={props.item.name + props.item.id + "Total"}
          allowDecimals={true}
          onValueChange={(e: any) => handleOtherLineTotal(e, props.item.id)}
          maxLength={8}
          size={8}
          decimalScale={2}
          name={props.item.name}
          value={props.item.qty}
        />
      </div>
    </div>
  );

  function getDateFromDateValue(lineId: string): any {
    return props.selectedLineItems.filter((l: iLineItem) => l.id === lineId)
      .length > 0 &&
      props.selectedLineItems.filter((obj: iLineItem) => obj.id === lineId)[0]
        .lineData.fromDate
      ? new Date(
          props.selectedLineItems.filter(
            (obj: iLineItem) => obj.id === lineId
          )[0].lineData.fromDate
        )
      : null;
  }

  function getDateToDateValue(lineId: string): any {
    return props.selectedLineItems.filter((l: iLineItem) => l.id === lineId)
      .length > 0 &&
      props.selectedLineItems.filter((obj: iLineItem) => obj.id === lineId)[0]
        .lineData.toDate
      ? new Date(
          props.selectedLineItems.filter(
            (obj: iLineItem) => obj.id === lineId
          )[0].lineData.toDate
        )
      : null;
  }

  function handleOthersDescription(event: any) {
    if (event.target.value.length <= 150 && event.target.value !== undefined) {
      props.updateOtherDescription(event);
    }
  }
  function handleOtherLineTotal(event: any, item: any): any {
    if (event === "" || event === undefined || event === null) {
      props.updateLineTotal("", item);
    } else props.updateLineTotal(event, item);
  }

  return (
    <>
      {screenSize >= ScreenSize.md ? (
        <>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popover}
            transition={false}
            delay={{ show: 500, hide: 0 }}
          >
            {({ ref, ...triggerHandler }) => (
              <td {...triggerHandler} ref={ref}>
                {props.item.title}🛈
              </td>
            )}
          </OverlayTrigger>
          <td>
            {props.item.lineType === LineType.PerDayFromToDatesWithMax &&
              displayPerDayWithMax}

            {props.item.lineType !== LineType.PerDayFromToDatesWithMax &&
              props.item.lineType !== LineType.FlatRate &&
              props.item.lineType !== LineType.Other &&
              props.item.lineType !== LineType.PerAmount &&
              props.item.lineType !== LineType.ProRatedMinutes &&
              props.item.lineType !== LineType.ProRatedIntervalMinutes &&
              displayPerUnit}

            {props.item.lineType === LineType.FlatRate && displayFlatRate}
            {props.item.lineType === LineType.PerAmount && displayPerAmount}
            {props.item.lineType === LineType.Other && displayOtherDescription}
            {props.item.lineType === LineType.ProRatedMinutes &&
              displayProratedMinutes}
            {props.item.lineType === LineType.ProRatedIntervalMinutes &&
              displayProratedMinutes}
            {props.item.lineType === LineType.PerDayFromToDatesWithMax &&
              displayDateRangePicker}
          </td>

          <td className="right">
            {props.item.lineType === LineType.Other && displayOtherAmount}

            {props.item.lineType !== LineType.Other && (
              <div data-testid={props.item.name + props.item.id + "Total"}>
                {currencyFormat(props.item.lineTotal)}
              </div>
            )}
          </td>
        </>
      ) : (
        //Small screen width
        <td>
          <table id="LineItemDetails" className="LineItemMobileTable">
            <tbody>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={popover}
                transition={false}
                delay={{ show: 500, hide: 0 }}
              >
                {({ ref, ...triggerHandler }) => (
                  <tr>
                    <td {...triggerHandler} ref={ref}>
                      {props.item.title}🛈
                    </td>
                  </tr>
                )}
              </OverlayTrigger>
              <tr>
                <td>
                  {props.item.lineType === LineType.PerDayFromToDatesWithMax &&
                    displayPerDayWithMax}

                  {props.item.lineType !== LineType.PerDayFromToDatesWithMax &&
                    props.item.lineType !== LineType.PerAmount &&
                    props.item.lineType !== LineType.FlatRate &&
                    props.item.lineType !== LineType.Other &&
                    props.item.lineType !== LineType.ProRatedMinutes &&
                    props.item.lineType !== LineType.ProRatedIntervalMinutes &&
                    displayPerUnit}

                  {props.item.lineType === LineType.PerAmount &&
                    displayPerAmount}
                  {props.item.lineType === LineType.ProRatedMinutes &&
                    displayProratedMinutes}
                  {props.item.lineType === LineType.ProRatedIntervalMinutes &&
                    displayProratedMinutes}
                  {props.item.lineType === LineType.Other &&
                    displayOtherDescription}

                  {props.item.lineType === LineType.PerDayFromToDatesWithMax &&
                    displayDateRangePickerMobile}
                </td>
              </tr>

              <tr>
                <td>
                  {props.item.lineType === LineType.Other && displayOtherAmount}

                  {props.item.lineType !== LineType.Other && (
                    <div
                      className="lineItemTotalMobile"
                      data-testid={props.item.name + props.item.id + "Total"}
                    >
                      {props.item.lineType === LineType.FlatRate &&
                        "Flat Rate: " + displayRate(props.item.lineTotal)}
                      {props.item.lineType !== LineType.FlatRate &&
                        "Total: " + displayRate(props.item.lineTotal)}

                      {props.item.lineTotal === props.item.rate.maxCharge &&
                        (props.item.lineType ===
                          LineType.PerDayFromToDatesWithMax ||
                          props.item.lineType === LineType.PerAmount) &&
                        "  (Maximum applied)"}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      )}
    </>
  );
}
