import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import "./Header.css";
import { useHistory } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTypedSelector } from "../../redux/store";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import { ToastErrors } from "../Toast/ToastErrors";
import { GetUserPermissions } from "../../utils/auth/authUtilities";

export function HeaderNavLinks() {
  const history = useHistory();
  const flags = useFlags();
  const { screenSize } = useWindowDimensions();

  const emptyStringArray: string[] = [];
  const [submitErrorMessages, setSubmitErrorMessages] =
    useState(emptyStringArray);

  const reduxConnectionStatus = useTypedSelector(
    (state) => state.connectionStatus
  );

  const permissions = GetUserPermissions();
  function routeToUrl(routeUrl: any) {
    if (!reduxConnectionStatus.connected) {
      setSubmitErrorMessages(["Internet Connection Required"]);
    }

    if (reduxConnectionStatus.connected) {
      history.push(routeUrl);
    }
  }

  var navLinkClass = screenSize >= ScreenSize.md ? "" : "mr-auto";
  return (
    <>
      <ToastErrors
        title="Error Message"
        setMessages={setSubmitErrorMessages}
        messages={submitErrorMessages}
      />

      {permissions?.canViewOwnInvoice && !permissions?.canViewAllInvoices && (
        <>
          <Nav.Link
            eventKey="2"
            className={navLinkClass}
            data-testid="VendorInvoiceListOption"
            onClick={() => {
              routeToUrl("/Invoices/List");
            }}
          >
            Invoices
          </Nav.Link>
        </>
      )}
      {permissions?.canViewInventory && (
        <Nav.Link
          eventKey="3"
          className={navLinkClass}
          data-testid="VendorInventoryListOption"
          onClick={() => {
            routeToUrl("/Invoices/TowerInventoryList");
          }}
        >
          Inventory
        </Nav.Link>
      )}
      {permissions?.canViewDashboard && (
        <Nav.Link
          eventKey="4"
          className={navLinkClass}
          data-testid="VendorDashboardOption"
          onClick={() => {
            routeToUrl("/Invoices/Dashboard");
          }}
        >
          Dashboard
        </Nav.Link>
      )}
      {permissions?.canViewBulletin && (
        <Nav.Link
          eventKey="13"
          className={navLinkClass}
          data-testid="BulletinListOption"
          onClick={() => {
            routeToUrl("/Bulletins");
          }}
        >
          Bulletins
        </Nav.Link>
      )}
      {permissions?.canSaveInvoice && (
        <Nav.Link
          eventKey="1"
          data-testid="VendorSubmitInvoiceOption"
          className={navLinkClass}
          onClick={() => {
            routeToUrl("/Invoices/SubmitInvoice");
          }}
        >
          <Button className="createButton" variant="outline-primary" size="sm">
            Create
          </Button>
        </Nav.Link>
      )}

      {permissions?.canViewAllInvoices && (
        <>
          <Nav.Link
            eventKey="5"
            className={navLinkClass}
            data-testid="EmployeeInvoiceListOption"
            onClick={() => {
              routeToUrl("/Employee/Invoices/List");
            }}
          >
            Invoices
          </Nav.Link>
        </>
      )}

      {permissions?.canViewHistoryList && (
        <Nav.Link
          eventKey="6"
          className={navLinkClass}
          data-testid="EmployeeInvoiceHistoryOption"
          onClick={() => {
            routeToUrl("/Employee/Invoices/History");
          }}
        >
          History
        </Nav.Link>
      )}

      {permissions?.canViewRateList && (
        <Nav.Link
          eventKey="10"
          className={navLinkClass}
          data-testid="EmployeeHitoricalRatesOption"
          onClick={() => {
            routeToUrl("/Employee/Rates/History");
          }}
        >
          Rate List
        </Nav.Link>
      )}

      {permissions?.canViewStorageList && (
        <Nav.Link
          eventKey="11"
          className={navLinkClass}
          data-testid="EmployeeStorageListOptions"
          onClick={() => {
            routeToUrl("/Employee/StorageList");
          }}
        >
          Storage List
        </Nav.Link>
      )}

      {permissions?.canViewVendorList &&
        flags.vendorPaymentUiEnableVendorListOption && (
          <Nav.Link
            eventKey="8"
            className={navLinkClass}
            data-testid="VendorListOption"
            onClick={() => {
              routeToUrl("/Employee/VendorList");
            }}
          >
            Vendor List
          </Nav.Link>
        )}

      {permissions?.canViewManageTransfer &&
        flags.enableTowappGisAttachmentProcessing && (
          <Nav.Link
            eventKey="9"
            className={navLinkClass}
            data-testid="ManageLegacyTransferOption"
            onClick={() => {
              routeToUrl("/Employee/ManageLegacyTransfer");
            }}
          >
            Manage Transfer
          </Nav.Link>
        )}

      {permissions?.canViewFeedback && (
        <Nav.Link
          eventKey="7"
          className={navLinkClass}
          data-testid="EmployeeFeedbackListOption"
          onClick={() => {
            routeToUrl("/Employee/FeedbackList");
          }}
        >
          Feedback
        </Nav.Link>
      )}
      {permissions?.canManageBulletin && (
        <Nav.Link
          eventKey="12"
          className={navLinkClass}
          data-testid="BulletinManageOption"
          onClick={() => {
            routeToUrl("/Employee/BulletinList");
          }}
        >
          Bulletin List
        </Nav.Link>
      )}
      <div className={screenSize >= ScreenSize.md ? "mr-auto" : navLinkClass}/>
    </>
  );
}
