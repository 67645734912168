import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BarcodeScanner } from "../../ScanditScanner/BarcodeScanner";
import { ScreenSize, useWindowDimensions } from "../../../utils/Utils";

interface ModalProps {
  show: any;
  onHide: any;
  scannerData: string;
  setScannerData: React.Dispatch<React.SetStateAction<string>>;
}

export function ModalBarcodeScanner(props: ModalProps) {
  const [errMessage, setErrMessage] = useState("");
  const { screenSize } = useWindowDimensions();

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      className={screenSize <= ScreenSize.md ? "fullScreenModal" : ""}
    >
      <Modal.Header closeButton>Scan the VIN Barcode</Modal.Header>
      <Modal.Body>
        <BarcodeScanner
          setScannerData={props.setScannerData}
          setErrorMessage={setErrMessage}
        />
        {errMessage && <p>{errMessage}</p>}
      </Modal.Body>
    </Modal>
  );
}
