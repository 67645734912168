import React from "react";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import "./Maps.css";
import {
	Address,
	getDisplayAddress,
	defaultNewLocation,
} from "../../types/Location";
import {
	validateLocation,
	validateLocationResult,
} from "../../utils/Validators/LocationValidator";
import { nullOrString } from "../../utils/Utils";
import { appInsights } from "../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { SGI_PICK_UP } from "../../types/Invoice";
export function Search(props: any) {
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			location: new google.maps.LatLng(53.50774486995124, -105.95601868417705),
			radius: 750 * 1000,
		},
	});

	const handleInput = (e: any) => {
		setValue(e.target.value);

		props.setInvoiceDetails({
			...props.invoiceDetails,
			locations: {
				...props.invoiceDetails.locations,
				[props.name]: {
					...defaultNewLocation,
				},
			},
		});
	};

	const handleBlur = () => {
		const ignoreLocationValidation =
			props.name === "towedTo" &&
			props.invoiceDetails.reasonForTow === SGI_PICK_UP;
		if (props.errorMessage === "") {
			props.setErrorMessage(
				validateLocation(
					props.invoiceDetails.locations[props.name],
					ignoreLocationValidation
				)
			);
		}
	};

	const handleSelect = async (address: any) => {
		setValue(address, false);
		clearSuggestions();

		props.setErrorMessage("");

		var results = null;
		var latitude = 0;
		var longitude = 0;
		try {
			results = await getGeocode({ address });
			const { lat, lng } = await getLatLng(results[0]);
			latitude = lat;
			longitude = lng;
		} catch (error) {
			if (appInsights !== undefined) {
				appInsights.trackTrace({
					message:
						"Map Search - error retrieving location information: " + error,
					severityLevel: SeverityLevel.Error,
				});
			}
			console.log("Map Search - error retrieving location information", error);
		}
		if (results) {
			var addrComp = results[0].address_components;
			props.setAreaBounds({
				northEast: results[0].geometry.viewport.getNorthEast().toJSON(),
				southWest: results[0].geometry.viewport.getSouthWest().toJSON(),
			});
			var addressData: Address = parseAddressComponents(addrComp);
			props.setInvoiceDetails({
				...props.invoiceDetails,
				locations: {
					...props.invoiceDetails.locations,
					[props.name]: {
						address: { ...addressData },
						formattedAddressFull: results[0].formatted_address,
						locationTypes: results[0].types,
						placeId: results[0].place_id,
						latitude: latitude,
						longitude: longitude,
						isSelectedByPin: false,
						specificLocation:
							props.invoiceDetails.locations[props.name].specificLocation,
					},
				},
			});
			props.setPrecisePosition(true);
			if (validateLocationResult(results) !== "") {
				props.setErrorMessage(validateLocationResult(results));
				props.setPrecisePosition(false);
			}
		} else {
			props.setErrorMessage(
				"Error retrieving location data, please enter a new location"
			);
		}
	};

	return (
		<div className="search">
			<Combobox onSelect={handleSelect}>
				<ComboboxInput
					value={
						props.invoiceDetails.locations[props.name].formattedAddressFull !==
						""
							? getDisplayAddress(props.invoiceDetails.locations[props.name])
							: value
					}
					onChange={handleInput}
					onBlur={handleBlur}
					disabled={!ready}
					placeholder={
						props.invoiceDetails.locations[props.name].formattedAddressFull !==
						""
							? getDisplayAddress(props.invoiceDetails.locations[props.name])
							: "Search for your location (or nearest)"
					}
					className="form-control"
					data-testid={"formEntryT" + props.name.slice(1)} //formEntryTowedFrom or ...TowedTo
				/>

				<ComboboxPopover>
					<ComboboxList>
						{status === "OK" &&
							data.map(({ place_id, description }) => (
								<ComboboxOption key={place_id} value={description} />
							))}
					</ComboboxList>
				</ComboboxPopover>
			</Combobox>
		</div>
	);
}
export default Search;

export function parseAddressComponents(
	addrComp: google.maps.GeocoderAddressComponent[]
): Address {
	return {
		streetNumber: nullOrString(
			addrComp.find((s) => s.types.includes("street_number"))?.long_name
		),
		street: nullOrString(
			addrComp.find((s) => s.types.includes("route"))?.long_name
		),
		city: nullOrString(
			addrComp.find((s) => s.types.includes("locality"))?.long_name
		),
		province: nullOrString(
			addrComp.find((s) => s.types.includes("administrative_area_level_1"))
				?.short_name
		),
		country: nullOrString(
			addrComp.find((s) => s.types.includes("country"))?.long_name
		),
		postalCode: nullOrString(
			addrComp.find((s) => s.types.includes("postal_code"))?.long_name
		),
	};
}
