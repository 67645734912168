import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { LogToAppInsights } from "./Utils";

export function CalcPerDayFromToDatesWithMax(rate: any, qty: number): number {
  try {
    let result = rate.baseRate * qty;
    if (result > rate.maxCharge) return rate.maxCharge;
    else {
      if (isNaN(result)) {
        throw new Error("err");
      }
      return result;
    }
  } catch (err: any) {
    LogToAppInsights(
      "Error Calculating Rate: " + err.toString(),
      SeverityLevel.Error
    );
    return 0;
  }
}
export function CalcPerAmount(rate: any, qty: number): number {
  try {
    var result = parseFloat((rate.baseRate * qty).toFixed(2));
    if (result > rate.maxCharge) return rate.maxCharge;
    else {
      if (isNaN(result)) {
        throw new Error("err");
      }
      return result;
    }
  } catch (err: any) {
    LogToAppInsights(
      "Error Calculating PerAmount Rate: " + err.toString(),
      SeverityLevel.Error
    );
    return 0;
  }
}
export function CalcProRatedMinutes(rate: any, qty: number): number {
  try {
    let increments = Math.ceil(qty / rate.increment);
    let ratePerIncrement = rate.baseRate / (60 / rate.increment);
    let result = increments * ratePerIncrement;

    if (result < rate.minCharge) return rate.minCharge;
    if (result > rate.maxCharge) return rate.maxCharge;
    else {
      if (isNaN(result)) {
        throw new Error("err");
      }
      return result;
    }
  } catch (err: any) {
    LogToAppInsights(
      "Error Calculating Rate: " + err.toString(),
      SeverityLevel.Error
    );
    return 0;
  }
}

export function CalcProRatedIntervalMinutes(rate: any, qty: number): number {
  try {
    for (let i of rate.intervals) {
      if (qty >= i.start && qty <= i.end) {
        return i.charge;
      }
    }
    let increments = Math.ceil(qty / rate.increment);
    let ratePerIncrement = rate.baseRate / (60 / rate.increment);
    let result = increments * ratePerIncrement;
    if (isNaN(result)) {
      throw new Error("err");
    }
    return result;
  } catch (err: any) {
    LogToAppInsights(
      "Error Calculating Rate: " + err.toString(),
      SeverityLevel.Error
    );
    return 0;
  }
}
