import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTypedSelector } from "../../redux/store";
import "./TowerInventoryList.css";
import { SortOrder } from "react-bootstrap-table-next";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import { getVehicleInventoriesByVendor } from "../../api/apiInvoice";
import { defaultTowerInventoryList } from "../../types/TowerInventoryList";
import { mapToITowerInventoryList } from "../../utils/TowerInventoryListTransformer";
import { ModalInventoryRemove } from "../../components/Modals/ModalInventoryRemove";
import { TowerInventoryListDisplay } from "./TowerInventoryListDisplay";
import { ToastSuccess } from "../../components/Toast/ToastSuccess";
import CustomTable from "../../components/CustomTable/CustomTable";
import { getLocationObject } from "../../types/Location";
import TableButton from "../../components/CustomTable/TableButton";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

export default function TowerInventoryList() {
	const login = useTypedSelector((state) => state.login);

	const [modalShow, setModalShow] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [inventoryList, setInventoryList] = useState(defaultTowerInventoryList);

	const [removeRow, setRemoveRow] = useState<any>({});

	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const { screenSize } = useWindowDimensions();

	useEffect(() => {
		if (toastMessage !== "") {
			let success = [];
			success.push(toastMessage);
			setSuccessMessages([...success]);
			setToastMessage("");
		}
	}, [toastMessage]);

	function noDataIndication() {
		return "There are no vehicles to display.";
	}

	const defaultSorted: [{ dataField: any; order: SortOrder }] = [
		{
			dataField: "serviceDate",
			order: "asc",
		},
	];

	function vehicleDetailsFormatter(cell: string, row: any, rowIndex: any) {
		return (
			row.vehicleColour +
			" " +
			row.vehicleYear +
			" " +
			row.vehicleMake +
			" " +
			row.vehicleModel
		);
	}

	function serviceDateSorter(cell: string) {
		return new Date(cell).toISOString();
	}

	async function HandleRemoveFromInventory(row: any) {
		setRemoveRow(row);
		setModalShow(true);
	}

	const columns = [
		{
			dataField: "vehicleVIN",
			text: "VIN",
			sort: true,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "vehiclePlate",
			text: "Plate",
			sort: true,
		},

		{
			dataField: "vehicleDetails",
			text: "Vehicle Details",
			formatter: vehicleDetailsFormatter,
		},
		{
			dataField: "serviceDate",
			text: "Date of Tow",
			sort: true,
			sortValue: serviceDateSorter,
		},
		{
			dataField: "totalImpoundmentDays",
			text: "Days in Compound",
			sort: true,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "locations",
			text: "Stall/Lot",
			sort: true,
			hidden: screenSize < ScreenSize.md,
			formatter: locationsFormatter,
			sortValue: locationsSorter,
		},
		{
			dataField: "removeButton",
			text: "",
			formatter: buttonsFormatter,
			hidden: screenSize < ScreenSize.md,
		},
		{
			dataField: "vehicleColour",
			text: "Colour",
			hidden: true,
		},
		{
			dataField: "vehicleYear",
			text: "Year",
			hidden: true,
		},
		{
			dataField: "vehicleMake",
			text: "Make",
			hidden: true,
		},
		{
			dataField: "vehicleModel",
			text: "Model",
			hidden: true,
		},
	];
	function locationsFormatter(cell: any) {
		return cell["towedTo"].specificLocation;
	}
	function locationsSorter(cell: any) {
		return cell["towedTo"].specificLocation;
	}
	function buttonsFormatter(cell: string, row: any, rowIndex: any) {
		return (
			<div className="buttonCellWrapper">
				<TableButton
					icon={faTrashAlt}
					onClick={() => HandleRemoveFromInventory(row)}
					tooltipText="Remove"
					data-testid={row.invoiceId + "removeButton"}
					id={row.invoiceId}
					datatestid={row.invoiceId + "removeButton"}
				/>
			</div>
		);
	}
	const expandRow = {
		renderer: (row: any) => (
			<div>
				<div className="buttonPadding">
					{buttonsFormatter(row.invoiceId, row, "")}
				</div>
				<table className="table-borderless" width="100%">
					<tbody>
						{row.vehicleVIN && (
							<tr>
								<td>
									<div className="bold">VIN</div>
								</td>
								<td className="mobileInventoryVIN">{row.vehicleVIN}</td>
							</tr>
						)}
						{getLocationObject("towedTo", row.locations).specificLocation && (
							<tr>
								<td>
									<div className="bold">Stall/Lot</div>
								</td>
								<td className="mobileSpecificLocation">
									{getLocationObject("towedTo", row.locations).specificLocation}
								</td>
							</tr>
						)}
						<tr>
							<td>
								<div className="bold">Days in Compound</div>
							</td>
							<td>{row.totalImpoundmentDays}</td>
						</tr>
					</tbody>
				</table>
			</div>
		),
		showExpandColumn: true,
	};

	return (
		<>
			<ModalInventoryRemove
				show={modalShow}
				onHide={() => setModalShow(false)}
				invoiceId={removeRow.invoiceId}
				payeeNumber={removeRow.payeeNumber}
				vehiclePlate={removeRow.vehiclePlate}
				vin={removeRow.vehicleVIN}
				vehicleDetails={vehicleDetailsFormatter("", removeRow, "")}
				setInventoryList={setInventoryList}
				inventoryList={inventoryList}
				toastMessage={setToastMessage}
			/>
			<ToastSuccess
				title="Removed From Inventory"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>
			<Container className="CustomTableContainer">
				<div
					className="inventoryListHeading"
					data-testid="TowerInventoryListTitle"
				>
					<div className="h1InventoryList">Inventory List</div>
					<div>
						<p className="InventoryListAddress">{login.companyName}</p>
						<p className="InventoryListAddress">
							{login.compoundLocation.streetAddress}
						</p>
						<p className="InventoryListAddress">
							{login.compoundLocation.city}, {login.compoundLocation.province}{" "}
							{login.compoundLocation.postalCode}
						</p>
					</div>
				</div>
				<div>
					<TowerInventoryListDisplay />
				</div>
				<CustomTable
					noDataIndication={noDataIndication}
					defaultSorted={defaultSorted}
					expandRow={expandRow}
					columns={columns}
					data={inventoryList}
					setData={setInventoryList}
					defaultData={defaultTowerInventoryList}
					dataGetter={getVehicleInventoriesByVendor}
					dataMapper={mapToITowerInventoryList}
					keyField={"invoiceId"}
				/>
			</Container>
		</>
	);
}
