import { useState } from "react";
import { Route } from "react-router-dom";
import EmployeeFeedbackList from "../containers/EmployeeFeedbackList/EmployeeFeedbackList";
import EmployeeInvoiceHistory from "../containers/EmployeeInvoiceHistory/EmployeeInvoiceHistory";
import EmployeeInvoiceList from "../containers/EmployeeInvoiceList/EmployeeInvoiceList";
import { EmployeeInvoiceSummary } from "../containers/EmployeeInvoiceSummary/EmployeeInvoiceSummary";
import EmployeeRateHistory from "../containers/EmployeeRateHistory/EmployeeRateHistory";
import EmployeeStorageList from "../containers/EmployeeStorageList/EmployeeStorageList";
import { HomePage } from "../containers/HomePage/HomePage";
import { InvoiceDetails } from "../containers/InvoiceDetails/InvoiceDetails";
import ManageLegacyTransfer from "../containers/ManageLegacyTransfer/ManageLegacyTransfer";
import { SubmitInvoiceConfirmationPage } from "../containers/SubmitInvoiceConfirmationPage/SubmitInvoiceConfirmationPage";
import TowerDashboard from "../containers/TowerDashboard/TowerDashboard";
import TowerInventoryList from "../containers/TowerInventoryList/TowerInventoryList";
import { TowerInvoiceSummary } from "../containers/TowerInvoiceSummary/TowerInvoiceSummary";
import VendorDetails from "../containers/VendorDetails/VendorDetails";
import VendorInvoiceList from "../containers/VendorInvoiceList/VendorInvoiceList";
import VendorList from "../containers/VendorList/VendorList";
import { useTypedSelector } from "../redux/store";
import PrivateRoute from "./PrivateRoute";
import BulletinList from "../containers/BulletinList";
import PublicBulletinList from "../containers/BulletinList/PublicBulletinList";

function PrivateRoutes() {
  var returnRoutes: any[] = [];
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const reduxLogin = useTypedSelector((state) => state.login);
  returnRoutes.push(
    <Route exact path="/" key="root">
      <HomePage />
    </Route>
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/FeedbackList"
      component={EmployeeFeedbackList}
      key="FeedbackList"
      requiredPermissions={["canViewFeedback"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Invoices/List"
      component={EmployeeInvoiceList}
      key="EmployeeInvoiceList"
      requiredPermissions={["canViewAllInvoices"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/EmployeeInvoiceSummary/:invoiceId"
      component={EmployeeInvoiceSummary}
      key="EmployeeInvoiceSummary"
      requiredPermissions={["canViewAllInvoices"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Invoices/History"
      component={EmployeeInvoiceHistory}
      key="EmployeeInvoiceHistory"
      requiredPermissions={["canViewAllInvoices"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/Rates/History"
      component={EmployeeRateHistory}
      key="EmployeeRateHistory"
      requiredPermissions={["canViewRateList"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/StorageList"
      component={EmployeeStorageList}
      key="EmployeeStorageList"
      requiredPermissions={["canViewStorageList"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/SubmitInvoice"
      render={() => (
        <InvoiceDetails
          setConfirmationNumber={setConfirmationNumber}
          vendorId={reduxLogin.vendorId}
          reduxLogin={reduxLogin}
        />
      )}
      key="SubmitInvoice"
      requiredPermissions={["canSubmitInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/SubmitInvoiceConfirmationPage"
      render={() => (
        <SubmitInvoiceConfirmationPage
          confirmationNumber={confirmationNumber}
        />
      )}
      key="ConfirmationPage"
      requiredPermissions={["canSubmitInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/List"
      component={VendorInvoiceList}
      key="InvoiceList"
      requiredPermissions={["canViewOwnInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/TowerInventoryList"
      component={TowerInventoryList}
      key="TowerInventoryList"
      requiredPermissions={["canViewInventory"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/Dashboard"
      component={TowerDashboard}
      key="Dashboard"
      requiredPermissions={["canViewDashboard"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/TowerInvoiceSummary/:invoiceId"
      component={TowerInvoiceSummary}
      key="TowerInvoiceSummary"
      requiredPermissions={["canViewOwnInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/SubmitInvoice/:invoiceId"
      render={() => (
        <InvoiceDetails
          setConfirmationNumber={setConfirmationNumber}
          vendorId={reduxLogin.vendorId}
          reduxLogin={reduxLogin}
        />
      )}
      key="SubmitDraftInvoice"
      requiredPermissions={["canSaveInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Invoices/SubmitSubsequentInvoice/:claimNumber"
      render={() => (
        <InvoiceDetails
          setConfirmationNumber={setConfirmationNumber}
          vendorId={reduxLogin.vendorId}
          reduxLogin={reduxLogin}
        />
      )}
      key="SubmitSubsequentInvoice"
      requiredPermissions={["canSaveInvoice"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/VendorList"
      component={VendorList}
      key="VendorList"
      requiredPermissions={["canEditPayee"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/VendorDetails/:vendorId"
      component={VendorDetails}
      key="VendorDetails"
      requiredPermissions={["canEditPayee"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/ManageLegacyTransfer"
      component={ManageLegacyTransfer}
      key="ManageLegacyTransfer"
      requiredPermissions={["canViewManageTransfer"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Employee/BulletinList"
      component={BulletinList}
      key="BulletinList"
      requiredPermissions={["canManageBulletin"]}
      redirectOnUnAuthorized
    />
  );
  returnRoutes.push(
    <PrivateRoute
      exact
      path="/Bulletins"
      component={PublicBulletinList}
      key="Bulletins"
      requiredPermissions={["canViewBulletin"]}
      redirectOnUnAuthorized
    />
  );
  
  return returnRoutes;
}

export default PrivateRoutes;
