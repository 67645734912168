export interface Account {
  email: string;
  name: string;
  payeeNumber: string;
  companyName: string;
  compoundLocation: CompoundLocation;
}

export interface CompoundLocation {
  streetAddress: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;
  placeId: string | null;
  googleResults: any;
}

export const emptyCompoundLocation: CompoundLocation = {
  streetAddress: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  placeId: "",
  googleResults: {},
};
