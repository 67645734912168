import { ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { createStore } from "redux";
import { emptyCompoundLocation } from "../types/Account";
import { defaultUserPermissions } from "../types/UserPermissions";
import rootReducer from "./reducers";

const preloadedState = {
  login: {
    signedIn: false,
    username: "",
    vendorId: "",
    organizationId: "",
    loading: false,
    fullName: "",
    payeeNumber: "",
    email: "",
    companyName: "",
    compoundLocation: emptyCompoundLocation,
    authUserId: "",
  },
  redirect: {
    redirect: "",
  },
  connectionStatus: {
    connected: true,
  },
  userPermissions: defaultUserPermissions,
};

// const composeEnhancers =	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  preloadedState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
