export interface Locations {
	towedFrom: Location;
	towedTo: Location;
}

export interface Location {
	address: Address;
	formattedAddressFull: string;
	locationTypes: string[];
	placeId: string;
	latitude: number;
	longitude: number;
	isSelectedByPin: boolean;
	specificLocation: string | null;
}

export interface Address {
	streetNumber: string | null;
	street: string | null;
	city: string | null;
	province: string | null;
	country: string | null;
	postalCode: string | null;
}

export function getDisplayAddress(location: Location): string {
	if (
		location &&
		location.address.streetNumber &&
		location.address.street &&
		location.address.city
	) {
		return (
			location.address.streetNumber +
			" " +
			location.address.street +
			", " +
			location.address.city
		);
	} else if (location) return location.formattedAddressFull;
	else return "";
}

export const defaultNewAddress: Address = {
	streetNumber: null,
	street: null,
	city: null,
	province: null,
	country: null,
	postalCode: null,
};

export const defaultNewLocation: Location = {
	address: defaultNewAddress,
	formattedAddressFull: "",
	locationTypes: [""],
	placeId: "",
	latitude: 0,
	longitude: 0,
	isSelectedByPin: false,
	specificLocation: "",
};

export function getLocationObject(l: string, locs: any): Location {
	var address: Address = {
		streetNumber: locs[l].address?.streetNumber,
		street: locs[l].address?.street,
		city: locs[l].address?.city,
		province: locs[l].address?.province,
		country: locs[l].address?.country,
		postalCode: locs[l].address?.postalCode,
	};
	return {
		address: address,
		formattedAddressFull: locs[l].formattedAddressFull,
		locationTypes: locs[l].locationTypes,
		placeId: locs[l].placeId,
		latitude: locs[l].latitude,
		longitude: locs[l].longitude,
		isSelectedByPin: locs[l].isSelectedByPin,
		specificLocation: locs[l].specificLocation,
	};
}
