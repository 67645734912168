import React, { useState } from "react";
import { Toast } from "react-bootstrap";
import "./Toast.css";

export function ToastErrors(props: any) {
  return (
    <div
      style={{
        position: "fixed",
        top: 10,
        right: 10,
        left: 10,
      }}
      className="ToastContainer"
    >
      {props.messages.map((body: string, index: number) => {
        return (
          <ToastItem
            key={index + body}
            body={body}
            setMessages={props.setMessages}
            messages={props.messages}
            title={props.title}
          >
            <Toast.Header className="ToastErrorHeader">
              <strong className="mr-auto">Error</strong>
              <small>{props.title}</small>
            </Toast.Header>
            <Toast.Body>{body}</Toast.Body>
          </ToastItem>
        );
      })}
    </div>
  );
}

function ToastItem(props: any) {
  const [showToast, setShowToast] = useState(true);

  const onClose = () => {
    setShowToast(false);
    props.setMessages([...props.messages.filter((s: any) => s !== props.body)]);
  };

  return (
    <Toast
      show={showToast}
      onClose={onClose}
      delay={10000}
      autohide
      id="ToastError"
      data-testid="ToastError"
      animation={false}
    >
      <Toast.Header className="ToastErrorHeader">
        <strong className="mr-auto">Error</strong>
        <small>{props.title}</small>
      </Toast.Header>
      <Toast.Body>{props.body}</Toast.Body>
    </Toast>
  );
}
