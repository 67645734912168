export enum FeedbackType {
  GeneralFeedback = "General Feedback",
  Bug = "Report an Issue",
}

export interface iFeedback {
  content: string;
  email: string;
  feedbackId: string;
  feedbackType: FeedbackType;
  name: string;
  submittedDate: string;
}
