import React from "react";
import { Alert } from "react-bootstrap";
import "./ReasonForDeletionDisplay.css";
export function ReasonForDeletionDisplay(props: any) {
  return (
    <div className="reasonForDeletionDisplayContainer">
      <Alert
        variant="info"
        className="reasonForDeletionDisplayAlert"
        data-testid="reasonForDeletionDisplayAlertTest"
      >
        <p>
          {" "}
          <span className="reasonForDeletionTitle">Reason For Deletion :</span>
          {props.reasonForDeletion !== "Other" ? (
            <span className="reasonForDeletion">
              &nbsp;{props.reasonForDeletion}
            </span>
          ) : (
            <span className="reasonForDeletion">
              &nbsp;{props.deletionReasonDetails}
            </span>
          )}
        </p>
      </Alert>
    </div>
  );
}
