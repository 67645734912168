const zoomLevels = [
  1128.49722, 2256.99444, 4513.98888, 9027.977761, 18055.95552, 36111.91104,
  72223.82209, 144447.6442, 288895.2884, 577790.5767, 1155581.153, 2311162.307,
  4622324.614, 9244649.227, 18489298.45, 36978596.91, 73957193.82, 147914387.6,
  295828775.3, 591657550.5,
];

export function getMapsZoomLevel(km: number) {
  let meters = km * 1000;
  for (var i = 0; i < 20; i++) {
    if (meters < zoomLevels[i]) {
      return 16 - (i + 1);
    }
  }
  return 6;
}
