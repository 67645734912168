import { defaultUserPermissions } from "../../types/UserPermissions";

const permissionsReducer = (
	state = defaultUserPermissions,
	action: { type: any; userPermissions?: any }
) => {
	let newState: any = { ...state };
	switch (action.type) {
		case "SET_USER_PERMISSIONS": {
			newState = action.userPermissions;
			return newState;
		}
		case "CLEAR_USER_PERMISSIONS": {
			return defaultUserPermissions;
		}
		default:
			return state;
	}
};

export default permissionsReducer;
