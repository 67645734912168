import { Button, Modal } from "react-bootstrap";
interface ModalProps {
  show: any;
  onHide: any;
  setToastMessage: any;
  changeFromDate: any;
  changeToDate: any;
  setCurrentDate: any;
  handleDateChangeConfirmed: any;
  handleDateChangeDenied: any;
}
export function ModalConfirmTowDateChange(props: ModalProps) {
  return (
    <Modal
      data-testid="dateChange"
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="RemoveInventory"
        >
          <h4>Confirm Date of Tow Service Change</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="RemoveInventoryBody">
          Are you sure you want to change the Tow Service Date on this invoice to{" "}
          {props.changeToDate}?
        </div>
        <p />
        <div className="RemoveInventoryBodyBold">
          Existing Line Items will be removed!
        </div>
      </Modal.Body>

      <Modal.Footer className="left">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={HandleCancel}
          data-testid="noButton"
        >
          No
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={HandleConfirm}
          data-testid="yesButton"
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function HandleCancel(event: any) {
    props.onHide(false);
    props.handleDateChangeDenied();
  }

  async function HandleConfirm() {
    props.onHide(false);
    props.handleDateChangeConfirmed();
    props.setCurrentDate(props.changeToDate);
    props.setToastMessage("Line Items have been reset");
  }
}
