import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Photo } from "../../types/Photo";

export function ImageTableItemMobile(props: { item: Photo }) {
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    let isCancelled = false;
    let reader = new FileReader();
    let file = props.item.thumbnail;

    reader.onloadend = () => {
      if (reader.result && !isCancelled)
        setImagePreview(reader.result as string);
    };

    reader.readAsDataURL(file);
    return () => {
      isCancelled = true;
    };
  }, [props.item]);

  return (
    <>
      <td>
        <table id="imageTableMobile" className="table-borderless">
          <tbody>
            <tr>
              <td>{props.item.name}</td>
            </tr>
            <tr>
              <td>{Math.ceil(props.item.size / 1024)} KB</td>
            </tr>
            <tr>
              <td>{props.item.type.slice(6).toUpperCase()}</td>
            </tr>
          </tbody>
        </table>
      </td>
      <td>
        {imagePreview !== "" && (
          <div className="thumbnailWrapper">
            <Image
              src={imagePreview}
              className="thumbnailImage"
              rounded
              thumbnail
              data-testid="thumbnailImage"
            />
          </div>
        )}
      </td>
    </>
  );
}
