import "./InvoiceStatus.css";

export default function InvoiceStatus(props: any) {
  return (
    <div
      className={
        props.status?.toLowerCase()?.replace(/\s+/g, "") +
        "Cell center CompleteCell"
      }
    >
      {props.status}
    </div>
  );
}
