import { iTowerInventoryList } from "../types/TowerInventoryList";

export function mapToITowerInventoryList(apiData: any): iTowerInventoryList[] {
	var returnData: iTowerInventoryList[] = [];

	// map api data received
	apiData.forEach((item: any) => {
		var locations = JSON.parse(item.locations);
		var newReturnData: iTowerInventoryList = {
			vehiclePlate: item.vehiclePlate,
			vehicleVIN: item.vehicleVIN,
			serviceDate: item.serviceDate,
			vehicleColour: item.vehicleColour,
			vehicleYear: item.vehicleYear,
			vehicleMake: item.vehicleMake,
			vehicleModel: item.vehicleModel,
			invoiceId: item.invoiceId,
			removedFromInventory: item.removedFromLocation,
			payeeNumber: item.payeeNumber,
			locations: locations,
			totalImpoundmentDays: item.totalImpoundmentDays,
		};

		returnData.push(newReturnData);
	});

	return returnData;
}
