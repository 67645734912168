import { CompoundLocation } from "../../types/Account";
import { Location } from "../../types/Location";

export function validateLocation(
	loc: Location,
	ignoreValidation: boolean
): string {
	if (
		loc &&
		loc.formattedAddressFull !== "" &&
		validateLocationTypes(loc) === ""
	)
		return "";
	else if (loc.formattedAddressFull === "" && !ignoreValidation)
		return "Please enter a valid location";
	else if (
		validateLocationTypes(loc) !== "" &&
		!loc.isSelectedByPin &&
		!ignoreValidation
	)
		return validateLocationTypes(loc);
	else if (validateLocationTypes(loc) !== "" && loc.isSelectedByPin) return "";
	else return "";
}

export function validateCompoundLocation(
	compoundLocation: CompoundLocation
): string {
	if (
		compoundLocation &&
		compoundLocation.googleResults.formattedAddressFull !== "" &&
		validateLocationTypes(compoundLocation.googleResults) === ""
	)
		return "";
	/*  else if (
    compoundLocation.googleResults.formattedAddressFull === "" &&
    compoundLocation.placeId === ""
  )
    return ""; */ else if (
		compoundLocation.googleResults.formattedAddressFull === ""
	)
		return "Please enter a valid location";
	else if (
		validateLocationTypes(compoundLocation.googleResults) !== "" &&
		!compoundLocation.googleResults.isSelectedByPin
	)
		return validateLocationTypes(compoundLocation.googleResults);
	else if (
		validateLocationTypes(compoundLocation.googleResults) !== "" &&
		compoundLocation.googleResults.isSelectedByPin
	)
		return "";
	else return "";
}

const validTypesWhitelist = [
	"establishment",
	"street_address",
	"premise",
	"intersection",
	"route",
];
//locations must include at least one of the whitelisted types above

const validTypesBlacklist = ["continent", "locality", "country"];
//locations can't include any of the blacklisted types above

export function validateLocationResult(locRes: any): string {
	if (
		locRes[0] &&
		locRes[0].types.some((t: string) => validTypesWhitelist.includes(t)) &&
		!locRes[0].types.some((t: string) => validTypesBlacklist.includes(t))
	) {
		return "";
	} else {
		return "Imprecise location, choose a precise address or use the map to add a pin.";
	}
}

export function validateLocationTypes(location: any): string {
	if (
		location.locationTypes !== undefined &&
		location.locationTypes !== [""] &&
		location.locationTypes.some((t: string) =>
			validTypesWhitelist.includes(t)
		) &&
		!location.locationTypes.some((t: string) => validTypesBlacklist.includes(t))
	) {
		return "";
	} else {
		return "Imprecise location, choose a precise address or use the map to add a pin.";
	}
}
