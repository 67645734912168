import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getAllVendors() {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(baseUrl + "Vendor", {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getVendorByVendorId(vendorId: string) {
  const baseUrl = getBaseApiUrl();
  const token = await getAccessToken();

  return axios
    .get(baseUrl + "Vendor/" + vendorId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function createOrEditVendor(vendor: any) {
  var baseUrl = getBaseApiUrl() + "Vendor/CreateOrEditVendor/";

  const token = await getAccessToken();

  let data = new FormData();
  data.append("vendorDto", JSON.stringify(vendor));

  return axios
    .put(baseUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getVendorByOrganizationId(organizationId: string) {
  const baseUrl = getBaseApiUrl() + "Vendor/Org/";
  const token = await getAccessToken();

  return axios
    .get(baseUrl + organizationId, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
