import { Modal } from "react-bootstrap";
import CustomMap from "../Maps/Maps";
import { getDisplayAddress } from "../../types/Location";

interface ModalProps {
  show: any;
  onHide: any;
  heading: string;
  title: string;
  body: string;
  locationField: string;
  invoiceDetails: any;
  setInvoiceDetails: any;
  setErrorMessage: any;
  autoZoom: number;
  precisePosition: any;
  setPrecisePosition: any;
}

export function ModalMap(props: ModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomMap
          invoiceDetails={props.invoiceDetails}
          setInvoiceDetails={props.setInvoiceDetails}
          locationField={props.locationField}
          setErrorMessage={props.setErrorMessage}
          autoZoom={props.autoZoom}
          precisePosition={props.precisePosition}
          setPrecisePosition={props.setPrecisePosition}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="left">
          {getDisplayAddress(
            props.invoiceDetails.locations[props.locationField]
          ) === "" ? (
            <h5>Click on map to set location. </h5>
          ) : (
            <h5>
              {getDisplayAddress(
                props.invoiceDetails.locations[props.locationField]
              )}
            </h5>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
