import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { appInsights } from "../../AppInsights";
import { Annotation } from "../../types/Invoice";
import { Error } from "../Alerts/Error";
interface ModalProps {
  show: any;
  onHide: any;
  heading: string;
  annotationId: string;
  lineDescription: string;
  annotations: any;
  setAnnotations: any;
}
export function ModalTowerFeedback(props: ModalProps) {
  const [rejectedMessage, setRejectedMessage] = useState("");
  var errorExists = false;
  const [rejectionErrorMessage, setRejectionErrorMessage] = useState("");

  useEffect(() => {
    const index = props.annotations.findIndex(
      (x: any) => x.id === props.annotationId
    );
    setRejectedMessage(
      props.annotations[index]?.rejectedComment
        ? props.annotations[index].rejectedComment
        : ""
    );
  }, [props.annotationId, props.annotations]);

  function handleError() {
    if (rejectedMessage === undefined || rejectedMessage.trim() === "") {
      setRejectionErrorMessage("Rejection message can't be empty");
      errorExists = true;
    } else {
      errorExists = false;
      setRejectionErrorMessage("");
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reject Requested Changes
          <p />
          <h5>{props.heading}</h5>
          <h6 data-testid="LineDescription" className="LineDescription">
            {props.lineDescription}
          </h6>
          <h6 data-testid="RateSheetLink" className="RateSheetLink">
            <a
              href={process.env.PUBLIC_URL + "/towRates2024.pdf"}
              rel="noreferrer"
              target="_blank"
            >
              Click to view Full Rate Sheet
            </a>
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please indicate why you are rejecting the suggested changes to '
          {props.heading}' here:
        </p>
        <p />
        <Form.Group controlId={"annotationReject." + props.heading}>
          <Form.Control
            as="textarea"
            rows={4}
            onChange={(e) => {
              HandleCommentChange(e);
            }}
            data-testid={"annotationText." + props.heading}
            name={props.heading}
            value={rejectedMessage}
          />
        </Form.Group>
      </Modal.Body>
      {rejectionErrorMessage !== "" && (
        <Error message={rejectionErrorMessage} />
      )}
      <Modal.Footer className="left">
        <Button
          variant="outline-secondary"
          onClick={HandleOnCloseChange}
          data-testid="closeRejectionButton"
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={HandleSave}
          data-testid="saveRejectionCommentButton"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
  function HandleCommentChange(event: any) {
    if (event.target.value.length <= 200) {
      setRejectedMessage(event.target.value);
    }
  }
  function HandleOnCloseChange(event: any) {
    const index = props.annotations.findIndex(
      (x: any) => x.id === props.annotationId
    );
    setRejectedMessage(
      props.annotations[index]?.rejectedComment
        ? props.annotations[index].rejectedComment
        : ""
    );
    props.onHide(false);
  }
  function HandleSave() {
    handleError();
    if (errorExists === false) {
      const index = props.annotations.findIndex(
        (x: any) => x.id === props.annotationId
      );
      if (index !== undefined) {
        try {
          const newAnnotation: Annotation = {
            name: props.annotations[index].name,
            id: props.annotations[index].id,
            title: props.annotations[index].title,
            annotationText: props.annotations[index].annotationText,
            annotatedValue: props.annotations[index].annotatedValue,
            rejectedComment: rejectedMessage,
            status: props.annotations[index].status,
          };
          var annotations = props.annotations.slice(0); //copy's whole annotations using SLICE
          annotations.splice(index, 1, newAnnotation); //remove # of items
          props.setAnnotations(annotations);
        } catch (error) {
          if (appInsights !== undefined) {
            appInsights.trackTrace({
              message: "Problem setting annotations " + error,
              severityLevel: SeverityLevel.Error,
            });
          }
          console.error(error);
        }
      }
      props.onHide(false);
    }
  }
}
