import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import File from "../../components/FormControls/File";
import { Bulletin, defaultBulletin } from "../../types/Bulletin";
import "./BulletinForm.css";
import { uploadBulletin } from "../../api/apiBulletin";

interface Props {
  handleCancel(): any;
  handleSubmit(error: boolean): void;
}

const BulletinForm = ({ handleCancel, handleSubmit }: Props) => {
  const [fileError, setFileError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [bulletin, setBulletin] = useState<Bulletin>({
    ...defaultBulletin,
  });
  const [validated, setValidated] = useState(false);

  const onTitleChange = (e: any) => {
    setBulletin((bulletin) => {
      return { ...bulletin, [e.target.name]: e.target.value };
    });
  };

  const onFileSelect = (file: any) => {
    setSelectedFile(file);
    setFileError(false);
  };

  const onSubmitClick = async (e: any) => {
    e.preventDefault();

    const form = e.currentTarget;
    let isValid = form.checkValidity();
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (selectedFile === undefined) {
      setFileError(true);
    }

    setValidated(!isValid);
    if (isValid && !fileError) {
      let error = undefined;
      try {
        await uploadBulletin(bulletin, selectedFile!);
      }
      catch (ex) {
        error = ex;
      }

      handleSubmit(error instanceof Error);
    }
  };

  const onFileRemove = () => {
    setBulletin((bulletin) => {
      return { ...bulletin, file: undefined };
    });
  };

  return (
    <div className="bulletin-container">
      <Form
        data-testid="custom-form"
        noValidate
        onSubmit={onSubmitClick}
        validated={validated}
        className="bulletin-form"
      >
        <Form.Group controlId="validationTitle">
          <Form.Label className="font-weight-bold" data-testid="title-label">
            Title <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            data-testid="title-control"
            type="text"
            name="title"
            value={bulletin.title}
            onChange={onTitleChange}
            required
          />
          <Form.Control.Feedback
            type="invalid"
            data-testid="title-control-error"
          >
            Title is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <File
            onFileRemove={onFileRemove}
            onFileSelect={onFileSelect}
            selectedFile={selectedFile}
          />
          {fileError && (
            <span
              className="danger"
              style={{ color: "rgb(220, 53, 69)", fontSize: "12.8px" }}
            >
              Document is required
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Button
            variant="outline-primary"
            type="submit"
            id={"bulletin-form-submit"}
            data-testid={"bulletin-form-submit"}
            style={{ width: "6rem" }}
          >
            Save
          </Button>
          <Button
            variant="outline-secondary"
            onClick={handleCancel}
            id={"bulletin-form-cancel"}
            data-testid={"bulletin-form-cancel"}
            className="ml-2"
            style={{ width: "6rem" }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default BulletinForm;
