import { MockUser } from "../../types/MockedUserTypes";

export const login = (mockUser: MockUser) => ({
  type: "USER_LOGIN",
  mockUser: mockUser,
});

export const logout = () => {
  return { type: "USER_LOGOUT" };
};
