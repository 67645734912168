import { Container } from "react-bootstrap";
import "./HomePage.css";

export function HomePage() {
  return (
    <Container id="HomePageContainer">
      <div id="WelcomeMessage">
        Welcome to the SGI Partner Portal Application
      </div>
    </Container>
  );
}
