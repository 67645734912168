import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import { LineType } from "../../types/LineItems";

export const getEmployeeSummaryOverlay = function (
  lineType: any,
  lineName: any,
  lineId: any
) {
  const datePopover = (props: any) => (
    <Popover className="infoPopover" id="popover" {...props}>
      <Popover.Title>Storage</Popover.Title>
      <Popover.Content>
        <blockquote>
          When the tow partner enters their date range, the application
          automatically calculates the number of days.
        </blockquote>
        <blockquote>
          The application also will not allow dates to be entered that are
          before the date of the tow or after the current dates.{" "}
          <strong>
            These values are valid and do not need to be manually validated.
          </strong>
        </blockquote>
        <blockquote>
          Max payable on this line is 72 days. Storage payouts may go beyond max
          when documented communication between adjuster and tower at 30 day and
          60 day intervals exist. This will be shown on an additional storage
          line
        </blockquote>
      </Popover.Content>
    </Popover>
  );

  var renderAdditionalInfo: any;
  var additionalInfoFound = false;

  if (lineType === LineType.PerDayFromToDatesWithMax) {
    renderAdditionalInfo = datePopover;
    additionalInfoFound = true;
  }

  if (!additionalInfoFound) {
    return "";
  } else {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderAdditionalInfo}
        trigger="click" // "hover"  "focus"
        rootClose
        transition={false}
      >
        {({ ref, ...triggerHandler }) => (
          <Button
            {...triggerHandler}
            ref={ref}
            className="additionalInfoButton"
            size="sm"
            id={"tooltipButton." + lineName + lineId}
            data-testid={"additionalInfoButton." + lineName + lineId}
            variant="outline-primary"
            name={lineName + lineId}
          >
            <QuestionCircle />
          </Button>
        )}
      </OverlayTrigger>
    );
  }
};
