const downloadBlob = (blob: any, filename: string) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export { downloadBlob };
