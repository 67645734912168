import React, { useState } from "react";
import { Container } from "react-bootstrap";
import CustomTable from "../../components/CustomTable/CustomTable";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import TableButton from "../../components/CustomTable/TableButton";
import { faBookOpen } from "@fortawesome/pro-light-svg-icons";
import { useHistory } from "react-router-dom";
import { SortOrder } from "react-bootstrap-table-next";
import { defaultVendorList } from "../../types/VendorList";
import { mockedVendorListData } from "../../testUtils/mockedVendorListData";
import { mapToIVendorList } from "../../utils/VendorListTransformer";
import { getAllVendors } from "../../api/apiVendor";
import "./VendorList.css";

export default function VendorList(props: any) {
  const history = useHistory();
  const [vendorList, setVendorList] = useState(defaultVendorList);
  const { screenSize } = useWindowDimensions();

  function noDataIndication() {
    return "There are no Vendors to display.";
  }

  function vendorViewButtonFormatter(cell: string, row: any, rowIndex: any) {
    return (
      <div className="buttonCellWrapper">
        <TableButton
          icon={faBookOpen}
          onClick={() =>
            history.push("/Employee/VendorDetails/" + row.vendorId)
          }
          tooltipText="View Vendor"
          id={row.vendorId}
          datatestid={"TestViewButton" + rowIndex.toString()}
        />
      </div>
    );
  }

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "companyName",
      order: "asc",
    },
  ];

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      sort: true,
      headerClasses: "width15",
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      hidden: screenSize < ScreenSize.lg,
    },
    {
      dataField: "payeeNumber",
      text: "Payee Number",
      sort: true,
      headerClasses: "width10",
    },
    {
      dataField: "viewButton",
      text: "",
      formatter: vendorViewButtonFormatter,
      headerClasses: "icons",
    },
  ];

  return (
    <>
      <Container className="CustomTableContainer">
        <div className="Title">
          <h3 data-testid="TestVendorListTitle">Vendor List</h3>
        </div>
        <CustomTable
          noDataIndication={noDataIndication}
          defaultSorted={defaultSorted}
          columns={columns}
          data={vendorList}
          setData={setVendorList}
          defaultData={mockedVendorListData}
          dataGetter={getAllVendors}
          dataMapper={mapToIVendorList}
          keyField={"vendorId"}
        />
      </Container>
    </>
  );
}
