import { iLineItem, LineType, WeightClass } from "../types/LineItems";

export const defaultLines: iLineItem[] = [
  {
    title: "Basic Tow",
    description:
      "Includes attaching vehicle to tow truck, 15 minutes for accident site debris clean-up, disconnect battery, securing doors or loose parts, pan pillows, boost, lockout, and reasonable preventative measures to reduce vehicle damage from exposure to the elements. This rate is for all on-road types or weather conditions.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 80 },
    lineTotal: 80,
    name: "BasicTow",
    id: "1",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Tow Truck Mileage",
    description:
      "Loaded Kilometres Only. There is no charge for Kilometres in urban centres. If a second truck is required to complete the job on the scene we allow for 1-way tow truck mileage to be added.",
    isStandard: true,
    lineType: LineType.PerKM,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 3 },
    lineTotal: 0,
    name: "TowTruckMileage",
    id: "2",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Dollies",
    description:
      "Dollies allow for vehicles to be raised off the ground to allow for non-movement of wheels. Can also be used for flat tires or damaged wheels. Use of jack is included.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 45 },
    lineTotal: 0,
    name: "Dollies",
    id: "3",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Dolly Mileage",
    description: "Loaded Kilometres Only.",
    isStandard: true,
    lineType: LineType.PerKM,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 0.5 },
    lineTotal: 0,
    name: "DollyMileageTo",
    id: "4",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Deck Truck",
    description:
      "Added when a deck truck is necessary for the tow. Used when a vehicle cannot be towed by traditional means, either due to the nature of the damage, or because the vehicle is 4WD or AWD.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 125 },
    lineTotal: 0,
    name: "DeckTruck",
    id: "5",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Deck Truck Mileage",
    description:
      "Loaded Kilometres Only. There is no charge for Kilometres in urban centres. If a second truck is required to complete the job on the scene we allow for 1-way tow truck mileage to be added.",
    isStandard: true,
    lineType: LineType.PerKM,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 3.5 },
    lineTotal: 0,
    name: "DeckTruckMileage",
    id: "6",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Tow Through Claims Wait Time",
    description:
      "Vehicle is brought to an SGI Adjuster/Appraiser, either inside an SGI Claims Centre or at another SGI location. You remain hooked and wait for vehicle to be adjusted. Rate includes up to a 40-minute appraisal wait time from scheduled appointment time, and delivery of vehicle to the final destination.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 40 },
    lineTotal: 0,
    name: "TowThroughClaimsWaitTime",
    id: "7",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Appraisal Loading Fee",
    description:
      "Added when SGI requests a vehicle be dropped for estimating. This is due to a Corporate Safety Initiative where SGI staff can no longer access deck trucks to estimate a vehicle.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 50 },
    lineTotal: 0,
    name: "AppraisalLoadingFee",
    id: "8",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "After Hours Fee",
    description:
      "Any calls that are either beginning or ending within 7PM - 7AM",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 10 },
    lineTotal: 0,
    name: "AfterHoursFee",
    id: "9",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Winching",
    description:
      "Relocating vehicle to provide access for hooking up. Pro-rated at $80/hr in 30 minute increments, with a $50 minimum. A description of the winch operation is required to be compensated for this service. For example, winch X feet from ditch/off of lawn/away from pole etc. Winch is considered using either the winch or towing vehicle to complete winching operation. Multiple cable contact points from a single tow truck still result in a single winch charge. Also includes rollover off roof, off side, or onto wheels.",
    isStandard: false,
    lineType: LineType.ProRatedMinutes,
    qty: undefined,
    lineData: {},
    rate: { minCharge: 50, increment: 30, baseRate: 80, maxCharge: 100000 },
    lineTotal: 0,
    name: "Winching",
    id: "10",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Remote Recovery",
    description:
      "Includes use of 4 wheel drive for any remote recovery. SGI understands that in extreme situations a second unit may be required. Please note, this charge must be accompanied by pictures and an explanation outlining the difficult or irregular tow circumstances to warrant this service.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 100 },
    lineTotal: 0,
    name: "RemoteRecovery",
    id: "11",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Drive Shaft Removal",
    description:
      "Required based on manufacturers recommendation. Removal only.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 35 },
    lineTotal: 0,
    name: "DriveShaftRemoval",
    id: "12",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Standby Ordered By Police",
    description:
      "If the police ask you standby once on a scene, or if waiting for accident reconstruction specialist or a coroner. If the Police ask for a specific tow unit but on the scene you require an additional/different tow unit, we will pay the applicable tow rate mileage for the non-required tow vehicle. If you are completing tasks on the scene while waiting for the proper tow unit to arrive we will compensate for those service items accordingly.",
    isStandard: false,
    lineType: LineType.ProRatedIntervalMinutes,
    qty: undefined,
    lineData: {},
    rate: {
      intervals: [
        { start: 0, end: 30, charge: 22.5 },
        { start: 31, end: 60, charge: 45 },
      ],
      increment: 1,
      baseRate: 45,
    },
    lineTotal: 0,
    name: "StandbyOrderedByPolice",
    id: "13",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Support Vehicle",
    description:
      "One-way kilometers only. A support vehicle is to be used to pull a trailer (if required) or to drive out additional labour to clean up a site (if required). Must Include detailed explanation and/or documentation.",
    isStandard: false,
    lineType: LineType.PerKM,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 2 },
    lineTotal: 0,
    name: "SupportVehicle",
    id: "14",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Extra Labour: Without WCB",
    description:
      "For additional labour that is necessary to complete the task/procedure without WCB coverage. Charged per man hour. Must Include detailed explanation and/or documentation",
    isStandard: false,
    lineType: LineType.PerHr,
    qty: 0,
    lineData: {},
    rate: { baseRate: 25 },
    lineTotal: 0,
    name: "ExtraLabourNoWCB",
    id: "15",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Extra Labour: With WCB",
    description:
      "For additional labour that is necessary to complete the task/procedure by employees covered under a WCB policy. Charged per man hour. Must Include detailed explanation and/or documentation",
    isStandard: false,
    lineType: LineType.PerHr,
    qty: 0,
    lineData: {},
    rate: { baseRate: 50 },
    lineTotal: 0,
    name: "ExtraLabourWCB",
    id: "16",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Tire Change",
    description: "When necessary to avoid dollies.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 35 },
    lineTotal: 0,
    name: "TireChange",
    id: "17",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Transmission Link Removal",
    description: "For removal of transmission link when necessary.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 25 },
    lineTotal: 0,
    name: "TransmissionLinkRemoval",
    id: "18",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Storage: Fenced Compound",
    description:
      "Up to a maximum of $720. Max is payed out in the event that there is no recorded communication between tower and adjuster explaining the need to exceed 72 days storage. If such communication exists, add a special 'Storage - Over 72 days' line item below instead.",
    isStandard: true,
    lineType: LineType.PerDayFromToDatesWithMax,
    qty: undefined,
    lineData: { FromDate: "", ToDate: "" },
    rate: { baseRate: 10, maxCharge: 720 },
    lineTotal: 0,
    name: "StorageFenced",
    id: "19",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Storage: Unsecured compound",
    description:
      "Up to a maximum of $360. Max is payed out in the event that there is no recorded communication between tower and adjuster explaining the need to exceed 72 days storage. If such communication exists, add a special 'Storage - Over 72 days' line item below instead.",
    isStandard: true,
    lineType: LineType.PerDayFromToDatesWithMax,
    qty: undefined,
    lineData: { FromDate: "", ToDate: "" },
    rate: { baseRate: 5, maxCharge: 360 },
    lineTotal: 0,
    name: "StorageUnsecured",
    id: "20",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Storage over 72 days: Fenced Compound",
    description:
      "Must include documented communication between tower and adjuster explaining the need to exceed 72 days storage, and documented communication at 30 day and 60 day intervals.",
    isStandard: false,
    lineType: LineType.PerDayFromToDatesWithMax,
    qty: undefined,
    lineData: { FromDate: "", ToDate: "" },
    rate: { baseRate: 10, maxCharge: 1000000 },
    lineTotal: 0,
    name: "StorageFencedOver72",
    id: "21",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Storage over 72 days: Unsecured compound",
    description:
      "Must include documented communication between tower and adjuster explaining the need to exceed 72 days storage, and documented communication at 30 day and 60 day intervals.",
    isStandard: false,
    lineType: LineType.PerDayFromToDatesWithMax,
    qty: undefined,
    lineData: { FromDate: "", ToDate: "" },
    rate: { baseRate: 5, maxCharge: 1000000 },
    lineTotal: 0,
    name: "StorageUnsecuredOver72",
    id: "22",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Traffic Control Time",
    description:
      "In order to administer charges for Traffic Control, the person conducting the Traffic Control must have flagperson certification and their sole job on scene is to conduct traffic control. A designated Traffic Control Truck is also required. . Must Include detailed explanation and/or documentation",
    isStandard: false,
    lineType: LineType.PerHr,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 150 },
    lineTotal: 0,
    name: "TrafficControlTime",
    id: "23",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Traffic Control Mileage ",
    description:
      "Charge is for One-Way kilometers only. In order to administer charges for Traffic Control, the person conducting the Traffic Control must have flagperson certification and their sole job on scene is to conduct traffic control. A designated Traffic Control Truck is also required. Must Include detailed explanation and/or documentation.",
    isStandard: false,
    lineType: LineType.PerKM,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 2 },
    lineTotal: 0,
    name: "TrafficControlMileage",
    id: "24",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Incident Trailers",
    description:
      "Used in Rare Circumstances. Incident trailers may carry chop saws, chain saws, recovery equipment, and additional traffic control equipment. Applicable loaded vehicle mileage will be payed one-way. Must Include detailed explanation and/or documentation.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 125 },
    lineTotal: 0,
    name: "IncidentTrailers",
    id: "25",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Garbage Dump Trailer",
    description:
      "Used in Rare Circumstances. Dump trailers are used for excessive clean-up of parts or product. Applicable loaded vehicle mileage will be payed one-way. Landfill fees are payable if required at cost. Daily storage rates are applicable if SGI has been Page 7 of 7notified in a timely manner. In the off chance where unique circumstances arise that require additional costs exceeding daily fees and storage fees please contact SGI. Must Include detailed explanation and/or documentation.",
    isStandard: false,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 125 },
    lineTotal: 0,
    name: "DumpTrailers",
    id: "26",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Skid Steer / Bobcat / Excavator",
    description:
      "Used in Rare Circumstances. This rate includes the operator of the skid steer / bobcat / excavator. One way tow mileage will be paid for the tow unit driving out the skid steer/bobcat/excavator. Must Include detailed explanation and/or documentation.",
    isStandard: false,
    lineType: LineType.PerHr,
    qty: undefined,
    lineData: {},
    rate: { baseRate: 110 },
    lineTotal: 0,
    name: "SkidSteerBobcatExcavator",
    id: "27",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Other",
    description: "Other charges not included in the Rate Sheet",
    isStandard: false,
    lineType: LineType.Other,
    qty: undefined,
    lineData: { otherDescription: "" },
    rate: {},
    lineTotal: 0,
    name: "Other",
    id: "28",
    allowMultiple: true,
    weightClass: WeightClass.light,
  },
];

export const multipleOtherLines: iLineItem[] = [
  {
    title: "Basic Tow",
    description:
      "Includes attaching vehicle to tow truck, 15 minutes for accident site debris clean-up, disconnect battery, securing doors or loose parts, pan pillows, boost, lockout, and reasonable preventative measures to reduce vehicle damage from exposure to the elements. This rate is for all on-road types or weather conditions.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 80 },
    lineTotal: 80,
    name: "BasicTow",
    id: "1",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Other",
    description: "Other charges not included in the Rate Sheet",
    isStandard: false,
    lineType: LineType.Other,
    qty: undefined,
    lineData: { otherDescription: "" },
    rate: {},
    lineTotal: 0,
    name: "Other",
    id: "2",
    allowMultiple: true,
    weightClass: WeightClass.light,
  },
  {
    title: "Other",
    description: "Other charges not included in the Rate Sheet",
    isStandard: false,
    lineType: LineType.Other,
    qty: undefined,
    lineData: { otherDescription: "" },
    rate: {},
    lineTotal: 0,
    name: "Other",
    id: "3",
    allowMultiple: true,
    weightClass: WeightClass.light,
  },
];

export const multipleBasicLineItem: iLineItem[] = [
  {
    title: "Basic Tow",
    description:
      "Includes attaching vehicle to tow truck, 15 minutes for accident site debris clean-up, disconnect battery, securing doors or loose parts, pan pillows, boost, lockout, and reasonable preventative measures to reduce vehicle damage from exposure to the elements. This rate is for all on-road types or weather conditions.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 80 },
    lineTotal: 80,
    name: "BasicTow",
    id: "1",
    allowMultiple: false,
    weightClass: WeightClass.light,
  },
  {
    title: "Basic Tow",
    description:
      "Includes attaching vehicle to tow truck, 15 minutes for accident site debris clean-up, disconnect battery, securing doors or loose parts, pan pillows, boost, lockout, and reasonable preventative measures to reduce vehicle damage from exposure to the elements. This rate is for all on-road types or weather conditions.",
    isStandard: true,
    lineType: LineType.FlatRate,
    qty: 0,
    lineData: {},
    rate: { baseRate: 80 },
    lineTotal: 80,
    name: "BasicTow",
    id: "2",
    allowMultiple: false,
    weightClass: WeightClass.medium,
  },
  {
    title: "Other",
    description: "Other charges not included in the Rate Sheet",
    isStandard: false,
    lineType: LineType.Other,
    qty: undefined,
    lineData: { otherDescription: "" },
    rate: {},
    lineTotal: 0,
    name: "Other",
    id: "3",
    allowMultiple: true,
    weightClass: WeightClass.light,
  },
  {
    title: "Other",
    description: "Other charges not included in the Rate Sheet",
    isStandard: false,
    lineType: LineType.Other,
    qty: undefined,
    lineData: { otherDescription: "" },
    rate: {},
    lineTotal: 0,
    name: "Other",
    id: "4",
    allowMultiple: true,
    weightClass: WeightClass.light,
  },
];
