import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function postFeedback(feedback: any) {
  var baseUrl = getBaseApiUrl() + "Feedback/Submit";

  const token = await getAccessToken();

  let data = new FormData();
  data.append("feedbackDto", JSON.stringify(feedback));

  return axios
    .post(baseUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getFeedbacks() {
  const baseUrl = getBaseApiUrl() + "Feedback";
  const token = await getAccessToken();

  return axios
    .get(`${baseUrl}/`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
