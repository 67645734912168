import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function postLegacyAttach(invoiceId: string) {
  var baseUrl = getBaseApiUrl() + "LegacyEvent/LegacyAttach/";

  const token = await getAccessToken();

  return axios
    .post(baseUrl + invoiceId, null, {
      headers: {
				Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}