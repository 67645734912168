import { displayRate, percentageFormat } from "../utils/Utils";

export enum LineType {
	FlatRate = "",
	PerKM = "Per kilometer",
	PerMin = "Minutes",
	PerHr = "Per Hour",
	PerDay = "Per Day",
	PerDayFromToDatesWithMax = "Per Day with date range and maximum",
	ProRatedMinutes = "Pro-Rated Minutes With Min/Max",
	ProRatedIntervalMinutes = "Pro-Rated Minutes With Intervals",
	Other = "Other",
	PerAmount = "Percentage of an Amount",
	PerWheel = "Per Wheel",
}

export const LineItemsWithDecimalValues: string[] = [
	"ExtraLabourNonWCB",
	"ExtraLabourWCB",
];

export function getUnits(l: LineType): string {
	switch (l) {
		case LineType.FlatRate:
			return "";
		case LineType.PerKM:
			return "km";
		case LineType.ProRatedMinutes:
		case LineType.ProRatedIntervalMinutes:
			return "Min";
		case LineType.PerMin:
			return "Min";
		case LineType.PerHr:
			return "Hr";
		case LineType.Other:
			return "";
		case LineType.PerAmount:
			return "Receipt Amount";
		case LineType.PerWheel:
			return "Wheel";
		case LineType.PerDay:
		case LineType.PerDayFromToDatesWithMax:
			return "Days";
		default:
			return "";
	}
}

export function getRatingDescription(l: LineType): string {
	switch (l) {
		case LineType.FlatRate:
			return "";
		case LineType.PerKM:
			return "";
		case LineType.ProRatedMinutes:
		case LineType.ProRatedIntervalMinutes:
			return " *";
		case LineType.PerMin:
			return "";
		case LineType.PerHr:
			return "";
		case LineType.Other:
			return "";
		case LineType.PerAmount:
			return "Receipt Amount *";
		case LineType.PerWheel:
			return "";
		case LineType.PerDay:
		case LineType.PerDayFromToDatesWithMax:
			return " *";
		default:
			return "";
	}
}

export function getUnitsForSpecialLineItemsSelection(
	lineType: LineType
): string {
	switch (lineType) {
		case LineType.FlatRate:
			return "flat rate";
		case LineType.PerKM:
			return "/km";
		case LineType.ProRatedMinutes:
			return "/Hr";
		case LineType.ProRatedIntervalMinutes:
			return "/Hr";
		case LineType.PerMin:
			return "/Min";
		case LineType.PerHr:
			return "/Hr";
		case LineType.Other:
			return "";
		case LineType.PerAmount:
			return "";
		case LineType.PerWheel:
			return "/Wheel";
		case LineType.PerDay:
			return "/Day";
		case LineType.PerDayFromToDatesWithMax:
			return "/Day *";
		default:
			return "";
	}
}

export function getTitleForSpecialLineItemsSelection(line: iLineItem): string {
	const baseRate = displayRate(line.rate.baseRate);

	switch (line.lineType) {
		case LineType.FlatRate:
			return line.title + " @" + baseRate;
		case LineType.Other:
			return line.title;
		case LineType.ProRatedIntervalMinutes:
		case LineType.ProRatedMinutes:
			return line.title + " @" + baseRate + " *";
		case LineType.PerAmount:
			var percentageAmount = Number(line.rate.baseRate) * 100;
			return line.title + " @" + percentageAmount.toString() + "% *";
		case LineType.PerDayFromToDatesWithMax:
			return line.title + " @" + baseRate + " *";
		default:
			return line.title + " @" + baseRate + " *";
	}
}

export function getRateUnitsForInvoiceSummary(line: iLineItem): string {
	switch (line.lineType) {
		case LineType.FlatRate:
			return "Flat rate: " + displayRate(line.rate.baseRate);
		case LineType.PerKM:
		case LineType.PerHr:
			return (
				line.qty +
				" " +
				getUnits(line.lineType) +
				" @ " +
				displayRate(line.rate.baseRate) +
				getUnitsForSpecialLineItemsSelection(line.lineType)
			);
		case LineType.ProRatedMinutes:
		case LineType.ProRatedIntervalMinutes:
		case LineType.PerMin:
		case LineType.PerWheel:
		case LineType.PerDay:
			return (
				line.qty +
				" " +
				getUnits(line.lineType) +
				" @ " +
				displayRate(line.rate.baseRate) +
				getUnitsForSpecialLineItemsSelection(line.lineType) +
				getRatingDescription(line.lineType)
			);
		case LineType.Other:
			return line.lineData.otherDescription;
		case LineType.PerAmount:
			return (
				getUnits(line.lineType) +
				" $" +
				line.qty +
				" @ " +
				percentageFormat(line.rate.baseRate) +
				" *" +
				(line.lineTotal === line.rate.maxCharge ? " maximum applied" : "")
			);
		case LineType.PerDayFromToDatesWithMax:
			return (
				line.qty +
				" " +
				getUnits(line.lineType) +
				" @ " +
				displayRate(line.rate.baseRate) +
				getUnitsForSpecialLineItemsSelection(line.lineType) +
				(line.lineTotal === line.rate.maxCharge ? " maximum applied" : "")
			);
		default:
			return "";
	}
}

export interface iLineItem {
	title: string;
	description: string;
	isStandard: boolean;
	lineType: LineType;
	lineData: any;
	qty: number | undefined;
	rate: any;
	lineTotal: number;
	name: string;
	id: string;
	allowMultiple: boolean;
	weightClass: WeightClass;
}

export enum WeightClass {
	light,
	medium,
	heavy,
}
