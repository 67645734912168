import { iLineItem, LineType } from "../types/LineItems";
import {
  CalcPerAmount,
  CalcPerDayFromToDatesWithMax,
  CalcProRatedIntervalMinutes,
  CalcProRatedMinutes,
} from "./specialRateCalc";

export function calcRate(line: iLineItem): number {
  switch (line.lineType) {
    case LineType.FlatRate:
      return line.rate.baseRate;
    case LineType.PerDay:
    case LineType.PerHr:
    case LineType.PerKM:
    case LineType.PerMin:
    case LineType.PerWheel:
      return line.rate.baseRate * (line.qty ? line.qty : 0);
    case LineType.PerDayFromToDatesWithMax:
      return CalcPerDayFromToDatesWithMax(line.rate, line.qty ? line.qty : 0);
    case LineType.PerAmount:
      return CalcPerAmount(line.rate, line.qty ? line.qty : 0);
    case LineType.ProRatedMinutes:
      return CalcProRatedMinutes(line.rate, line.qty ? line.qty : 0);
    case LineType.ProRatedIntervalMinutes:
      return CalcProRatedIntervalMinutes(line.rate, line.qty ? line.qty : 0);
    default:
      return 0;
  }
}
