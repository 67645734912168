import { Badge } from "react-bootstrap";
import { ArrowReturnRight } from "react-bootstrap-icons";
import { Annotation, AnnotationStatus } from "../../types/Invoice";
import { getUnits, LineType, WeightClass } from "../../types/LineItems";
import { DifferenceType, LineDifference } from "../../utils/diffInvoices";
import currencyFormat from "../../utils/Utils";

import "./EmployeeInvoiceLineItemDiff.css";

interface Props {
  lineDiff: LineDifference;
  annotations: Annotation[];
}

export default function EmployeeInvoiceDeletedLineItemDiff(props: Props) {
  const previousLineType =
    props.lineDiff.previous?.lineType ?? LineType.FlatRate;

  return (
    <>
      {props.lineDiff.diffType === DifferenceType.Deleted && (
        <tr>
          <td
            className="noPadding"
            data-testid={
              "lineDiff-Title." + props.lineDiff.name + props.lineDiff.id
            }
          >
            {props.lineDiff.previous?.title}
            {"  "}
            <Badge pill variant="light">
              {
                WeightClass[
                  props.lineDiff.previous?.weightClass === undefined
                    ? 0
                    : props.lineDiff.previous?.weightClass
                ]
              }
            </Badge>
          </td>
          <td
            className="noPadding"
            data-testid={
              "lineDiff-Action." + props.lineDiff.name + props.lineDiff.id
            }
          >
            Deleted
          </td>
          <td
            className="noPadding"
            data-testid={
              "lineDiff-Units." + props.lineDiff.name + props.lineDiff.id
            }
          >
            <table className="table-borderless">
              <tbody>
                {props.lineDiff.previous?.lineType === LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      {props.lineDiff.previous?.lineData.otherDescription}
                    </td>
                  </tr>
                )}
                {props.lineDiff.previous?.lineType !== LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      {props.lineDiff.previous?.qty +
                        " " +
                        getUnits(previousLineType)}
                    </td>
                  </tr>
                )}
                {props.lineDiff.previous?.lineType ===
                  LineType.PerDayFromToDatesWithMax && (
                  <>
                    <tr>
                      <td className="noPadding">
                        From: {props.lineDiff.previous?.lineData.fromDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="noPadding">
                        To: {props.lineDiff.previous?.lineData.toDate}
                      </td>
                    </tr>
                  </>
                )}

                {props.lineDiff.previous?.lineType === LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      Total:{" "}
                      {currencyFormat(props.lineDiff.previous?.lineTotal)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
      {/* show any completed annotations */}
      {props.lineDiff.diffType === DifferenceType.Deleted &&
        props.annotations
          .filter(
            (item: Annotation) =>
              item.status !== AnnotationStatus.Draft &&
              item.id === props.lineDiff.id
          )
          .map((annotation: Annotation) => {
            return (
              <tr key={annotation.name + annotation.id}>
                <td className="employeeAnnotationPadding">
                  <ArrowReturnRight />
                  <div className="annotationHeaderDiv">SGI:&nbsp;</div>
                  <div
                    data-testid={
                      "annotationTextDisplay." + annotation.name + annotation.id
                    }
                    className="annotationInlineDiv"
                  >
                    {annotation.annotationText}
                  </div>
                  <div>
                    {annotation.rejectedComment !== null &&
                      annotation.rejectedComment !== "" &&
                      annotation.rejectedComment !== undefined && (
                        <div className="vendorReplyPadding">
                          <ArrowReturnRight />
                          <div className="changesHeaderDiv">Vendor:&nbsp;</div>
                          <div className="changesInlineDiv">
                            {annotation.rejectedComment}
                          </div>
                        </div>
                      )}
                  </div>
                </td>
                <td className="">
                  <div
                    data-testid={
                      "annotatedValueDisplay." + annotation.name + annotation.id
                    }
                  >
                    {" "}
                    Suggested Value:&nbsp;
                    {annotation.annotatedValue}
                  </div>
                </td>
              </tr>
            );
          })}
    </>
  );
}
