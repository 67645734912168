import React from "react";
import { Alert } from "react-bootstrap";

export function TowerInventoryListDisplay(props: any) {
  return (
    <div className="inventoryListDisplayContainer">
      <Alert
        variant="info"
        className="inventoryListAlert"
        data-testid="inventoryListAlertTest"
      >
        If a vehicle is towed to your compound on a current invoice, it will
        appear on this list.
      </Alert>
    </div>
  );
}
