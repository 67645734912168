import { CompoundLocation } from "./Account";
import { defaultNewCompoundLocation } from "./CompoundLocation";
interface IObjectKeys {
  [key: string]: string | number | Object;
}
export interface Vendor extends IObjectKeys {
  vendorId: string;
  payeeNumber: string;
  companyName: string;
  compoundLocation: CompoundLocation;
  organizationId: string;
  address: string;
  phoneNumber: string;
  email: string;
}

export const defaultVendor: Vendor = {
  vendorId: "",
  payeeNumber: "",
  companyName: "",
  compoundLocation: defaultNewCompoundLocation,
  organizationId: "",
  address: "",
  phoneNumber: "",
  email: "",
};

export const testVendor: Vendor = {
  vendorId: "abcdef",
  payeeNumber: "123456",
  companyName: "Test Towing Company",
  compoundLocation: defaultNewCompoundLocation,
  organizationId: "uvwxyz",
  address: "100 Main Street, Smalltown SK",
  phoneNumber: "(123)555-5555",
  email: "test@test.com",
};
