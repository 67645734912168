import { iFeedbackList } from "../types/FeedbackList";

export function feedbackListTransformer(apiData: any): iFeedbackList[] {
  var returnData: iFeedbackList[] = [];

  // map api data received
  apiData.forEach((item: any) => {
    var newReturnData: iFeedbackList = {
      content: item.content,
      email: item.email,
      feedbackType: item.feedbackType,
      name: item.name,
      submittedDate: item.submittedDate,
    };
    returnData.push(newReturnData);
  });

  return returnData;
}
