const initialState = {
  connected: true,
};

const connectionStatusReducer = (
  state = initialState,
  action: { type: any; connected?: boolean }
) => {
  let newState = { ...state };
  switch (action.type) {
    case "SET_CONNECTIONSTATUS": {
      action.connected === true
        ? (newState.connected = true)
        : (newState.connected = false);
      return newState;
    }
    default:
      return state;
  }
};

export default connectionStatusReducer;
