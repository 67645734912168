import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import "../../components/Maps/Maps.css";
import "./VendorDetails.css";
import { Address, getDisplayAddress } from "../../types/Location";
import {
  validateCompoundLocation,
  validateLocationResult,
} from "../../utils/Validators/LocationValidator";
import { nullOrString } from "../../utils/Utils";
import { appInsights } from "../../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { defaultNewCompoundLocation } from "../../types/CompoundLocation";
export function CompoundInput(props: any) {
  var {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: new google.maps.LatLng(53.50774486995124, -105.95601868417705),
      radius: 750 * 1000,
    },
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    props.setVendorDetails({
      ...props.vendorDetails,
      compoundLocation: {
        ...defaultNewCompoundLocation,
      },
    });
  };

  const handleBlur = () => {
    if (value !== "") {
      props.vendorDetails.compoundLocation === undefined
        ? props.setErrorMessage("")
        : props.vendorDetails.compoundLocation === null
        ? props.setErrorMessage("")
        : props.setErrorMessage(
            validateCompoundLocation(props.vendorDetails.compoundLocation)
          );
    } else {
      props.setErrorMessage("");
      props.setChangedFields({
        ...props.changedFields,
        compoundLocation: {
          ...props.changedFields.compoundLocation,
          changed:
            props.currentlySavedCompoundLocation &&
            value !== props.currentlySavedCompoundLocation
              ? true
              : false,
        },
      });
    }
  };

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    props.setErrorMessage("");

    var results = null;
    var latitude = 0;
    var longitude = 0;
    try {
      results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      latitude = lat;
      longitude = lng;
    } catch (error) {
      if (appInsights !== undefined) {
        appInsights.trackTrace({
          message:
            "Map Search - error retrieving location information: " + error,
          severityLevel: SeverityLevel.Error,
        });
      }
      console.log("Map Search - error retrieving location information", error);
    }
    if (results) {
      var addrComp = results[0].address_components;
      props.setAreaBounds({
        northEast: results[0].geometry.viewport.getNorthEast().toJSON(),
        southWest: results[0].geometry.viewport.getSouthWest().toJSON(),
      });
      var addressData: Address = parseAddressComponents(addrComp);

      props.setVendorDetails({
        ...props.vendorDetails,
        compoundLocation: {
          streetAddress: results[0].formatted_address,
          city: addressData.city,
          province: addressData.province,
          country: addressData.country,
          postalCode: addressData.postalCode,
          placeId: results[0].place_id,
          googleResults: {
            address: { ...addressData },
            formattedAddressFull: results[0].formatted_address,
            locationTypes: results[0].types,
            placeId: results[0].place_id,
            latitude: latitude,
            longitude: longitude,
            isSelectedByPin: false,
          },
        },
      });
      props.setChangedFields({
        ...props.changedFields,
        compoundLocation: {
          ...props.changedFields.compoundLocation,
          changed:
            props.currentlySavedCompoundLocation &&
            results[0].place_id !== props.currentlySavedCompoundLocation
              ? true
              : false,
        },
      });

      props.setPrecisePosition(true);
      if (validateLocationResult(results) !== "") {
        props.setErrorMessage(validateLocationResult(results));
        props.setPrecisePosition(false);
      }
    } else {
      props.setErrorMessage(
        "Error retrieving location data, please enter a new location"
      );
    }
  };
  return (
    <div className="search">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={
            props.vendorDetails.compoundLocation &&
            props.vendorDetails.compoundLocation.googleResults
              .formattedAddressFull !== ""
              ? getDisplayAddress(
                  props.vendorDetails.compoundLocation.googleResults
                )
              : value
          }
          onChange={handleInput}
          onBlur={handleBlur}
          disabled={!ready || !props.compoundLocationEditable}
          placeholder={
            props.vendorDetails.compoundLocation &&
            props.vendorDetails.compoundLocation.googleResults
              .formattedAddressFull !== ""
              ? getDisplayAddress(
                  props.vendorDetails.compoundLocation.googleResults
                )
              : "Search for your location (or nearest)"
          }
          className="form-control compoundAddress"
          data-testid={"vendorCompoundLocation"}
          name="vendorCompoundLocation"
        />

        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
export default CompoundInput;

export function parseAddressComponents(
  addrComp: google.maps.GeocoderAddressComponent[]
): Address {
  return {
    streetNumber: nullOrString(
      addrComp.find((s) => s.types.includes("street_number"))?.long_name
    ),
    street: nullOrString(
      addrComp.find((s) => s.types.includes("route"))?.long_name
    ),
    city: nullOrString(
      addrComp.find((s) => s.types.includes("locality"))?.long_name
    ),
    province: nullOrString(
      addrComp.find((s) => s.types.includes("administrative_area_level_1"))
        ?.short_name
    ),
    country: nullOrString(
      addrComp.find((s) => s.types.includes("country"))?.long_name
    ),
    postalCode: nullOrString(
      addrComp.find((s) => s.types.includes("postal_code"))?.long_name
    ),
  };
}
