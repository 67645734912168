import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function decodeVin(vin: string) {
	const baseUrl = getBaseApiUrl();
	const token = await getAccessToken();

	return axios
		.get(baseUrl + "VinSearch/decodevin/" + vin, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}

export async function decodeVinForEmployee(vin: string) {
	const baseUrl = getBaseApiUrl();
	const token = await getAccessToken();

	return axios
		.get(baseUrl + "VinSearch/employee/decodevin/" + vin, {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
