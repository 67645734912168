import { UserPermissions } from "../../types/UserPermissions";

export const setUserPermissions = (userPermissions: UserPermissions) => ({
	type: "SET_USER_PERMISSIONS",
	userPermissions: userPermissions,
});

export const clearUserPermissions = () => {
	return { type: "CLEAR_USER_PERMISSIONS" };
};
