import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File) =>
	new Promise<any>((resolve) => {
		Resizer.imageFileResizer(
			file,
			100,
			100,
			"PNG",
			10,
			0,
			(uri) => {
				resolve(uri);
			},
			"file"
		);
	});

export const formatToBase64 = (file: File) =>
	new Promise<any>((resolve) => {
		Resizer.imageFileResizer(
			file,
			100,
			100,
			"PNG",
			10,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
