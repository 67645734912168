import { Container } from "react-bootstrap";

interface Props {
  message?: string;
}
export function ApiUnavailable(props: Props) {
  return (
    <Container className="alertContainer" data-testid="apiUnavailable">
      <div>
        <br></br>
        Oops! Something went wrong! <br></br>
        <br></br>Please wait a moment and try again.
        <br></br>
        <br></br>
      </div>
      {props.message && (
        <div>
          props.message
          <br></br>
        </div>
      )}
    </Container>
  );
}
