import BootstrapTable, { PaginationOptions } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ScreenSize, useWindowDimensions } from "../../utils/Utils";
import { ScrollArrow } from "./ScrollArrow";
import "./CustomTable.css";
import { useCallback, useEffect, useState } from "react";
import { useTypedSelector } from "../../redux/store";
import { PageSpinner } from "../PageSpinner/PageSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSort,
	faSortDown,
	faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";
import filterFactory from "react-bootstrap-table2-filter";

export default function CustomTable(props: any) {
	const { screenSize } = useWindowDimensions();
	const { SearchBar } = Search;
	const login = useTypedSelector((state) => state.login);
	const [loading, setLoading] = useState(true);

	const noDataIndication = props.noDataIndication;
	const defaultSorted = props.defaultSorted;
	const rowClasses = props.rowClasses;
	const keyField = props.keyField;
	const [apiUnavailable, setApiUnavailable] = useState(false);

	const loadData = useCallback(async () => {
		try {
			const result: any = await props.dataGetter(login.vendorId);
			if (result.status === 200) {
				var mapData = props.dataMapper(result.data);
				props.setData(mapData);
			} else {
				props.setData(props.defaultData);
			}
		} catch (error) {
			setApiUnavailable(true);
			props.setData(props.defaultData);
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login.vendorId]);

	useEffect(() => {
		if (login.username !== "") {
			loadData();
		} else {
			props.setData(props.defaultData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [login, loadData]);

	function sortCaretFormatter(order: any, column: any) {
		if (!order)
			return (
				<span style={{ color: "dimgray", whiteSpace: "nowrap" }}>
					{" "}
					<FontAwesomeIcon icon={faSort} />
				</span>
			);
		else if (order === "asc")
			return (
				<span style={{ color: "black", whiteSpace: "nowrap" }}>
					{" "}
					<FontAwesomeIcon icon={faSortUp} />
				</span>
			);
		else if (order === "desc")
			return (
				<span style={{ color: "black", whiteSpace: "nowrap" }}>
					{" "}
					<FontAwesomeIcon icon={faSortDown} />
				</span>
			);
		return null;
	}

	const modifiedColumns = props.columns.map((item: any) => ({
		...item,
		sortCaret: sortCaretFormatter,
	}));

	const expandRow = {
		...props.expandRow,
		expandColumnRenderer: function (row: any) {
			return (
				<>
					{row.expanded ? (
						<FontAwesomeIcon icon={faSortDown} />
					) : (
						<FontAwesomeIcon
							icon={faSortUp}
							className="fa-rotate-90"
							style={{ color: "dimgray" }}
						/>
					)}
				</>
			);
		},
		expandHeaderColumnRenderer: () => {
			return <></>;
		},
	};

	const paginationOptions: PaginationOptions = {
		showTotal: true,
		alwaysShowAllBtns: false,
		sizePerPage: 25,
		sizePerPageList: [10, 25, 100, 200],
		hidePageListOnlyOnePage: true,
	};

	const paginationOptionsSmall: PaginationOptions = {
		showTotal: false,
		alwaysShowAllBtns: false,
		sizePerPage: 25,
		hidePageListOnlyOnePage: true,
		hideSizePerPage: true,
	};
	if (apiUnavailable) {
		return <ApiUnavailable />;
	} else
		return (
			<>
				<div className="CustomTableWrapper">
					<ToolkitProvider
						bootstrap4={true}
						keyField={keyField}
						data={props.data}
						columns={modifiedColumns}
						search={{
							searchFormatted: true,
						}}
					>
						{(tableProps) => (
							<>
								{loading ? (
									<PageSpinner />
								) : (
									<>
										{" "}
										<div className="searchBar">
											<SearchBar
												{...tableProps.searchProps}
												className="SearchText"
											/>
										</div>
										{screenSize >= ScreenSize.md && (
											<BootstrapTable
												{...tableProps.baseProps}
												bordered={false}
												striped={false}
												hover={true}
												noDataIndication={noDataIndication}
												defaultSorted={defaultSorted}
												condensed={true}
												pagination={paginationFactory(paginationOptions)}
												defaultSortDirection="asc"
												rowClasses={rowClasses}
												classes="cellStyle stickyHeader"
												filter={filterFactory()}
											></BootstrapTable>
										)}
										{screenSize < ScreenSize.md && (
											<BootstrapTable
												{...tableProps.baseProps}
												bordered={false}
												striped={false}
												hover={true}
												noDataIndication={noDataIndication}
												defaultSorted={defaultSorted}
												condensed={true}
												pagination={paginationFactory(paginationOptionsSmall)}
												defaultSortDirection="asc"
												rowClasses={rowClasses}
												classes="cellStyle stickyHeader"
												expandRow={expandRow}
												filter={filterFactory()}
											></BootstrapTable>
										)}
									</>
								)}
							</>
						)}
					</ToolkitProvider>
				</div>
				<ScrollArrow />
			</>
		);
}
