import { ArrowReturnRight } from "react-bootstrap-icons";
import { getUnits, LineType } from "../../types/LineItems";
import { DifferenceType, LineDifference } from "../../utils/diffInvoices";
import currencyFormat from "../../utils/Utils";

import "./EmployeeInvoiceLineItemDiff.css";

interface Props {
  lineDiff: LineDifference;
}

export default function EmployeeInvoiceLineItemDiff(props: Props) {
  const previousLineType =
    props.lineDiff.previous?.lineType ?? LineType.FlatRate;

  return (
    <>
      {props.lineDiff.diffType === DifferenceType.Change && (
        <>
          <td
            className="vendorPadding"
            data-testid={
              "lineDiff-Action." + props.lineDiff.name + props.lineDiff.id
            }
          >
            <ArrowReturnRight />
            <div className="changesHeaderDiv">Vendor:&nbsp;</div>
            <div className="changesInlineDiv">Unit(s) Changed from</div>
          </td>
          <td
            className="noPadding"
            data-testid={
              "lineDiff-Units." + props.lineDiff.name + props.lineDiff.id
            }
          >
            <table className="table-borderless">
              <tbody>
                {props.lineDiff.previous?.lineType === LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      {props.lineDiff.previous?.lineData.otherDescription}
                    </td>
                  </tr>
                )}
                {props.lineDiff.previous?.lineType !== LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      {props.lineDiff.previous?.qty +
                        " " +
                        getUnits(previousLineType)}
                    </td>
                  </tr>
                )}
                {props.lineDiff.previous?.lineType ===
                  LineType.PerDayFromToDatesWithMax && (
                  <>
                    <tr>
                      <td className="noPadding">
                        From: {props.lineDiff.previous?.lineData.fromDate}
                      </td>
                    </tr>
                    <tr>
                      <td className="noPadding">
                        To: {props.lineDiff.previous?.lineData.toDate}
                      </td>
                    </tr>
                  </>
                )}

                {props.lineDiff.previous?.lineType === LineType.Other && (
                  <tr>
                    <td className="noPadding">
                      Total:{" "}
                      {currencyFormat(props.lineDiff.previous?.lineTotal)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </>
      )}

      {props.lineDiff.diffType === DifferenceType.Added && (
        <>
          <td
            className="vendorPadding"
            data-testid={
              "lineDiff-Action." + props.lineDiff.name + props.lineDiff.id
            }
          >
            <ArrowReturnRight />
            <div className="changesHeaderDiv">Vendor:&nbsp;</div>
            <div className="changesInlineDiv">Added</div>
          </td>
          <td></td>
        </>
      )}
    </>
  );
}
