import { Col } from "react-bootstrap";
import { UpcScan } from "react-bootstrap-icons";
import "../../containers/TowerInvoiceSummary/TowerInvoiceSummary.css";
import { NoVinReasonText } from "../EmployeeInvoiceSummary/EmployeeSummaryFields";

export default function VehicleInfoDisplayOnly(props: any) {
	return (
		<>
			{props.invoiceDetails.plate !== "" ? (
				<>
					<Col className="summaryLabel" md={4} lg={4}>
						Plate&nbsp;#:
					</Col>
					<Col className="summaryField" md={2} lg={2}>
						{props.invoiceDetails.plate}
					</Col>
				</>
			) : (
				<>
					<Col className="summaryLabel" md={4} lg={4}>
						Reason for No Plate&nbsp;#:
					</Col>
					<Col className="summaryField" md={2} lg={2}>
						{props.invoiceDetails.reasonForNoVehiclePlate}
					</Col>
				</>
			)}
			{props.invoiceDetails.vehicleVIN &&
			props.invoiceDetails.vehicleVIN !== "" ? (
				<>
					<Col className="summaryLabel " md={3} lg={3}>
						VIN:
					</Col>
					<Col className="summaryField" md={3} lg={3}>
						{props.invoiceDetails.vehicleVIN}
						&nbsp;
						<span title="Vin Scanned">
							{props.invoiceDetails.vinScanned ? (
								<UpcScan name="vin Scanner" data-testid="scannerIcon" />
							) : (
								""
							)}
						</span>
					</Col>
				</>
			) : (
				<>
					<Col className="summaryLabel" md={3} lg={3}>
						No VIN Reason:
					</Col>
					<Col className="summaryField" md={3} lg={3}>
						{NoVinReasonText(props)}
					</Col>
				</>
			)}
			<Col className="summaryLabel" md={4} lg={4}>
				Year/Make/Model:
			</Col>
			<Col className="summaryField" md={2} lg={2}>
				{props.invoiceDetails.vehicleYear} {props.invoiceDetails.vehicleMake}{" "}
				{props.invoiceDetails.vehicleModel}
			</Col>

			<Col className="summaryLabel" md={3} lg={3}>
				Colour:
			</Col>
			<Col className="summaryField" md={3} lg={3}>
				{props.invoiceDetails.vehicleColour}
			</Col>
			<Col className="summaryLabel" md={4} lg={4}>
				Driveable:
			</Col>
			<Col className="summaryField" md={2} lg={2}>
				{props.invoiceDetails.driveable === "Y" ? "Yes" : "No"}
			</Col>
			<Col className="summaryLabel" md={3} lg={3}>
				Have keys:
			</Col>
			<Col className="summaryField" md={3} lg={3}>
				{props.invoiceDetails.keys === "Y" ? "Yes" : "No"}
			</Col>
		</>
	);
}
