export function validateClaimNumber(claimNumber: string): string {
  if (!claimNumber || claimNumber === "") {
    return "";
  }

  if (claimNumber.length !== 7) {
    return "SGI Claim Number must be 7 numeric characters";
  }

  if (!/^([0-9]{1,7})$/.test(claimNumber)) {
    return "SGI Claim Number must only contain numeric characters";
  }

  return "";
}
