import { ReasonForNoVehiclePlate, ReasonForNoVIN } from "../../types/Invoice";

export function validateVin(VinToValidate: string) {
  if (VinToValidate.trim().length === 0) {
    return "VIN cannot be blank";
  }

  if (VinToValidate.trim().length > 17) {
    return "VIN must not be more than 17 characters";
  }

  if (VinToValidate.trim().length < 11) {
    return "VIN must not be less than 11 characters";
  }

  if (!/^([a-zA-Z0-9]{1,17})$/.test(VinToValidate.trim())) {
    return "VIN can only contain letters and numbers";
  }

  if (/([iIoOqQ]{1,17})/.test(VinToValidate.trim())) {
    return "VIN cannot contain the letters i, o, q, I, O, Q";
  }

  return "";
}

export function validatePlate(plateToValidate: string) {
  if (
    //Plate requirements - can only start/end with alphanumeric, ' ' and '-' allowed in between
    /^[a-zA-Z0-9]([a-zA-Z0-9- ]*[a-zA-Z0-9])?$/.test(plateToValidate.trim()) &&
    plateToValidate.length <= 7
  )
    return "";
  else return "Invalid Vehicle Plate";
}

export function validateVehicleYear(vehicleYearToValidate: string) {
  if (!vehicleYearToValidate || vehicleYearToValidate.trim() === "") {
    return "";
  }
  if (vehicleYearToValidate.length !== 4) {
    return "Invalid year";
  }
  return "";
}
export function validateVehicleMake(vehicleMakeToValidate: string) {
  if (!vehicleMakeToValidate || vehicleMakeToValidate.trim() === "") {
    return "Make cannot be blank";
  }

  if (!/^([ a-zA-Z0-9-()/.]*)$/.test(vehicleMakeToValidate.trim())) {
    return "Make can only contain letters, numbers, and some special characters";
  }

  return "";
}

export function validateVehicleModel(vehicleModelToValidate: string) {
  if (!vehicleModelToValidate || vehicleModelToValidate.trim() === "") {
    return "Model cannot be blank";
  }

  if (!/^([ a-zA-Z0-9-()/.]*)$/.test(vehicleModelToValidate.trim())) {
    return "Model can only contain letters, numbers, and some special characters";
  }

  return "";
}

export function validateVehicleColour(vehicleColourToValidate: string) {
  if (!/^([ a-zA-Z]*)$/.test(vehicleColourToValidate.trim())) {
    return "Colour can only contain letters";
  }

  return "";
}

export function validateNoVINReasonDetails(
  noVINReasonDetailsToValidate: string,
  reasonForNoVIN: string
) {
  if (
    reasonForNoVIN === "Other" &&
    noVINReasonDetailsToValidate.trim() === ""
  ) {
    return "Reason Details must be entered";
  }

  if (reasonForNoVIN !== "Other" && noVINReasonDetailsToValidate === "") {
    return "";
  }

  if (
    !/^[ 0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.#'-]+$/.test(
      noVINReasonDetailsToValidate.trim()
    )
  ) {
    return "The Reason Details you entered contains invalid characters";
  }

  return "";
}
export function reasonForNoVINValidator(reason: ReasonForNoVIN): string {
  if (reason === "") {
    return "A reason for No VIN must be provided";
  }

  return "";
}
export function reasonForNoVehiclePlateValidator(
  reason: ReasonForNoVehiclePlate
): string {
  if (reason === "") {
    return "A reason for No Plate must be provided";
  }

  return "";
}
