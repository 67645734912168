import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { getAccessToken } from "../utils/auth/authApiUtils";

export async function getScanditLicenseKey() {
	const baseUrl = getBaseApiUrl();
	const token = await getAccessToken();

	return axios
		.get(baseUrl + "Scandit/scanditkey", {
			headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
		})
		.then(handleResponse)
		.catch(handleError);
}
