import React from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./SubmitInvoiceConfirmationPage.css";

export function SubmitInvoiceConfirmationPage(props: any) {
  const history = useHistory();

  return (
    <Container
      className="SubmitInvoiceConfirmationContainer"
      id="SubmitInvoiceConfirmationContainer"
    >
      <div className="SubmitInvoiceConfirmationHeading">
        Your invoice has been submitted
      </div>
      <br />
      <div
        className="SubmitInvoiceConfirmationMessage"
        data-testid="ConfirmationNumber"
      >
        Your confirmation number is: {props.confirmationNumber?.substring(0, 8)}
      </div>
      <div className="SubmitInvoiceDiv">
        <div>
          <Button
            variant="outline-primary"
            id="SubmitInvoiceGotoInvoiceDetailsButton"
            data-testid="SubmitInvoiceGotoInvoiceDetailsButton"
            onClick={() => history.push(`/Invoices/TowerInvoiceSummary/${props.confirmationNumber}`)}
          >
            Go to Invoice Details
          </Button>
        </div>
        <div>
          <Button
            variant="outline-primary"
            id="SubmitInvoiceConfirmationButton"
            data-testid="SubmitInvoiceConfirmationButton"
            onClick={() => history.push("/Invoices/List")}
          >
            Ok
          </Button>
        </div>
      </div>
    </Container>
  );
}
