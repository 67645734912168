import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { ModalFeedback } from "../Modals/ModalFeedback";
import "./Footer.css";
import { useTypedSelector } from "../../redux/store";
import { ToastSuccess } from "../Toast/ToastSuccess";
import { Nav, Navbar } from "react-bootstrap";
import { GetUserPermissions } from "../../utils/auth/authUtilities";

function isAuthenticated(login: any): boolean {
	return login.signedIn;
}

export function Footer() {
	const [modalShow, setModalShow] = useState(false);
	const reduxLogin = useTypedSelector((state) => state.login);
	const [, setLoggedIn] = useState(false);
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	const emptyStringArray: string[] = [];
	const [successMessages, setSuccessMessages] = useState(emptyStringArray);
	const [footerClass, setFooterClass] = useState("footer py-0 my-0");
	const permissions = GetUserPermissions();

	useEffect(() => {
		async function updateLoginStatus() {
			setEmail(reduxLogin.email);
			setName(reduxLogin.fullName);
			if (isAuthenticated(reduxLogin) === true) {
				setLoggedIn(true);
				setFooterClass("footer py-0 my-0");
			} else {
				setLoggedIn(false);
				setFooterClass("footerNotLoggedIn py-0 my-0");
			}
		}
		updateLoginStatus();
	}, [reduxLogin, reduxLogin.username]);

	useEffect(() => {
		if (toastMessage !== "") {
			let success = [];
			success.push(toastMessage);
			setSuccessMessages([...success]);
			setToastMessage("");
		}
	}, [toastMessage]);

	return (
		<>
			<ToastSuccess
				title="Feedback Sent"
				setMessages={setSuccessMessages}
				messages={successMessages}
			/>

			<Navbar expand="lg" bg="light" className={footerClass}>
				<Container>
					<div className="footerNavBar" data-testid="footerNavBarTest">
						<Nav>
							<Navbar.Text className="py-2">
								&copy; {new Date().getFullYear()} SGI. All rights reserved.
							</Navbar.Text>
						</Nav>
					</div>
					<div className="footerRightDiv">
						<Nav className="mr-4 py-my-lg-0">
							<Nav.Link href="https://www.sgi.sk.ca/privacy" target="_blank">
								<u>Privacy</u>
							</Nav.Link>
						</Nav>
						{permissions?.canSubmitFeedback && (
							<Nav className="mr-4 py-my-lg-0">
								<div
									className="navbar-text"
									style={{ cursor: "pointer" }}
									onClick={(e) => {
										setModalShow(true);
									}}
								>
									<u>Feedback</u>
								</div>
								<ModalFeedback
									show={modalShow}
									onHide={setModalShow}
									toastMessage={setToastMessage}
									email={email}
									name={name}
								/>
							</Nav>
						)}

						<div
							className="footerBreak my-0 py-0"
							data-testid="footerBreakTest"
						></div>
					</div>
				</Container>
			</Navbar>
		</>
	);
}
