import axios from "axios";
import { getAccessToken } from "../utils/auth/authApiUtils";
import { getBaseApiUrl } from "./apiHelper";
import { handleError, handleResponse } from "./apiUtils";

export async function getInvoicePdf(invoiceId: string) {
  const baseUrl =
    getBaseApiUrl() + `InvoicePdf/GetInvoicePdf?invoiceId=` + invoiceId;

  const token = await getAccessToken();

  return axios
    .get(baseUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
