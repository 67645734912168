import { emptyCompoundLocation } from "../../types/Account";

const initialState = {
  signedIn: false,
  username: "",
  vendorId: "",
  organizationId: "",
  loading: false,
  fullName: "",
  payeeNumber: "",
  email: "",
  companyName: "",
  compoundLocation: emptyCompoundLocation,
  authUserId: "",
};

const loginReducer = (
  state = initialState,
  action: { type: any; mockUser?: any }
) => {
  let newState = { ...state };
  switch (action.type) {
    case "USER_LOGIN": {
      newState.signedIn = true;
      newState.username = action.mockUser.username;
      newState.vendorId = action.mockUser.vendorId;
      newState.organizationId = action.mockUser.organizationId;
      newState.fullName = action.mockUser.fullName;
      newState.payeeNumber = action.mockUser.payeeNumber;
      newState.email = action.mockUser.email;
      newState.companyName = action.mockUser.companyName;
      newState.compoundLocation = action.mockUser.compoundLocation;
      newState.authUserId = action.mockUser.authUserId;
      return newState;
    }
    case "USER_LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};

export default loginReducer;
