import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { getFeedbacks } from "../../api/apiFeedback";
import { PageSpinner } from "../../components/PageSpinner/PageSpinner";
import { useTypedSelector } from "../../redux/store";
import { defaultFeedbackList } from "../../types/FeedbackList";
import { feedbackListTransformer } from "../../utils/FeedbackListTransformer";
import { sortByDate, sortByStringThenDate } from "../../utils/Utils";
import FeedbackListLineItem from "../../components/FeedbackListLineItem/FeedbackListLineItem";
import { CSVLink } from "react-csv";
import { getNewSortOrderForFeedbackList } from "../../utils/FeedbackSorter";
import { ApiUnavailable } from "../../components/Alerts/ApiUnavailable";

export default function EmployeeFeedbackList() {
  const login = useTypedSelector((state) => state.login);
  const [loading, setLoading] = useState(true);
  const [feedbackList, setFeedbackList] = useState(defaultFeedbackList);
  const [currentSortColumn, setCurrentSortColumn] = useState("");
  const [sortClassName, setSortClassName] = useState("th-sort-asc");
  const [apiUnavailable, setApiUnavailable] = useState(false);

  const loadFeedbacks = useCallback(() => {
    var loadSortColumn = "submittedDate";
    var loadSortClass = "dsc";
    if (loadSortColumn !== "") {
      getFeedbacks().then((result) => {
        if (result !== undefined) {
          var mapData = feedbackListTransformer(result.data);

          if (loadSortColumn === "submittedDate") {
            mapData.sort(sortByDate(loadSortColumn, loadSortClass));
          } else {
            mapData.sort(sortByStringThenDate(loadSortColumn, loadSortClass));
          }
          setCurrentSortColumn(loadSortColumn);
          setSortClassName("th-sort-" + loadSortClass);

          if (Array.isArray(result?.data)) {
            setLoading(false);
            setFeedbackList(mapData);
          } else {
            setFeedbackList(defaultFeedbackList);
          }
        } else {
          setApiUnavailable(true);
          setLoading(false);
          setFeedbackList(defaultFeedbackList);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (login.username !== "") {
      loadFeedbacks();
    } else {
      setFeedbackList(defaultFeedbackList);
    }
  }, [loadFeedbacks, login]);

  function sortFeedbackList(sortByColumn: any) {
    var { newArray, sortAttribute } = getNewSortOrderForFeedbackList(
      sortByColumn,
      sortClassName,
      feedbackList,
      currentSortColumn,
      setSortClassName
    );
    setFeedbackList(newArray);
    setCurrentSortColumn(sortAttribute);
  }

  function handleTableHeaderClick(e: any) {
    sortFeedbackList(e.target.id);
  }

  if (apiUnavailable) {
    return <ApiUnavailable />;
  } else
    return (
      <>
        {loading ? (
          <PageSpinner />
        ) : (
          <Container className="feedbackListContainer">
            <Row>
              <Col lg="10">
                <div className="Title">
                  <h3>Feedback List </h3>
                </div>
              </Col>
              <Col lg="2">
                <CSVLink
                  data={feedbackList.map((line: any, key: any) => {
                    return line;
                  })}
                  filename={"feedback.csv"}
                  className="btn btn-outline-primary"
                  target="_blank"
                  data-testid="ExportCSV"
                >
                  Export CSV
                </CSVLink>
              </Col>
            </Row>
            <Table className="table-sortable" striped bordered hover>
              <thead data-testid="FeedbackListLineHeader">
                <tr>
                  <th
                    className={
                      "feedbackType" === currentSortColumn
                        ? sortClassName
                        : "th-sort-none"
                    }
                    scope="col"
                    id="sort-feedbackType"
                    data-testid="test-feedbackTypeHeader"
                    onClick={handleTableHeaderClick}
                  >
                    Feedback Type
                  </th>
                  <th
                    className={
                      "content" === currentSortColumn
                        ? sortClassName
                        : "th-sort-none"
                    }
                    scope="col"
                    id="sort-content"
                    data-testid="test-contentHeader"
                    onClick={handleTableHeaderClick}
                  >
                    Content
                  </th>
                  <th
                    className={
                      "name" === currentSortColumn
                        ? sortClassName
                        : "th-sort-none"
                    }
                    scope="col"
                    id="sort-name"
                    data-testid="test-nameHeader"
                    onClick={handleTableHeaderClick}
                  >
                    Name
                  </th>
                  <th
                    className={
                      "email" === currentSortColumn
                        ? sortClassName
                        : "th-sort-none"
                    }
                    scope="col"
                    id="sort-email"
                    data-testid="test-emailHeader"
                    onClick={handleTableHeaderClick}
                  >
                    Email
                  </th>
                  <th
                    className={
                      "submittedDate" === currentSortColumn
                        ? sortClassName
                        : "th-sort-none"
                    }
                    scope="col"
                    id="sort-submittedDate"
                    data-testid="test-submittedDateHeader"
                    onClick={handleTableHeaderClick}
                  >
                    Date Submitted
                  </th>
                </tr>
              </thead>
              <tbody>
                {feedbackList.map((line: any, key: any) => {
                  return <FeedbackListLineItem lineItem={line} key={key} />;
                })}
              </tbody>
            </Table>
          </Container>
        )}
      </>
    );
}
